import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
import * as palabrasAlertasAction from "../../actions/palabrasAlertasAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
//import {URL} from '../../../src/General/url'
/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */
export class palabrasAlerta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permisosArray: [],
      id_lugar: "",
      nombre_lugar: "",
      nombre_permiso: "",
    };
  }

  async componentDidMount() {
    const { consultar_correos } = this.props;

    await consultar_correos();
  }
  componentDidUpdate() {
    const { consultar_correos } = this.props;
    console.log(this.state);
    if(!this.props.correos_consulta){
      consultar_correos()
    }
  }

  componentWillUnmount() {
    this.props.researchtrue();
  }

  handleSubmit(e) {
    e.preventDefault();
  }



 

  deletePermiso(id) {
    const { eliminarEmailReport } = this.props;
   
    eliminarEmailReport(id)
  }


  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  ListaPermisosBaseDatos = () => {
    const { ListaPermisos } = this.props;
    const ponerPermisosBaseDatos = () =>
    ListaPermisos.map((permiso, key) => (
      <div  className="col-md-2  palabras__claves__div" style={{textAlign:'right',margin:'5px'}} >
      {permiso.nombre}
      <MdDeleteForever size={"32px"}     onClick={(e) => {
        this.deletePermiso(permiso.id);
      }}/>
    </div>
      ));

    return ponerPermisosBaseDatos();
  };
  
  crearPermiso = (e) => {
    e.preventDefault();

    const {
      crear_permiso_extension,
    } = this.props;
    
    const data = {
      nombre: this.state.nombre_permiso,
    };

    crear_permiso_extension(data);
  };

  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {


    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">PALABRAS CLAVES</h1>

          <div className="reporte__div__principal__permisos">
            {this.mostrarAccion()}
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 mx-auto">
                  <div className="row">
                    <div
                      className="col-md-6 mx-auto"
                      style={{ display: "flex",paddingTop: "20px" }}
                    >
                      <div style={{ width: "100%",textAlign:"center" }}>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="Palabra clave"
                          onChange={(e) => {
                            this.setState({ nombre_permiso: e.target.value });
                          }}
                        />
                      
                        <button className="invetario__btn" onClick={this.crearPermiso}>
                  Agregar Palabra
                </button>
                      </div>

                    </div>
                  </div>
        
                </div>
            
                
              </div>

           
            </div>
          </div>
          <div className="row reporte__div__secundario__permisos" >  
     
                <div className="col-md-10 mx-auto" style={{textAlign:'center'}}>
                <span className="pageTitulos">PALABRAS CLAVE CREADAS</span>
                <div style={{display:'flex'}}>
                <div className='row'>
                {this.ListaPermisosBaseDatos()}
                  </div> 
          
                </div>
                </div>

          </div>
       
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (reducers) => {
  return reducers.palabrasAlertasReducer;
};

export default connect(mapStateToProps, palabrasAlertasAction)(palabrasAlerta);
