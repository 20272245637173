import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as crearaction from "../../actions/CrearAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import btnAnteriorImg from "../../img/btnAnteriorImg.png";
import btnSiguienteImg from "../../img/btnSiguienteImg.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import { createBrowserHistory } from 'history';
import {URL,CORREO_STUDIO} from '../../../src/General/url'
/* import {} from '../../General/url'
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */
const history = createBrowserHistory();
export class AgregarCorreoModelo extends Component {


  state = {
    dateStart: new Date(),
    dateEnd: new Date(),
  }

  componentDidMount() {

    const { restaurar_cambio } = this.props;
    restaurar_cambio()
  }

  componentDidUpdate() {
    if(this.props.estadoFlag){
      this.props.history.push('/AgregarFirmaModelo')
      console.log(this.props)
    }
  }

  componentWillUnmount() {

  }

  handleSubmit(e) {
    e.preventDefault();
  }


  handleChange(dataType, value) {
    this.props.handleChange(dataType, value)
  }

  handleNextStep(e) {
    e.preventDefault()
    const { validar_creacion_modelos } = this.props;
    
    const datos={
      tipo:'informacioncorreomodelo',
      dato:this.props.correo
    }
    validar_creacion_modelos(datos)
      }
      deshabilitar = () => {
        const {
          correo,
          passwordCorreo
        } = this.props;
    
        if (
          !correo ||
          !passwordCorreo 
        ) {
         
          return true
        }
    
        return false
        
      };

  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  listaLugares = () => {
    const { lugares } = this.props;

    const ponerOpcionesLugares = () =>
      lugares.map((lugar, key) => (
        <option className='option-values' key={key} value={lugar.id}>
          {this.MaysPrimera(lugar.nombre.toLowerCase())}{}
        </option>
      ));

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        onChange={this.cambioLugarId}

        required
      >
        <option className='option-values' >
          Lugar
        </option>
        {ponerOpcionesLugares()}
      </select>
    );
  };



  listaTurnos = () => {
    const { turnos } = this.props;

    const ponerOpcionesTurnos = () =>
      turnos.map((turnos, key) => (
        <option className='option-values' key={key} value={turnos.id}>
          {this.MaysPrimera(turnos.nombre.toLowerCase())}{}
        </option>
      ));

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        onChange={this.cambioTurnoId}
        required


      >
        <option className='option-values'>
          Turno
        </option>
        {ponerOpcionesTurnos()}
      </select>
    );
  };

  back = (event) =>{
    event.preventDefault()
    history.goBack()
  }
  ponerInformacionModelo = () => {

   
    const ponerInfo = () => (
      <Fragment >
        <div className="col-md-3 ">

          <img
            className="avatarModeloimg"
            alt="imgagerfil"
            src={`${URL}getImage/${
              this.props.foto_perfil
                ? this.props.foto_perfil
                : "defaultLogo.png"
              }`}
          />
        
          <input

            className="form-element-field -hasvalue"
            id={`foto_perfil_modelo`}
            type="file"
            onChange={this.cambioFotoPerfil}
          />

        </div>

        <div className="col-md-4 ">
          <div className='informacion_personal_modificar_info_modelo'>
            <div className='div__personal__modificar__info__correo'>
              <div className='text__rigth'>Correo  </div>   
            <input
              type="text"
              className='input__modificiar_informacion_modelo'
              value={this.props.correo}
              onChange={(e) => this.handleChange('CAMBIO_CUENTA_CORREO', e.target.value)}
            />
            <div className='text__rigth'>{CORREO_STUDIO}  </div>   </div>

          </div>
        </div>

        <div className="col-md-4 ">
          <div className='informacion_personal_modificar_info_modelo'>
          <div className='div__personal__modificar__info__derecha'><div className='text__rigth'>Contraseña </div> <input
              type="text"
              className='input__modificiar_informacion_modelo'
              value={this.props.passwordCorreo}
              onChange={(e) => this.handleChange('CAMBIO_CUENTA_PASSWORDCORREO', e.target.value)}
            /></div>

        


          </div>

   
        </div>

      </Fragment>
    )

    return (ponerInfo())
  }





  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {

    console.log(this.props);
    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            PERFIL <strong className="tituloPaginasPequeño">AGREGAR</strong>
          </h1>
          <div className='Paso__paso__div'>
            <h2>PASO</h2>
            <div className="circuloPasoPaso">
              <h2>3</h2>
            </div>
            <h2>CORREO</h2>
          </div>

          <div className="reporte__div__principal">

            <div className="container-fluid">
              <div className="row">

                {this.ponerInformacionModelo()}


              </div>



            </div>
          </div>
          {this.mostrarAccion()}
          <div className="paso__paso__div__control">
         <div className="paso__paso__div__central">
         <div className="circuloPasoPaso__control">
              <h2>1</h2>
            </div>
         
          <div className="circuloPasoPaso__control">
              <h2>2</h2>
            </div>
            <img src={btnAnteriorImg}  onClick={e =>this.back(e)} alt="Click aquí para ir al siguiente paso" title="Click aquí para ir al siguiente paso" />

            <div className="circuloPasoPaso__control">
              <h2>3</h2>
            </div>
            <button
            type='button'
            disabled={this.deshabilitar()}
            className='boton__control__paso__paso'
            onClick={e =>this.handleNextStep(e)}>
            <img src={btnSiguienteImg}  style={this.deshabilitar()?{opacity:'30%'}:{opacity:'100%'}} alt="Click aquí para subir tu foto" title="Click aquí para ir al anterior paso" />
            </button>
            <div className="circuloPasoPaso__control">
              <h2>4</h2>
            </div>
          </div>
          </div>

        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.crearReducer;
};

export default connect(mapStateToProps, crearaction)(AgregarCorreoModelo);
