import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as reporteTotalAction from '../../actions/ReporteTotalAction'
import Spinner from '../../General/Spinner'
import Fatal from '../../General/Fatal'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles.scss'
import Success from '../../General/Success'
import { Redirect } from 'react-router-dom'
import moment from 'moment'

/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */









export class ReporteTotalDeudas extends Component {

    state = {
        dateStart: new Date(),
        dateEnd: new Date(),
    }

    componentDidMount() {

        this.cambiofecha1(this.state.dateStart)
        this.cambiofecha2(this.state.dateEnd)


    }


    componentDidUpdate(prevState) {
        console.log(this.props)


    }

    componentWillUnmount() {

        const {
            researchtrue,
        } = this.props

        researchtrue()
    }


   
    filtrReporteTotal = () => {

        const {
           consultar_deuda_modelo,

        } = this.props

        consultar_deuda_modelo()



    }

    handleSubmit(e) {
        e.preventDefault()
    }


  
    cambiofecha1 = date => {
        this.setState({ dateStart: date })
      
        this.props.cambiofecha1((moment(date).format('YYYY/MM/DD') ));
    };

    cambiofecha2 = date => {
        this.setState({ dateEnd: date })
        var fechaFinalString = ''
        if (date) {
            fechaFinalString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        } else {
            fechaFinalString = ''
        }
        this.props.cambiofecha2(fechaFinalString);
    };

    descargarExcelModelosReportTotal=(e)=>{
        e.preventDefault();
         this.props.descargarExcelModelosReportTotalDeudas()
      }
      
    ponerReporte = () => {
        const { reporteTotalDeuda,

        } = this.props
        const ponerInfo = () => reporteTotalDeuda.map((reporte, key) => (


            <tr key={key}>
                <td>{reporte.cedula}</td>
                <td>{reporte.nombres} {reporte.apellidos}</td>
                <td>{new Intl.NumberFormat().format(reporte.cantidad)}</td>
                
      

            </tr>

        ))

        return (ponerInfo())
    }



    mostrarAccion = () => {


        const { error, cargando, success } = this.props
        if (cargando) {
            return <Spinner />;
        }


        if (success) {
            return <Success mensaje={success} />;
        }


        if (error) {
            return <Fatal mensaje={error} />;
        }
        return false;
    }




    render() {



        return (



            <Fragment>
                {(!window.localStorage.getItem('token')) ? <Redirect to='/' /> : ''}
                <div className='main-content fade-in'>
                <h1 className="tituloPaginas">
                REPORTES <strong className="tituloPaginasPequeño">TOTAL DEUDA MODELOS</strong>
          </h1>
                    <div className='reporte__div__principal'>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 ">
                                    {this.mostrarAccion()}
                                </div>

                                <div className="col-md-2 center__reportes__botones">
                                    <div className="btn__reportes_div">
                                        <button className="btn__reportes_page" type="submit"
                                            onClick={this.filtrReporteTotal} >Buscar deudas</button>
                                    </div>
                                    <div className="btn__reportes_div">
                                        <button className="btn__reportes_page" type="submit"
                                            onClick={this.descargarExcelModelosReportTotal} >Descargar Excel</button>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                    <div className=''>
                        <div className="container-fluid">
                            <div className="row">
                           
                                <div className="col-md-12 ">

                                    <table  className="table table-striped tabla-global" >
                                        <thead>
                                            <tr>
                                                <th>CEDULA </th>
                                                <th>NOMBRE</th>
                                                <th>TOTAL DEUDA HASTA LA FECHA</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.ponerReporte()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = (reducers) => {
    return reducers.ReporteTotalReducer
}

export default connect(mapStateToProps, reporteTotalAction)(ReporteTotalDeudas)