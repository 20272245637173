import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import "../css/menu.scss";
import {
  IoIosMenu
} from "react-icons/io";
import { AiFillBook } from "react-icons/ai";
import {  MdCameraAlt, MdEmail } from "react-icons/md";
import {
  FaMoneyCheck,
  FaHome,
  FaFontAwesomeFlag,
  FaCalendarAlt,
  FaMoneyCheckAlt,
  FaKey
} from "react-icons/fa";
import { connect } from "react-redux";
import * as tareasaction from "../actions/TareasAction";
import * as loginAction from "../actions/LoginAction";
import {URL} from '../../src/General/url'
export class Menu extends Component {
  componentDidMount() {
    this.menuJs()
    this.props.consultarPermisos();
    this.props.getUser(JSON.parse(window.localStorage.getItem("userData")));
  }

  toggleMenu(event) {
    document
      .getElementsByClassName("navbar-primary")[0]
      .classList.toggle("collapsed");
      document.getElementsByClassName("navbar-primary-bg")[0]
      .classList.toggle("collapsed");
  }

 menuJs(){
  var mClass = '.sub-toggle'
  var menu = document.querySelectorAll(mClass);
   function removeClass(e, c) {
    var elm = document.querySelectorAll(mClass);
    for (var i = 0; i < elm.length; i++) {
      if (c === 'active') {
        elm[i].classList.remove('active');
      } else {
        elm[i].querySelector('.list').classList.remove('show');
      }
    }
  } 


  menu.forEach(function(o) {
    
    o.addEventListener('click', function(e) {
      
      removeClass(o, 'active');
      this.classList.add('active');
      removeClass(o);
      this.querySelector('.list').classList.toggle('show');
    });

    
  }); 
 }


  logOut=(event)=>{
event.preventDefault()
this.props.logOut();
  }


  

  ponerMenu = () => {

    return (
      <Fragment>

        <nav className="navbar  fixed-top navbar-inverse navbar-global navbar-fixed-top">
          <div className="container-fluid">
            <div className="navbar-header">
              {/*    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>  */}
            </div>
            <div id="navbard" className="">
              <ul className="nav navbar-nav navbar-user navbar-right">
                <li>
                  <Link to="#"
                  onClick={(e) => this.logOut( e)}
                  >
                    <span className="glyphicon glyphicon-log-out" 
                      ></span> Logout
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <nav className="navbar-primary collapsed">
          <ul className="navbar-primary-menu">
            <div onClick={e => this.toggleMenu()} className="navbar-primary-bg collapsed">

            </div>
            <li className="nav-label">
              <img
                alt="menuLogo"
                className="profil_img"
                src={`${URL}getImage/${this.props.TareasReducer.perfil_img}`}
              />
            </li>
            <li className="nav-label">
              <span className="welcometext">
                {" "}
                {`¡Bienvenido, ${
                  this.props.TareasReducer.username
                }!`}
              </span>
              <span className="welcometext"> Staff</span>
            </li>
            <li
              className="btn-expand-collapse"
              onClick={e => this.toggleMenu()}
            >
              <span>
                <IoIosMenu size={"16px"} className="icon_color" />
              </span>
            </li>
            <li>
              <h1 className="menuSeparator">
                <span className="nav-label">MENÚ</span>
              </h1>
              <Link to="/Home">
                <span>
                  <FaHome size={"16px"} className="icon_color" />
                </span>
                <span className="nav-label">Inicio</span>
              </Link>
              <Link to="/miPerfil">
                <span>
                  <FaFontAwesomeFlag size={"16px"} className="icon_color" />
                </span>
                <span className="nav-label">Mi cuenta</span>
              </Link>

              {this.props.TareasReducer.permisosM.calendario?
              <Link to="/Calendario">
                <span>
                  <FaCalendarAlt size={"16px"} className="icon_color" />
                </span>
                <span className="nav-label">Calendario</span>
              </Link>:''
  }
              <h1 className="menuSeparator">
                <span className="nav-label">FINANZAS</span>
              </h1>

              <div className="sub-toggle">
                <Link to="#">
                  <span>
                    <FaMoneyCheck size={"16px"} className="icon_color" />
                  </span>
                  <span className="nav-label">Reportes</span>
                </Link>
                <ul className="list">

                {this.props.TareasReducer.permisosM.reportesVer?
                  <li className=" nav-label submenu__items">
                    <Link to="/Reportes">Ver</Link>
                  </li>:''}
                  {this.props.TareasReducer.permisosM.reportesDia?
                  <li className=" nav-label submenu__items">
                    <Link to="/ReportesModelosDia">Dia</Link>
                  </li>:''}
                  
                  {this.props.TareasReducer.permisosM.reportesModelos?
                  <li className=" nav-label submenu__items">
                    <Link to="/ReportesModelos">Modelos</Link>
                  </li>:''}
              
                  {this.props.TareasReducer.permisosM.reportesPagos?
                  <li className=" nav-label submenu__items">
                    <Link to="/ReporteModelosPagos">Pagos</Link>
                  </li>:''}
                {/*   {this.props.TareasReducer.permisosM.reportesPagos?
                  <li className=" nav-label submenu__items">
                    <Link to="/ReporteModelosPagos">ReportesGananciaLugares</Link>
                  </li>:''} */}

               {/*    <li className=" nav-label submenu__items">
                    <Link to="/ReportesGananciaLugares">Reportes Lugares</Link>
                  </li> */}
                  {this.props.TareasReducer.permisosM.reportesDiario?
                  <li className=" nav-label submenu__items">
                    <Link to="/ReportesDiariosActionStudio">Diario</Link>
                  </li>:''}
                  {this.props.TareasReducer.permisosM.reportesTotal?
                  <li className=" nav-label submenu__items">
                    <Link to="/ReporteTotal">Total</Link>
                  </li>:''}
                  {this.props.TareasReducer.permisosM.deuda_modelos?
                      <li className=" nav-label submenu__items">
                      <Link to="/ReporteTotalDeudaModelo">Total Deudas Modelos</Link>
                    </li>:''}
                  {this.props.TareasReducer.permisosM.reportesTotalSinDescuento?
                  <li className=" nav-label submenu__items">
                    <Link to="/ReporteTotalSinDeducciones">Total sin descuento</Link>
                  </li>:''}
                  {this.props.TareasReducer.permisosM.reportesRTE?
                  <li className=" nav-label submenu__items">
                    <Link to="/ReporteRetencion">RTE</Link>
                  </li>:''}
                </ul>
              </div>
             
              
              <div className="sub-toggle">
              {this.props.TareasReducer.permisosM.modelosPrestamosPequenos 
              || this.props.TareasReducer.permisosM.modelosPrestamosGrande?
              <Link to="#">
                <span>
                  <FaMoneyCheck size={"16px"} className="icon_color" />
                </span>
                <span className="nav-label">Préstamos</span>
              </Link>
              :''}
              <ul className="list">
              {this.props.TareasReducer.permisosM.modelosPrestamosPequenos?
              <li className=" nav-label submenu__items">
                  <Link to="/Prestamopequeno">Pequeños</Link>
                </li>:''}
                {this.props.TareasReducer.permisosM.modelosPrestamosGrande?
                <li className=" nav-label submenu__items">
                  <Link to="/Prestamogrande">Grandes</Link>
                </li>:''}
           
              </ul>
              
            </div>

            {this.props.TareasReducer.permisosPagosM &&
            <div className="sub-toggle">
             
            {this.props.TareasReducer.permisosPagosM.nomina_pagos_pago 
            || this.props.TareasReducer.permisosPagosM.nomina_pagos_recibos_modelos
            || this.props.TareasReducer.permisosPagosM.nomina_pagos_recibos_monitores
              || this.props.TareasReducer.permisosPagosM.nomina_pagos_procentaje
              || this.props.TareasReducer.permisosPagosM.nomina_pagos_subir_archivo?
            <Link to="#">
              <span>
                <FaMoneyCheckAlt size={"16px"} className="icon_color" />
              </span>
              <span className="nav-label">Nómina</span>
            </Link>:''}
              <ul className="list">
              {this.props.TareasReducer.permisosPagosM.nomina_pagos_pago?
                <li className=" nav-label submenu__items">
                  <Link to="/Pagos">Pagos</Link>
                </li>:''}
                {this.props.TareasReducer.permisosPagosM.nomina_pagos_recibos_modelos?
                <li className=" nav-label submenu__items">
                  <Link to="/Nomina">Recibos</Link>
                </li>:''}
                {this.props.TareasReducer.permisosPagosM.nomina_pagos_procentaje?
                <li className=" nav-label submenu__items">
                  <Link to="/nominaPorcentaje">Porcentaje</Link>
                </li>:''}
                {this.props.TareasReducer.permisosPagosM.nomina_pagos_subir_archivo?
                <li className=" nav-label submenu__items">
                  <Link to="/archivoPagos">Archivos</Link>
                </li>:''}
              </ul>
            </div>
            }

{this.props.TareasReducer.permisosPagosM &&
            <div className="sub-toggle">
            {this.props.TareasReducer.permisosPagosM.nomina_pagos_recibos_modelos?
            <Link to="#">
              <span>
                <FaMoneyCheckAlt size={"16px"} className="icon_color" />
              </span>
              <span className="nav-label">Gastos</span>
            </Link>:''}
              <ul className="list">
              {this.props.TareasReducer.permisosPagosM.nomina_pagos_recibos_modelos?
                <li className=" nav-label submenu__items">
                  <Link to="/Gastos">Ver gastos</Link>
                </li>:''}
              </ul>
            </div>
            }
       
            <div className="sub-toggle">
            {this.props.TareasReducer.permisosM.modelosMultasAgregar 
            || this.props.TareasReducer.permisosM.modelosMultasReporte
             ?
            <Link to="#">
              <span>
                <FaMoneyCheckAlt size={"16px"} className="icon_color" />
              </span>
              <span className="nav-label">Multas</span>
            </Link>:''}
              <ul className="list">
              {this.props.TareasReducer.permisosM.modelosMultasAgregar?
                <li className=" nav-label submenu__items">
                <Link to="/MultasAgregar">
              <span className="nav-label">Agregar</span>
            </Link>
                </li>:''}
                {this.props.TareasReducer.permisosM.modelosMultasReporte?
                <li className=" nav-label submenu__items">
                  <Link to="/MultaTotal">Reporte</Link>
                </li>:''}
         
              </ul>
              
            </div> 


            {this.props.TareasReducer.permisosM.servicios_reporte?
                <Link to="/SistemaServicios">
                <span>
                  <FaMoneyCheckAlt size={"16px"} className="icon_color" />
                </span>
                <span className="nav-label">Servicios</span>
              </Link>:''}
         
              
              <h1 className="menuSeparator">
                <span className="nav-label">MODELOS</span>
              </h1>

              <div className="sub-toggle">
              {this.props.TareasReducer.permisosM.modelosPerfilVer 
            || this.props.TareasReducer.permisosM.modelosPerfilAgregar
             ?
                <Link to="#">
                  <span>
                    <AiFillBook size={"16px"} className="icon_color" />
                  </span>
                  <span className="nav-label ">Perfil</span>
                </Link>:''}
                <ul className="list">
                {this.props.TareasReducer.permisosM.modelosPerfilVer?
                <li className=" nav-label submenu__items">
                  <Link to="/PerfilVer">
                <span className="nav-label">Ver</span>
              </Link>
                  </li>:''}
                  {this.props.TareasReducer.permisosM.modelosPerfilAgregar?
                  <li className=" nav-label submenu__items"> 
                  <Link to="/AgregarInformacionModelo">
                <span className="nav-label">Agregar</span>
              </Link>
                  </li>:''}
             {/*      <li className=" nav-label submenu__items">
                  <Link to="/MultasAgregar">
                <span className="nav-label">Archivos</span>
              </Link>
                  </li> */}
                </ul>
              </div>
              {this.props.TareasReducer.permisosM.correos?
              <Link to="/CorreosPlataforma">
                <span>
                  <MdEmail size={"16px"} className="icon_color" />
                </span>
                <span className="nav-label">Correo</span>
              </Link>:''}

              <div className="sub-toggle">
              {this.props.TareasReducer.permisosM.habitacionesAgregar 
            || this.props.TareasReducer.permisosM.habitacionesVerReportes
             ?
              <Link to="#">
                <span>
                  <FaKey size={"16px"} className="icon_color" />
                </span>
                <span className="nav-label">Habitaciones</span>
              </Link>:''}
                <ul className="list">
                {this.props.TareasReducer.permisosM.habitacionesAgregar?
                  <li className=" nav-label submenu__items">
                    <Link to="/HabitacionesAgregar">Agregar</Link>
                  </li>:''}
                  {this.props.TareasReducer.permisosM.habitacionesVerTurnos?
                  <li className=" nav-label submenu__items">
                    <Link to="/HabitacionesVerTurnos">Ver turnos</Link>
                  </li>:''}
             {/*      {this.props.TareasReducer.permisosM.habitacionesVerReportes?
                  <li className=" nav-label submenu__items">
                    <Link to="/HabitacionesVerReportes">Ver Reportes</Link>
                  </li>:''} */}
                </ul>
              </div>
              {this.props.TareasReducer.permisosM.camaras?
              <Link to="/Camara">
                <span>
                  <MdCameraAlt size={"16px"} className="icon_color" />
                </span>
                <span className="nav-label">Cámaras</span>
              </Link>:''}


             
              {this.props.TareasReducer.permisosM.monitoresPerfilVer 
            || this.props.TareasReducer.permisosM.monitoresPerfilAgregar
            || this.props.TareasReducer.permisosM.monitoresMultasAgregar
            || this.props.TareasReducer.permisosM.monitoresMultasAgregar
            || this.props.TareasReducer.permisosM.monitoresMultasReporte
             ? 
      <h1 className="menuSeparator">
                <span className="nav-label">MONITORES</span>
              </h1>
:''}


              <div className="sub-toggle">
              {this.props.TareasReducer.permisosM.monitoresPerfilVer 
            || this.props.TareasReducer.permisosM.monitoresPerfilAgregar
             ? 
                <Link to="#">
                  <span>
                    <AiFillBook size={"16px"} className="icon_color" />
                  </span>
                  <span className="nav-label ">Perfil</span>
                </Link>:''}
                <ul className="list">
                {this.props.TareasReducer.permisosM.monitoresPerfilVer?
                <li className=" nav-label submenu__items">
                  <Link to="/PerfilVErMonitores">
                <span className="nav-label">Ver</span>
              </Link>
                  </li>:''}
                  {this.props.TareasReducer.permisosM.monitoresPerfilAgregar?
                <li className=" nav-label submenu__items">
                  
                  <Link to="/AgregarMonitor">
                <span className="nav-label">Agregar</span>
              </Link>
                  </li>:''}
        
          
                </ul>
              </div>
              <div className="sub-toggle">
              {this.props.TareasReducer.permisosM.monitoresPrestamosPequenos 
            || this.props.TareasReducer.permisosM.monitoresPrestamosGrande
            
             ? 
                <Link to="#">
                  <span>
                    <FaMoneyCheck size={"16px"} className="icon_color" />
                  </span>
                  <span className="nav-label">Préstamos</span>
                </Link>:''}
                <ul className="list">
                {this.props.TareasReducer.permisosM.monitoresPrestamosPequenos ?
                <li className=" nav-label submenu__items">
                    <Link to="/PrestamosPequenoMonitores">Pequeños</Link>
                  </li>:''}
                  {this.props.TareasReducer.permisosM.monitoresPrestamosGrande ?
                  <li className=" nav-label submenu__items">
                    <Link to="/PrestamosGrandesMonitores">Grandes</Link>
                  </li>:''}
                  
                </ul>
              </div>
              <div className="sub-toggle">
              {this.props.TareasReducer.permisosM.monitoresMultasAgregar 
            || this.props.TareasReducer.permisosM.monitoresMultasGanancias
            || this.props.TareasReducer.permisosM.monitoresMultasReporte
             ? 
              <Link to="#">
                <span>
                  <FaMoneyCheckAlt size={"16px"} className="icon_color" />
                </span>
                <span className="nav-label">Multas</span>
              </Link>:''}
                <ul className="list">
                {this.props.TareasReducer.permisosM.monitoresMultasAgregar ?
                  <li className=" nav-label submenu__items">
                  <Link to="/MultasAgregarMonitor">
                <span className="nav-label">Agregar</span>
              </Link>
                  </li>:''}
                  {this.props.TareasReducer.permisosM.monitoresMultasGanancias ?
                  <li className=" nav-label submenu__items">
                    <Link to="/MultasgGananciasAgregarMonitor">Ganancia</Link>
                  </li>:''}
                  {this.props.TareasReducer.permisosM.monitoresMultasReporte ?
                  <li className=" nav-label submenu__items">
                    <Link to="/MultaTotalMonitores">Reporte</Link>
                  </li>:''}
           
                </ul>
              </div>

              {this.props.TareasReducer.permisosM.alertasPlataforma 
            || this.props.TareasReducer.permisosM.correo_alertas
            || this.props.TareasReducer.permisosM.palabras_clave
             ? 
      <h1 className="menuSeparator">
                <span className="nav-label">EXTENSIÓN</span>
              </h1>
            :''}


      {this.props.TareasReducer.permisosM.correo_alertas ?
          
          <Link to="/correosReportesExtension">
            <span>
              <AiFillBook size={"16px"} className="icon_color" />
            </span>
            <span className="nav-label ">Correo Alertas</span>
          </Link>
       :''}
          {this.props.TareasReducer.permisosM.palabras_clave ?
          
          <Link to="/palabrasAlertas">
            <span>
              <AiFillBook size={"16px"} className="icon_color" />
            </span>
            <span className="nav-label ">Palabras Claves</span>
          </Link>
       :''}
        {this.props.TareasReducer.permisosM.alertasPlataforma ?
          
          <Link to="/AlertasPlataforma">
            <span>
              <AiFillBook size={"16px"} className="icon_color" />
            </span>
            <span className="nav-label ">Alertas Plataforma</span>
          </Link>
       :''}
              {this.props.TareasReducer.permisosM.tokens 
            || this.props.TareasReducer.permisosM.lugares
            || this.props.TareasReducer.permisosM.Usuarios
             ? 
              <h1 className="menuSeparator">
                <span className="nav-label">ADMIN</span>
              </h1>
:''}

{this.props.TareasReducer.permisosM.monitoresMultasReporte ?
           
                
                <Link to="/tokenAgregar">
                  <span>
                    <AiFillBook size={"16px"} className="icon_color" />
                  </span>
                  <span className="nav-label ">Token</span>
                </Link>
           :''}

              {this.props.TareasReducer.permisosM.monitoresMultasReporte ?
          
                <Link to="/LugarAgregar">
                  <span>
                    <AiFillBook size={"16px"} className="icon_color" />
                  </span>
                  <span className="nav-label ">Sedes</span>
                </Link>
             :''}

          {this.props.TareasReducer.permisosM.servicios_rol?
                  <Link to="/ServiciosAgregar">
                  <span>
                    <AiFillBook size={"16px"} className="icon_color" />
                  </span>
                  <span className="nav-label ">Servicios</span>
                </Link>:''}


      {this.props.TareasReducer.permisosM.correo_reportes ?
          
          <Link to="/correosReportes">
            <span>
              <AiFillBook size={"16px"} className="icon_color" />
            </span>
            <span className="nav-label ">Correos Reportes</span>
          </Link>
       :''}

       {this.props.TareasReducer.permisosM.correo_reportes ?
          
          <Link to="/trm">
            <span>
              <AiFillBook size={"16px"} className="icon_color" />
            </span>
            <span className="nav-label ">Trm y Logo</span>
          </Link>
       :''}
              <div className="sub-toggle">
              { this.props.TareasReducer.permisosM.Usuarios
            || this.props.TareasReducer.permisosM.usuario_permisos?
                <Link to="#">
                  <span>
                    <AiFillBook size={"16px"} className="icon_color" />
                  </span>
                  <span className="nav-label ">Usuarios</span>
                </Link>:''}
                <ul className="list">
                {this.props.TareasReducer.permisosM.Usuarios ?
                <li className=" nav-label submenu__items">
                  <Link to="/PerfilUser">
                <span className="nav-label">Agregar</span>
              </Link>
                  </li>:''}
                  {this.props.TareasReducer.permisosM.usuario_permisos ?
                <li className=" nav-label submenu__items">
                  <Link to="/PermisoUsuarios">
                <span className="nav-label">Permisos</span>
              </Link>
                  </li>:''}
                  {this.props.TareasReducer.permisosM.usuario_permisos ?
                <li className=" nav-label submenu__items">
                  <Link to="/PermisoPagos">
                <span className="nav-label">Permisos Pagos</span>
              </Link>
                  </li>:''}
                  
                </ul>
              </div>
    
       

          
           
        
            </li>
          </ul>
        </nav>

   
      </Fragment>

      
    );
  };

  render() {
   

    return this.ponerMenu();

    
  }
}
const mapStateToProps = ({TareasReducer,LoginReducer})=> {
  return {TareasReducer,LoginReducer}
};

const mapDispatchToProps ={
  ...loginAction,
  ...tareasaction
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
