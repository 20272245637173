export const CONSULTAR = 'archivo_pagos_traer_todas';
export const CARGANDO = 'archivo_pagos_cargando';
export const ERROR = 'archivo_pagos_error';
export const GUARDAR = 'archivo_pagos_guardada';
export const ACTUALIZAR = 'archivo_pagos_actualizar';
export const LIMPIAR = 'archivo_pagos_limpiar';
export const RESEARCHTRUE = 'archivo_pagos_research';
export const SUCCESS = 'archivo_pagos_succes';
export const CAMBIO_FECHAPRESTAMO = 'archivo_pagos_fecha';
export const CAMBIO_CANTIDAD = 'archivo_pagos_cantidad';
export const CAMBIO_CONCEPTO = 'archivo_pagos_concepto';
export const CAMBIO_FECHA1 = 'archivo_pagos_fecha1';
export const CAMBIO_FECHA2 = 'archivo_pagos_fecha2';
export const FILTRAR_PRESTAMOS_MINIMOS = 'archivo_pagos_minimos_filtrar';
export const CAMBIO_LUGARES = 'archivo_pagos_cambio_lugares';
export const CONSULTAR_LUGARES = 'archivo_pagos_consultar_lugares';




