export const CONSULTAR = 'multa_total_traer_todas';
export const CARGANDO = 'multa_total_cargando';
export const ERROR = 'multa_total_error';
export const GUARDAR = 'multa_total_guardada';
export const ACTUALIZAR = 'multa_total_actualizar';
export const LIMPIAR = 'multa_total_limpiar';
export const RESEARCHTRUE = 'multa_total_research';
export const SUCCESS = 'multa_total_succes';
export const CAMBIO_FECHAPRESTAMO = 'multa_total_fecha';
export const CAMBIO_CANTIDAD = 'multa_total_cantidad';
export const CAMBIO_CONCEPTO = 'multa_total_concepto';
export const CAMBIO_FECHA1 = 'multa_total_fecha1';
export const CAMBIO_FECHA2 = 'multa_total_fecha2';
export const FILTRAR_PRESTAMOS_MINIMOS = 'multa_total_minimos_filtrar';
export const CAMBIO_LUGARES = 'multa_cambio_lugares';
export const CONSULTAR_SUMA_TOTAL = 'multa_total_consultar_suma_total';
export const CONSULTAR_ELIMINADAS = 'multa_total_consultar_eliminadas';




