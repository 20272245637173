export const CONSULTAR = 'crear_monitor__traer_todas_agregar_monitor';
export const CARGANDO = 'crear_monitor__cargando_agregar_monitor';
export const ERROR = 'crear_monitor__error_agregar_monitor';
export const GUARDAR = 'crear_monitor__guardada_agregar_monitor';
export const ACTUALIZAR = 'crear_monitor__actualizar_agregar_monitor';
export const LIMPIAR = 'crear_monitor__limpiar_agregar_monitor';
export const RESEARCHTRUE = 'crear_monitor__research_agregar_monitor';
export const SUCCESS = 'crear_monitor__succes_agregar_monitor';
export const CAMBIO_CEDULA = 'crear_monitor__cambio_cedula_agregar_monitor';
export const CAMBIO_NOMBRES = 'crear_monitor__cambio_nombres_agregar_monitor';
export const CAMBIO_APELLIDOS = 'crear_monitor__cambio_arriendo_agregar_monitor';
export const CAMBIO_SUELDO = 'crear_monitor__cambio_lugares_agregar_monitor';
export const CAMBIO_TIPO = 'crear_monitor__cambio_turnos_agregar_monitor';
export const CAMBIO_CUENTA_BANCARIA = 'crear_monitor__cambio_cuentabancaria_contable';
export const CAMBIO_ESTADO_CREAR = 'crear_monitor__estado_creacion_agregar_monitor';
export const CAMBIO_ESTADO_INICIAL = 'crear_monitor__estado_inicial_agregar_monitor';
export const RELOAD = 'crear_monitor__reload_agregar_monitor';
export const IMAGEN_PERFIL = 'crear_monitor_perfil_imagen_perfil_agregar_monitor';




