import {
    CARGANDO, ERROR, GUARDAR, CONSULTAR, CONSULTARS, CONSULTAR_TOTALES, CAMBIO_FECHAPRESTAMO, CAMBIO_CANTIDAD, RESEARCHTRUE, CAMBIO_CONCEPTO
    , SUCCESS, CAMBIO_FECHA1, CAMBIO_FECHA2, CONSULTAR_MULTAS_MODELOS,RESEARPRESTAMO
} from '../types/MultasAgregarTypes'

const INITIAL_STATE = {
    MultasModelo: [],
    multasModelos: [],
    verperstamosModelo: [],
    SuggestionDatabase: [],
    cargando: false,
    error: '',
    cantidad: '',
    fechaprestamo: '',
    fecha1: '',
    fecha2: '',
    modelos_id: '',
    concepto: '',
    research: '',
    researchPrestamo: true,
    success: ''

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTAR:
            return {
                ...state,
                MultasModelo: action.payload,
                cargando: false,
                research: false,
                

            }


        case CONSULTAR_TOTALES:
            return {
                ...state,
                verperstamos: action.payload,
                cargando: false,
                research: false,
                cantidad: 0,
                fechaprestamo: '',
                concepto: '',
               
            }

        case CONSULTARS:
            return {
                ...state,
                SuggestionDatabase: action.payload,

            }
            case RESEARPRESTAMO:
                return {
                    ...state,
                    researchPrestamo: action.payload,
    
                }        

            

        case CONSULTAR_MULTAS_MODELOS:
            return {
                ...state,
                multasModelos: action.payload,
                researchPrestamo: true,
                cargando: false,
                research: false,


            }



        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false, success: '' }


        case SUCCESS:
            return { ...state, success: action.payload, cargando: false, error: '' }


        case GUARDAR:
            return {
                ...state,
                cargando: false,
                error: '',
                research: true,
                success: ''


            }



        case CAMBIO_FECHAPRESTAMO:
            return {
                ...state,
                fechaprestamo: action.payload,

            }

        case CAMBIO_FECHA1:
            return {
                ...state,
                fecha1: action.payload,

            }

        case CAMBIO_FECHA2:
            return {
                ...state,
                fecha2: action.payload,

            }



        case CAMBIO_CANTIDAD:
            return {
                ...state,
                cantidad: action.payload,

            }

        case CAMBIO_CONCEPTO:
            return {
                ...state,
                concepto: action.payload,

            }

        case RESEARCHTRUE
            :
            return {
                ...state,

                prestamos: [],
                verperstamos: [],
                verperstamosModelo:[],
                cargando: false,
                error: '',
                cantidad: '',
                fechaprestamo: '',
                fecha1: '',
                fecha2: '',
                modelos_id: '',
                concepto: '',
                success: '',
                research: '',
                researchPrestamo: true,



            }


        default: return state
    }

}