import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as nominaaction from "../../actions/NominaAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import defaultLogo from "../../img/defaultLogo.png";
import {URL} from '../../../src/General/url'

/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */

export class NominaRecibos extends Component {


  state = {
    dateStart: new Date(),
    dateEnd: new Date(),
  }

  componentDidMount() {

    const {
      match: {
        params: { fecha_id }
      },
      consultar_nomina,
      consultarInfoPlataforma
    } = this.props
    consultar_nomina(fecha_id);
    consultarInfoPlataforma()


  }

  componentDidUpdate() {
    console.log(this.props);


  }

  componentWillUnmount() {
    const { researchtrue } = this.props;

    researchtrue();
  }

  handleSubmit(e) {
    e.preventDefault();
  }


  ponerPagoModeloArriendo = () => {
      const URL_LOGO=`${URL}getImageOriginal/${this.props.logo}`
    const { pagosNominaArriendo,

    } = this.props
    const ponerInfoArriendo = () => pagosNominaArriendo.map((infoModelo, key) => (
      <div className="col-md-6 justify-content-md-center" key={key}>
        <div className='informe__modelo__div__container_pagonomina'>
          <div className='div__one__informe__modelo'>
            <img className='logoReportImg' alt='logoReportPagos' src={this.props.logo?URL_LOGO:defaultLogo}></img>
            <span>{infoModelo.lugar_trabajo} </span>
            <span>{infoModelo.cedula} </span>
            <span>{`${infoModelo.nombres} ${infoModelo.apellidos}`}</span>
            <span>{infoModelo.fecha} </span>
            <span> {infoModelo.cuentabancaria} </span>
          </div>
          <div>
            <table className="table table-striped tabla-global-repore-modelo"  >

              <tbody>
               <tr>
                  <td  colspan="3" ><strong>Ingresos</strong></td>
        
                </tr>

                {infoModelo.suma_chaturbate?
                   <tr>
                   <td>Chaturbate</td>
                   <td>${infoModelo.suma_chaturbate}</td>
                   <td>{infoModelo.suma_chaturbate * 20} - Tokens</td>
                 </tr>:''}
             
                {infoModelo.suma_strip?     <tr>
                  <td>Stripchat</td>
                  <td>${infoModelo.suma_strip}</td>
                  <td>{infoModelo.suma_strip * 20} - Tokens</td>
                </tr>:''}
                {infoModelo.suma_cam4?
                 <tr>
                 <td>Cam4</td>
                 <td>${infoModelo.suma_cam4}</td>
               </tr>:''}
               
               {infoModelo.suma_stremate?
                   <tr>
                   <td>Streamate</td>
                   <td>${infoModelo.suma_stremate}</td>
                 </tr>:''}
            
            {infoModelo.suma_paypal?
               <tr>
               <td>Paypal</td>
               <td>${infoModelo.suma_paypal}</td>
             </tr>:''}
             
             {infoModelo.suma_flirt?
                  <tr>
                  <td>Flirt4Free</td>
                  <td>${infoModelo.suma_flirt}</td>
                </tr>:''}
           
           {infoModelo.suma_jasmin?
             <tr>
             <td>BongaCams</td>
             <td>${infoModelo.suma_jasmin}</td>
           </tr>:''}
              
              
       
                <tr>
                  <td>Total Dólares</td>
                  <td>${infoModelo.suma_total_plataformas}</td>
                </tr>

                {infoModelo.suma_multas?
                
                <tr>
                  <td  colspan="3" ><strong>Descuentos en dolares</strong></td>
                <td>Porcentaje {infoModelo.porcentaje_pago * 100}%</td>
                <td>${infoModelo.dolares_modelo}</td>
              </tr>:''}
              
              <tr>
                <td>Porcentaje {infoModelo.porcentaje_pago * 100}%</td>
                <td>${infoModelo.dolares_modelo}</td>
              </tr>
             
                <tr>
                  <td>Total Pesos</td>
                  <td>${infoModelo.dolares_modelo * infoModelo.dolar}</td>
                </tr>
                

                <tr>
                <td  colspan="3" > <strong>Descuentos en pesos</strong></td>
                </tr>
                             
                <tr>
                  <td>Arriendo</td>
                  <td>$-{infoModelo.arriendo_nomina}</td>
                </tr>

          {infoModelo.suma_prestamos ? <tr>
                  <td colspan="2"><strong>Préstamos</strong></td>
                </tr>:''}
               
                {infoModelo.suma_prestamos ?infoModelo.Prestamo_modelo.map((prestamo_modelo, key) => (
                     <Fragment>
                         <tr>
                <td>{prestamo_modelo.concepto}</td>
                <td>{`${prestamo_modelo.cantidad}  (${new Date(prestamo_modelo.fecha_prestamo).toLocaleDateString("en-US", {timeZone: "America/New_York"})})`}</td>
                </tr>
                     </Fragment>
                )) : ''}

                {infoModelo.suma_prestamos ? <tr>
                  <td>Total Préstamos</td>
                  <td>${infoModelo.suma_prestamos ? - infoModelo.suma_prestamos : 0}</td>
                </tr>:''} 


                 {infoModelo.suma_servicios ? <tr>
                  <td colspan="2"><strong>Servicios</strong></td>
                </tr>:''}
               
                {infoModelo.suma_servicios ?infoModelo.servicios_modelo.map((servicios_modelo, key) => (
                     <Fragment>
                         <tr>
                <td>{servicios_modelo.nombre}</td>
                  <td>{servicios_modelo.cantidad}</td>
                </tr>
                     </Fragment>
                )) : ''}

                {infoModelo.suma_servicios ? <tr>
                  <td>Total Servicios</td>
                  <td>${infoModelo.suma_servicios ? - infoModelo.suma_servicios : 0}</td>
                </tr>:''}
               
              

                {infoModelo.prestamog_nomina > 0 ?
                  <Fragment>

<tr>
                <td  colspan="3" > <strong>Prestamos a Cuotas</strong></td>
                </tr>
                    <tr>
                      <td>Deuda restante</td>
                      <td>${infoModelo.prestamog_nomina ? infoModelo.prestamog_nomina : 0}</td>
                    </tr>
                    <tr>
                      <td>Cuota</td>
                      <td>${infoModelo.cuota ? -infoModelo.cuota : 0}</td>
                    </tr>
                    <tr>
                      <td>Debe de la deuda</td>
                      <td>${infoModelo.prestamog_nomina - infoModelo.cuota}</td>
                    </tr>
                  </Fragment>
                  : ''}

                <tr>
                <td  colspan="3" > <strong>Total neto</strong></td>
                </tr>
                <tr>
                  <td>Total Pesos</td>
                  <td>${infoModelo.dolares_modelo * infoModelo.dolar}</td>
                </tr>
              
                <tr>
                  <td>Arriendo</td>
                  <td>$-{infoModelo.arriendo_nomina}</td>
                </tr>
                {infoModelo.cuota>0&&
                <tr>
                <td>Cuota</td>
                <td>${infoModelo.cuota }</td>
              </tr>}
                     
                    {infoModelo.suma_prestamos ? <tr>
                  <td>Préstamos</td>
                  <td>${infoModelo.suma_prestamos ? - infoModelo.suma_prestamos : 0}</td>
                </tr>:''} 
                <tr>
                  <td><strong>Total a pagar</strong></td>
                  <td>${Math.round(infoModelo.total_pesos)}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>TRM</td>
                  <td>{infoModelo.dolar}</td>
                </tr>



              </tbody>
            </table>
          </div>

        </div>
      </div>

    ))

    return (ponerInfoArriendo())
  }


  ponerPagoModeloNoArriendo = () => {
    const URL_LOGO=`${URL}getImage/${this.props.logo}`
    const { pagosNominaNoArriendo,

    } = this.props
    const ponerInfoNoArriendo = () => pagosNominaNoArriendo.map((infoModelo, key) => (
      <div className="col-md-6 justify-content-md-center" key={key}>
        <div className='informe__modelo__div__container_pagonomina'>
          <div className='div__one__informe__modelo'>
          <img className='logoReportImg' alt='logoReportPagos' src={this.props.logo?URL_LOGO:defaultLogo}></img>
          <span>{infoModelo.lugar_trabajo} </span>
            <span>{infoModelo.cedula} </span>
            <span>{`${infoModelo.nombres} ${infoModelo.apellidos}`}</span>
            <span>{infoModelo.fecha} </span>
            <span> {infoModelo.cuentabancaria} </span>
          </div>
          <div>
            <table className="table table-striped tabla-global-repore-modelo"  >

              <tbody>
                <tr>
                  <td>Chaturbate</td>
                  <td>${infoModelo.suma_chaturbate}</td>
                </tr>
                <tr>
                  <td>Stripchat</td>
                  <td>${infoModelo.suma_strip}</td>
                </tr>

                <tr>
                  <td>Cam4</td>
                  <td>${infoModelo.suma_cam4}</td>
                </tr>
                <tr>
                  <td>Streamate</td>
                  <td>${infoModelo.suma_stremate}</td>
                </tr>
                <tr>
                  <td>Paypal</td>
                  <td>${infoModelo.suma_paypal}</td>
                </tr>
                <tr>
                  <td>Flirt4Free</td>
                  <td>${infoModelo.suma_flirt}</td>
                </tr>
                <tr>
                  <td>BongaCams</td>
                  <td>${infoModelo.suma_jasmin}</td>
                </tr>
                <tr>
                  <td>Total Dólares</td>
                  <td>${infoModelo.suma_total_plataformas}</td>
                </tr>
                <tr>
                  <td>Multas</td>
                  <td>${infoModelo.suma_multas ? - infoModelo.suma_multas : 0}</td>
                </tr>
                <tr>
             
                  <td>Porcentaje {infoModelo.porcentaje_pago * 100}%</td>
                  <td>${infoModelo.dolares_modelo}</td>
                </tr>
                <tr>
                  <td>Total Pesos</td>
                  <td>${infoModelo.dolares_modelo * infoModelo.dolar}</td>
                </tr>
                <tr>
                  <td>Retención (4%)</td>
                  <td>$-{infoModelo.retencion}</td>
                </tr>
                <tr>
                  <td>Arriendo</td>
                  <td>$-{infoModelo.arriendo_nomina}</td>
                </tr>
                {infoModelo.suma_prestamos ? <tr>
                  <td colspan="2"><strong>Préstamos</strong></td>
                </tr>:''}
               
                {infoModelo.suma_prestamos ?infoModelo.Prestamo_modelo.map((prestamo_modelo, key) => (
                     <Fragment>
                         <tr>
                <td>{prestamo_modelo.concepto}</td>
                  <td>{`${prestamo_modelo.cantidad}  (${new Date(prestamo_modelo.fecha_prestamo).toLocaleDateString("en-US", {timeZone: "America/New_York"})})`}</td>
                </tr>
                     </Fragment>
                )) : ''}

                {infoModelo.suma_prestamos ? <tr>
                  <td>Total Préstamos</td>
                  <td>${infoModelo.suma_prestamos ? - infoModelo.suma_prestamos : 0}</td>
                </tr>:''}


                
                {infoModelo.suma_servicios ? <tr>
                  <td colspan="2"><strong>Servicios</strong></td>
                </tr>:''}
               
                {infoModelo.suma_servicios ?infoModelo.servicios_modelo.map((servicios_modelo, key) => (
                     <Fragment>
                         <tr>
                <td>{servicios_modelo.nombre}</td>
                  <td>{servicios_modelo.cantidad}</td>
                </tr>
                     </Fragment>
                )) : ''}

                {infoModelo.suma_servicios ? <tr>
                  <td>Total Servicios</td>
                  <td>${infoModelo.suma_servicios ? - infoModelo.suma_servicios : 0}</td>
                </tr>:''}
             

                {infoModelo.prestamog_nomina > 0 ?
                  <Fragment>
                    <tr>
                      <td>Deuda restante</td>
                      <td>${infoModelo.prestamog_nomina ? infoModelo.prestamog_nomina : 0}</td>
                    </tr>
                    <tr>
                      <td>Cuota</td>
                      <td>${infoModelo.cuota ? -infoModelo.cuota : 0}</td>
                    </tr>
                    <tr>
                      <td>Debe de la deuda</td>
                      <td>${infoModelo.prestamog_nomina - infoModelo.cuota}</td>
                    </tr>
                  </Fragment>
                  : ''}

                <tr>
                  <td>Total</td>
                  <td>$ {Math.round(infoModelo.total_pesos)}</td>
                </tr>


              </tbody>
            </table>
          </div>
          <div className='div__third__informe__modelo'>
            <div>
              <div><FaLongArrowAltRight size={"3rem"} className="informes__pago__arrow" /> {`${infoModelo.suma_chaturbate * 20} Tokens`}</div>
              <div><FaLongArrowAltRight size={"3rem"} className="informes__pago__arrow" />  {`${infoModelo.suma_strip * 20} Tokens`} </div>
            </div>
            <div className='div__third__informe__modelo__dolar__div'>

              <div className='div__third__informe__modelo__dolar__div__dolar__signo'>
                $
            </div>
              <div className='div__third__informe__modelo__dolar__div__dolar__detalle'>

                <span>{infoModelo.dolar}</span>
                <span>TRM</span>

              </div>

            </div>
          </div>
        </div>
      </div>

    ))

    return (ponerInfoNoArriendo())
  }


  ponerPagoTotales = () => {
    const { pagosNominaSuma,

    } = this.props
    const ponerTotales = () => pagosNominaSuma.map((infoModelo, key) => (
      <Fragment  key={key}>
      <div className="col-md-3 justify-content-md-center">
        <span>TOTAL A PAGAR:{infoModelo.total_pagar}</span>

      </div>

      <div className="col-md-3 justify-content-md-center">
        <span>TOTAL DÓLARES:{infoModelo.dolares_modelos_studio + infoModelo.dolares_modelos_apartamento}</span>

      </div>
      <div className="col-md-3 justify-content-md-center" >
        <span>TOTAL USD MODELOS ST:{infoModelo.dolares_modelos_studio}</span>

      </div>
      <div className="col-md-3 justify-content-md-center" >
        <span>TOTAL USD MODELOS APT:{infoModelo.dolares_modelos_apartamento}</span>

      </div>
      </Fragment>
    ))

    return (ponerTotales())
  }


  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {
    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            NÓMINA <strong className="tituloPaginasPequeño">RECIBOS</strong>
          </h1>
          <div className="reporte__div__principal_pagonomina">
            <div className="container-fluid">
              <div className="row">
              {this.ponerPagoTotales()}
                

                {this.ponerPagoModeloArriendo()}
                {this.ponerPagoModeloNoArriendo()}

                {

                }
              </div>
              {this.mostrarAccion()}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.NominaReducer;
};

export default connect(mapStateToProps, nominaaction)(NominaRecibos);
