import axios from 'axios'
import { CARGANDO, ERROR,  GUARDAR,RESEARCHTRUE,PAGINAR_MONITORES,
    SUCCESS,CONSULTARS} from '../types/PerfilVerMonitorTypes'

    import {URL} from '../../src/General/url'





export const agregar = (prestamomodelo) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(prestamomodelo)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'createFines', params,{
            headers: headers
        })
    
      
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })


        if(respuesta.data.status==="success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const consultar = (model_id) => async(dispatch)=>{
    

}


export const consultarSuggestions = (data) => async(dispatch)=>{
    


    try {

     
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
    console.log(params)
        const respuesta = await axios.post(URL+`getSuggestionMonitor`, params,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTARS,
            payload:respuesta.data.data,
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
    }
}


export const buscarMontior = (data) => async(dispatch)=>{
    
    dispatch({

        type: CARGANDO
        
    
    })


    try {

    

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`getMonitorId/${data}`,{
            headers: headers
        })
    
  
        dispatch({
            type: PAGINAR_MONITORES,
            payload: respuesta.data.data
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const researchtrue = () => dispatch => {
    dispatch({
      type: RESEARCHTRUE
    });
  };
export const paginarMonitores = () => async(dispatch)=>{
    
    dispatch({

        type: CARGANDO
        
    
    })

    
    try {

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'allMonitors', {
            headers: headers
        })




        dispatch({
            type: PAGINAR_MONITORES,
            payload: respuesta.data.data
        })

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}