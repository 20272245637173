import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import imgPrincipal from "../../img/imgPrincipal.jpg";
import addBtn from "../../img/addBtn.png";
import * as HomeAction from "../../actions/HomeAction";
import "./Styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import { FaCheckCircle, FaTimes} from "react-icons/fa";
import { AiFillPlusCircle } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";


class Home extends Component {
  state = { show: false };

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
    console.log('close')
  };

  async componentDidMount() {

    await this.props.consultarmodelos();
    await this.props.contartareas();
    await this.props.consultartareas();
    await this.props.consultarEventosProximos();

    
  }

  componentDidUpdate() {
    if (this.props.researchtareas) {
      this.props.contartareas();
      this.props.consultartareas();
    }


    /*   if (!this.props.research) {
        this.props.consultarmodelos();
      } */

    console.log(this.props)
  }

  componentWillUnmount() {
    const { researchtrue } = this.props;
    researchtrue();
  }

  crearNuevaTarea = () => {

    const {
        concepto,
        crearNuevaTarea,
    } = this.props
    const tareatext = {
        descripcion: concepto,
    }
    crearNuevaTarea(tareatext)
}

  PonerContenido = () => {
    if (this.props.cargando) {
      return <Spinner />;
    }
    if (this.props.error) {
      return <Fatal mensaje={this.props.error} />;
    }

    //  return <ModalHome /*Usuarios={this.props.Usuarios} */ />;
  };

  cambioTareas = (event) => {
    this.props.cambioTareas(event.target.value)
  }
  mostrarAccion = () => {
    const { cargando } = this.props;
    if (cargando) {
      return <Spinner />;
    }
    return false;
  };

  mostrarAccionModal = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };


  getDiaSeamana(fecha) {
    var d = new Date(this.convertDateFormatcoma(fecha));
    var dias = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"];
    return dias[d.getDay()]

  }

  getmes(fecha) {
    var m = new Date(this.convertDateFormatcoma(fecha));
    var mes = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Agos", "Sept", "Oct", "Nov", "Dic"];
    return mes[m.getMonth()]
  }

  getDia(fecha) {


    var d = new Date(this.convertDateFormatcoma(fecha));
    return d.getDate()

  }

  getHora(fecha) {
    var d = new Date(fecha);
    var zHora = ''
    var horaN = ''
    if (d.getHours() > 12 && d.getHours() <= 24) {
      horaN = 12 - d.getHours()
      if(horaN<0){
        horaN = horaN * -1
      }
      zHora = 'PM'
    } else if (d.getHours() === 24) {

      horaN = 12 - d.getHours()
      zHora = 'AM'
    }
    else {
      horaN = d.getHours()
      zHora = 'AM'
    }

    return `${horaN}:${d.getMinutes()} ${zHora} `

  }

  getyeardate(fecha) {
    var d = new Date(this.convertDateFormatcoma(fecha));
    return d.getFullYear()

  }

  convertDateFormatcoma(string) {
  
    var info = string.split('-');
    return info[0] + ',' + info[1] + ',' + info[2];

  }

  buscarmodelo(e) {
    e.preventDefault();

    const { search, buscador } = this.props;
    const searchData = {
      data: search,
      estado: 1
    };

    buscador(searchData);
  }

  handleok(id,e){
    e.preventDefault()
        const {
          realizarTarea,
        } = this.props

        realizarTarea(id)
  }

  handledelete(id,e){

    e.preventDefault()
    const {
      eliminarTarea,
    } = this.props

    eliminarTarea(id)
    
  }


  listaTareas = () => {
    const { tareas } = this.props;

    const ponertareas = () =>
      tareas.map((tareas, key) => (

        <div key={key} id={tareas.id} className='Home_Work_Stage__insideDiv'>
          <div className='Home_Work_Stage__insideDiv__flex'>
            <span className='Home_Work_Stage__insideDiv__text__user' >  {tareas.username}</span>
            <span> {this.getDiaSeamana(tareas.fechaasignada)}, {this.getDia(tareas.fechaasignada)} de {this.getmes(tareas.fechaasignada)} {this.getHora(tareas.fechaasignada)}</span>
           
          </div >
          <div className='Home_Work_Stage__insideDiv__flex'>
          {tareas.estado ? <Fragment> <div className='Home_Work_Stage__insideDiv__text__description'>  {tareas.descripcion}</div> 
         
           <div  className='action__tareas__container' ><span  onClick={e => this.handleok(tareas.id, e)} ><FaCheckCircle  className="Home_Work_Stage__insideDiv__icon__ok size_btn" /></span>
           <span  onClick={e => this.handledelete(tareas.id, e)}><FaTimes className="Home_Work_Stage__insideDiv__icon__x size_btn" /></span></div> </Fragment>
          : 
          <div className='Home_Work_Stage__insideDiv__text__description Home_Work_Stage__done'>  {tareas.descripcion}</div>}
            <span className='Home_Work_Stage__insideDiv__text__user' >  {tareas.realizado}</span>
            
          </div>

        </div>

      ));

    return (ponertareas()
    );
  };

  listarEventos = () => {
    const { eventosProximos } = this.props;

    const ponertareas = () =>
    eventosProximos.map((eventos, key) => (

        <div key={key} id={eventos.id} className='Home_Work_Stage__insideDiv'>
          
          <div className=' '> <span style={{width:'auto',fontWeight:'bold',marginLeft:'10px'}} >  {eventos.usuario.username}</span></div>
            <div style={{textAlign:'right',width:'100%',marginTop:'-10px'}}><span><strong>  Inicio:</strong>{this.getDiaSeamana(eventos.start)}, {this.getDia(eventos.start)} de {this.getmes(eventos.start)} {this.getHora(eventos.start)}</span></div>
            <div style={{textAlign:'right',width:'100%',marginTop:''}}><span> <strong> Fin:</strong>{this.getDiaSeamana(eventos.end)}, {this.getDia(eventos.end)} de {this.getmes(eventos.end)} {this.getHora(eventos.end)}</span></div>

            <div style={{width:'auto',marginLeft:'10px'}}> <strong>Evento:</strong> {eventos.title}</div>
          <div style={{width:'auto',marginLeft:'10px'}}> <strong> Descripción:</strong> {eventos.description}</div>
         

        
            
       

        </div>

      ));

    return (ponertareas()
    );
  };

  cambioSearch(e) {
    e.preventDefault();

    const { cambioSearch } = this.props;

    cambioSearch(e.target.value);

    if (!e.target.value) {
      this.props.consultarmodelos();
    }
  }

  render() {
    return (
      <Fragment>
        <div className="main-content fade-in ">
          <img
            className="imgPrincipal"
            alt="imagen principal"
            src={imgPrincipal}
          />
          {!window.localStorage.getItem("token") ? <Redirect to="/Home" /> : ""}
          {/*        <div className="container">
  
  <div className="nav-wrapper">
    <div className="input-field b-search">
      <input type="search" id="search-text" className="navbarsearch" placeholder="search" onChange={(e) => this.cambioSearch(e)} value={this.props.search}/>
      
      <button className='searchboton'> <MdYoutubeSearchedFor size={'32px'} onClick={(e) => this.buscarmodelo(e)}/></button>
    </div>

  </div>
  
</div>  
  {this.PonerContenido()}*/}

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 margintop">
                <div>
                  <Link to="/AgregarInformacionModelo">
                    {" "}
                    <img
                      className="imgBtnaddHome"
                      alt="imagen principal"
                      src={addBtn}
                    />
                  </Link>
                </div>
                <div className="modelos_activos">
                  <h1>{this.props.countModels}</h1>
                  <h2>Modelos</h2>
                </div>
                <div></div>
              </div>
              <div className="col-md-4">
                <div className="Home_Work_Stage">
                  <h1 className='Home_Work_Stage__tittle_text'>Tareas</h1>
                  <div className="Home_Work_Stage__WorkDivHeader">
                    <div className='flex' >
                      
                      <div className='Home__Work__Stage__count__text' style={this.props.tareasc?{backgroundColor:'red'}:{backgroundColor:'white'}}>{this.props.tareasc}</div>
                      <span>Próximos tareas</span>
                    </div>

                    <li
                      className="Home_Work_Stage__Button"
                      onClick={this.showModal}
                    >
                      <span>
                        <AiFillPlusCircle
                         
                          className="Home_Work_Stage__Button size_btn_add"
                        />
                      </span>
                    </li>
                  </div>
                  <div className="Home_Work_Stage__WorkDiv">

                    <div className="Home_Work_Stage__WorkDivContainer">
                      {this.mostrarAccion()}
                      <div>

                        {this.listaTareas()}


                      </div>

                    </div>
                  </div>
                </div>

                <Modal show={this.state.show} onHide={this.hideModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Tareas</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <textarea id="textareaHomeWork" rows="10" onChange={this.cambioTareas} value={this.props.concepto}>

                    </textarea>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      onClick={this.hideModal}
                      className="btn__modal btn-secondary"
                    >
                      Cancelar
                    </button>
                    <button
                      onClick={this.crearNuevaTarea}
                      className="btn__modal btn__primary__modal"
                    >
                      Crear Tarea
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
              <div className="col-md-4">
              <div className="Home_Work_Stage">
                  <h1 className='Home_Work_Stage__tittle_text'>Planeador</h1>
                  <div className="Home_Work_Stage__WorkDivHeader">
                    <div className='flex' >
                      <span>Próximas eventos</span>
                    </div>
                  </div>
                  <div className="Home_Work_Stage__WorkDiv">

                    <div className="Home_Work_Stage__WorkDivContainer">
                      <div>

                        {this.listarEventos()}


                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

//conectar tareas al reducer y traer las acciones del tareas actions
const mapStateToProps = reducers => {
  return reducers.HomeReducer;
};
//conectar tareas al reducer y traer las acciones del tareas actions
export default connect(mapStateToProps, HomeAction)(Home);
