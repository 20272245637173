import {
  CARGANDO,
  ERROR,
  CONSULTAR,
  RESEARCHTRUE,
  CAMBIO_LUGAR_ID,
  CAMBIO_TURNO_ID,
  CONSULTAR_BANCOS,
  CAMBIO_BANCO_ID,
  RELOAD,
  CONTABLE_FLAG,
  SUCCESS,
  IMAGEN_PERFIL,
  CONSULTAR_LUGARES,
  CONSULTAR_TURNOS,
  GALERIA_MODELO,
  GALERIA_FLAG,
  INFORMACION_CONTABLE_DULICADA,
  INFORMACION_CONTABLE_DULICADA_ID,
  IMAGEN_FIRMA,
  CAMBIO_NOMBRE_FIRMA
} from "../types/PerfilModificarInfoTypes";

const INITIAL_STATE = {
  ListaFechasPagos: [],
  lugares: [],
  ListaBancos:[],
  turnos: [],
  galeriaModelo: [],
  banco:'',
  pagosNominaSuma: [],
  fechas_pagos_id: '',
  informacionModelo: [],
  perfilImg: '',
  lugares_id: '',
  turno_id: '',
  cargando: false,
  galeriaFlag:true,
  contable_flag:0,
  informacion_contable_duplicada:[],
  informacion_contable_duplicada_id:0,
  error: "",
  research: "",
  success: "",
  imagen_firma:'',
  firma_modelo:''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONSULTAR:
      return {
        ...state,
        informacionModelo: action.payload,
        cargando: false,
        research: false
      };

    case CARGANDO:
      return { ...state, cargando: true };

    case ERROR:
      return { ...state, error: action.payload, cargando: false, success: "" };

    case SUCCESS:
      return { ...state, success: action.payload, cargando: false, error: "" };


    case IMAGEN_PERFIL:
      return {
        ...state,
        perfilImg: action.payload,
        cargando: false,
        research: false,
      }

      case IMAGEN_FIRMA:
        return {
          ...state,
          imagen_firma: action.payload,
    
        }
        case CAMBIO_NOMBRE_FIRMA:
          return {
            ...state,
            firma_modelo: action.payload,
      
          }
          case CAMBIO_BANCO_ID:
            return {
              ...state,
              banco: action.payload,
        
            }

          
    case GALERIA_MODELO:
      return {
        ...state,
        galeriaModelo: action.payload,
        cargando: false,
        research: false,
        galeriaFlag:true
      }
      

      case INFORMACION_CONTABLE_DULICADA:
        return {
          ...state,
          informacion_contable_duplicada:action.payload,
        }


        case CONSULTAR_BANCOS:
          return {
            ...state,
            ListaBancos:action.payload,
          }

        case INFORMACION_CONTABLE_DULICADA_ID:
          return {
            ...state,
            informacion_contable_duplicada_id:action.payload,
          }
      case GALERIA_FLAG:
        return {
          ...state,
          galeriaFlag:action.payload,
        }
        case CONTABLE_FLAG:
          return {
            ...state,
            contable_flag:action.payload,
          }


    case CONSULTAR_LUGARES:
      return {
        ...state,
        lugares: action.payload,
        cargando: false,
        research: false,
      }
    case CONSULTAR_TURNOS:
      return {
        ...state,
        turnos: action.payload,
        cargando: false,
        research: false,
      }

    case CAMBIO_LUGAR_ID:
      return {
        ...state,
        lugares_id: action.payload,
      }

    case CAMBIO_TURNO_ID:
      return {
        ...state,
        turno_id: action.payload,
      }





    case RESEARCHTRUE:
      return {
        ...state,

        fecha1: "",
        fecha2: "",
        modelos_id: "",
        cargando: false,
        error: "",
        research: "",
        success: "",
        firma_modelo:'',
        imagen_firma:''
      };

    case RELOAD:
      return {
        ...state,

        cargando: false,
        research: true
      };
    default:
      return state;
  }
};
