import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as crearaction from "../../actions/CrearAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import cambiarFotoImg from "../../img/cambiarFotoImg.png";
import btnAnteriorImg from "../../img/btnAnteriorImg.png";
import btnSiguienteImg from "../../img/btnSiguienteImg.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import {URL} from '../../../src/General/url'
/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */
export class AgregarInformacionModelo extends Component {


  state = {
    opacity:'30%'
  }

  componentDidMount() {
    const {

      consultar_turnos,
      consultar_lugares,
      consultar_banco

    } = this.props

    consultar_lugares()
    consultar_turnos()
    consultar_banco()



  }

  componentDidUpdate() {
    if(this.props.estadoFlag){
      this.props.history.push('/AgregarInformacionContableModelo')
      console.log(this.props)
    }
  
  }



  componentWillUnmount() {

  }

  handleSubmit(e) {
    e.preventDefault();
  }
  deshabilitar = () => {
    const {
      cedulaModelo,
      nombresModelo,
      apellidosModelo,
      lugares_id,
      turno_id,
    } = this.props;

    if (
      !cedulaModelo ||
      !nombresModelo ||
      !lugares_id ||
      !turno_id ||
      !apellidosModelo 
    ) {
     
      return true
    }

    return false
    
  };

  handleChange(dataType, value) {
    this.props.handleChange(dataType, value)
  }

  cambioFotoPerfil = event => {
    const { guardarimagen } = this.props;
    const files = event.target.files[0];
    if (files) {
      const data = new FormData();
      data.append("file0", files);
      guardarimagen(data);
    }

  };

  cambioLugarId = (event) => {
    this.props.cambioLugarId(event.target.value)
  }



  cambioTurnoId = (event) => {
    this.props.cambioTurnoId(event.target.value)
  }


  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  


handleNextStep(e) {
e.preventDefault()
const { validar_creacion_modelos } = this.props;

const datos={
  tipo:'informacionmodelo',
  dato:this.props.cedulaModelo
}
validar_creacion_modelos(datos)
  }

  listaLugares = () => {
    const { lugares } = this.props;

    const ponerOpcionesLugares = () =>
      lugares.map((lugar, key) => (
        <option className='option-values' key={key} value={lugar.id}>
          {this.MaysPrimera(lugar.nombre.toLowerCase())}{}
        </option>
      ));

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        onChange={this.cambioLugarId}
        required
      >
        <option className='option-values' >
          Lugar
        </option>
        {ponerOpcionesLugares()}
      </select>
    );
  };



  listaTurnos = () => {
    const { turnos } = this.props;

    const ponerOpcionesTurnos = () =>
      turnos.map((turnos, key) => (
        <option className='option-values' key={key} value={turnos.id}>
          {this.MaysPrimera(turnos.nombre.toLowerCase())}{}
        </option>
      ));

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        onChange={this.cambioTurnoId}
        required


      >
        <option className='option-values'>
          Turno
        </option>
        {ponerOpcionesTurnos()}
      </select>
    );
  };

  ponerInformacionModelo = () => {

    const ponerInfo = () => (
      <Fragment >
        <div className="col-md-3 ">

          <img
            className="avatarModeloimg"
            alt="imgagerfil"
            src={`${URL}getImage/${
              this.props.foto_perfil
                ? this.props.foto_perfil
                : "defaultLogo.png"
              }`}
          />
          <label className='foto_perfil_modelo_label' htmlFor="foto_perfil_modelo">
            <img src={cambiarFotoImg} alt="Click aquí para subir tu foto" title="Click aquí para subir tu foto" />
          </label>
          <input

            className="form-element-field -hasvalue"
            id={`foto_perfil_modelo`}
            type="file"
            onChange={this.cambioFotoPerfil}
          />

        </div>

        <div className="col-md-4 ">
          <div className='informacion_personal_modificar_info_modelo'>
            <div className='div__personal__modificar__info'><div className='text__rigth'>Cédula  </div>   <input
              type="number"
              className='input__modificiar_informacion_modelo'
              value={this.props.cedulaModelo}
              onChange={(e) => this.handleChange('CAMBIO_CEDULA', e.target.value)}
            /></div>

            <div className='div__personal__modificar__info'><div className='text__rigth'>Nombre  </div>   <input
              type="text"
              className='input__modificiar_informacion_modelo'
              value={this.props.nombresModelo}
              onChange={(e) => this.handleChange('CAMBIO_NOMBRES', e.target.value)}
            /></div>

            <div className='div__personal__modificar__info'><div className='text__rigth'>Apellidos  </div>   <input
              type="text"
              className='input__modificiar_informacion_modelo'
              value={this.props.apellidosModelo}
              onChange={(e) => this.handleChange('CAMBIO_APELLIDOS', e.target.value)}
            /></div >

            <div className='div__personal__modificar__info'><div className='text__rigth'>Teléfono  </div>   <input
              type="number"
              className='input__modificiar_informacion_modelo'
              value={this.props.telefonoModelo}
              onChange={(e) => this.handleChange('CAMBIO_TELEFONO', e.target.value)}
            /></div>
          </div>
        </div>

        <div className="col-md-4 ">
          <div className='informacion_personal_modificar_info_modelo'>
            <div className='div__personal__modificar__info__derecha'><div className='text__rigth'>Nick </div> <input
              type="text"
              className='input__modificiar_informacion_modelo'
              value={this.props.nickModelo}
              onChange={(e) => this.handleChange('CAMBIO_NICK_NAME', e.target.value)}
            /></div>

            <div className='centrar_verticalmente div__personal__modificar__info__derecha'><div className='text__rigth'>Trabaja desde  </div> {this.listaLugares()}</div>

            <div className='centrar_verticalmente div__personal__modificar__info__derecha'><div className='text__rigth'>Turnos </div>   {this.listaTurnos()}</div>

            <div className='div__personal__modificar__info__derecha'><div className='text__rigth'>Arriendo  </div>   <input
              type="number"
              className='input__modificiar_informacion_modelo'
              value={this.props.arriendoModelo}
              onChange={(e) => this.handleChange('CAMBIO_ARRIENDO', e.target.value)}
            /></div>

            <div className='div__personal__modificar__info__derecha'><div className='text__rigth'>Contraseña perfil  </div>  <input
              type="text"
              className='input__modificiar_informacion_modelo'
              value={this.props.passwordPerfil}
              onChange={(e) => this.handleChange('CAMBIO_PASSWORDM', e.target.value)}
            /></div>


          </div>

  
        </div>

      </Fragment>
    )

    return (ponerInfo())
  }





  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {

    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            PERFIL <strong className="tituloPaginasPequeño">AGREGAR</strong>
          </h1>
          <div className='Paso__paso__div'>
            <h2>PASO</h2>
            <div className="circuloPasoPaso">
              <h2>1</h2>
            </div>
            <h2>INFORMACIÓN PERSONAL</h2>
          </div>

          <div className="reporte__div__principal">

            <div className="container-fluid">
              <div className="row">

                {this.ponerInformacionModelo()}


              </div>



            </div>
          </div>
          {this.mostrarAccion()}
          <div className="paso__paso__div__control">
           
         <div className="paso__paso__div__central">
          <img src={btnAnteriorImg} alt="Click aquí para ir al siguiente paso" title="Click aquí para ir al siguiente paso" />
          <div className="circuloPasoPaso__control">
              <h2>1</h2>
            </div>
            <button
            type='button'
            disabled={this.deshabilitar()}
            className='boton__control__paso__paso'
            onClick={e =>this.handleNextStep(e)}>
            <img src={btnSiguienteImg}  style={this.deshabilitar()?{opacity:'30%'}:{opacity:'100%'}} alt="Click aquí para subir tu foto" title="Click aquí para ir al anterior paso" />
            </button>
         
        
          <div className="circuloPasoPaso__control">
              <h2>2</h2>
            </div>
            <div className="circuloPasoPaso__control">
              <h2>3</h2>
            </div>
            <div className="circuloPasoPaso__control">
              <h2>4</h2>
            </div>
          </div>
          </div>

        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.crearReducer;
};

export default connect(mapStateToProps, crearaction)(AgregarInformacionModelo);
