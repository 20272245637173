import axios from 'axios'
import { CARGANDO, ERROR,  GUARDAR, CONSULTAR, CAMBIO_FECHAPRESTAMO,CAMBIO_CANTIDAD,RESEARCHTRUE,CAMBIO_FECHA1,CAMBIO_CONCEPTO,
    SUCCESS,CAMBIO_FECHA2,FILTRAR_PRESTAMOS_MINIMOS,CONSULTARS,RESEARPRESTAMO,CONSULTAR_SERVICIOS,CONSULTAR_SERVICIOS_MODELO} from '../types/PrestamoGlobalTypes'

    import {URL} from '../../src/General/url'






export const agregar = (prestamomodelo) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(prestamomodelo)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'createloans', params,{
            headers: headers
        })
    
      
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })


        if(respuesta.data.status==="success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

            dispatch({
        
                type: RESEARPRESTAMO,
                payload: false
                
            
            })

        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const agregar_servicio = (prestamomodelo) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(prestamomodelo)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'createServiceModel', params,{
            headers: headers
        })
    
      
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })


        if(respuesta.data.status==="success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

            dispatch({
        
                type: RESEARPRESTAMO,
                payload: false
                
            
            })

        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const consultar_servicios= () => async (dispatch) => {


    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getServices', {
            headers: headers
        })




        dispatch({
            type: CONSULTAR_SERVICIOS,
            payload: respuesta.data.data
        })
    
        

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}

export const editarPrestamo = (data,model_id) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateLoans/${model_id}`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
}
if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
        
 /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

 
}


export const editarServicio = (data,model_id) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateService/${model_id}`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
}
if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
        
 /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

 
}
export const consultarPrestamos = () => async(dispatch)=>{
    

    try {

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+'loansReport' ,{
            headers: headers
        })
      
        dispatch({
            type: FILTRAR_PRESTAMOS_MINIMOS,
            payload:respuesta.data.data
        })
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}

export const consultarServicios = () => async(dispatch)=>{
    

    try {

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+'ServiceReport' ,{
            headers: headers
        })
      
        dispatch({
            type: FILTRAR_PRESTAMOS_MINIMOS,
            payload:respuesta.data.data
        })
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}




export const eliminarServicio = (id) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.delete(URL+`deleteModelService/${id}`,{
            headers: headers
        })
    
    

        if(respuesta.data.status==="Success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

            dispatch({
        
                type: RESEARPRESTAMO,
                payload: false
                
            
            })

        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
 
        
        
    } catch (error) {

        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}

export const eliminar = (id) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.delete(URL+`deleteModelLoans/${id}`,{
            headers: headers
        })
    
    

        if(respuesta.data.status==="Success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

            dispatch({
        
                type: RESEARPRESTAMO,
                payload: false
                
            
            })

        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
 
        
        
    } catch (error) {

        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const consultar = (model_id) => async(dispatch)=>{
    


    try {

    

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`searchModelsloansReport/${model_id}`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTAR,
            payload:respuesta.data.data,
        })

        
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
    } catch (error) {
        
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}


export const consultarServices = (model_id) => async(dispatch)=>{
    


    try {

    

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`searchModelServiceReport/${model_id}`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTAR_SERVICIOS_MODELO,
            payload:respuesta.data.data,
        })

        
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
    } catch (error) {
        
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

export const consultarSuggestions = (data) => async(dispatch)=>{

    console.log(data.data)
    
           if(data.data){
            try {

     
                let json = JSON.stringify(data)
                let params = json;
        
                
        
                let headers = {
                    "Content-Type": "application/json",
                    'Authorization': window.localStorage.getItem('token')
                }
            
                const respuesta = await axios.post(URL+`getSuggestionModels`, params,{
                    headers: headers
                })
            
          
                dispatch({
                    type: CONSULTARS,
                    payload:respuesta.data.data,
                })
        
                if(respuesta.data.status==="Error"){
                    dispatch({
                
                        type: ERROR,
                        payload: respuesta.data.message
                        
                    
                    })
        
                }
                
            } catch (error) {
                
                dispatch({
        
                    type: ERROR,
                    payload: error.message
                    
                
                })
            }
           }else{
            dispatch({
                type: CONSULTAR,
                payload:[],
            })

           }

 
}


export const cambioconcepto = (concepto_prestamo) => (dispatch)=>{

    dispatch({
        type: CAMBIO_CONCEPTO,
        payload: concepto_prestamo
        
    })


}


export const cambiocantidad = (cantidad_prestamo) => (dispatch)=>{
    dispatch({
        type: CAMBIO_CANTIDAD,
        payload: cantidad_prestamo
        
    })
}

export const cambiofechaprestamo  = (fecha_prestamo) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHAPRESTAMO,
        payload: fecha_prestamo
        
    
    })
    
};

export const cambiofecha1  = (fecha1) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA1,
        payload: fecha1
        
    
    })
    
};

export const cambiofecha2  = (fecha2) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA2,
        payload: fecha2
        
    
    })
    
};



export const researchtrue  = () => (dispatch,) => {
    dispatch({

        type: RESEARCHTRUE
        
    
    })


    

   
};

export const handleChange  = (index, dataType, value) => (dispatch,getState) => {

    const {prestamos} = getState().PrestamoGlobalReducer


    const newPrestamo = prestamos.map((item, i) => {
        if (i === index) {
          return {...item, [dataType]: value};
        }
        return item;
      });
  

      dispatch({
        type: CONSULTAR,
        payload:newPrestamo
    })


    

   
};




export const handleChangeServicios  = (index, dataType, value) => (dispatch,getState) => {

    const {servicios} = getState().PrestamoGlobalReducer


    const newServicio= servicios.map((item, i) => {
        if (i === index) {
          return {...item, [dataType]: value};
        }
        return item;
      });
  

      dispatch({
        type: CONSULTAR_SERVICIOS_MODELO,
        payload:newServicio
    })


    

   
};


export const handleChangePrestamo  = (index, dataType, value) => (dispatch,getState) => {

    const {verperstamos} = getState().PrestamoGlobalReducer


    const newPrestamo = verperstamos.map((item, i) => {
        if (i === index) {
          return {...item, [dataType]: value};
        }
        return item;
      });
  

      dispatch({
        type: FILTRAR_PRESTAMOS_MINIMOS,
        payload:newPrestamo
    })


    

   
};