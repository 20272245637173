import {
  CARGANDO,
  ERROR,
  GUARDAR,
  RESEARCHTRUE,
  RELOAD,
  SUCCESS,
  IMAGEN_PERFIL,
  CAMBIO_ESTADO_CREAR,
  CAMBIO_ESTADO_INICIAL,
  CAMBIO_PERMISOS_ID,
  CAMBIO_PERMISOS,
  CAMBIO_PERMISOS_PAGOS,
  CAMBIO_PASSWORD_MONITOR,
  CAMBIO_USUARIO,
  NEW_USERS,
  CONSULTAR_TODOS_MONITORES

} from "../types/crearMonitoresTypes";

const INITIAL_STATE = {
  foto_perfil: '',
  usuario:'',
  usuarios_todos:[],
  password_monitor:'',
  permisos_monitor: '',
  id_permisos: '',
  permisos: [],
  permisosPagos: [],
  cargando: false,
  estadoFlag:false,
  error: "",
  research: "",
  success: ""
};




export default (state = INITIAL_STATE, action) => {
  switch (action.type) {


    case CARGANDO:
      return { ...state, cargando: true };

    case ERROR:
      return { ...state, error: action.payload, cargando: false, success: "" };

    case SUCCESS:
      return { ...state, success: action.payload, cargando: false, error: "" };


    case IMAGEN_PERFIL:
      return {
        ...state,
        foto_perfil: action.payload,
        cargando: false,
        research: false,
      }



      
      case CONSULTAR_TODOS_MONITORES:
        return {
          ...state,
          usuarios_todos: action.payload,
          cargando: false,
          research: false,
        }

        case CAMBIO_ESTADO_CREAR:
          return {
            ...state,
            estadoFlag: action.payload,
            cargando: false,
            research: false,
          }

          case CAMBIO_ESTADO_INICIAL:
            return {
              ...state,
              estadoFlag: action.payload,
            }
            case GUARDAR:
              return { ...state, success: "" };
            
  

    case CAMBIO_USUARIO:
      return {
        ...state,
        usuario: action.payload,
      }
    case CAMBIO_PASSWORD_MONITOR:
      return {
        ...state,
        password_monitor: action.payload,
      }
    case CAMBIO_PERMISOS:
      return {
        ...state,
        permisos: action.payload,
      }
      case CAMBIO_PERMISOS_PAGOS:
        return {
          ...state,
          permisosPagos: action.payload,
        }
      

      case NEW_USERS:
        return {
          ...state,
          usuarios_todos: action.payload,
        }

    case CAMBIO_PERMISOS_ID:
      return {
        ...state,
        id_permisos: action.payload,
      }





    case RESEARCHTRUE:
      return {
        ...state,

        foto_perfil: '',
        usuario:'',
        password_monitor:'',
        permisos_monitor: '',
        id_permisos: '',
        permisos: [],
        cargando: false,
        estadoFlag:false,
        error: "",
        research: "",
        success: ""
      };

    case RELOAD:
      return {
        ...state,

        cargando: false,
        research: true
      };
    default:
      return state;
  }
};
