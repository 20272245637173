import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
import { Link } from "react-router-dom";
import * as permisosaction from "../../actions/PermisosUsuariosAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import btonAgregarPermiso from "../../img/btonAgregarPermiso.png";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import { FiDelete } from "react-icons/fi";
import { FaEye } from "react-icons/fa";
//import {URL} from '../../../src/General/url'
/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */
export class permisosPagos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permisosArray: [],
      id_lugar: "",
      nombre_lugar: "",
      nombre_permiso: "",
    };
  }

  async componentDidMount() {
    const { consultar_lugares,consultar_roles_pagos } = this.props;
    await consultar_lugares();
    await consultar_roles_pagos();
  }
  componentDidUpdate() {
    console.log(this.state);
  }

  componentWillUnmount() {
    this.props.researchtrue();
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  setLugar() {
    const found = this.state.permisosArray.find(
      (element) => element.id === this.state.id_lugar
    );
    if (!found) {
      if (this.state.id_lugar) {
        this.state.permisosArray.push({
          id: this.state.id_lugar,
          nombre: this.state.nombre_lugar,
        });
        this.setState({ nombre_lugar: "", id_lugar: "" });
      }
    }
  }

  listaLugares = () => {
    const { ListaLugares } = this.props;

    const poneropcioneslugaress = () =>

      ListaLugares.map((lugares, key) => (
        <option className="option-values" key={key} value={key}>
          {lugares.nombre}
        </option>
      ));

    return (
      <Fragment>
        <select
          className="right-submenu"
          name="select"
          required
          onChange={(e) => {
            e.target.value==='todo'? this.setState({
              id_lugar: '0',
              nombre_lugar: 'Todos',
            }): this.setState({
              id_lugar: this.props.ListaLugares[e.target.value].id,
              nombre_lugar: this.props.ListaLugares[e.target.value].nombre,
            })
           
          }}
        >
           
          <option className="option-values">Lugares</option>
          <option className="option-values" value='todo'>
    Todos
  </option>
          {poneropcioneslugaress()}
        </select>
    
      </Fragment>
    );
  };

  deletePermiso(id) {
    const arrayPer = this.state.permisosArray;
    const newArrayPer = Object.values(
      Object.fromEntries(
        Object.entries(arrayPer).filter(([key, val]) => val.id !== id)
      )
    );

    this.setState({ permisosArray: newArrayPer });
  }

  listaPermisos = () => {
    const ponerPermisos = () =>
      this.state.permisosArray.map((permiso, key) => (
        <tr key={key}>
          <td>{permiso.nombre}</td>
          <td>
            {" "}
            <span
              className="Permisos_Usuarios_Stage__insideDiv__icon__add"
              onClick={(e) => {
                this.deletePermiso(permiso.id);
              }}
            >
              <FiDelete size={"32px"} />
            </span>
          </td>
        </tr>
      ));

    return ponerPermisos();
  };

  ponerHabitaciones = () => {
    const ponerInfo = () => (
      <Fragment>
     
        <span className="pageTitulos">PAGOS</span>
        <tr>
          <td>nomina-pagos-pago</td>
          <td>
            <input type="checkbox" className="checkbox" id="nomina_pagos_pago" />
          </td>
        </tr>
        <tr>
          <td>nomina-pagos-subir-archivo</td>
          <td>
            <input type="checkbox" className="checkbox" id="nomina_pagos_subir_archivo" />
          </td>
        </tr>
        <tr>
          <td>nomina-pagos-enviar-correo</td>
          <td>
            <input type="checkbox" className="checkbox" id="nomina_pagos_enviar_correo" />
          </td>
        </tr>
        <tr>
          <td>nomina-pagos-recibos-modelos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="nomina_pagos_recibos_modelos"
            />
          </td>
        </tr>
        <tr>
          <td>nomina-pagos-recibos-monitores</td>
          <td>
            <input type="checkbox" className="checkbox" id="nomina_pagos_recibos_monitores" />
          </td>
        </tr>
        <tr>
          <td>nomina-pagos-recibos-ganancias</td>
          <td>
            <input type="checkbox" className="checkbox" id="nomina_pagos_recibos_ganancias" />
          </td>
        </tr>    
        <tr>
          <td>nomina-pagos-procentaje</td>
          <td>
            <input type="checkbox" className="checkbox" id="nomina_pagos_procentaje" />
          </td>
        </tr>
        <tr>
          <td>nomina-pagos-archivos</td>
          <td>
            <input type="checkbox" className="checkbox" id="nomina_pagos_archivos" />
          </td>
        </tr>
       
      </Fragment>
    );
    return ponerInfo();
  };

  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  ListaPermisosBaseDatos = () => {
    const { ListaPermisosPagos } = this.props;
    const ponerPermisosBaseDatos = () =>
    ListaPermisosPagos.map((permiso, key) => (
        <tr key={key}>
          <td>{permiso.nombre}</td>
          <td>
            {" "}


            <Link to={`PermisoPagosUsuariosModificar/${permiso.id}`}>
            <span
              className="Permisos_Usuarios_Stage__insideDiv__icon__add"
            >
              <FaEye size={"32px"} />
            </span>
              </Link>
         
          </td>
        </tr>
      ));

    return ponerPermisosBaseDatos();
  };
  
  crearPermiso = (e) => {
    e.preventDefault();

    const {
      crear_permiso_pagos,
    } = this.props;

    let arrayPer = this.state.permisosArray;
    let StrigPer = "";
    for (let index = 0; index < arrayPer.length; index++) {
      const element = arrayPer[index];

      if (arrayPer.length > 1) {
        index+1===arrayPer.length?StrigPer = StrigPer + `${element.id}`:StrigPer = StrigPer + `${element.id},`;
      } else {
        StrigPer = StrigPer + `${element.id}`;
      }
    }

    
    const data = {
      nombre: this.state.nombre_permiso,
      lugares_id: StrigPer,
      nomina_pagos_pago: document.getElementById(`nomina_pagos_pago`).checked,
      nomina_pagos_subir_archivo: document.getElementById(`nomina_pagos_subir_archivo`).checked,
      nomina_pagos_enviar_correo: document.getElementById(`nomina_pagos_enviar_correo`).checked,
      nomina_pagos_recibos_modelos:document.getElementById(`nomina_pagos_recibos_modelos`).checked,
      nomina_pagos_recibos_monitores: document.getElementById(`nomina_pagos_recibos_monitores`).checked,
      nomina_pagos_recibos_ganancias: document.getElementById(`nomina_pagos_recibos_ganancias`).checked,
      nomina_pagos_procentaje: document.getElementById(`nomina_pagos_procentaje`).checked,
      nomina_pagos_archivos:document.getElementById(`nomina_pagos_archivos`).checked,
      
      
    };

    crear_permiso_pagos(data);
  };

  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {


    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">PERMISOS PAGOS</h1>

          <div className="reporte__div__principal__permisos">
            {this.mostrarAccion()}
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 mx-auto">
                  <div className="row">
                    <div
                      className="col-md-6 mx-auto"
                      style={{ display: "flex",paddingTop: "20px" }}
                    >
                      <div style={{ width: "100%" }}>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="Nombre Permiso"
                          onChange={(e) => {
                            this.setState({ nombre_permiso: e.target.value });
                          }}
                        />
                        <div style={{ width: "100%" }}>{this.listaLugares()}</div>
                      </div>

                      
                      <span
          className="Permisos_Usuarios_Stage__insideDiv__icon__add_permisos"
          onClick={(e) => {
            this.setLugar(e.target.value);
          }}
        >
         <img alt='img para aagregar permisos a la lista' src={btonAgregarPermiso}/>
        </span>
                    </div>
                    <div className="col-md-6 mx-auto">
                      <table className="table table-striped tabla-global" style={{textAlign:"center"}}>
                        <tbody>
                          {this.listaPermisos()}
                        </tbody>
                      </table>
                    </div>
                  </div>
        
                </div>
            
                
              </div>

           
            </div>
          </div>
          <div className="row reporte__div__secundario__permisos" >  
          <div className="col-md-6 mx-auto">
                <table className="table table-striped tabla-global">
                <span className="pageTitulos">MENÚ</span>
                    <tbody>
                   
                      {this.ponerHabitaciones()}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-6 mx-auto">
                <table className="table table-striped tabla-global" >
                        <tbody>
                        <span className="pageTitulos">PERMISOS-PAGOS-CREADOS</span>
                          {this.ListaPermisosBaseDatos()}
                        </tbody>
                      </table>
                </div>

                <div className="col-md-6 mx-auto ">
                <button className="invetario__btn" onClick={this.crearPermiso}>
                  Crear Permiso
                </button>
              </div>
          
          </div>
       
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (reducers) => {
  return reducers.PermisosUsuariosReducer;
};

export default connect(mapStateToProps, permisosaction)(permisosPagos);
