import {
    CARGANDO, ERROR, GUARDAR, CONSULTAR, CAMBIO_FECHAPRESTAMO, CAMBIO_LUGARES, RESEARCHTRUE
    , SUCCESS, CAMBIO_FECHA1, CAMBIO_FECHA2, FILTRAR_PRESTAMOS_MINIMOS, CONSULTAR_LUGARES
} from '../types/ArchivoPagostypes'

const INITIAL_STATE = {
    Reporte: [],
    ListaLugares: [],
    cargando: false,
    error: '',
    lugares_id: '',
    fecha1: '',
    fecha2: '',
    research: '',
    success: ''

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTAR:
            return {
                ...state,
                multas: action.payload,
                cargando: false,
                research: false,
                cantidad: 0,
                fechaprestamo: '',
                concepto: '',

            }

        case FILTRAR_PRESTAMOS_MINIMOS:
            return {
                ...state,
                Reporte: action.payload,
                cargando: false,
                research: false,


            }



        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false, success: '' }


        case SUCCESS:
            return { ...state, success: action.payload, cargando: false, error: '' }


        case GUARDAR:
            return {
                ...state,
                cargando: false,
                error: '',
                cantidad: 0,
                fechaprestamo: '',
                modelos_id: '',
                concepto: '',
                research: true,
                success: ''


            }

        case CONSULTAR_LUGARES:
            return {
                ...state,
                ListaLugares: action.payload,

            }


        case CAMBIO_FECHAPRESTAMO:
            return {
                ...state,
                fechaprestamo: action.payload,

            }

        case CAMBIO_FECHA1:
            return {
                ...state,
                fecha1: action.payload,

            }

        case CAMBIO_FECHA2:
            return {
                ...state,
                fecha2: action.payload,

            }


            case CAMBIO_LUGARES:
                return {
                    ...state,
                    lugares_id: action.payload,
    
                }
        case RESEARCHTRUE
            :
            return {
                ...state,

                multas: [],
                vermultas: [],
                cargando: false,
                error: '',
                lugares_id: '',
                fecha1: '',
                fecha2: '',
                success: '',
                research: '',



            }


        default: return state
    }

}