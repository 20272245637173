import {
  CARGANDO,
  ERROR,
  GUARDAR,
  CUENTA_CREADA,
  CONSULTAR_NOMBRE_MODELO,
  CONSULTAR_CUENTAS_CHATURBATE,
  CONSULTAR_CUENTAS_STRIPCHAT,
  CONSULTAR_CUENTAS_BONGA,
  CONSULTAR_CUENTAS_CAM4,
  CONSULTAR_CUENTAS_STREAMATE,
  CAMBIO_USUARIO_ID,
  CAMBIO_PASSWORD,
  RESEARCHTRUE,
  CARGANDO_CUENTAS,
  ACTUALIZAR,
  SUCCESS,
  CONSULTARS,
  CORREOS_CUENTAS,
  MODELO_CUENTA_REPETIDA,
  FLAG_CUENTAS,
  BUSCAR_CUENTAS,
  CONSULTAR_CUENTAS_FLIRT,
} from "../types/CuentasTypes";

const INITIAL_STATE = {
  SuggestionDatabase: [],
  nombreModelo: [],
  cuentasvinculadas: [],
  cuentasChaturbate: [],
  cuentasStripchat: [],
  cuentasCam4: [],
  cuentasStreamate: [],
  cuentasFlirt: [],
  cuentasBonga: [],
  cuentasCreada: "",
  cargando: false,
  error: "",
  user_cuenta: "",
  password_cuenta: "",
  modelos_id: "",
  plataformas_id: "",
  extra1: "",
  extra2: "",
  research: true,
  success: "",
  modeloRepetido: "",
  buscarcuentas: true,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONSULTAR_NOMBRE_MODELO:
      return {
        ...state,
        nombreModelo: action.payload,
        cargando: false,
        research: false,
      };

      case CONSULTARS:
        return {
            ...state,
            SuggestionDatabase: action.payload,

        }


        case CORREOS_CUENTAS:
          return {
            ...state,
            cuentasvinculadas: action.payload,
            cargando: false,
          };

    case CUENTA_CREADA:
      return {
        ...state,
        cuentasCreada: action.payload,
        cargando: false,
      };
    case MODELO_CUENTA_REPETIDA:
      return {
        ...state,
        modeloRepetido: action.payload,
      };

    case CONSULTAR_CUENTAS_CHATURBATE:
      return {
        ...state,
        cuentasChaturbate: action.payload,
        cargando: false,
        research: false,
        error: "",
      };

    case FLAG_CUENTAS:
      return {
        ...state,
        FLAG_CUENTAS: action.payload,
      };

    case CONSULTAR_CUENTAS_STRIPCHAT:
      return {
        ...state,
        cuentasStripchat: action.payload,
        cargando: false,
        research: false,
        error: "",
      };

    case CONSULTAR_CUENTAS_CAM4:
      return {
        ...state,
        cuentasCam4: action.payload,
        cargando: false,
        research: false,
        error: "",
      };

    case CONSULTAR_CUENTAS_BONGA:
      return {
        ...state,
        cuentasBonga: action.payload,
        cargando: false,
        research: false,
        error: "",
      };

    case CONSULTAR_CUENTAS_STREAMATE:
      return {
        ...state,
        cuentasStreamate: action.payload,
        cargando: false,
        research: false,
        buscarcuentas: true,
        error: "",
      };

    case CONSULTAR_CUENTAS_FLIRT:
      return {
        ...state,
        cuentasFlirt: action.payload,
        cargando: false,
        research: false,
        buscarcuentas: true,
        error: "",
      };

    case BUSCAR_CUENTAS:
      return {
        ...state,
        buscarcuentas: true,
        error: "",
      };

    case CARGANDO:
      return { ...state, cargando: true };

    case CARGANDO_CUENTAS:
      return { ...state, cargando2: true };

    case ERROR:
      return { ...state, error: action.payload, cargando: false, success: "" };

    case SUCCESS:
      return { ...state, success: action.payload, cargando: false, error: "" };

    case GUARDAR:
      return {
        ...state,
        cuentas: [],
        cargando: false,
        error: "",
        user_cuenta: "",
        password_cuenta: "",
        research: true,
      };

    case ACTUALIZAR:
      return {
        ...state,
        cuentas: [],
        cargando: false,
        cargando2: false,
        error: "",
        user_cuenta: "",
        password_cuenta: "",
        research: true,
      };

    case CAMBIO_USUARIO_ID:
      return {
        ...state,
        user_cuenta: action.payload,
      };

    case CAMBIO_PASSWORD:
      return {
        ...state,
        password_cuenta: action.payload,
      };

    case RESEARCHTRUE:
      return {
        ...state,
        cuentas: [],
        cargando: false,
        cargando2: false,
        error: "",
        user_cuenta: "",
        password_cuenta: "",
        modelos_id: "",
        plataformas_id: "",
        extra1: "",
        extra2: "",
        research: true,
        success: "",
      };

    default:
      return state;
  }
};
