import {
  CARGANDO,
  ERROR,
  GUARDAR,
  RESEARCHTRUE,
  CAMBIO_LUGAR_ID,
  CAMBIO_TURNO_ID,
  RELOAD,
  SUCCESS,
  CAMBIO_BANCO,
  CONSULTAR_LISTA_BANCO,
  IMAGEN_PERFIL,
  IMAGEN_FIRMA,
  CONSULTAR_LUGARES,
  CONSULTAR_TURNOS,
  CAMBIO_CEDULA,
  CAMBIO_NOMBRES,
  CAMBIO_NICK_NAME,
  CAMBIO_TELEFONO,
  CAMBIO_APELLIDOS,
  CAMBIO_ARRIENDO,
  CAMBIO_PASSWORDM,
  CAMBIO_CEDULA_CONTABLE,
  CAMBIO_NOMBRES_CONTABLE,
  CAMBIO_APELLIDOS_CONTABLE,
  CAMBIO_DIRECCION,
  CAMBIO_CUENTA_BANCARIA,
  CAMBIO_CUENTA_CORREO,
  CAMBIO_CUENTA_PASSWORDCORREO,
  CAMBIO_ESTADO_CREAR,
  CAMBIO_ESTADO_INICIAL,
  CAMBIO_NOMBRE_FIRMA,
  ID_INFORMACION_CONTABLE,
  INFORMACION_CONTABLE,
  INFORMACION_CONTABLE_FLAG,
  CAMBIO_LUGAR_TRASNMISION,
  CAMBIO_FECHA_INICIAL,
  CAMBIO_FECHA_FINAL,
  IMAGEN_GASTOS,
  GASTOS_OBTENER,
  TOTAL_GASTOS_OBTENER

} from "../types/crearTypes";

const INITIAL_STATE = {
  foto_perfil: '',
  imagen_firma:'',
  firma_modelo:'',
  cedulaModelo: '',
  nombresModelo: '',
  apellidosModelo: '',
  telefonoModelo: '',
  nickModelo: '',
  arriendoModelo: 0,
  passwordPerfil: '',
  cedulaContable:'',
  nombresContables:'',
  apellidosContable:'',
  direccion:'',
  banco:1,
  cuentaBancaria:'',
  correo:'',
  passwordCorreo:'',
  lugares: [],
  ListaBancos:[],
  lugar_transmision:'',
  turnos: [],
  lugares_id: '',
  informacionContableModeloCreado:[],
  idInformacionContableModeloCreado:0,
  idInformacionContableModeloCreadoFlag:0,
  turno_id: '',
  cargando: false,
  estadoFlag:false,
  error: "",
  research: "",
  success: "",
  fechaInicialPago:'',
  fechaFinalPago:'',
  img_gastos:'',
  gastos:[],
  totalGastos:0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {


    case CARGANDO:
      return { ...state, cargando: true };

    case ERROR:
      return { ...state, error: action.payload, cargando: false, success: "" };

    case SUCCESS:
      return { ...state, success: action.payload, cargando: false, error: "" };


    case IMAGEN_PERFIL:
      return {
        ...state,
        foto_perfil: action.payload,
        cargando: false,
        research: false,
      }

      case GASTOS_OBTENER:
        return {
          ...state,
          gastos: action.payload,
          cargando: false,
          research: false,
          success: "" 
        }
        case TOTAL_GASTOS_OBTENER:
          return {
            ...state,
            totalGastos: action.payload,
          }
        
      case IMAGEN_GASTOS:
        return {
          ...state,
          img_gastos: action.payload,
          cargando: false,
          research: false,
        }

      case IMAGEN_FIRMA:
        return {
          ...state,
          imagen_firma: action.payload,
    
        }
        case CAMBIO_NOMBRE_FIRMA:
          return {
            ...state,
            firma_modelo: action.payload,
      
          }


          case CAMBIO_FECHA_INICIAL:
            return {
              ...state,
              fechaInicialPago: action.payload,
        
            }
        
            
            case CAMBIO_FECHA_FINAL:
              return {
                ...state,
                fechaFinalPago: action.payload,
          
              }
            
        
          case INFORMACION_CONTABLE:
      return {
        ...state,
        informacionContableModeloCreado: action.payload,
      }

    case ID_INFORMACION_CONTABLE:
      return {
        ...state,
        error: "",
        idInformacionContableModeloCreado: action.payload,
      }

      case INFORMACION_CONTABLE_FLAG:
        return {
          ...state,
          idInformacionContableModeloCreadoFlag: action.payload,
          cargando: false,
          research: false,
        }

      

        
        case CAMBIO_ESTADO_CREAR:
          return {
            ...state,
            estadoFlag: action.payload,
            cargando: false,
            research: false,
          }

          case CAMBIO_ESTADO_INICIAL:
            return {
              ...state,
              estadoFlag: action.payload,
            }
            case GUARDAR:
              return { ...state, success: "" };
            
  

    case CONSULTAR_LUGARES:
      return {
        ...state,
        lugares: action.payload,
        cargando: false,
        research: false,
      }
      
      case CONSULTAR_LISTA_BANCO:
        return {
          ...state,
          ListaBancos: action.payload,
          cargando: false,
          research: false,
        }
      
    case CONSULTAR_TURNOS:
      return {
        ...state,
        turnos: action.payload,
        cargando: false,
        research: false,
      }

    case CAMBIO_CEDULA:
      return {
        ...state,
        cedulaModelo: action.payload,
      }

    case CAMBIO_NOMBRES:
      return {
        ...state,
        nombresModelo: action.payload,
      }

    case CAMBIO_APELLIDOS:
      return {
        ...state,
        apellidosModelo: action.payload,
      }

    case CAMBIO_TELEFONO:
      return {
        ...state,
        telefonoModelo: action.payload,
      }
    case CAMBIO_NICK_NAME:
      return {
        ...state,
        nickModelo: action.payload,
      }

    case CAMBIO_ARRIENDO:
      return {
        ...state,
        arriendoModelo: action.payload,
      }

    case CAMBIO_PASSWORDM:
      return {
        ...state,
        passwordPerfil: action.payload,
      }

    case CAMBIO_CEDULA_CONTABLE:
      return {
        ...state,
        cedulaContable: action.payload,
        idInformacionContableModeloCreado:0
      }
    case CAMBIO_NOMBRES_CONTABLE:
      return {
        ...state,
        nombresContables: action.payload,
      }
    case CAMBIO_APELLIDOS_CONTABLE:
      return {
        ...state,
        apellidosContable: action.payload,
      }
    case CAMBIO_DIRECCION:
      return {
        ...state,
        direccion: action.payload,
      }
    case CAMBIO_CUENTA_BANCARIA:
      return {
        ...state,
        cuentaBancaria: action.payload,
      }


      case CAMBIO_BANCO:
        return {
          ...state,
          banco: action.payload,
        }
  

      case CAMBIO_LUGAR_TRASNMISION:
        return {
          ...state,
          lugar_transmision: action.payload,
        }

      case CAMBIO_CUENTA_CORREO:
        return {
          ...state,
          correo: action.payload,
        }
      case CAMBIO_CUENTA_PASSWORDCORREO:
        return {
          ...state,
          passwordCorreo: action.payload,
        }


    case CAMBIO_LUGAR_ID:
      return {
        ...state,
        lugares_id: action.payload,
      }

    case CAMBIO_TURNO_ID:
      return {
        ...state,
        turno_id: action.payload,
      }





    case RESEARCHTRUE:
      return {
        ...state,

        foto_perfil: '',
        imagen_firma:'',
        firma_modelo:'',
        cedulaModelo: '',
        nombresModelo: '',
        apellidosModelo: '',
        telefonoModelo: '',
        nickModelo: '',
        arriendoModelo: 0,
        passwordPerfil: '',
        cedulaContable:'',
        nombresContables:'',
        apellidosContable:'',
        direccion:'',
        cuentaBancaria:'',
        correo:'',
        passwordCorreo:'',
        lugares: [],
        turnos: [],
        lugares_id: '',
        turno_id: '',
        cargando: false,
        estadoFlag:false,
        error: "",
        research: "",
      };

    case RELOAD:
      return {
        ...state,

        cargando: false,
        research: true
      };
    default:
      return state;
  }
};
