import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as reportesdiarioaction from '../../actions/ReportesDiarioStudioAction'
import Spinner from '../../General/Spinner'
import Fatal from '../../General/Fatal'
import fechaImg from "../../img/reporteDiarioImg.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles.scss'
import Success from '../../General/Success'
import { Redirect } from 'react-router-dom'
import DatePicker from 'react-date-picker';
import {  FaFileUpload } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import moment from 'moment'


/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */









export class ReporteDiario extends Component {

    state = {
        dateStart: new Date(),
        Entrega: '',
        modeloTurno: '',
        Recive: '',
        novedades: '',
        dolares: '',
        horario_id: '',
        fecha_novedad: '',
        reportesdiarios: [],
        novedadTexto:''

    }

    componentDidMount() {

        const {
            consultar_horarios,
       } = this.props
        consultar_horarios();
        this.cambiofecha1(this.state.dateStart)
   }


   async componentDidUpdate(prevState) {
        console.log(this.props)
        const {
            fecha1,
            horarios_id,
            consultar_novedades_fechas

        } = this.props

        const datosNovedades = {
            fecha_novedad: fecha1,
            horario_id: horarios_id


        }

       if(!this.props.ResearNovedades){
        await consultar_novedades_fechas(datosNovedades)
       }
  

    }

    handleChange(index, dataType, value) {


        this.props.handleChange(index, dataType, value)

    }

    componentWillUnmount() {

        const {
            researchtrue,
        } = this.props

        researchtrue()
    }


    cambioHorarios = (event) => {
        this.props.cambioHorarios(event.target.value)
    }

    filtrarReportesDiarios = () => {

        const {
            fecha1,
            horarios_id,
            filtrarReportesDiarios,
            consultar_novedades_fechas

        } = this.props
        const porpsReportesDiarios = {
            fecha: fecha1,
            horario_id: horarios_id


        }
        const datosNovedades = {
            fecha_novedad: fecha1,
            horario_id: horarios_id


        }

        this.setState({ horario_id: horarios_id })
        this.setState({ fecha_novedad: fecha1 })
        filtrarReportesDiarios(porpsReportesDiarios)
        consultar_novedades_fechas(datosNovedades)
    }

    handleCrearReporte = () =>{
        const {
            fecha1,
            horarios_id,
            crearRporteNovedad,

        } = this.props
        const dataNovedad = {
            fecha_novedad: fecha1,
            horario_id: horarios_id,
            novedad_turno:this.state.novedadTexto
       }

        crearRporteNovedad(dataNovedad)

        this.setState({ novedadTexto: '' })
    }


    cambioNovedadText = (event) => {
        this.setState({ novedadTexto: event.target.value })
    }
    

    handleSubmit(e) {
        e.preventDefault()
    }


    listaHorarios = () => {
        const { ListaHorarios } = this.props;

        const poneropcionesHorarios = () =>
            ListaHorarios.map((horarios, key) => (
                <option className='option-values' key={key} value={horarios.id}>
                    {horarios.nombre}
                </option>
            ));

        return (
            <select
                className="right-submenu"
                name="select"
                onChange={this.cambioHorarios}
                required
            >
                <option className='option-values'>
                    Horarios
                </option>
                {poneropcionesHorarios()}
            </select>
        );
    };

    cambiofecha1 = date => {
        this.setState({ dateStart: date })
  
        this.props.cambiofecha1((moment(date).format('YYYY/MM/DD') ));
    };

    handleEditar = (id, e) => {
        const { editar } = this.props;
        const UploaddDatosReporte = {
            entrega: document.getElementById(`Entrega${id}`).value,
            modeloTurno: document.getElementById(`ModeloTurno${id}`).value,
            recibe: document.getElementById(`Recibe${id}`).value,
            novedades_turno: document.getElementById(`Novedades${id}`).value,
            dolares: document.getElementById(`Dolares${id}`).value
        };
        e.preventDefault();

        editar(UploaddDatosReporte, id);


    };

    ponerReporte = () => {
        const { ReporteDiario,

        } = this.props
        const ponerInfo = () => ReporteDiario.map((reporte, key) => (


            <tr key={key}>
                <td>{reporte.numero_habitacion}</td>
                <td>
                    <input
                        type="text"
                        id={`Entrega${reporte.id}`}
                        value={this.props.ReporteDiario[key].entrega || ''}
                        onChange={(e) => this.handleChange(key, 'entrega', e.target.value)}
                    />
                </td>
                <td>
                    <input
                        type="text"
                        id={`ModeloTurno${reporte.id}`}
                        value={this.props.ReporteDiario[key].modelo_turno || ''}
                        onChange={(e) => this.handleChange(key, 'modelo_turno', e.target.value)}

                    />{" "}


                </td>
                <td>
                    <input
                        type="text"
                        id={`Recibe${reporte.id}`}
                        value={this.props.ReporteDiario[key].recibe || ''}
                        onChange={(e) => this.handleChange(key, 'recibe', e.target.value)}
                    />{" "}
                </td>
                <td>
                    <input
                        type="text"
                        id={`Novedades${reporte.id}`}
                        value={this.props.ReporteDiario[key].novedades_turno || ''}
                        onChange={(e) => this.handleChange(key, 'novedades_turno', e.target.value)}
                    />
                </td>
                <td>
                    <input
                        type="text"
                        id={`Dolares${reporte.id}`}
                        value={this.props.ReporteDiario[key].dolares || ''}
                        onChange={(e) => this.handleChange(key, 'dolares', e.target.value)}
                    />
                </td>
                <td>

                    <span
                        onClick={e => this.handleEditar(reporte.id, e)}
                        className="btnactualizarreport"
                    >
                        <FaFileUpload size={"16px"} /> </span>
                </td>


            </tr>

        ))

        return (ponerInfo())
    }

    ponerNovedades = () => {
        const { Novedades,

        } = this.props
        const ponerInfo = () => Novedades.map((novedades, key) => (


            <tr key={key}>
                <td><span>{novedades.novedad_turno}</span></td>
            </tr>

        ))

        return (ponerInfo())
    }

    Ponerlfechasreporte = () => {


        return <div><form onSubmit={(e) => this.handleSubmit(e)}>
            <fieldset className="form-fieldset">
                <div>

                    <div className=" form-input select__places ">
                        {this.listaHorarios()}
                    </div>
                    <DatePicker
                        onChange={this.cambiofecha1}
                        value={this.state.dateStart}
                    />
                </div>


            </fieldset>

        </form>
        </div>
    }



    mostrarAccion = () => {


        const { error, cargando, success } = this.props
        if (cargando) {
            return <Spinner />;
        }


        if (success) {
            return <Success mensaje={success} />;
        }


        if (error) {
            return <Fatal mensaje={error} />;
        }
        return false;
    }




    render() {

        console.log(this.state)

        return (



            <Fragment>
                {(!window.localStorage.getItem('token')) ? <Redirect to='/' /> : ''}
                <div className='main-content fade-in'>
                    <h1 className="tituloPaginas">
                        REPORTE <strong className="tituloPaginasPequeño">DIARIO</strong>
                    </h1>
                    <div className='reporte__div__principal'>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-3 ">
                                    {this.Ponerlfechasreporte()}
                                   
                                </div>

                                <div className="col-md-2 center__reportes__botones">
                                    <div className="btn__reportes__diario__div">
                                        <button className="btn__reportes_diario" type="submit"
                                            onClick={this.filtrarReportesDiarios} >Buscar</button>
                                    </div>

                                </div>
                                <div className="col-md-3 ">
                                    <img alt='imagen_reporte_diario_fecha' src={fechaImg} className='img_reporte_diario_fecha'></img>
                                    <div className='fecha__reporte__diario'>{this.props.fecha1}</div>
                                </div>
                            </div>
                        </div>


                    </div>
                    {this.mostrarAccion()}
                    <div className=''>
                        <div className="container-fluid">
                            <div className="row justify-content-center">

                                <div className="col-md-12 ">

                                    <table className="table table-striped tabla-global" >
                                        <thead>
                                            <tr>
                                                <th>ROOM </th>
                                                <th>QUIEN ENTREGA</th>
                                                <th>MODELO </th>
                                                <th>QUIEN RECIBE </th>
                                                <th>NOVEDADES </th>
                                                <th>DÓLARES </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.ponerReporte()}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="col-md-6 ">
                                    <div className='time__Pick__calendar__divs__text'>

                                        <input onChange={this.cambioNovedadText} value={this.state.novedadTexto} className='time__Pick__calendar__text' type='text'></input>
                                        <span className="Home_Work_Stage__insideDiv__icon__add" onClick={e => this.handleCrearReporte(e)} ><IoMdAdd size={"32px"} /></span>
                                    </div>
                                    <table className="table table-striped tabla__global__reportes" >
                                        <thead>

                                        </thead>
                                        <tbody>
                                            {this.ponerNovedades()}
                                        </tbody>
                                    </table>

                                </div>
                            </div>




                        </div>


                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = (reducers) => {
    return reducers.ReportesDiarioStudioReducer
}

export default connect(mapStateToProps, reportesdiarioaction)(ReporteDiario)