import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as nominaaction from "../../actions/NominaAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import imgNominaModelos from "../../img/imgNominaModelos.png";
import imgNominaMonitores from "../../img/imgNominaMonitores.png";
import imgNominaGanancias from "../../img/imgNominaGanancias.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import Modal from "react-bootstrap/Modal";


/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */

export class Nomina extends Component {


  state = {
    show: false,
    year:0,
    mes:0,
    dateStart: new Date(),
    dateEnd: new Date(),
    id_lugar:'0',
  }



  showModal = (e) => {
    this.setState({ show: true});
   this.props.consultar_ganancias(this.state.year,this.state.mes,this.state.id_lugar)
  };

  hideModal = () => {
    this.setState({ show: false });

  };

  componentDidMount() {
    const {
      consultar_fechas_pagos,
      consultar_fechas_pagos_monitores,
      consultar_year,
      consultar_lugares
    } = this.props
    consultar_year()
    consultar_fechas_pagos();
    consultar_fechas_pagos_monitores()
    consultar_lugares()


  }

  componentDidUpdate() {
    console.log(this.props);


  }

  componentWillUnmount() {
    const { researchtrue } = this.props;

    researchtrue();
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  pagarNomina = () => {
    const { fecha1, fecha2, dolar, pagar } = this.props;
    const infopagos = {
      fecha_inicial: fecha1,
      fecha_final: fecha2,
      dolar: dolar
    };
    pagar(infopagos);
  };



  cambioFechaPagos = (event) => {
    this.props.cambioFechaPagos(event.target.value)
  }
  cambioFechaPagosMonitores = (event) => {
    this.props.cambioFechaPagosMonitores(event.target.value)
  }
  descargarExcelMonitores = (e, id) => {
    e.preventDefault();
    this.props.descargarExcel(id)
  }
  descargarExcelModelos = (e, id) => {
    e.preventDefault();
    this.props.descargarExcelModelos(id)
  }


  listaLugares = () => {
    const { ListaLugares } = this.props;

    const poneropcioneslugaress = () =>
        ListaLugares.map((lugares, key) => (
            <option className='option-values' key={key} value={lugares.id}>
                {lugares.nombre}
            </option>
        ));

    return (
        <select
            className="right-submenu" 
            style={{border:'none'}}
            name="select"
            onChange={ 
              (e)=>{
                this.setState({
                  id_lugar: e.target.value
                })
              }
           
            }
            required
        >
            <option className='option-values' value='0'>
                Lugares
            </option>
            {poneropcioneslugaress()}
        </select>
    );
};

  listaFechasPagos = () => {
    const { ListaFechasPagos } = this.props;

    const ponerOpcionesFechas = () =>
      ListaFechasPagos.map((fechas, key) => (
        <option className='option-values' key={key} value={fechas.id}>
          {fechas.fecha}
        </option>
      ));

    return (
      <select
        className="lista__fechas__nomina__modelos"
        name="select"
        onChange={this.cambioFechaPagos}
        required
      >
        <option className='option-values'>
          Fechas De Pagos
            </option>
        {ponerOpcionesFechas()}
      </select>
    );
  };

  listaFechasPagosMonitores = () => {
    const { ListaFechasPagosMonitores } = this.props;

    const ponerOpcionesFechas = () =>
      ListaFechasPagosMonitores.map((fechas, key) => (
        <option className='option-values' key={key} value={fechas.id}>
          {fechas.fecha}
        </option>
      ));

    return (
      <select
        className="lista__fechas__nomina__modelos"
        name="select"
        onChange={this.cambioFechaPagosMonitores}
        required
      >
        <option className='option-values'>
          Fechas De Pagos
            </option>
        {ponerOpcionesFechas()}
      </select>
    );
  };



  
  listaYear = () => {
    const { years } = this.props;

    const ponerOpcionesFechas = () =>
    years.map((fechas, key) => (
        <option className='option-values' key={key} value={fechas.year}>
          {fechas.year}
        </option>
      ));

    return (
      <select
        className="lista__fechas__nomina__modelos"
        name="select"
        onChange={(e)=>this.setState({ year: e.target.value})}
        
      >
        <option className='option-values'value=''>
          Año
            </option>
        {ponerOpcionesFechas()}
      </select>
    );
  };


  listaMes = () => {
    return (
      <select
        className="lista__fechas__nomina__modelos"
        style={{marginTop:'0'}}
        name="select"
        onChange={(e)=>this.setState({ mes: e.target.value})}
        
      >
           <option className='option-values'value=''>
          Mes
            </option>
        <option className='option-values'value='1'>
          Enero
            </option>
            <option className='option-values'value='2'>
          Febrero
            </option>
            <option className='option-values'value='3'>
          Marzo
            </option>
            <option className='option-values'value='4'>
          Abril
            </option>
            <option className='option-values'value='5'>
          Mayo
            </option>
            <option className='option-values'value='6'>
          Junio
            </option>
            <option className='option-values'value='7'>
          Julio
            </option>
            <option className='option-values'value='8'>
          Agosto
            </option>
            <option className='option-values'value='9'>
          Septiembre
            </option>
            <option className='option-values'value='10'>
          Octubre
            </option>
            <option className='option-values'value='11'>
          Noviembre
            </option>
            <option className='option-values'value='12'>
          Diciembre
            </option>
 
      </select>
    );
  };

  mostrar_ganancias_plataforma=()=>{
    const { ganaciasPlataforma,

    } = this.props
    const ponerInfo = () => ganaciasPlataforma.map((reporte, key) => (


        <tr key={key}>
            <td>{reporte.year}</td>
            <td>{reporte.mes} </td>
            <td>{reporte.fecha} </td>
            <td>{reporte.cantidad} </td>
            <td>{reporte.dolar} </td>
            <td>{reporte.dolar*reporte.cantidad} </td>
     
            
  

        </tr>

    ))

    return (ponerInfo())

  }

  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {
    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            NÓMINA <strong className="tituloPaginasPequeño">RECIBOS</strong>
          </h1>
          <div className="reporte__div__principal">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 justify-content-md-center paddingPagos">
                  <img alt='imagen nomina modelos' className='img__nomina__modelos' src={imgNominaModelos}></img>
                  {this.listaFechasPagos()}

                  <div className="btn__reportes__div__pagos">
                    <Link
                      to={`/NominaRecibos/${this.props.fechas_pagos_id}`}
                      className="btn__nemoina">Imprimir Recibos</Link>
                  </div>

                  <div className="btn__reportes__div__pagos">
                    <Link
                      to={`/NominaRecibosPlanilla/${this.props.fechas_pagos_id}`}
                      className="btn__nemoina">Planilla</Link>

                  </div>
                  <div className="btn__reportes__div__pagos">

                    <button
                      className="btn__nemoina"
                      type="submit"
                      onClick={(e) => this.descargarExcelModelos(e, this.props.fechas_pagos_id)}
                    >
                      Excel
               </button>
                  </div>
                </div>

                <div className="col-md-4 justify-content-md-center paddingPagos">

                  <img alt='imagen nomina monitores' className='img__nomina__monitores' src={imgNominaMonitores}></img>

                  {this.listaFechasPagosMonitores()}

                  <div className="btn__reportes__div__pagos">
                    <Link
                      to={`/NominaRecibosMonitores/${this.props.fechas_pagos_monitores_id}`}
                      className="btn__nemoina">Imprimir Recibos</Link>
                  </div>

                  <div className="btn__reportes__div__pagos">
                    <Link
                      to={`/NominaRecibosMonitoresPlanilla/${this.props.fechas_pagos_monitores_id}`}
                      className="btn__nemoina">Planilla</Link>
                  </div>
                  <div className="btn__reportes__div__pagos">

                    <button
                      className="btn__nemoina"
                      type="submit"
                      onClick={(e) => this.descargarExcelMonitores(e, this.props.fechas_pagos_monitores_id)}
                    >
                      Excel
               </button>
                  </div>
                </div>


                <div className="col-md-4 justify-content-md-center paddingPagos">

                  <img alt='imagen nomina monitores' className='img__nomina__monitores' src={imgNominaGanancias}></img>

                  {this.listaYear()}
                  {this.listaMes()}
                  {this.listaLugares()}

                  <div className="btn__reportes__div__pagos">
                    <Link
                      to={`#`}
                      onClick={e=>{this.showModal()}}
                      className="btn__nemoina">ver Ganacias</Link>
                  </div>
                </div>
              </div>
              {this.mostrarAccion()}
            </div>
          </div>
        </div>

        <Modal show={this.state.show} onHide={this.hideModal} 
         size="xl"
         aria-labelledby="contained-modal-title-vcenter"
         centered>
          <Modal.Header closeButton>
            <Modal.Title>Mis Ganacias: {this.props.ganaciasPlataformaTotal} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <table  className="table table-striped tabla-global" >
                                        <thead>
                                            <tr>
                                                <th>Año </th>
                                                <th>Mes</th>
                                                <th>Fecha</th>
                                                <th>Ganancia</th>
                                                <th>Dolar</th>
                                                <th>Ganancia Pesos</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.mostrar_ganancias_plataforma()}
                                        </tbody>
                                    </table>
        
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.hideModal}
              className="btn__modal btn-secondary"
            >
              cerrar
                    </button>

          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.NominaReducer;
};

export default connect(mapStateToProps, nominaaction)(Nomina);
