import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as perfilModificarInfoMonitorAction from "../../actions/perfilModificarInfoMonitorAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import cambiarFotoImg from "../../img/cambiarFotoImg.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import {URL} from '../../../src/General/url'
/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */

export class ModificarInfoMonitor extends Component {


  state = {
    dateStart: new Date(),
    dateEnd: new Date(),
  }

  componentDidMount() {
    const {
      match: {
        params: { model_id }
      },
      consultarModeloInformacion,

    } = this.props
    consultarModeloInformacion(model_id);
  


  }

  componentDidUpdate() {
 
    console.log(this.props);


  }

  componentWillUnmount() {
    const { researchtrue } = this.props;

    researchtrue();
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  pagarNomina = () => {
    const { fecha1, fecha2, dolar, pagar } = this.props;
    const infopagos = {
      fecha_inicial: fecha1,
      fecha_final: fecha2,
      dolar: dolar
    };
    pagar(infopagos);
  };
  handleChange(index, dataType, value) {
    this.props.handleChange(index, dataType, value)
  }
  cambioFotoPerfil = event => {
    const { guardarimagen } = this.props;
    const files = event.target.files[0];
    if (files) {
      const data = new FormData();
      data.append("file0", files);
      guardarimagen(data);
    }

  };

  add_imagen= (event,id) => {
    const { guardarimagenModelo } = this.props;
    const files = event.target.files[0];
    if (files) {
      const data = new FormData();
      data.append("file0", files);
       guardarimagenModelo(data,id);
 
    }

  };


  handleEditar = (id, e,key) => {
    e.preventDefault();
    const { editar } = this.props;
    const datosInformacionModelo = {
      nombre: this.props.informacionModelo[0].nombre,
      apellidos: this.props.informacionModelo[0].apellidos,
      cuentabancaria: this.props.informacionModelo[0].cuentabancaria,
      profile_picture: this.props.informacionModelo[0].profile_picture,
      salario: this.props.informacionModelo[0].salario,
      foto_perfil: this.props.informacionModelo[0].foto_perfil,
      estado: this.props.informacionModelo[0].estado,
      tipo: this.props.informacionModelo[0].tipo,
 

      /*     
          
          
          
           */

    };


    editar(datosInformacionModelo, id);


  };


  cambioLugarId = (event) => {
    this.props.cambioLugarId(event.target.value)
  }

  cambioTurnoId = (event) => {
    this.props.cambioTurnoId(event.target.value)
  }

  ponerNomnreModelo = () => {
    const { informacionModelo,

    } = this.props
    const ponerNombre = () =>
      informacionModelo.map((modelo, key) => (
        <div className="" key={key}>
          <div className='nombre__reporte__container__modificarinfo'> <div className='nombre__div__reporte'> UPDATE </div> <div className='triangulo__report__container'>  </div> <span className='nombre__modelo__div'> {`${modelo.nombre} ${modelo.apellidos}`} </span>   </div>
        </div>
      ));

    return (ponerNombre())
  }

  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  listaEstado = (key) => {

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        required
        onChange={(e) => this.handleChange(key, 'estado', e.target.value)}
      >
        <option className='option-values' value={this.props.informacionModelo[0].estado}>
          {this.props.informacionModelo[0].estado ? this.MaysPrimera('Activ@'.toLowerCase()) : this.MaysPrimera('Inactiv@'.toLowerCase())}
        </option>
        <option className='option-values' value='1'>
          {this.MaysPrimera('Activ@'.toLowerCase())}{}
        </option>
        <option className='option-values' value='0'>
          {this.MaysPrimera('Inactiv@'.toLowerCase())}{}
        </option>

      </select>
    );
  };

  listaTipo = (key) => {

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        required
        onChange={(e) => this.handleChange(key, 'tipo', e.target.value)}
      >
        <option className='option-values' value={this.props.informacionModelo[0].tipo}>
          {this.props.informacionModelo[0].tipo === 1 ? this.MaysPrimera('Monitor'.toLowerCase()) : this.MaysPrimera('Otro'.toLowerCase())}
        </option>
        <option className='option-values' value='1'>
          {this.MaysPrimera('Monitor'.toLowerCase())}{}
        </option>
        <option className='option-values' value='2'>
          {this.MaysPrimera('Otro'.toLowerCase())}{}
        </option>

      </select>
    );
  };

  
  ponerInformacionModelo = () => {
    const { informacionModelo,

    } = this.props
    const ponerInfo = () => informacionModelo.map((Monitor, key) => (
      <Fragment key={key}>
        <div className="col-md-3 ">

          <img
            className="avatarModeloimg"
            alt="imgagerfil"
            src={`${URL}getImage/${
              Monitor.profile_picture
                ? Monitor.profile_picture
                : "defaultLogo.png"
              }`}
          />
          <label className='foto_perfil_modelo_label' htmlFor="foto_perfil_modelo">
            <img   src={cambiarFotoImg} alt="Click aquí para subir tu foto" title="Click aquí para subir tu foto" />
          </label>
          <input

            className="form-element-field -hasvalue"
            id={`foto_perfil_modelo`}
            type="file"
            onChange={this.cambioFotoPerfil}
          />

        </div>

        <div className="col-md-4 ">
          <div className='informacion_personal_modificar_info_modelo'>
            <div className='div__personal__modificar__info'><div className='text__rigth'>Cédula  </div>   <input
              type="text"
              readOnly
              className='input__modificiar_informacion_modelo'
              value={Monitor.cedula || ''}
            /></div>
            <div className='div__personal__modificar__info'><div className='text__rigth'>Nombre  </div>   <input
              type="text"
              className='input__modificiar_informacion_modelo'
              value={Monitor.nombre || ''}
              onChange={(e) => this.handleChange(key, 'nombre', e.target.value)}
            /></div>
            <div className='div__personal__modificar__info'><div className='text__rigth'>Apellidos  </div>   <input
              type="text"
              className='input__modificiar_informacion_modelo'
              value={Monitor.apellidos || ''}
              onChange={(e) => this.handleChange(key, 'apellidos', e.target.value)}
            /></div >
            <div className='div__personal__modificar__info'><div className='text__rigth'>Estado </div> {this.listaEstado(key)}</div>
          </div>
        </div>

        <div className="col-md-4 ">
          <div className='informacion_personal_modificar_info_modelo'>
            <div className='div__personal__modificar__info__derecha'><div className='text__rigth'>Nomina </div> <input
              type="text"
              className='input__modificiar_informacion_modelo'
              value={Monitor.salario || ''}
              onChange={(e) => this.handleChange(key, 'salario', e.target.value)}
            /></div>
                 <div className='div__personal__modificar__info__derecha'><div className='text__rigth'>Cuenta Bancaria </div> <input
              type="text"
              className='input__modificiar_informacion_modelo'
              value={Monitor.cuentabancaria || ''}
              onChange={(e) => this.handleChange(key, 'cuentabancaria', e.target.value)}
            /></div>
            <div className='centrar_verticalmente div__personal__modificar__info__derecha'><div className='text__rigth'>Tipo</div> {this.listaTipo(key)}</div>

          </div>

          <div className="btn__perfil_div">
            <button className="Boton__buscar__perfil__modelos" type="submit"
              onClick={e => this.handleEditar(Monitor.id, e,key)} >Actualizar</button>
          </div>
        </div>

      </Fragment>
    ))

    return (ponerInfo())
  }




  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {

    console.log(this.props);
    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            PERFIL <strong className="tituloPaginasPequeño">MODIFICAR INFO MONITOR</strong>
          </h1>
          {this.ponerNomnreModelo()}
          <div className="reporte__div__principal">

            <div className="container-fluid">
              <div className="row">

                {this.ponerInformacionModelo()}


              </div>



            </div>
          </div>
          {this.mostrarAccion()}
        </div>
    
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.PerfilModificarInfoMonitorReducer;
};

export default connect(mapStateToProps, perfilModificarInfoMonitorAction)(ModificarInfoMonitor);
