export const TRAER_TODAS = 'auth_traer_todas';
export const CARGANDO = 'auth_cargando';
export const ERROR = 'auth_error';
export const CAMBIO_USUARIO_ID = 'auth_cambio_usuario_id';
export const CAMBIO_TITULO = 'auth_cambio_titulo';
export const GUARDAR = 'auth_guardada';
export const ACTUALIZAR = 'auth_actualizar';
export const LIMPIAR = 'auth_limpiar';
export const TOKENVERIFY = 'auth_verify';
export const CERRAR_SESION = 'auth_log_out';
export const PERMISOS = 'pemisos_app';
