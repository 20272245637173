import axios from "axios";
import {
  CARGANDO,
  ERROR,
  CAMBIO_FECHA1,
  RESEARCHTRUE,
  CAMBIO_FECHA2,
  CAMBIO_DOLAR,
  CAMBIO_PAGOID,
  SUCCESS,
  CAMBIO_FECHA_END,
  NOT_FOUND,
  CAMBIO_FECHA_END_EMAILS
} from "../types/PagosTypes";

import {URL} from '../../src/General/url'



export const pagar = infopagos => async dispatch => {
  dispatch({
    type: CARGANDO
  });

  try {
    let json = JSON.stringify(infopagos);
    let params = json;

        

    let headers = {
        "Content-Type": "application/json",
      Authorization: window.localStorage.getItem("token")
    };
    const respuesta = await axios.post(URL + "modelsPayments", params, {
      headers: headers
    });
    

    dispatch({
      type: CAMBIO_PAGOID,
      payload: respuesta.data.data
    });

    if (respuesta.data.status === "success") {
      dispatch({
        type: SUCCESS,
        payload: respuesta.data.message
      });
    }

    if (respuesta.data.status === "Error") {
      dispatch({
        type: ERROR,
        payload: respuesta.data.message
      });
    }
  } catch (error) {
    if (error.message === "Request failed with status code 401") {
      window.localStorage.setItem("token", "");
      window.localStorage.setItem("userData", "");
    } else {
      dispatch({
        type: ERROR,
        payload: error.message
      });
    }
  }
};

export const enviar_correos = infopagos => async dispatch => {
  dispatch({
    type: CARGANDO
  });

  try {
    let json = JSON.stringify(infopagos);
    let params = "json=" + json;
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: window.localStorage.getItem("token")
    };
    const respuesta = await axios.post(URL + "emailsMessage", params, {
      headers: headers
    });
  
    if (respuesta.data.status === "Success") {
      dispatch({
        type: SUCCESS,
        payload: respuesta.data.message
      });
    }

    if (respuesta.data.status === "Error") {
      dispatch({
        type: ERROR,
        payload: respuesta.data.message
      });
    }
  } catch (error) {
    if (error.message === "Request failed with status code 401") {
      window.localStorage.setItem("token", "");
      window.localStorage.setItem("userData", "");
    } else {
      dispatch({
        type: ERROR,
        payload: error.message
      });
    }
  }
};

export const pagarMonitores = infopagos => async dispatch => {
  dispatch({
    type: CARGANDO
  });

  try {
    let json = JSON.stringify(infopagos);
    let params = "json=" + json;
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: window.localStorage.getItem("token")
    };
    const respuesta = await axios.post(URL + "monitoresPayments", params, {
      headers: headers
    });
    

    dispatch({
      type: CAMBIO_PAGOID,
      payload: respuesta.data.data
    });

    if (respuesta.data.status === "success") {
      dispatch({
        type: SUCCESS,
        payload: respuesta.data.message
      });
    }

    if (respuesta.data.status === "Error") {
      dispatch({
        type: ERROR,
        payload: respuesta.data.message
      });
    }
  } catch (error) {
    if (error.message === "Request failed with status code 401") {
      window.localStorage.setItem("token", "");
      window.localStorage.setItem("userData", "");
    } else {
      dispatch({
        type: ERROR,
        payload: error.message
      });
    }
  }
};

export const cambiofecha1  = (fecha1) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA1,
        payload: fecha1
        
    
    })
    
};

export const cambiofecha2  = (fecha2) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA2,
        payload: fecha2
        
    
    })
    
};


export const cambiofechaFiles  = (data) => async(dispatch,) => {
  dispatch({

      type: CAMBIO_FECHA_END,
      payload: data
      
  
  })
  
};


export const cambiofechaEmails  = (data) => async(dispatch,) => {
  dispatch({

      type: CAMBIO_FECHA_END_EMAILS,
      payload: data
      
  
  })
  
};



export const cambiodolar  = (dolar) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_DOLAR,
        payload: dolar
        
    
    })
    
};


export const researchtrue = () => dispatch => {
  dispatch({
    type: RESEARCHTRUE
  });
};

export const enviarCsv = (data,tipo,fecha) => async (dispatch) => {




  dispatch({

      type: CARGANDO


  })

  try {


      let params = data
      let headers = {
          'Content-Type': 'multipart/from-data',
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.post(URL + `storeFilesCscv/${tipo}/${fecha}`, params, {
          headers: headers
      })

      if (respuesta.data.status === "Success") {
        dispatch({
          type: SUCCESS,
          payload: respuesta.data.message
        });
        dispatch({
          type: NOT_FOUND,
          payload: respuesta.data.data
        });
      }
  
      if (respuesta.data.status === "Error") {
        dispatch({
          type: ERROR,
          payload: respuesta.data.message
        });
      }

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}

export const enviarChaturbate = (fecha) => async(dispatch)=>{
   
  dispatch({

      type: CARGANDO
      
  
  })

  try {


      let headers = {
          "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL+`apiChaturCall/${fecha}`,{
          headers: headers
      })
 
      if (respuesta.data.status === "Success") {
        dispatch({
          type: SUCCESS,
          payload: respuesta.data.message
        });
        dispatch({
          type: NOT_FOUND,
          payload: respuesta.data.data
        });
      }
  
      if (respuesta.data.status === "Error") {
        dispatch({
          type: ERROR,
          payload: respuesta.data.message
        });
      }
      
  } catch (error) {
      
   
      if(error.message==="Request failed with status code 401"){
          window.localStorage.setItem('token','')
          window.localStorage.setItem('userData','')
      }else{
          dispatch({

              type: ERROR,
              payload: error.message
              
          
          })
      }
  }
}

/* export const enviarChaturbate = (fecha) => async (dispatch) => {




  dispatch({

      type: CARGANDO


  })

  try {

      let headers = {
          'Content-Type': 'multipart/from-data',
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL + `apichaturcall/${fecha}`, {
          headers: headers
      })

      if (respuesta.data.status === "Success") {
        dispatch({
          type: SUCCESS,
          payload: respuesta.data.message
        });
        dispatch({
          type: NOT_FOUND,
          payload: respuesta.data.data
        });
      }
  
      if (respuesta.data.status === "Error") {
        dispatch({
          type: ERROR,
          payload: respuesta.data.message
        });
      }

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
} */