import axios from "axios";
import {
  CARGANDO,
  ERROR,
  RESEARCHTRUE,
  SUCCESS,
  CONSULTAR_PERMISOS,
  CONSULTAR_PERMISOS_UPDATE,
  RESEARCH_CORREO
  
} from "../types/AlertasPlataformasTypes";

//acciones para tareas

import {URL} from '../../src/General/url'






export const researchtrue = () => dispatch => {
  dispatch({
    type: RESEARCHTRUE
  });
};



export const consultar_correos = () => async (dispatch) => {


    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getAlertsExtension', {
            headers: headers
        })




        dispatch({
            type: CONSULTAR_PERMISOS,
            payload: respuesta.data.data
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}
export const crear_permiso_extension = (data) => async (dispatch) => {



    dispatch({

        type: CARGANDO


    })

    try {

        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }


        const respuesta = await axios.post(URL + 'crearPalabraClave', params, {
            headers: headers
        })
       
        if (respuesta.data.status === "Success") {
            dispatch({

                type: SUCCESS,
                payload: respuesta.data.message


            })

            dispatch({

                type: RESEARCH_CORREO,
                payload: false


            })
      
      

            
        }else if(respuesta.data.status === "Error") {
            dispatch({

                type: ERROR,
                payload: respuesta.data.message


            })


        }






    } catch (error) {

        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}

export const handleChange = (dataType, value) => (dispatch,getState) => {

    const {ListaPermisosUpdate} = getState().PermisosUsuariosReducer

  console.log(value)

    const newinfo = {...ListaPermisosUpdate, [dataType]: value};
  console.log(newinfo)

      dispatch({
        type: CONSULTAR_PERMISOS_UPDATE,
        payload:newinfo
    })


    

   
};


export const eliminarEmailReport = (id) => async (dispatch) => {
    dispatch({
        type: CARGANDO,
    });

    try {
  

        let headers = {
            "Content-Type": "application/json",
            Authorization: window.localStorage.getItem("token"),
        };
        const respuesta = await axios.delete(URL + `deletePalabraCalve/${id}`, {
            headers: headers,
        });
        if (respuesta.data.status === "Success") {
            dispatch({
                type: SUCCESS,
                payload: respuesta.data.message,
            });

            dispatch({

                type: RESEARCH_CORREO,
                payload: false


            })
        }
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
     

    } catch (error) {
        dispatch({
            type: ERROR,
            payload: "Error al intentar realizar esta operación",
        });
    }
};
