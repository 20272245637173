import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as reportesaction from '../../actions/ReportesAction'
import Spinner from '../../General/Spinner'
import Fatal from '../../General/Fatal'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles.scss'
import Success from '../../General/Success'
import { Redirect } from 'react-router-dom'
import DatePicker from 'react-date-picker';
import moment from 'moment'

/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */









export class Multas extends Component {

    state = {
        flagAll:0,
    }

    componentDidMount() {

        const {
            consultar_lugares,
        } = this.props
        consultar_lugares();

        if(!this.props.fecha1){

            this.cambiofecha1(new Date())
        }
        if(!this.props.fecha2){

            this.cambiofecha2(new Date())
        }
       
        

    }


    componentDidUpdate(prevState) {
        console.log(this.props)


    }


    descargarExcelModelosReport=(e,id)=>{
        e.preventDefault();
        const {
            fecha1,
            fecha2,
            lugares_id,

        } = this.props
        const data = {
            fecha_inicial: fecha1,
            fecha_final: fecha2,
            lugares_id: lugares_id


        }
        this.props.descargarExcelModelosReport(data)
      }

    componentWillUnmount() {

        const {
            researchtrue,
        } = this.props

        researchtrue()
    }


    cambioLugares = (event) => {
        this.props.cambioLugares(event.target.value)
    }

    filtrapretamosminimos = () => {

        const {
            fecha1,
            fecha2,
            lugares_id,
            filtrapretamosminimos,

        } = this.props
        const reprotesmodelo = {
            fecha_inicial: fecha1,
            fecha_final: fecha2,
            lugares_id: lugares_id


        }



        filtrapretamosminimos(reprotesmodelo)



    }

    handleSubmit(e) {
        e.preventDefault()
    }


    listaLugares = () => {
        const { ListaLugares ,lugaresTodos} = this.props;

        const poneropcioneslugaress = () =>
            ListaLugares.map((lugares, key) => (
                <option className='option-values' key={key} value={lugares.id}>
                    {lugares.nombre}
                </option>
            ));

        return (
            <select
                className="right-submenu"
                name="select"
                onChange={this.cambioLugares}
                required
            >
                <option className='option-values' value=''>
                    Horarios
                </option>
                  {lugaresTodos?
                    <option className='option-values' value='0'>
                    todos
                   </option>:''
                      
                  }
                {poneropcioneslugaress()}
            </select>
        );
    };

    cambiofecha1 = date => {
   
    
        var fechaFinalString = ''
        if (date) {
            fechaFinalString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        } else {
            fechaFinalString = ''
        }
        this.props.cambiofecha1(fechaFinalString);
    };

    cambiofecha2 = date => {
       
        var fechaFinalString = ''
        if (date) {
            fechaFinalString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        } else {
            fechaFinalString = ''
        }
        this.props.cambiofecha2(fechaFinalString);
    };





    ponerReporte = () => {
        const { Reporte,lugaresTodosTabla

        } = this.props

    
        const ponerInfo = () => Reporte.map((reporte, key) => (


            <tr key={key}>
                <td>{reporte.cedula}</td>
                <td>{reporte.nombres} {reporte.apellidos}</td>
                <td>{reporte.tokens_plataforma1}</td>
                <td>{reporte.tokens_plataforma2}</td>
                <td>{reporte.tokens_plataforma3}</td>
                <td>{reporte.tokens_plataforma4}</td>
                <td>{reporte.tokens_plataforma5}</td>
                <td>{reporte.tokens_plataforma7}</td>
                <td>{reporte.tokens_plataforma8}</td>
                <td>{reporte.Dolars}</td>
                <td>{lugaresTodosTabla?reporte.lugar_name:''}</td>
               
                

            </tr>

        ))

        return (ponerInfo())
    }




    Ponerlfechasreporte = () => {


        return <div><form onSubmit={(e) => this.handleSubmit(e)}>
            <fieldset className="form-fieldset">
                <div>


                    <DatePicker
                        onChange={this.cambiofecha1}
                        value={this.props.fecha1?new Date(this.props.fecha1):''}
                    />
                </div>
                <div>
                    <DatePicker
                        onChange={this.cambiofecha2}
                        value={this.props.fecha2?new Date(this.props.fecha2):''}
                    />

                </div>
                <div className=" form-input select__places ">
                    {/*    <input  className="form-element-field -hasvalue" placeholder=" " type="number" value={this.props.lugares_id}
            onChange={this.cambiocantidad}  /> */}
                    {this.listaLugares()}
                </div>
            </fieldset>

        </form>
        </div>
    }




    mostrarAccion = () => {


        const { error, cargando, success } = this.props
        if (cargando) {
            return <Spinner />;
        }


        if (success) {
            return <Success mensaje={success} />;
        }


        if (error) {
            return <Fatal mensaje={error} />;
        }
        return false;
    }




    render() {

       const {lugaresTodosTabla}=this.props

        return (

 

            <Fragment>
                {(!window.localStorage.getItem('token')) ? <Redirect to='/' /> : ''}
                <div className='main-content fade-in'>
                <h1 className="tituloPaginas">
                REPORTES <strong className="tituloPaginasPequeño">VER</strong>
          </h1>
                    <div className='reporte__div__principal'>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 ">
                                    {this.Ponerlfechasreporte()}
                                    {this.mostrarAccion()}
                                </div>

                                <div className="col-md-2 center__reportes__botones">
                                    <div className="btn__reportes_div">
                                        <button className="btn__reportes_page" type="submit"
                                            onClick={this.filtrapretamosminimos} >Filtrar</button>
                                    </div>
                                    <div className="btn__reportes_div">
                                        <button className="btn__reportes_page" type="submit"
                                            onClick={this.descargarExcelModelosReport} >Descargar Excel</button>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                    <div className=''>
                        <div className="container-fluid">
                            <div className="row">

                                {this.props.total?     <h2 className="titulo__total" >
                TOTAL: <strong className="">{this.props.total}</strong>
          </h2>:""}
                       
                                <div className="col-md-12 ">

                                    <table  className="table table-striped tabla-global" >
                                        <thead>
                                            <tr>
                                                <th>CÉDULA </th>
                                                <th>NOMBRE</th>
                                                <th>CHATURBATE </th>
                                                <th>BONGA </th>
                                                <th>STREAMATE </th>
                                                <th>CAM4 </th>
                                                <th>STRIPCHAT </th>
                                                <th>PAYPAL</th>
                                                <th>FLIRT</th>
                                                <th>TOTAL</th>
                                                <th>{lugaresTodosTabla?'LUGAR':''}</th>

                                                

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.ponerReporte()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = (reducers) => {
    return reducers.ReportesReducer
}

export default connect(mapStateToProps, reportesaction)(Multas)