import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as tokenAgregarAction from "../../actions/tokenAgregarAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import { FaTimes } from "react-icons/fa";
import { MdFileUpload } from "react-icons/md";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";

// Imagine you have a list of languages that you'd like to autosuggest.

// Teach Autosuggest how to calculate suggestions for any given input value.

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.

export class tokenAgregar extends Component {
  constructor() {
    super();

    this.state = {
      nombreLugar: "",
      cantidad1: "",
      porcentaje1: "",
      cantidad2: "",
      porcentaje2: "",
      porcentaje3: "",
      bonificacion: "",
    };
  }

  componentDidMount() {
    this.props.consultar();
  }

  componentDidUpdate() {
    const { researchPrestamo } = this.props;

    if (!researchPrestamo) {
      this.props.consultar();
    }
    console.log(this.state)
  }
  componentWillUnmount() {
    const { researchtrue } = this.props;

    researchtrue();
  }

  cambioNombre = (event) => {
    this.props.cambioNombre(event.target.value);
  };

  cambioNombreLugar = (event) => {
    this.props.cambioNombreLugar(event.target.value);
  };


  handleChange(index, dataType, value) {
    this.props.handleChange(index, dataType, value)
  }
  
  numeros0100 = (value) => {
    console.log('valor de el numero',value)
    const regexp = new RegExp('^[0-9][0-9]?$|^100$', 'gi')
    const text = value
    if (regexp.test(text)) {
      return text
    }else{
      return ""
    }

    
    
  }


  cambioToken = (event) => {
    this.props.cambioToken(event.target.value);
  };

  guardar = (e) => {
    e.preventDefault();
    const { agregarLugar } = this.props;
    const data = {
      nombre: this.state.nombreLugar,
      cantidad1: this.state.cantidad1,
      porcentaje1: this.state.porcentaje1,
      cantidad2: this.state.cantidad2,
      porcentaje2: this.state.porcentaje2,
      porcentaje3: this.state.porcentaje3,
      bonificacion: this.state.bonificacion,

    };
    agregarLugar(data);
  };

  handleSubmit(e) {
    e.preventDefault();
  }

  handledelete(id, e) {
    e.preventDefault();
    const { eliminarLugar } = this.props;

    eliminarLugar(id);
  }


  handleEditar = (id, e,key) => {
    const { editar } = this.props;
    const data = {
      nombre: this.props.lugar[key].nombre,
      cantidad1: this.props.lugar[key].cantidad1,
      cantidad2: this.props.lugar[key].cantidad2,
      porcentaje1: this.props.lugar[key].porcentaje1,
      porcentaje2: this.props.lugar[key].porcentaje2,
      porcentaje3: this.props.lugar[key].porcentaje3,
      bonificacion: this.props.lugar[key].bonificacion,

    };
    e.preventDefault();

    editar(data, id);


  };

  renderPrestamos = () => {
    const { lugar } = this.props;

    if (lugar) {
      const ponerInfo = () =>
        lugar.map((token, key) => (
          <tr key={key}>
            <td>{`${token.nombre}`}</td>
            <td>{ <input
        type="number"
        value={token.cantidad1 || ''}
        onChange={(e) => this.handleChange(key, 'cantidad1', e.target.value)}
      />}</td>
                <td>{ <input
        type="number"
        value={token.porcentaje1 || ''}
        onChange={(e) => this.handleChange(key, 'porcentaje1',this.numeros0100(e.target.value ))}
      />}</td>
            <td>{ <input
        type="number"
        value={token.cantidad2 || ''}
        onChange={(e) => this.handleChange(key, 'cantidad2', e.target.value)}
      />}</td>
              <td>{ <input
        type="number"
        value={token.porcentaje2 || ''}
        onChange={(e) => this.handleChange(key, 'porcentaje2', this.numeros0100(e.target.value ))}
      />}</td>
               <td>{ <input
        type="number"
        value={token.porcentaje3 || ''}
        onChange={(e) => this.handleChange(key, 'porcentaje3', this.numeros0100(e.target.value ))}
      />}</td>

<td>{ <input
        type="number"
        value={token.bonificacion || ''}
        onChange={(e) => this.handleChange(key, 'bonificacion', this.numeros0100(e.target.value ))}
      />}</td>
            <td>
              <span
                className="loans__icon__x"
                onClick={(e) => this.handledelete(token.id, e)}
              >
                <FaTimes className="Home_Work_Stage__insideDiv__icon__x btn_action" />
              </span>

              <span
                className="loans__icon__x"
                onClick={(e) => this.handleEditar(token.id, e, key)}
              >
                <MdFileUpload
                  size={"1vw"}
                  className="Home_Work_Stage__insideDiv__icon__x"
                />
              </span>
            </td>
          </tr>
        ));

      return ponerInfo();
    }
  };

  Ponerlabelguardar = () => {
    // Autosuggest will pass through all these props to the input.

    return (
      <Fragment>
        <div className="col-md-6">
          <input
            className="prestamosGlobar__text"
            placeholder="Nombre"
            type="text"
            value={this.state.nombreLugar}
            onChange={(e) => {
              this.setState({ nombreLugar: e.target.value });
            }}
          />

          <input
            className="prestamosGlobar__text"
            placeholder="cantidad 1"
            type="number"
            value={this.state.cantidad1}
            onChange={(e) => {
              this.setState({ cantidad1: e.target.value });
            }}
          />

          <input
            className="prestamosGlobar__text"
            placeholder="porcentaje 1"
            type="number"
            value={this.state.porcentaje1}
            onChange={(e) => {   
              this.setState({ porcentaje1: this.numeros0100(e.target.value ) });      
             
            
            }}
          />

          <input
            className="prestamosGlobar__text"
            placeholder="cantidad 2"
            type="number"
            value={this.state.cantidad2}
            onChange={(e) => {
              this.setState({ cantidad2: e.target.value });
            }}
          />

          <input
            className="prestamosGlobar__text"
            placeholder="porcentaje 2"
            type="number"
            value={this.state.porcentaje2}
            onChange={(e) => {
              this.setState({ porcentaje2: e.target.value });
            }}
          />

          <input
            className="prestamosGlobar__text"
            placeholder="porcentaje 3"
            type="number"
            value={this.state.porcentaje3}
            onChange={(e) => {
              this.setState({ porcentaje3: e.target.value });
            }}
          />

<input
            className="prestamosGlobar__text"
            placeholder="bonificación"
            type="number"
            value={this.state.bonificacion}
            onChange={(e) => {
              this.setState({ bonificacion: e.target.value });
            }}
          />

   <div className="btn__reportes_div">
            <button
              className="btn__reportes_page"
              type="submit"
              onClick={(e) => this.guardar(e)}
            >
              Guardar
            </button>
         
          </div>
    
          {this.mostrarAccion()}
        </div>

        <div className="col-md-6 ">

          <div style={{marginTop:"4vw"}}>{`si los dolares generados por el modelo son <  a `} <strong>{`${this.state.cantidad1}`}</strong> {`el  porcenteje a  pagar es `} <strong>{`${this.state.porcentaje1}%`}</strong></div>
          <div>{`si los dolares generados por el modelo son >  a `} <strong>{`${this.state.cantidad1}`}</strong> {`y < `} <strong>{`${this.state.cantidad2}`}</strong>  {`el  porcenteje a  pagar es `} <strong>{`${this.state.porcentaje2}%`}</strong></div>
          <div>{`si los dolares generados por el modelo son >  a `} <strong>{`${this.state.cantidad2}`}</strong> {`el  porcenteje a  pagar es `} <strong>{`${this.state.porcentaje3}%`}</strong></div> 
          <div>{`si el modelo llega al ultimo tramo y no tiene multas recibira una bonificación de `} <strong>{`${this.state.bonificacion}%`}</strong> </div> 
      
        </div>
      </Fragment>
    );
  };

  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {
    return (
      <Fragment>
        <div className="main-content fade-in">
          <h1 className="tituloPaginas">
            SEDE <strong className="tituloPaginasPequeño">AGREGAR</strong>
          </h1>
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <div className="reporte__div__principal">
            <div className="container-fluid">
              <div className="row">
                {this.Ponerlabelguardar()}
               
                <div className="col-md-12 ">
                  <table className="table table-striped tabla-global">
                    <thead>
                      <tr>
                        <th>NOMBRE</th>
                        <th>CANTIDAD 1 </th>
                        <th>PORCENTAJE 1</th>
                        <th>CANTIDAD 2 </th>
                        <th>PORCENTAJE 2 </th>
                        <th>PORCENTAJE 3</th>
                        <th>BONIFICACIÓN</th>
                        <th>ACCIÓN</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderPrestamos()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (reducers) => {
  return reducers.tokenAgregarReducer;
};

export default connect(mapStateToProps, tokenAgregarAction)(tokenAgregar);
