export const CONSULTAR = 'report_total_no_deductions_traer_todas';
export const CARGANDO = 'report_total_no_deductions_cargando';
export const ERROR = 'report_total_no_deductions_error';
export const GUARDAR = 'report_total_no_deductions_guardada';
export const ACTUALIZAR = 'report_total_no_deductions_actualizar';
export const LIMPIAR = 'report_total_no_deductions_limpiar';
export const RESEARCHTRUE = 'report_total_no_deductions_research';
export const SUCCESS = 'report_total_no_deductions_succes';
export const CAMBIO_FECHAPRESTAMO = 'report_total_no_deductions_fecha';
export const CAMBIO_CANTIDAD = 'report_total_no_deductions_cantidad';
export const CAMBIO_CONCEPTO = 'report_total_no_deductions_concepto';
export const CAMBIO_FECHA1 = 'report_total_no_deductions_fecha1';
export const CAMBIO_FECHA2 = 'report_total_no_deductions_fecha2';
export const FILTRAR_PRESTAMOS_MINIMOS = 'report_total_no_deductions_minimos_filtrar';
export const CAMBIO_LUGARES = 'report_total_no_deductions_cambio_lugares';
export const CONSULTAR_LUGARES = 'report_total_no_deductions_consultar_lugares';




