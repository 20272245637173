import {
    CARGANDO, ERROR, GUARDAR, CONSULTAR, RESEARCHTRUE, CAMBIO_CONCEPTO, RELOAD
    , SUCCESS, RESEARCHCALENDARIO,CAMBIO_FECHA_INCIO,CAMBIO_FECHA_FINAL,CAMBIO_EVENTO_TEXT,ACTUALIZAR
} from '../types/CalendarioTypes'

const INITIAL_STATE = {
    eventos: [],
    vertareas: [],
    cargando: false,
    error: '',
    eventoText: '',
    fechaInicio: '',
    fechaFinal: '',
    researchCalendarios: false,
    success: '',
    newSearch:false

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTAR:
            return {
                ...state,
                eventos: action.payload,
                cargando: false,
                researchCalendarios: true,
                newSearch:false


            }




        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false, success: '' }


        case SUCCESS:
            return { ...state, success: action.payload, cargando: false, error: ''}


        case GUARDAR:
            return {
                ...state,
                cargando: false,
                error: '',
                eventoText: '',
                research: true,
                newSearch:true


            }

            case ACTUALIZAR:
                return {
                    ...state,
                    cargando: false,
                    error: '',
                    research: true,
                    newSearch:true
    
    
                }




        case CAMBIO_CONCEPTO:
            return {
                ...state,
                concepto: action.payload,

            }
        case CAMBIO_FECHA_INCIO:
            return {
                ...state,
                fechaInicio: action.payload,

            }
        case CAMBIO_FECHA_FINAL:
            return {
                ...state,
                fechaFinal: action.payload,

            }
        case CAMBIO_EVENTO_TEXT:
            return {
                ...state,
                eventoText: action.payload,

            }

        case RESEARCHTRUE
            :
            return {
                ...state,
                eventos: [],
                vertareas: [],
                cargando: false,
                error: '',
                eventoText: '',
                fechaInicio: new Date(),
                fechaFinal: '',
                researchCalendarios: false,
                success: '',
                newSearch:false



            }

        case RELOAD
            :
            return {
                ...state,
                cargando: false,
                research: true,
            }

        case RESEARCHCALENDARIO
            :
            return {
                ...state,
                eventos: [],
                cargando: false,
                researchCalendarios: action.payload,
                error: '',
                concepto: '',
                success: '',
                research: '',
                eventoText: '',
                fechaInicio: '',
                fechaFinal: ''
            }
        default: return state
    }

}