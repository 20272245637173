import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as NominaPorcentajeaction from '../../actions/NominaPorcentajeAction'
import imgProcentaje from "../../img/imgProcentaje.png";
import Spinner from '../../General/Spinner'
import Fatal from '../../General/Fatal'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles.scss'
import Success from '../../General/Success'
import { Redirect } from 'react-router-dom'

/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */









export class NominaPorcentaje extends Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: "",
      id: "",
      text: "",
      suggestVar: false,
    };
  }


  componentDidMount() {


  }


  componentDidUpdate(prevState) {
    console.log(this.props)


  }

  componentWillUnmount() {

    const {
      researchtrue,
    } = this.props

    researchtrue()
  }


  procentajeModelo = () => {

    const {
      filtrarPorcetajeModelo,
    } = this.props


    filtrarPorcetajeModelo(this.state.id)
  }

  cambioFechaPagos = (event) => {
    this.props.cambioFechaPagos(event.target.value)
  }

  handleEditar = (id, e) => {
    const { editar } = this.props;
    const Uploaddata = {

      p1: document.getElementById(`porcentaje1${id}`).value,
      p2: document.getElementById(`porcentaje2${id}`).value,
      p3: document.getElementById(`porcentaje3${id}`).value,
      c1: document.getElementById(`cantidad1${id}`).value,
      c2: document.getElementById(`cantidad2${id}`).value,
      bonificacion: document.getElementById(`bonificacion${id}`).value,
    };
    e.preventDefault();

    editar(Uploaddata, id);


  };

  handleSubmit(e) {
    e.preventDefault()
  }

  onTextChanged = e => {
    const value = e.target.value;
    let suggestions = [];

    const { consultarSuggestions } = this.props;
    const dataSuggestion = {
      data: value
    };
    if (value.length > 2) {
      this.setState(() => ({ suggestVar: true }));
      consultarSuggestions(dataSuggestion);
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      suggestions =
        inputLength === 0
          ? []
          : this.props.SuggestionDatabase.filter(
            lang =>
              lang.name.toLowerCase().slice(0, inputLength) === inputValue
          );
    } else {
      this.setState(() => ({ suggestVar: false }));
    }
    this.setState(() => ({ suggestions, text: value }));
  };

  suggestionSelected(value) {
    this.setState(() => ({
      text: value.name,
      id: value.id,
      suggestVar: false
    }));
  }

  renderSuggestions() {
    if (!this.state.suggestVar) {
      return null;
    }
    return (
      <div className="srchList">
        {this.props.SuggestionDatabase.map((item, key) => (
          <li key={key} onClick={() => this.suggestionSelected(item)}>
            {item.name}
          </li>
        ))}
      </div>
    );
  }

  handleChange(index, dataType, value) {


    this.props.handleChange(index, dataType, value)

  }

  ponerInformeModelo = () => {
    const { ModeloPorcentaje,

    } = this.props
    const ponerInfo = () => ModeloPorcentaje.map((infoModelo, key) => (
      <div className="col-md-12" key={key}>
        <div className='nombre__reporte__container_porcentaje'> <div className='nombre__div__reporte'> NOMBRE </div> <div className='triangulo__report__container'>  </div> <span className='nombre__modelo__div'> {`${infoModelo.nombres.toUpperCase()} ${infoModelo.apellidos.toUpperCase()}`} </span>   </div>
      </div>


    ))

    return (ponerInfo())
  }


  ponerInformeModeloPorcentaje = () => {
    const { ModeloPorcentaje,

    } = this.props
    const ponerInfo = () => ModeloPorcentaje.map((infoModelo, key) => (
      <Fragment key={key}>
        <div className="col-md-3 ">
          <span className='titulo_porcentaje'>CANTIDAD</span>
          <div className='text__cantidad'>
            De 0 a
      <input
              type="text"
              id={`cantidad1${infoModelo.id}`}
              className='porcentajesInput__Cantidad'
              value={this.props.ModeloPorcentaje[key].c1 || ''}
              onChange={(e) => this.handleChange(key, 'c1', e.target.value)}
            /> Dólares
  </div>

          <div className='text__cantidad'>
            De {parseInt(infoModelo.c1) + 1} a
      <input
              type="text"
              id={`cantidad2${infoModelo.id}`}
              className='porcentajesInput__Cantidad'
              value={this.props.ModeloPorcentaje[key].c2 || ''}
              onChange={(e) => this.handleChange(key, 'c2', e.target.value)}
            /> Dólares
  </div>

          <div className='text__cantidad'>
            Más de {infoModelo.c2} Dólares

  </div>
  <div className='text__cantidad'>
  Bonificación

  </div>


        </div>
        <div className="col-md-3">


          <span className='titulo_porcentaje'> PORCENTAJE</span>
          <div>

            <input
              type="text"
              id={`porcentaje1${infoModelo.id}`}
              className='porcentajesInput'
              value={this.props.ModeloPorcentaje[key].p1 || ''}
              onChange={(e) => this.handleChange(key, 'p1', e.target.value)}
            /> %
  </div>

          <div>

            <input
              type="text"
              id={`porcentaje2${infoModelo.id}`}
              className='porcentajesInput'
              value={this.props.ModeloPorcentaje[key].p2 || ''}
              onChange={(e) => this.handleChange(key, 'p2', e.target.value)}
            /> %
  </div>

          <div>

            <input
              type="text"
              id={`porcentaje3${infoModelo.id}`}
              className='porcentajesInput'
              value={this.props.ModeloPorcentaje[key].p3 || ''}
              onChange={(e) => this.handleChange(key, 'p3', e.target.value)}
            /> %

            

</div>

<div className='text__cantidad'>
            
            <input
              type="text"
              id={`bonificacion${infoModelo.id}`}
              className='porcentajesInput'
              value={this.props.ModeloPorcentaje[key].bonificacion || ''}
              onChange={(e) => this.handleChange(key, 'bonificacion', e.target.value)}
            /> %
  </div>

          <button
            className="btn__porcentaje_page"
            type="submit"
            onClick={e => this.handleEditar(infoModelo.id, e)}
          >
            Actualizar
                    </button>
        </div>
        <div className="col-md-6 third__col__porcentaje">
          <div className='text__div__porcentaje'>

            <div className='simbolo__dinero__porcentaje__div'>$</div>
            <div className='text_right'> De 0 a {infoModelo.c1}</div>
            <div className='text_right text__margin'>De 0 {parseInt(infoModelo.c1) + 1} a {infoModelo.c2}</div>
            <div className='text_right text__margin'>+ {infoModelo.c2}</div>

          </div>
          <div>
            <div className="progress_bar_porcentaje">
              <div style={{ width: `${this.props.ModeloPorcentaje[key].p1}%`, background: "#CD0F5B" }}></div>

            </div>

            <div className="progress_bar_porcentaje2">
              <div style={{ width: `${this.props.ModeloPorcentaje[key].p2}%`, background: "#40234A" }}></div>
            </div>

            <div className="progress_bar_porcentaje3">
              <div style={{ width: `${this.props.ModeloPorcentaje[key].p3}%`, background: "#828282" }}></div>
            </div>

            <img className='imgPorcentaje' src={imgProcentaje} alt='porcentaje Img'></img>
          </div>


        </div>
      </Fragment>

    ))

    return (ponerInfo())
  }
  Ponerlfechasreporte = () => {
    const { text } = this.state;

    return <div><form onSubmit={(e) => this.handleSubmit(e)}>
      <fieldset className="form-fieldset">

        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-12 input">
              <input
                className="input__autocomplete__Text prestamosGlobar__text"
                value={text}
                onChange={this.onTextChanged}
                type="text"
                placeholder="Buscar Modelo"
                required
              />
            </div>
            <div className="col-md-12 justify-content-md-center input__autocomplete__SuggestionText">
              {this.renderSuggestions()}
            </div>
            {this.ponerInformeModelo()}
          </div>
        </div>

      </fieldset>

    </form>
    </div>
  }




  mostrarAccion = () => {


    const { error, cargando, success } = this.props
    if (cargando) {
      return <Spinner />;
    }


    if (success) {
      return <Success mensaje={success} />;
    }


    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  }




  render() {
    console.log(this.state)


    return (



      <Fragment>
        {(!window.localStorage.getItem('token')) ? <Redirect to='/' /> : ''}
        <div className='main-content fade-in'>
          <h1 className="tituloPaginas">
            NÓMINA <strong className="tituloPaginasPequeño">%</strong>
          </h1>
          <div className='reporte__div__principal'>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 ">
                  {this.Ponerlfechasreporte()}
                  {this.mostrarAccion()}
                </div>

                <div className="col-md-2 center__reportes__botones">
                  <div className="btn__reportes_div">
                    <button className="Boton__reportes__porcentajes__modelos" type="submit"
                      onClick={this.procentajeModelo} >Filtrar</button>
                  </div>
                </div>

              </div>
            </div>


          </div>
          <div className='reporte__div__principal'>
            <div className="container-fluid">
              <div className="row">

                {this.ponerInformeModeloPorcentaje()}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
const mapStateToProps = (reducers) => {
  return reducers.NominaPorcentajeReducer
}

export default connect(mapStateToProps, NominaPorcentajeaction)(NominaPorcentaje)