export const CONSULTAR = 'tareas_traer_todas';
export const CONSULTAR_USUARIO = 'tareas_traer_usuario';
export const CARGANDO = 'tareas_cargando';
export const ERROR = 'tareas_error';
export const GUARDAR = 'tareas_guardada';
export const ACTUALIZAR = 'tareas_actualizar';
export const LIMPIAR = 'tareas_limpiar';
export const RESEARCHTRUE = 'tareas_research';
export const SUCCESS = 'tareas_succes';
export const CAMBIO_FECHAPRESTAMO = 'tareas_fecha';
export const CAMBIO_CANTIDAD = 'tareas_cantidad';
export const CAMBIO_CONCEPTO = 'tareas_concepto';
export const CAMBIO_FECHA1 = 'tareas_fecha1';
export const CAMBIO_FECHA2 = 'tareas_fecha2';
export const FILTRAR_PRESTAMOS_MINIMOS = 'tareas_minimos_filtrar';
export const CONSULTAR_TAREAS_REALIZADAS = 'tareas_traer_realizadas';
export const RELOAD = 'tareas_reload';
export const CONTAR_TAREAS = 'tareas_contar';
export const IMAGEN_PERFIL = 'tareas_perfil_imagen';
export const CAMBIO_PASSWORD = 'tareas_cambio_password';
export const CAMBIO_NEW_PASSWORD = 'tareas_cambio_new_password';
export const CAMBIO_IMG_PERFIL = 'auth_img_perfil';
export const CAMBIO_PERMISOS = 'auth_permisos_user';
export const CAMBIO_USER_NAME = 'auth_nombre_user';
export const CAMBIO_TRM = 'cambio_trm';
export const IMAGEN_PERFIL_LOGO = 'auth_img_perfil_logo';
export const CONSULTAR_PERMISOS_PAGOS = 'consultar_permisos_pagos_menu';












