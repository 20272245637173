import {
    CARGANDO, ERROR, RESEARCHTRUE
    , SUCCESS, FILTRAR_MODELO_PORCENJATE,CONSULTARS,CAMBIO_FECHA_PAGOS
} from '../types/NominaPorcentajeTypes'

const INITIAL_STATE = {
    ModeloPorcentaje: [],
    SuggestionDatabase: [],
    cargando: false,
    error: '',
    research: '',
    success: ''

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case FILTRAR_MODELO_PORCENJATE:
            return {
                ...state,
                ModeloPorcentaje: action.payload,
                cargando: false,
                research: false,


            }

                case CAMBIO_FECHA_PAGOS:
                    return {
                        ...state,
                        fechas_pagos_id: action.payload,
                        cargando: false,
                        research: false,
        
        
                    }
                
            
        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false, success: '' }


        case SUCCESS:
            return { ...state, success: action.payload, cargando: false, error: '' }


            case CONSULTARS:
            return {
                ...state,
                SuggestionDatabase: action.payload,

            }


        case RESEARCHTRUE
            :
            return {
                ...state,

                multas: [],
                vermultas: [],
                cargando: false,
                error: '',
                success: '',
                research: '',



            }


        default: return state
    }

}