import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as perfilModificaraction from "../../actions/perfilModificarInfoAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import cambiarFotoImg from "../../img/cambiarFotoImg.png";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import { MdAddAPhoto } from "react-icons/md";
import { FaCopy } from "react-icons/fa";
import defaultLogo from "../../img/defaultLogo.png";
import Popup from "reactjs-popup";
import SignaturePAd from "react-signature-canvas";
import { URL, CORREO_STUDIO } from '../../../src/General/url'
import firmaIconImg from "../../img/firmaIconImg.png";

/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */

export class ModificarInfo extends Component {

  constructor(props) {
    super(props);
    this.sigCanvas = React.createRef();
  }
  state = {
    dateStart: new Date(),
    dateEnd: new Date(),
  }

  async componentDidMount() {
    const {
      match: {
        params: { model_id }
      },
      consultarModeloInformacion,
      consultar_turnos,
      consultar_lugares,
      consultar_galeria_modelo,
      consultar_banco
    } = this.props
    await consultarModeloInformacion(model_id);
    await consultar_lugares()
    await consultar_turnos()
    await consultar_galeria_modelo(model_id)
    await consultar_banco()

   


  }


  encontrarValue = (data,valueObject) =>{

    let resultado = data.find( value => value.valueObject > 0 );
    return resultado
  }

  
  componentDidUpdate() {
    const {
      match: {
        params: { model_id }
      },
      consultar_galeria_modelo
    } = this.props
    if (!this.props.galeriaFlag) {
      consultar_galeria_modelo(model_id)
    }
    if (!this.props.banco && this.props.informacionModelo.length) {
      this.cambioBancoId(this.props.informacionModelo[0].banco_id)
    }


  }

  componentWillUnmount() {
    const { researchtrue } = this.props;

    researchtrue();
  }

  clear = () => {
    this.sigCanvas.current.clear()
  }
  save = () => {
    const { guardarimagenFirma } = this.props;
    let files = this.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    files = files.replace(/^data:image.+;base64,/, '');
    let filesLocal = this.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    const data = {
      file0: files,

    };
    this.setState({ imageCanvasFirma: filesLocal })
    guardarimagenFirma(data, filesLocal)

  }


  ponerFirma = () => {

    const ponerInfo = () => (
      <Fragment >
        <div className="col-md-3">

          <Popup modal

            trigger={<img alt='firma imagen' src={firmaIconImg} className='firma_btn'
            />}>

         
            {close => (
              <>
                <SignaturePAd
                  ref={this.sigCanvas}
                  canvasProps={{ className: 'signature__canvas' }} />
                <button onClick={this.save}>Guardar</button>
                <button onClick={this.clear}>Borrar</button>


              </>
            )}


          </Popup>
          <div className="btn__perfil_div">
            <button className="Boton__buscar__perfil__modelos" type="submit"
              onClick={e => this.handleEditarFirma( this.props.informacionModelo[0].id, e)} >Actualizar</button>
          </div>

        </div>
        <div className="col-md-7 ">


          {this.props.imagen_firma
                  ?      <img
                  src={
                    this.props.imagen_firma
                  }
                  alt="mi firma"
                  style={{
                    display: "block",
                    margin: "0 auto",
                    objectFit: "contain",
                    width: "100%"
                  }}
                />
                  :this.props.firma_modelo?
                  <img
                  src={
                    `${URL}getImageOriginal/${this.props.firma_modelo}`
                  }
                  alt="mi firma"
                  style={{
                    display: "block",
                    margin: "0 auto",
                    objectFit: "contain",
                    width: "100%"
                  }}
                />:''}
   
        </div>


      </Fragment>
    )

    return (ponerInfo())
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  pagarNomina = () => {
    const { fecha1, fecha2, dolar, pagar } = this.props;
    const infopagos = {
      fecha_inicial: fecha1,
      fecha_final: fecha2,
      dolar: dolar
    };
    pagar(infopagos);
  };
  handleChange(index, dataType, value, id_contable) {

    if (id_contable) {
      const { id_contable_cero } = this.props;
      id_contable_cero(0)
    }
    this.props.handleChange(index, dataType, value)
  }
  cambioFotoPerfil = event => {
    const { guardarimagen } = this.props;
    const files = event.target.files[0];
    if (files) {
      const data = new FormData();
      data.append("file0", files);
      guardarimagen(data);
    }

  };

  add_imagen = (event, id) => {
    const { guardarimagenModelo } = this.props;
    const files = event.target.files[0];
    if (files) {
      const data = new FormData();
      data.append("file0", files);
      guardarimagenModelo(data, id);

    }

  };


  handleEditar = (id, e) => {
    e.preventDefault();

    const { editar } = this.props;
    const datosInformacionModelo = {
      nombres: document.getElementById(`nombres${id}`).value,
      apellidos: document.getElementById(`apellidos${id}`).value,
      nick_name: document.getElementById(`nick_name${id}`).value,
      passwordm: document.getElementById(`passwordm${id}`).value,
      arriendo: document.getElementById(`arriendo${id}`).value,
      foto_perfil: this.props.perfilImg,
      estado: document.getElementById(`estado${id}`).value,
      lugares_id: document.getElementById(`lugar${id}`).value,
      horarios_id: document.getElementById(`turno${id}`).value,
      telefono: document.getElementById(`telefono${id}`).value,
    

      /*     
          
          
          
           */

    };


    editar(datosInformacionModelo, id);


  };



  
  handleEditarFirma = (id, e) => {
    e.preventDefault();

    const { editarFirma,firma_modelo } = this.props;
    const data = {
      firma_modelo: firma_modelo,
      
      /*     
          
          
          
           */

    };


    editarFirma(data, id);


  };

  handleEditarInfoContable = (id, e) => {
    e.preventDefault();
    const { editarInformacionContable, match: {
      params: { model_id }
    }, informacion_contable_duplicada_id } = this.props;
    const datosInformacionModelo = {
      modelos_id: model_id,
      id_contable: informacion_contable_duplicada_id,
      documento_contable: document.getElementById(`cedulaContable${id}`).value,
      nombres: document.getElementById(`nombresContable${id}`).value,
      apellidos: document.getElementById(`apellidosContable${id}`).value,
      cuentabancaria: document.getElementById(`cuentabancaria${id}`).value,
      direccion: document.getElementById(`direccion${id}`).value,
      tipo_cuenta_id: this.props.banco,
      /*     
          
          
          
           */

    };


    editarInformacionContable(datosInformacionModelo, id);


  };


  putModeloInformationContable = (e) => {

    const { handleChangeContable } = this.props;

    e.preventDefault()
    this.handleChange(0, 'informacion_contable_id', this.props.informacion_contable_duplicada.id)
    this.handleChange(0, 'documento_contable', this.props.informacion_contable_duplicada.documento_contable)
    this.handleChange(0, 'nombres_contables', this.props.informacion_contable_duplicada.nombres)
    this.handleChange(0, 'direccion', this.props.informacion_contable_duplicada.apellidos.direccion)
    this.handleChange(0, 'cuentabancaria', this.props.informacion_contable_duplicada.cuentabancaria)
    this.handleChange(0, 'apellidos_contable', this.props.informacion_contable_duplicada.apellidos)


    handleChangeContable(this.props.informacion_contable_duplicada.id)
  }

  cambioLugarId = (event) => {
    this.props.cambioLugarId(event.target.value)
  }

  cambioBancoId = (value) => {
    this.props.cambioBancoId(value)
  }

  cambioTurnoId = (event) => {
    this.props.cambioTurnoId(event.target.value)
  }

  ponerNomnreModelo = () => {
    const { informacionModelo,

    } = this.props
    const ponerNombre = () =>
      informacionModelo.map((modelo, key) => (
        <div className="" key={key}>
          <div className='nombre__reporte__container__modificarinfo'> <div className='nombre__div__reporte'> UPDATE </div> <div className='triangulo__report__container'>  </div> <span className='nombre__modelo__div'> {`${modelo.nombres} ${modelo.apellidos}`} </span>   </div>
        </div>
      ));

    return (ponerNombre())
  }

  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  listaLugares = () => {
    const { lugares } = this.props;

    const ponerOpcionesLugares = () =>
      lugares.map((lugar, key) => (
        <option className='option-values' key={key} value={lugar.id}>
          {this.MaysPrimera(lugar.nombre.toLowerCase())}{}
        </option>
      ));

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        onChange={this.cambioLugarId}
        required
        id={`lugar${this.props.informacionModelo[0].id}`}
      >
        <option className='option-values' value={this.props.informacionModelo[0].lugar_id}>
          {this.MaysPrimera(this.props.informacionModelo[0].lugar_name.toLowerCase())}
        </option>
        {ponerOpcionesLugares()}
      </select>
    );
  };

  listarBancos = () => {
    const { ListaBancos } = this.props;

    const ponerOpcionesLugares = () =>
    ListaBancos.map((lugar, key) => (
        <option className='option-values' key={key} value={lugar.id}>
          {this.MaysPrimera(lugar.nombre.toLowerCase())}{}
        </option>
      ));

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        onChange={e=>{this.cambioBancoId(e.target.value)}}
        required
      >
        <option className='option-values' value={this.props.informacionModelo[0].banco_id}>
          {this.MaysPrimera(this.props.informacionModelo[0].banco_nombre.toLowerCase())}
        </option>
        {ponerOpcionesLugares()}
      </select>
    );
  };

  

  listaEstado = () => {

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        required
        id={`estado${this.props.informacionModelo[0].id}`}
      >
        <option className='option-values' value={this.props.informacionModelo[0].estado}>
          {this.props.informacionModelo[0].estado ? this.MaysPrimera('Activ@'.toLowerCase()) : this.MaysPrimera('Inactiv@'.toLowerCase())}
        </option>
        <option className='option-values' value='1'>
          {this.MaysPrimera('Activ@'.toLowerCase())}{}
        </option>
        <option className='option-values' value='0'>
          {this.MaysPrimera('Inactiv@'.toLowerCase())}{}
        </option>

      </select>
    );
  };

  listaTurnos = () => {
    const { turnos } = this.props;

    const ponerOpcionesTurnos = () =>
      turnos.map((turnos, key) => (
        <option className='option-values' key={key} value={turnos.id}>
          {this.MaysPrimera(turnos.nombre.toLowerCase())}{}
        </option>
      ));

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        onChange={this.cambioTurnoId}
        required
        id={`turno${this.props.informacionModelo[0].id}`}

      >
        <option className='option-values' value={this.props.informacionModelo[0].horario_id}>
          {this.MaysPrimera(this.props.informacionModelo[0].horario_name.toLowerCase())}
        </option>
        {ponerOpcionesTurnos()}
      </select>
    );
  };

  ponerInformacionModelo = () => {
    const { informacionModelo,

    } = this.props

    const ponerInfo = () => informacionModelo.map((modelo, key) => (
      <Fragment key={key}>
        <div className="col-md-3 ">

          <img
            className="avatarModeloimg"
            alt="imgagerfil"
            src={
              modelo.foto_perfil
                ? `${URL}getImage/${modelo.foto_perfil}`
                : defaultLogo 
            }
          />
          <label className='foto_perfil_modelo_label' htmlFor="foto_perfil_modelo">
            <img src={cambiarFotoImg} alt="Click aquí para subir tu foto" title="Click aquí para subir tu foto" />
          </label>
          <input

            className="form-element-field -hasvalue"
            id={`foto_perfil_modelo`}
            type="file"
            onChange={this.cambioFotoPerfil}
          />

        </div>

        <div className="col-md-4 ">
          <div className='informacion_personal_modificar_info_modelo'>
            <div className='div__personal__modificar__info'><div className='text__rigth'>Cédula  </div>   <input
              type="text"
              readOnly
              id={`cedula${modelo.id}`}
              className='input__modificiar_informacion_modelo'
              value={this.props.informacionModelo[key].cedula || ''}
            /></div>
            <div className='div__personal__modificar__info'><div className='text__rigth'>Nombre  </div>   <input
              type="text"
              className='input__modificiar_informacion_modelo'
              id={`nombres${modelo.id}`}
              value={this.props.informacionModelo[key].nombres || ''}
              onChange={(e) => this.handleChange(key, 'nombres', e.target.value)}
            /></div>
            <div className='div__personal__modificar__info'><div className='text__rigth'>Apellidos  </div>   <input
              type="text"
              id={`apellidos${modelo.id}`}
              className='input__modificiar_informacion_modelo'
              value={this.props.informacionModelo[key].apellidos || ''}
              onChange={(e) => this.handleChange(key, 'apellidos', e.target.value)}
            /></div >
            <div className='div__personal__modificar__info'><div className='text__rigth'>Teléfono  </div>   <input
              type="text"
              id={`telefono${modelo.id}`}
              className='input__modificiar_informacion_modelo'
              value={this.props.informacionModelo[key].telefono || ''}
              onChange={(e) => this.handleChange(key, 'telefono', e.target.value)}
            /></div>
            <div className='div__personal__modificar__info'><div className='text__rigth'>Estado </div> {this.listaEstado()}</div>
            <div className='div__personal__modificar__info'><div className='text__rigth'>E-mail  </div>   <label>{`${modelo.correo?modelo.correo:''}${CORREO_STUDIO}`}</label></div>
          </div>
        </div>

        <div className="col-md-4 ">
          <div className='informacion_personal_modificar_info_modelo'>
            <div className='div__personal__modificar__info__derecha'><div className='text__rigth'>Nick </div> <input
              type="text"
              className='input__modificiar_informacion_modelo'
              id={`nick_name${modelo.id}`}
              value={this.props.informacionModelo[key].nick_name || ''}
              onChange={(e) => this.handleChange(key, 'nick_name', e.target.value)}
            /></div>
            <div className='centrar_verticalmente div__personal__modificar__info__derecha'><div className='text__rigth'>Trabaja desde  </div> {this.listaLugares()}</div>
            <div className='centrar_verticalmente div__personal__modificar__info__derecha'><div className='text__rigth'>Turnos </div>   {this.listaTurnos()}</div>
            <div className='div__personal__modificar__info__derecha'><div className='text__rigth'>Arriendo  </div>   <input
              type="text"
              id={`arriendo${modelo.id}`}
              className='input__modificiar_informacion_modelo'
              value={this.props.informacionModelo[key].arriendo || 0}
              onChange={(e) => this.handleChange(key, 'arriendo', e.target.value)}
            /></div>
            <div className='div__personal__modificar__info__derecha'><div className='text__rigth'>Contraseña perfil  </div>  <input
              type="text"
              id={`passwordm${modelo.id}`}
              className='input__modificiar_informacion_modelo'
              value={this.props.informacionModelo[key].passwordm || ''}
              onChange={(e) => this.handleChange(key, 'passwordm', e.target.value)}
            /></div>

          </div>

          <div className="btn__perfil_div">
            <button className="Boton__buscar__perfil__modelos" type="submit"
              onClick={e => this.handleEditar(modelo.id, e)} >Actualizar</button>
          </div>
        </div>

      </Fragment>
    ))

    return (ponerInfo())
  }


  ponerInformacionContableModelo = () => {
    const { informacionModelo,

    } = this.props

    const ponerInfo = () => informacionModelo.map((modelo, key) => (
      <Fragment key={key}>

        <div className="col-md-6 ">
          <div className='informacion_personal_modificar_info_modelo'>
            <div className='div__personal__modificar__info'><div className='text__rigth'>Cédula  </div>   <input
              type="text"
              id={`cedulaContable${modelo.informacion_contable_id}`}
              className='input__modificiar_informacion_modelo'
              value={this.props.informacionModelo[key].documento_contable || ''}
              onChange={(e) => this.handleChange(key, 'documento_contable', e.target.value, 'id_contable')}
            /></div>
            <div className='div__personal__modificar__info'><div className='text__rigth'>Nombre  </div>   <input
              type="text"
              className='input__modificiar_informacion_modelo'
              id={`nombresContable${modelo.informacion_contable_id}`}
              value={this.props.informacionModelo[key].nombres_contables || ''}
              onChange={(e) => this.handleChange(key, 'nombres_contables', e.target.value)}
            /></div>
            <div className='div__personal__modificar__info'><div className='text__rigth'>Apellidos  </div>   <input
              type="text"
              id={`apellidosContable${modelo.informacion_contable_id}`}
              className='input__modificiar_informacion_modelo'
              value={this.props.informacionModelo[key].apellidos_contable || ''}
              onChange={(e) => this.handleChange(key, 'apellidos_contable', e.target.value)}
            /></div >

          </div>
        </div>
        <div className="col-md-6 ">
          <div className='informacion_personal_modificar_info_modelo'>
            <div className='div__personal__modificar__info__derecha'><div className='text__rigth'>Dirección </div> <input
              type="text"
              className='input__modificiar_informacion_modelo'
              id={`direccion${modelo.informacion_contable_id}`}
              value={this.props.informacionModelo[key].direccion || ''}
              onChange={(e) => this.handleChange(key, 'direccion', e.target.value)}
            /></div>
                <div className='div__personal__modificar__info__derecha'><div className='text__rigth'>Banco </div> {this.listarBancos()}</div>
            <div className='div__personal__modificar__info__derecha'><div className='text__rigth'>Cuenta Bancaria  </div>  <input
              type="number"
              id={`cuentabancaria${modelo.informacion_contable_id}`}
              className='input__modificiar_informacion_modelo'
              value={this.props.informacionModelo[key].cuentabancaria || ''}
              onChange={(e) => this.handleChange(key, 'cuentabancaria', e.target.value)}
            /></div>
          
          </div>

          <div className="btn__perfil_div">
            <button className="Boton__buscar__perfil__modelos" type="submit"
             disabled={this.props.banco?false:true}
              onClick={e => this.handleEditarInfoContable(modelo.informacion_contable_id, e)} >Actualizar</button>
          </div>
        </div>

      </Fragment>
    ))

    return (ponerInfo())
  }

  ponerGaleriaModelo = () => {
    const { galeriaModelo,

    } = this.props

    const ponerInfo = () => galeriaModelo.map((modelo, key) => (
      <Fragment key={key}>


        <div className="col-md-4 d-flex">
          <CopyToClipboard text={`${URL}getImageOriginal/${modelo.imagen_name}`}>
            <FaCopy size={"3vh"} className="icon_color_galerry" style={{ cursor: "pointer" }} />
          </CopyToClipboard>
          <img
            className="avatarModeloimgGaleria"
            alt="imgagerfil"
            src={`${URL}getImage/${modelo.imagen_name}`}
          />


        </div>



      </Fragment>
    ))

    return (ponerInfo())
  }


  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {

    console.log(this.props);
    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            PERFIL <strong className="tituloPaginasPequeño">MODIFICAR INFO</strong>
          </h1>
          {this.ponerNomnreModelo()}
          <div className="reporte__div__principal">

            <div className="container-fluid">
              <div className="row">

                {this.ponerInformacionModelo()}


              </div>



            </div>
          </div>

          <h1 className="titulo__infor__contable">
            INFO CONTABLE
          </h1>
          <div className="reporte__div__principal">

            <div className="container-fluid">
              <div className="row">

                {this.ponerInformacionContableModelo()}


              </div>

            </div>
          </div>
          {this.mostrarAccion()}
          {this.props.contable_flag ?
            <div class="alert alert-danger" role="alert">

              Quieres ingresar la informacion contable del modelo  <strong>
                {`${this.props.informacion_contable_duplicada.nombres} ${this.props.informacion_contable_duplicada.apellidos} `}</strong>
                 cualquien modificacion realizada a este perfil afectara a las cuentas asociadas al mismo<Link
                to={`/#`}
                onClick={e => this.putModeloInformationContable(e)}
                className="btn__perfil">Insertar</Link>
            </div>


            : ''}

          <h1 className="tituloPaginas">
            FIRMA
          </h1>

          <div className="container-fluid">
              <div className="row">

              {this.ponerFirma()}


              </div>

            </div>
          <h1 className="tituloPaginas">
            GALERÍA
          </h1>
          <div className="reporte__div__principal">

            <div className="container-fluid">
              <div className="row">
                {this.ponerGaleriaModelo()}

                <div className="col-md-4 d-flex">


                  <label className='foto_perfil_modelo_label__add' htmlFor="img_modelo_perfil_add">
                    <span  ><MdAddAPhoto size={"14vw"} className="img__add__perfil__modelo" /></span>
                  </label>
                  <input

                    className="form-element-field -hasvalue"
                    id={`img_modelo_perfil_add`}
                    type="file"
                    onChange={event => this.add_imagen(event, this.props.informacionModelo[0].id)}
                  />
                </div>

              </div>

            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.PerfilModificarInfoReducer;
};

export default connect(mapStateToProps, perfilModificaraction)(ModificarInfo);
