export const CONSULTAR = 'MultasAgregar_traer_todas';
export const CONSULTARS = 'MultasAgregar_traer_todas_suggestions';
export const CONSULTAR_TOTALES = 'MultasAgregar_traer_todas_prestamos';
export const CARGANDO = 'MultasAgregar_cargando';
export const ERROR = 'MultasAgregar_error';
export const GUARDAR = 'MultasAgregar_guardada';
export const ACTUALIZAR = 'MultasAgregar_actualizar';
export const LIMPIAR = 'MultasAgregar_limpiar';
export const RESEARCHTRUE = 'MultasAgregar_research';
export const RESEARPRESTAMO = 'MultasAgregar_research_prestamo';
export const SUCCESS = 'MultasAgregar_succes';
export const CAMBIO_FECHAPRESTAMO = 'MultasAgregar_fecha';
export const CAMBIO_CANTIDAD = 'MultasAgregar_cantidad';
export const CAMBIO_CONCEPTO = 'MultasAgregar_concepto';
export const CAMBIO_FECHA1 = 'MultasAgregar_fecha1';
export const CAMBIO_FECHA2 = 'MultasAgregar_fecha2';
export const CONSULTAR_MULTAS_MODELOS = 'MultasAgregar_minimos_filtrar';







