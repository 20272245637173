export const CONSULTAR = 'reportesmodelos_traer_todas';
export const CARGANDO = 'reportesmodelos_cargando';
export const ERROR = 'reportesmodelos_error';
export const GUARDAR = 'reportesmodelos_guardada';
export const ACTUALIZAR = 'reportesmodelos_actualizar';
export const LIMPIAR = 'reportesmodelos_limpiar';
export const RESEARCHTRUE = 'reportesmodelos_research';
export const SUCCESS = 'reportesmodelos_succes';
export const CAMBIO_FECHAPRESTAMO = 'reportesmodelos_fecha';
export const CAMBIO_CANTIDAD = 'reportesmodelos_cantidad';
export const CAMBIO_CONCEPTO = 'reportesmodelos_concepto';
export const CAMBIO_FECHA1 = 'reportesmodelos_fecha1';
export const CAMBIO_FECHA2 = 'reportesmodelos_fecha2';
export const FILTRAR_GANANCIAS = 'reportesmodelos_ganancias_filtrar';
export const CAMBIO_LUGARES = 'reportesmodelos_cambio_lugares';
export const CONSULTAR_LUGARES = 'reportesmodelos_consultar_lugares';
export const CONSULTARS = 'reportesmodelos_traer_todas_suggestions';
export const REPORTE_MODELO = 'reportesmodelos_traer_todas_reporte_dia';
export const REPORTE_MODELO_PERMIISO = 'reportesmodelos_traer_todas_reporte_dia_permisos';
export const REPORTE_MODELO_ACTUALIZAR = 'reportesmodelos_traer_todas_reporte_dia_permisos_altualizar';





