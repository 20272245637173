import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as correosplataformaaction from '../../actions/CorreosPlataformaAction'
import Spinner from '../../General/Spinner'
import Fatal from '../../General/Fatal'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles.scss'
import Success from '../../General/Success'
import { Redirect } from 'react-router-dom'
import searchImg from "../../img/searchImg.png";
import { MdFileUpload } from "react-icons/md";
import {CORREO_STUDIO} from '../../General/url'


/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */









export class CorreosPlataforma extends Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: "",
      id: "",
      text: "",
      suggestVar: false,
    };
  }


  componentDidMount() {
this.props.consultar_correo_modelos()

  }


  componentDidUpdate() {
    const { consultar_correo_modelo } = this.props;
   if(this.props.researchEmailFlag){
    consultar_correo_modelo(this.state.id)
   }
console.log(this.props)

  }

  componentWillUnmount() {

    const {
      researchtrue,
    } = this.props

    researchtrue()
  }


  crearCorreo = () => {

    const {
      crear_correo,
    } = this.props
    const data = {
      modelos_id: this.state.id,
      nombre:this.props.correo,
      passwordc:this.props.passwordCorreo

    }

    crear_correo(data)
  }

  cambioCorreo = (value) => {
    this.props.cambioCorreo(value)
  }

  cambioPasswordCorreo = (value) => {
    this.props.cambioPasswordCorreo(value)
  }

  handleSubmit(e) {
    e.preventDefault()
  }

  onTextChanged = e => {
    const value = e.target.value;
    let suggestions = [];

    const { consultarSuggestions } = this.props;
    const dataSuggestion = {
      data: value
    };
    if (value.length > 2) {
      this.setState(() => ({ suggestVar: true }));
      consultarSuggestions(dataSuggestion);
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      suggestions =
        inputLength === 0
          ? []
          : this.props.SuggestionDatabase.filter(
            lang =>
              lang.name.toLowerCase().slice(0, inputLength) === inputValue
          );
    } else {
      if (!value) {
        console.log('input vacio')
      }
      this.setState(() => ({ suggestVar: false }));
    }
    this.setState(() => ({ suggestions, text: value }));
  };

  suggestionSelected(value) {
    this.setState(() => ({
      text: value.name,
      id: value.id,
      suggestVar: false
    }));
    const { consultar_correo_modelo } = this.props;

    consultar_correo_modelo(value.id)
  }

  
  handleChange(index, dataType, value) {
    this.props.handleChange(index, dataType, value)

  }

  handleEditar(id){

    const {
      editar_correo,
    } = this.props
    const data = {
     
      passwordc:document.getElementById(`passwordc${id}`).value

    }

    editar_correo(data,id)
  
  }
  renderSuggestions() {
    if (!this.state.suggestVar) {
      return null;
    }
    return (
      <div className="srchList__correo">
        {this.props.SuggestionDatabase.map((item, key) => (
          <li key={key} onClick={() => this.suggestionSelected(item)}>
            {item.name}
          </li>
        ))}
      </div>
    );
  }
  renderCorreo = () => {
    const { correosModelo } = this.props;
    const ponerInfo = () =>
    correosModelo.map((correos, key) => (
        <tr key={key}>
          <td>{`${correos.nombres} ${correos.apellidos}`}</td>
          <td>{`${correos.nombre}${CORREO_STUDIO}`}</td>
          <td>    <input
            type="text"
            id={`passwordc${correos.id}`}
            value={this.props.correosModelo[key].passwordc || ''}
            onChange={(e) => this.handleChange(key, 'passwordc', e.target.value)}
          /></td>
          <td>
            {<Fragment>

              <span
                className="loans__icon__x"
                onClick={e => this.handleEditar(correos.id, e)}
              >
                <MdFileUpload
                  
                  className="Home_Work_Stage__insideDiv__icon__x btn_action"
                />
              </span>

            </Fragment>
            }
          </td>
        </tr>
      ));

    return ponerInfo();
  };

  Ponerlfechasreporte = () => {
    const { text } = this.state;

    return <div><form onSubmit={(e) => this.handleSubmit(e)}>
      <fieldset className="form-fieldset">

        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-12 input">
              <img alt='correo imagen search' className='searchImgCorreo' src={searchImg} />
              <input
                className="input__autocomplete__Text perfil__Search__text__correo"
                value={text}
                onChange={this.onTextChanged}
                type="text"
                placeholder="Buscar Modelo"
                required
              />
            </div>
            <div className="col-md-12 justify-content-md-center input__autocomplete__SuggestionText">
              {this.renderSuggestions()}
            </div>
          </div>
        </div>

      </fieldset>

    </form>
    </div>
  }




  mostrarAccion = () => {


    const { error, cargando, success } = this.props
    if (cargando) {
      return <Spinner />;
    }


    if (success) {
      return <Success mensaje={success} />;
    }


    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  }




  render() {
    console.log(this.state)


    return (



      <Fragment>
        {(!window.localStorage.getItem('token')) ? <Redirect to='/' /> : ''}
        <div className='main-content fade-in'>
          <h1 className="tituloPaginas">
            CORREOS
          </h1>
          <div className='reporte__div__principal'>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 ">
                  {this.Ponerlfechasreporte()}

                  <div className='nombre__correo__container'> <div className='nombre__div__reporte'> NOMBRE </div>
                    <div className='triangulo__report__container'>  </div> <span className='nombre__modelo__div'> {this.state.text.toUpperCase()} </span>   </div>

                  <div className='informacion_correos_modificar_info_modelo'>
                    <div className='div__text__correo__info'><div className='text__rigth'>correo  </div>   <input
                      type="text"
                      className='input__correo'
                      value={this.props.correo}
                      onChange={(e) => this.cambioCorreo(e.target.value)}
                    />{CORREO_STUDIO}</div>

                    <div className='div__personal__correo__info'><div className='text__rigth'>Contraseña  </div>   <input
                      type="text"
                      className='input__modificiar_informacion_correos'
                      value={this.props.passwordCorreo}
                      onChange={(e) => this.cambioPasswordCorreo(e.target.value)}
                    /></div>

                    <div className="">
                      <button className="Boton__crear__correos" type="submit"
                        onClick={this.crearCorreo} >Crear</button>
                    </div>
                  </div>

                </div>

          

              </div>
            </div>


          </div>
          {this.mostrarAccion()}
          <div className='container-fluid'>
            <div className="row">


            <div className="col-md-10 mx-auto">
                  <table className="table table-striped tabla-global">
                    <thead>
                      <tr>
                        <th>NOMBRE</th>
                        <th>CORREO </th>
                        <th>CONTRASEÑA </th>
                        <th>ACCIÓN </th>
                      </tr>
                    </thead>
                    <tbody>{this.renderCorreo()}</tbody>
                  </table>
                </div>

            </div>


          </div>
        </div>
      </Fragment>
    )
  }
}
const mapStateToProps = (reducers) => {
  return reducers.CorreosPlataformaReducer
}

export default connect(mapStateToProps, correosplataformaaction)(CorreosPlataforma)