import axios from "axios";
import {
  CARGANDO,
  ERROR,
  CONSULTAR,
  CONSULTAR_LUGARES,
  CONSULTAR_TURNOS,
  RESEARCHTRUE,
  CAMBIO_LUGAR_ID,
  CAMBIO_TURNO_ID,
  CAMBIO_BANCO_ID,
  CONSULTAR_BANCOS,
  SUCCESS,
  IMAGEN_PERFIL,
  GALERIA_MODELO,
  GALERIA_FLAG,
  CONTABLE_FLAG,
  INFORMACION_CONTABLE_DULICADA,
  INFORMACION_CONTABLE_DULICADA_ID,
  IMAGEN_FIRMA,
  CAMBIO_NOMBRE_FIRMA
} from "../types/PerfilModificarInfoTypes";

import {URL} from '../../src/General/url'
export const editar = (data,model_id) => async(dispatch)=>{
   
    
  dispatch({

      type: CARGANDO
      
  
  })

  try {
    
      let json = JSON.stringify(data)
      let params = json;

        

      let headers = {
          "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.put(URL+`updateModel/${model_id}`, params,{
          headers: headers
      })
   
if(respuesta.data.status==="Success"){
  dispatch({

      type: SUCCESS,
      payload: respuesta.data.message
      
  
  })
}

if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
      
/*    
      */
      
      
      
  } catch (error) {
      
      if(error.message==="Request failed with status code 401"){
          window.localStorage.setItem('token','')
          window.localStorage.setItem('userData','')
      }else{
  dispatch({

      type: ERROR,
      payload: error.message
      
  
  })
  }
}


}

export const editarFirma = (data,model_id) => async(dispatch)=>{
   
    
    dispatch({
  
        type: CARGANDO
        
    
    })
  
    try {
      
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateSignatureModel/${model_id}`, params,{
            headers: headers
        })
     
  if(respuesta.data.status==="Success"){
    dispatch({
  
        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
  }

  if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
        
  /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({
  
        type: ERROR,
        payload: error.message
        
    
    })
    }
  }
  
  
  }
export const guardarimagenFirma = (data,filesLoca) => async (dispatch) => {

  
    try {
  
  
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL + 'storeFirma', params, {
            headers: headers
        })
  
        dispatch({
          type: IMAGEN_FIRMA,
          payload: filesLoca
        });

        dispatch({
            type: CAMBIO_NOMBRE_FIRMA,
            payload: respuesta.data.data
          });
          if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
  
      /*   dispatch({
            type: CAMBIO_FOTO_PERFIL,
            payload: respuesta.data.imagename
        }) */
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }
export const editarInformacionContable = (data,model_id) => async(dispatch)=>{
   
    
  dispatch({

      type: CARGANDO
      
  
  })

  try {
    
      let json = JSON.stringify(data)
      let params = json;

        

      let headers = {
          "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.put(URL+`updateAccountantInformation/${model_id}`, params,{
          headers: headers
      })
   
if(respuesta.data.status==="Success"){
  dispatch({

      type: SUCCESS,
      payload: respuesta.data.message
      
  
  })
}if(respuesta.data.status==="Error"){
    dispatch({
  
        type: ERROR,
        payload: respuesta.data.message
        
    
    })

    if(respuesta.data.data){

        dispatch({
  
            type: INFORMACION_CONTABLE_DULICADA,
            payload: respuesta.data.data
            
        
        })
    
        
    
        dispatch({
      
            type: CONTABLE_FLAG,
            payload: 1
            
        
        })

    }

    
  }
        
  } catch (error) {
      
      if(error.message==="Request failed with status code 401"){
          window.localStorage.setItem('token','')
          window.localStorage.setItem('userData','')
      }else{
  dispatch({

      type: ERROR,
      payload: error.message
      
  
  })
  }
}


}


export const handleChangeCedula  = (data) => (dispatch,getState) => {

    dispatch({
        type: INFORMACION_CONTABLE_DULICADA_ID,
        payload:0
    })
  
    dispatch({
        type: ERROR,
        payload:''
    })
  
  
   
  };
export const handleChangeContable  = (data) => (dispatch,getState) => {

    dispatch({
        type: INFORMACION_CONTABLE_DULICADA_ID,
        payload:data
    })
  
    dispatch({
        type: CONTABLE_FLAG,
        payload:0
    })

    dispatch({
        type: ERROR,
        payload:''
    })
  
  
   
  };

  export const id_contable_cero  = (data) => (dispatch,getState) => {

    dispatch({
        type: INFORMACION_CONTABLE_DULICADA_ID,
        payload:data
    })

  
   
  };


export const consultarModeloInformacion = (id) => async (dispatch) => {

  dispatch({
    type: CARGANDO
  });

  try {

  

    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL +  `getModelsIdModify/${id}`, {
          headers: headers
      })


      if (respuesta.data.status === "success") {
        dispatch({
          type: CONSULTAR,
          payload: respuesta.data.data
        });
        dispatch({
          type: IMAGEN_PERFIL,
          payload: respuesta.data.data[0].foto_perfil
        });
        dispatch({
            type: CAMBIO_NOMBRE_FIRMA,
            payload: respuesta.data.data[0].firma_modelo
          });
        
      }
      if(respuesta.data.status==="Error"){
        dispatch({
    
            type: ERROR,
            payload: respuesta.data.message
            
        
        })

    }
  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}

export const guardarimagen = (data) => async (dispatch,getState) => {

  const {informacionModelo} = getState().PerfilModificarInfoReducer


  dispatch({

      type: CARGANDO


  })

  try {


      let params = data
      let headers = {
          'Content-Type': 'multipart/from-data',
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.post(URL + 'StoreImage', params, {
          headers: headers
      })

    

      const newInfomodelo = informacionModelo.map((item, i) => {
        if (i === 0) {
          return {...item, 'foto_perfil': respuesta.data.imagename};
        }
        return item;
      });
    
        dispatch({
          type: CONSULTAR,
          payload:newInfomodelo
      })

      dispatch({
        type: IMAGEN_PERFIL,
        payload: respuesta.data.imagename
      });
    

    /*   dispatch({
          type: CAMBIO_FOTO_PERFIL,
          payload: respuesta.data.imagename
      }) */

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}



export const guardarimagenModelo = (data,id) => async (dispatch,getState) => {



  dispatch({

      type: CARGANDO


  })

  try {


      let params = data
      let headers = {
          'Content-Type': 'multipart/from-data',
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.post(URL + `storeImageModel/${id}`, params, {
          headers: headers
      })

    
      if(respuesta.data.status==="success"){
        dispatch({
      
            type: SUCCESS,
            payload: respuesta.data.message
            
        
        })

        dispatch({
      
          type: GALERIA_FLAG,
          payload: false
          
      
      })
      }
      if(respuesta.data.status==="Error"){
        dispatch({
    
            type: ERROR,
            payload: respuesta.data.message
            
        
        })

    }

    /*   dispatch({
          type: CAMBIO_FOTO_PERFIL,
          payload: respuesta.data.imagename
      }) */

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}



export const handleChange  = (index, dataType, value) => (dispatch,getState) => {

  const {informacionModelo} = getState().PerfilModificarInfoReducer


  const newInfomodelo = informacionModelo.map((item, i) => {
      if (i === index) {
        return {...item, [dataType]: value};
      }
      return item;
    });


    dispatch({
      type: CONSULTAR,
      payload:newInfomodelo
  })


  

 
};




export const researchtrue = () => dispatch => {
  dispatch({
    type: RESEARCHTRUE
  });
};

export const consultar_turnos = () => async (dispatch) => {


  try {



    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL + 'getSchedules', {
          headers: headers
      })




      dispatch({
          type: CONSULTAR_TURNOS,
          payload: respuesta.data.data
      })

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}


export const consultar_lugares = () => async (dispatch) => {


  try {



    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL + 'getPlaces', {
          headers: headers
      })




      dispatch({
          type: CONSULTAR_LUGARES,
          payload: respuesta.data.data
      })

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}

export const consultar_galeria_modelo = (id) => async (dispatch) => {


  try {

    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL + `getGalleryModel/${id}`, {
          headers: headers
      })



 if(respuesta.data.status==="Success"){
        dispatch({
      
            type: GALERIA_MODELO,
            payload: respuesta.data.data
            
        
        })
      }

      if(respuesta.data.status==="Error"){
        dispatch({
    
            type: ERROR,
            payload: respuesta.data.message
            
        
        })

    }

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}

export const cambioLugarId = (data) => (dispatch)=>{

  dispatch({
      type: CAMBIO_LUGAR_ID,
      payload: data
      
  })
}

export const consultar_banco = () => async (dispatch) => {


    try {


        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getTipeAccount', {
            headers: headers
        })




        dispatch({
            type: CONSULTAR_BANCOS,
            payload: respuesta.data.data
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })
        }

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}

export const cambioBancoId = (data) => (dispatch)=>{

    dispatch({
        type: CAMBIO_BANCO_ID,
        payload: data
        
    })
  }


  export const cambioTurnoId = (data) => (dispatch)=>{

    dispatch({
        type: CAMBIO_TURNO_ID,
        payload: data
        
    })


}
