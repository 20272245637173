export const CONSULTAR = 'correos_plataforma_traer_todas';
export const CARGANDO = 'correos_plataforma_cargando';
export const ERROR = 'correos_plataforma_error';
export const GUARDAR = 'correos_plataforma_guardada';
export const ACTUALIZAR = 'correos_plataforma_actualizar';
export const LIMPIAR = 'correos_plataforma_limpiar';
export const RESEARCHTRUE = 'correos_plataforma_research';
export const SUCCESS = 'correos_plataforma_succes';
export const CAMBIO_FECHAPRESTAMO = 'correos_plataforma_fecha';
export const CAMBIO_CANTIDAD = 'correos_plataforma_cantidad';
export const CAMBIO_CONCEPTO = 'correos_plataforma_concepto';
export const CAMBIO_FECHA1 = 'correos_plataforma_fecha1';
export const CAMBIO_FECHA2 = 'correos_plataforma_fecha2';
export const FILTRAR_MODELO_PAGO = 'correos_plataforma_ganancias_filtrar';
export const CAMBIO_FECHA_PAGOS = 'correos_plataforma_cambio_fecha_pagos';
export const CONSULTAR_CORREOS_MODELO = 'correos_plataforma_consultar_correos_modelo';
export const CAMBIO_CORREO = 'correos_plataforma_cambio_correo';
export const CAMBIO_PASSWORD_CORREO = 'correos_plataforma_cambio_passwordcorreo';
export const CONSULTARS = 'reportesmodelos_pago_traer_todas_suggestions';
export const CONSULTAR_FECHAS_PAGOS = 'correos_plataforma_traer_todas_fechas_pagos';






