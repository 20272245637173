import axios from 'axios'
import { CARGANDO, ERROR,  GUARDAR,RESEARCHTRUE,
    SUCCESS,FILTRAR_MODELO_PORCENJATE,CONSULTARS,CONSULTAR_FECHAS_PAGOS,CAMBIO_FECHA_PAGOS} from '../types/NominaPorcentajeTypes'
    import {URL} from '../../src/General/url'




export const editar = (updatePorcentaje,model_id) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(updatePorcentaje)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updatePorcentage/${model_id}`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
}

if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
        
 /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

 
}




export const agregar = (prestamomodelo) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(prestamomodelo)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'createFines', params,{
            headers: headers
        })
    
      
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })


        if(respuesta.data.status==="success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}




export const eliminar = (id) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.delete(URL+`destroyAccountsModel/${id}`,{
            headers: headers
        })
    
    
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
        
    } catch (error) {

        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const filtrarPorcetajeModelo = (model_id) => async(dispatch)=>{
    
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {



        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`getModelPorcentage/${model_id}`,{
            headers: headers
        })
    
  
        dispatch({
            type: FILTRAR_MODELO_PORCENJATE,
            payload:respuesta.data.data,
        })


        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}

export const cambioFechaPagos = (id_pago) => (dispatch) => {
    dispatch({
        type: CAMBIO_FECHA_PAGOS,
        payload: id_pago

    })
}

export const consultar_fechas_pagos = () => async (dispatch) => {


    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getPaymentDate', {
            headers: headers
        })




        dispatch({
            type: CONSULTAR_FECHAS_PAGOS,
            payload: respuesta.data.data
        })


        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}


export const researchtrue  = () => (dispatch,) => {
    dispatch({

        type: RESEARCHTRUE
        
    
    })


    

   
};

export const consultarSuggestions = (data) => async(dispatch)=>{
    


    try {

     
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
    
        const respuesta = await axios.post(URL+`getSuggestionModels`, params,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTARS,
            payload:respuesta.data.data,
        })
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
        
    } catch (error) {
        
    }
}

export const handleChange  = (index, dataType, value) => (dispatch,getState) => {

    const {ModeloPorcentaje} = getState().NominaPorcentajeReducer


    const newPorcentaje = ModeloPorcentaje.map((item, i) => {
        if (i === index) {
          return {...item, [dataType]: value};
        }
        return item;
      });
  

      dispatch({
        type: FILTRAR_MODELO_PORCENJATE,
        payload:newPorcentaje
    })


    

   
};
