import axios from 'axios'
import { CARGANDO, ERROR, CONSULTAR,RESEARCHTRUE,CAMBIO_IMG_PERFIL,CAMBIO_PERMISOS,CAMBIO_USER_NAME ,RELOAD,CONTAR_TAREAS,IMAGEN_PERFIL,CAMBIO_PASSWORD,CAMBIO_NEW_PASSWORD,CAMBIO_TRM,IMAGEN_PERFIL_LOGO,
    CONSULTAR_PERMISOS_PAGOS,
    SUCCESS} from '../types/TareasTypes'


    import {URL} from '../../src/General/url'





export const updataeProfile = (data) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateMonitorProfilePicture`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
 
   
}
if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
        
 /*   const {prestamos} = getState().PrestamoGlobalReducer getState 
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

 
}


export const updataeProfileLogo = (data) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateLogoProfilePicture`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
 
   
}
if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
        
 /*   const {prestamos} = getState().PrestamoGlobalReducer getState 
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

 
}

export const UpdatePassword = (data) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateMonitorinformation`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
    dispatch({

        type: RELOAD,
        
    
    })
   
}
if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
        
 /*   const {prestamos} = getState().PrestamoGlobalReducer getState 
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

 
}

export const updateTrm = (data) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateTrm`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
    dispatch({

        type: RELOAD,
        
    
    })
   
}
if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
        
 /*   const {prestamos} = getState().PrestamoGlobalReducer getState 
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

 
}

export const guardarimagen = (data) => async (dispatch) => {




    dispatch({
  
        type: CARGANDO
  
  
    })
  
    try {
  
  
        let params = data
        let headers = {
            'Content-Type': 'multipart/from-data',
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL + 'StoreImage', params, {
            headers: headers
        })
  
        dispatch({
          type: IMAGEN_PERFIL,
          payload: respuesta.data.imagename
        });
      
  
      /*   dispatch({
            type: CAMBIO_FOTO_PERFIL,
            payload: respuesta.data.imagename
        }) */
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }

  export const guardarimagenlogo = (data) => async (dispatch) => {




    dispatch({
  
        type: CARGANDO
  
  
    })
  
    try {
  
  
        let params = data
        let headers = {
            'Content-Type': 'multipart/from-data',
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL + 'StoreImage', params, {
            headers: headers
        })
  
        dispatch({
          type: IMAGEN_PERFIL_LOGO,
          payload: respuesta.data.imagename
        });
      
  
      /*   dispatch({
            type: CAMBIO_FOTO_PERFIL,
            payload: respuesta.data.imagename
        }) */
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }




export const consultarPermisos = () => async(dispatch)=>{
    


    try {


        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`getRolPermisos`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTAR,
            payload:respuesta.data.data,
        })
        dispatch({
            type: CONSULTAR_PERMISOS_PAGOS,
            payload:respuesta.data.dataPagos,
        })
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
        
    } catch (error) {
        
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}


export const consultarInfoPlataforma = () => async(dispatch)=>{
    


    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`getPlataformaMasterInfo`,{
            headers: headers
        })
    
  
        dispatch({
            type: CAMBIO_TRM,
            payload:respuesta.data.data[0].trm,
        })

        dispatch({
            type: IMAGEN_PERFIL_LOGO,
            payload:respuesta.data.data[0].logo,
        })
        
    } catch (error) {
        
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

export const contartareas = () => async(dispatch)=>{
    


    try {

    

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`countHomework`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONTAR_TAREAS,
            payload:respuesta.data.data,
        })

        
        
    } catch (error) {
        
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}




export const getUser = (model_id) => async(dispatch)=>{
    

    try {

     
   

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`getUserInfo/${model_id}`,{
            headers: headers
        })
    


        if(respuesta.data.status==="Success"){
            dispatch({
        
                type: CAMBIO_IMG_PERFIL,
                payload: respuesta.data.data.profile_picture
                
            
            })

            dispatch({
        
                type: CAMBIO_USER_NAME,
                payload: respuesta.data.data.username
                
            
            })


            dispatch({
                type: CAMBIO_PERMISOS,
                payload:respuesta.data.data.rol_id,
            })

        }
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }

        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}

export const cambioPassword  = (value) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_PASSWORD,
        payload: value
        
    
    })
    
};



export const cambioTrm  = (value) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_TRM,
        payload: value
        
    
    })
    
};



export const cambioNewPassword  = (value) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_NEW_PASSWORD,
        payload: value
        
    
    })
    
};




export const researchtrue  = () => (dispatch,) => {
    dispatch({

        type: RESEARCHTRUE
        
    
    })


    

   
};