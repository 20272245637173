import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as perfilveraction from '../../actions/PerfilVerAction'
import { Link } from "react-router-dom";
import Spinner from '../../General/Spinner'
import Fatal from '../../General/Fatal'
import 'bootstrap/dist/css/bootstrap.min.css';
import Popover from './popover'
import './Styles.scss'
import Success from '../../General/Success'
import { Redirect } from 'react-router-dom'
import cedulaImg from "../../img/cedulaImg.png";
import correoImg from "../../img/correoImg.png";
import telefonoImg from "../../img/telefonoImg.png";
import modeloNombresImg from "../../img/modeloNombresImg.png";
import chaturbateCuentaImg from "../../img/chaturbateCuentaImg.png";
import streamateCuentaImg from "../../img/streamateCuentaImg.png";
import cam4CuentaImg from "../../img/cam4CuentaImg.png";
import stripchatCuentaImg from "../../img/stripchatCuentaImg.png";
import searchImg from "../../img/searchImg.png";

import {URL,CORREO_STUDIO} from '../../../src/General/url'
import nickImg from "../../img/nickImg.png";

/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */









export class PerfilVer extends Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: "",
      id: "",
      text: "",
      suggestVar: false,
      estado: 1,
      page:1
    };
  }


  componentDidMount() {

    const {
      consultar_fechas_pagos,
      paginarModelo,
      Modelos



    } = this.props
    consultar_fechas_pagos();
    if(Modelos.length===0){
      paginarModelo(this.state.page,this.state.estado)
    }

    console.log(this.props.Modelos)
   
  }


  componentDidUpdate() {
    console.log(this.state)


  }



  cambio_texto_busqueda =(value)=>{
    this.props.cambio_texto_busqueda(value)
  }

  componentWillUnmount() {

    const {
      researchtrue,
    } = this.props

    researchtrue()
  }


  buscarModelo = () => {

    const {
      buscarModelo,
    } = this.props

    buscarModelo(this.props.idTextoBusqueda)
  }

  handleChangeEstado = event => {
    this.setState({estado: event.target.value});
    this.setState({page: 1});
    const {
      paginarModelo
    } = this.props
    paginarModelo(this.state.page,event.target.value)
  }

  handleSubmit(e) {
    e.preventDefault()
  }

  onTextChanged = e => {
    const value = e.target.value;
    let suggestions = [];

    const { consultarSuggestions } = this.props;
    const dataSuggestion = {
      data: value,
      estado:this.state.estado
    };
    if (value.length > 2) {
      this.setState(() => ({ suggestVar: true }));
      consultarSuggestions(dataSuggestion);
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      suggestions =
        inputLength === 0
          ? []
          : this.props.SuggestionDatabase.filter(
            lang =>
              lang.name.toLowerCase().slice(0, inputLength) === inputValue
          );
    } else {
     if(value.length===0){
      this.props.paginarModelo(this.state.page,this.state.estado)
     }
  
      this.setState(() => ({ suggestVar: false }));
    }
    this.props.cambio_texto_busqueda(value)
    this.setState(() => ({ suggestions, text: value }));
  };

  suggestionSelected(value) {
    this.props.cambio_texto_busqueda(value.name)
    this.props.cambio_id_busqueda(value.id)
    this.setState(() => ({
      suggestVar: false
    }));
  }

  renderSuggestions() {
    if (!this.state.suggestVar) {
      return null;
    }
    return (
      <div className="srchList">
        {this.props.SuggestionDatabase.map((item, key) => (
          <li key={key} onClick={() => this.suggestionSelected(item)}>
            {item.name}
          </li>
        ))}
      </div>
    );
  }





  ponerInformeModelo = () => {
    
    const { Modelos,

    } = this.props
    const ponerInfo = () => Modelos.map((modelo, key) => (
      <tr key={key}>
        <td className='perfiltd'>
          <div className='avatarModeloPerfil'>
            <img alt='avatar perfil' className='avatarModeloPerfilimg' src={`${URL}getImage/${modelo.foto_perfil ? modelo.foto_perfil : 'defaultLogo.png'}`} />
          </div>
        </td>

        <td >
          <div className='informacion_personal_td'>

            <div><img alt='perfil cedulaImg'  src={cedulaImg} />{modelo.cedula}</div>
            <div><img alt='perfil nombreImg'  src={modeloNombresImg} />{`${modelo.nombres} ${modelo.apellidos}`}</div>
            <div><img alt='perfil correoImg'  src={correoImg} />{modelo.correo ? <span>{modelo.correo + CORREO_STUDIO} <Popover mensaje={modelo.passwordcorreo} /> </span> : ''}</div>
            <div><img alt='perfil telefonoImg'  src={telefonoImg} />{modelo.telefono}</div>
            <div><img alt='perfil nickImg'  src={nickImg} />{modelo.nick_name}</div>
          </div>
        </td>

        <td >
          <div className='informacion_personal_td'>

          
            <div><img alt='imagen chaturbate'  src={chaturbateCuentaImg} />{modelo.cuentaChaturbate}<Popover data={'cuentas'} mensaje={modelo.passwordChaturbate} /> </div>
            <div><img alt='avatar stripchat'  src={stripchatCuentaImg} />{`${modelo.cuentaStripchat} `}<Popover data={'cuentas'} mensaje={modelo.passwordStripchat} /> </div>
            <div><img alt='avatar cam4'  src={cam4CuentaImg} />{modelo.cuentaCam4}<Popover data={'cuentas'} mensaje={modelo.passwordCam4} /> </div>
            <div><img alt='avatar streamate'  src={streamateCuentaImg} />{modelo.cuentaStreamate}<Popover data={'cuentas'} mensaje={modelo.passwordStreamate} /> </div>
          </div>
        </td>
        <td >
          <div className='informacion_personal_td'>
            <div className="btn__reportes__div__pagos">
              <Link
                to={`/ModificarInfo/${modelo.id}`}
                className="btn__perfil">Modificar Info</Link>
            </div>
            <div className="btn__reportes__div__pagos">
              <Link
                to={`/Cuentas/${modelo.id}`}
                className="btn__perfil">Ver Cuentas</Link>
            </div>
          </div>
        </td>

      </tr>
    ))

    return (ponerInfo())
  }
  Ponerlfechasreporte = () => {

    return <div><form onSubmit={(e) => this.handleSubmit(e)}>
      <fieldset className="form-fieldset">

        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-12 input">
            <img  alt='imagen de busqueda' className='searchImg' src={searchImg} /> 
              <input
                className="input__autocomplete__Text perfil__Search__text"
                value={this.props.textoBusqueda}
                onChange={this.onTextChanged}
                type="text"
                placeholder="Buscar Modelo"
                required
              />
            </div>
            <div className="col-md-12 justify-content-md-center input__autocomplete__SuggestionText">
              {this.renderSuggestions()}
            </div>
          </div>
        </div>

      </fieldset>

    </form>
    </div>
  }


  ponerPaginas = () => {
    const arrayBase = [];
    for (let index = 1; index <= this.props.Pag_modelos; index++) {
      arrayBase.push(index)
      
    }
    const ponerInfo = () => arrayBase.map((modelo, key) => (
      <li key={key} className={this.state.page===key+1?"page-item active":"page-item"}><Link className="page-link" onClick={(e)=>this.handleClickPaginate(key+1,this.state.estado)} to="#">{key+1}</Link></li>
    ))

    return(ponerInfo())
  }


  handleClickPaginate(page,estado){
    const {
      paginarModelo
    } = this.props
    this.setState({page:page})
    paginarModelo(page,estado)


  }



  mostrarAccion = () => {


    const { error, cargando, success } = this.props
    if (cargando) {
      return <Spinner />;
    }


    if (success) {
      return <Success mensaje={success} />;
    }


    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  }




  render() {
    console.log(this.state)


    return (



      <Fragment>
        {(!window.localStorage.getItem('token')) ? <Redirect to='/' /> : ''}
        <div className='main-content fade-in'>
          <h1 className="tituloPaginas">
            PERFIL <strong className="tituloPaginasPequeño">VER</strong>
          </h1>
          <div className='reporte__div__principal__perfiil'>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 Selecbox_estado">
                  <div className='nombre__estado__perfil__div'> <div className='nombre__div__reporte'> ESTADO </div>
                    <div className='triangulo__report__container'>
                    </div>
                    <select onChange={this.handleChangeEstado}>
                      <option value="1">Activas</option>
                      <option value="0">Inactivas</option>
                    </select> </div>

                </div>
                <div className="col-md-4 ">
                  {this.Ponerlfechasreporte()}

                </div>

                <div className="col-md-4 center__reportes__botones">
                  <div className="btn__perfil_div">
                    <button className="Boton__buscar__perfil__modelos" type="submit"
                      onClick={this.buscarModelo} >Buscar</button>
                  </div>
                </div>




              </div>
              {this.mostrarAccion()}
            </div>


          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <table className="table table-striped tabla-global">
                  <thead>
                    <tr>
                      <th>FOTO</th>
                      <th>DATOS PERSONALES </th>
                      <th>PLATAFORMA </th>
                      <th>ACCIONES </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.ponerInformeModelo()}
                  </tbody>
                </table>

                <nav aria-label="Page navigation example" style={{overflow:'scroll'}}>
  <ul className="pagination mb-0">
    <li className={this.state.page===1?"page-item disabled":"page-item"}><Link className="page-link"   onClick={(e)=>this.handleClickPaginate(this.state.page-1,this.state.estado)} to="#" tabIndex="-1">Previous</Link></li>
         {this.ponerPaginas()}
    <li className={this.state.page===this.props.Pag_modelos?"page-item disabled":"page-item"}><Link className="page-link" onClick={(e)=>this.handleClickPaginate(this.state.page+1,this.state.estado)} to="#">Next</Link></li>
  </ul>
</nav>
              </div>
            </div>
          </div>

        </div>
      </Fragment>
    )
  }
}
const mapStateToProps = (reducers) => {
  return reducers.PerfilVerReducer
}

export default connect(mapStateToProps, perfilveraction)(PerfilVer)