export const CONSULTAR = 'calendario_traer_todas';
export const CARGANDO = 'calendario_cargando';
export const ERROR = 'calendario_error';
export const GUARDAR = 'calendario_guardada';
export const ACTUALIZAR = 'calendario_actualizar';
export const LIMPIAR = 'calendario_limpiar';
export const RESEARCHTRUE = 'calendario_research';
export const SUCCESS = 'calendario_succes';
export const CAMBIO_FECHAPRESTAMO = 'calendario_fecha';
export const CAMBIO_CANTIDAD = 'calendario_cantidad';
export const CAMBIO_CONCEPTO = 'calendario_concepto';
export const CAMBIO_FECHA1 = 'calendario_fecha1';
export const CAMBIO_FECHA2 = 'calendario_fecha2';
export const FILTRAR_PRESTAMOS_MINIMOS = 'calendario_minimos_filtrar';
export const CONSULTAR_TAREAS_REALIZADAS = 'calendario_traer_realizadas';
export const RELOAD = 'calendario_reload';
export const CONTAR_TAREAS = 'calendario_contar';
export const RESEARCHCALENDARIO = 'calendario_research_busqueda';
export const CAMBIO_FECHA_INCIO = 'calendario_fecha_inicio';
export const CAMBIO_FECHA_FINAL = 'calendario_fecha_final';
export const CAMBIO_EVENTO_TEXT = 'calendario_evento_text';



