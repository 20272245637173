export const CONSULTAR = 'reportes_diario_studio_traer_todas';
export const CARGANDO = 'reportes_diario_studio_cargando';
export const ERROR = 'reportes_diario_studio_error';
export const GUARDAR = 'reportes_diario_studio_guardada';
export const ACTUALIZAR = 'reportes_diario_studio_actualizar';
export const LIMPIAR = 'reportes_diario_studio_limpiar';
export const RESEARCHTRUE = 'reportes_diario_studio_research';
export const SUCCESS = 'reportes_diario_studio_succes';
export const CAMBIO_FECHAPRESTAMO = 'reportes_diario_studio_fecha';
export const CAMBIO_CANTIDAD = 'reportes_diario_studio_cantidad';
export const CAMBIO_CONCEPTO = 'reportes_diario_studio_concepto';
export const CAMBIO_FECHA1 = 'reportes_diario_studio_fecha1';
export const CAMBIO_FECHA2 = 'reportes_diario_studio_fecha2';
export const FILTRAR_PRESTAMOS_MINIMOS = 'reportes_diario_studio_minimos_filtrar';
export const CAMBIO_HORARIOS = 'reportes_diario_studio_cambio_horaios';
export const CONSULTAR_HORARIOS = 'reportes_diario_studio_consultar_horaios';
export const FILTRAR_REPORTES_DIARIOS = 'reportes_diario_studio_consultar_reportes_diarios';
export const CONSULTAR_NOVEDADES = 'reportes_diario_studio_consultar_novedades';
export const CREAR_NOVEDADES = 'reportes_diario_studio_crear_novedades';






