export const CONSULTAR = 'ver_traer_todas';
export const CARGANDO = 'ver_perfil_monitor_cargando';
export const ERROR = 'ver_perfil_monitor_error';
export const GUARDAR = 'ver_perfil_monitor_guardada';
export const ACTUALIZAR = 'ver_perfil_monitor_actualizar';
export const LIMPIAR = 'ver_perfil_monitor_limpiar';
export const RESEARCHTRUE = 'ver_perfil_monitor_research';
export const SUCCESS = 'ver_perfil_monitor_succes';
export const CAMBIO_FECHAPRESTAMO = 'ver_perfil_monitor_fecha';
export const CAMBIO_CANTIDAD = 'ver_perfil_monitor_cantidad';
export const CAMBIO_CONCEPTO = 'ver_perfil_monitor_concepto';
export const CAMBIO_FECHA1 = 'ver_perfil_monitor_fecha1';
export const CAMBIO_FECHA2 = 'ver_perfil_monitor_fecha2';
export const FILTRAR_MODELO_PAGO = 'ver_perfil_monitor_ganancias_filtrar';
export const CAMBIO_FECHA_PAGOS = 'ver_perfil_monitor_cambio_fecha_pagos';
export const CONSULTAR_LUGARES = 'ver_perfil_monitor_consultar_lugares';
export const CONSULTARS = 'ver_perfil_monitor_traer_todas_suggestions';
export const CONSULTAR_FECHAS_PAGOS = 'ver_perfil_monitor_traer_todas_fechas_pagos';
export const PAGINAR_MONITORES = 'ver_perfil_monitor_paginacion_modelos';







