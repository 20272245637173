import {
  CARGANDO,
  ERROR,
  GUARDAR,
  RESEARCHTRUE,
  RELOAD,
  SUCCESS,
  IMAGEN_PERFIL,
  CAMBIO_CEDULA,
  CAMBIO_NOMBRES,
  CAMBIO_APELLIDOS,
  CAMBIO_SUELDO,
  CAMBIO_CUENTA_BANCARIA,
  CAMBIO_TIPO,
  CAMBIO_ESTADO_CREAR,
  CAMBIO_ESTADO_INICIAL,
  

} from "../types/crearMonitorTypes";

const INITIAL_STATE = {
  foto_perfil_monitor: '',
  cedulaMonitor: '',
  nombresMonitor: '',
  apellidosMonitor: '',
  sueldoMonitor: '',
  cuentaBancaria:'',
  tipo: 0,
  cargando: false,
  estadoFlag:false,
  error: "",
  research: "",
  success: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {


    case CARGANDO:
      return { ...state, cargando: true };

    case ERROR:
      return { ...state, error: action.payload, cargando: false, success: "" };

    case SUCCESS:
      return { ...state, success: action.payload, cargando: false, error: "" };


    case IMAGEN_PERFIL:
      return {
        ...state,
        foto_perfil_monitor: action.payload,
        cargando: false,
        research: false,
      }

      
        
        case CAMBIO_ESTADO_CREAR:
          return {
            ...state,
            estadoFlag: action.payload,
            cargando: false,
            research: false,
          }

          case CAMBIO_ESTADO_INICIAL:
            return {
              ...state,
              estadoFlag: action.payload,
            }
            case GUARDAR:
              return { ...state, success: "" };
            

    case CAMBIO_CEDULA:
      return {
        ...state,
        cedulaMonitor: action.payload,
      }

    case CAMBIO_NOMBRES:
      return {
        ...state,
        nombresMonitor: action.payload,
      }

    case CAMBIO_APELLIDOS:
      return {
        ...state,
        apellidosMonitor: action.payload,
      }
      case CAMBIO_SUELDO:
        return {
          ...state,
          sueldoMonitor: action.payload,
        }

    case CAMBIO_CUENTA_BANCARIA:
      return {
        ...state,
        cuentaBancaria: action.payload,
      }



    case CAMBIO_TIPO:
      return {
        ...state,
        tipo: action.payload,
      }




    case RESEARCHTRUE:
      return {
        ...state,
        foto_perfil_monitor: '',
        cedulaMonitor: '',
        nombresMonitor: '',
        apellidosMonitor: '',
        sueldoMonitor: '',
        cuentaBancaria:'',
        tipo: 0,
        cargando: false,
        estadoFlag:false,
        error: "",
        research: "",
        success: ""
      };

    case RELOAD:
      return {
        ...state,

        cargando: false,
        research: true
      };
    default:
      return state;
  }
};
