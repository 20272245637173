import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import Menu from './menu'
import * as LoginAction from '../actions/LoginAction'
import Login from './Login'
import Home from './Home'
import Cuentas from './Cuentas'
import Prestamoglobal from './PrestamosGlobal'
import PrestamosPequenoMonitores from './PrestamosPequenoMonitores'
import Prestamogrande from './PrestamosGrandes'
import PrestamosGrandesMonitores from './PrestamosGrandesMonitores'
import Modelo from './Modelo'
import ReportesModelos from './ReportesModelos'
import ReportesModelosDia from './ReportesModelosDia'
import ReporteModelosPagos from './ReportesModelosPagos'
import Reportes from './Reportes'
import ReporteTotal from './ReporteTotal'
import ReporteTotalSinDeducciones from './ReporteTotalSinDeducciones'
import ReporteRetencion from './ReporteRetencion'
import ReportesDiariosActionStudio from './ReportesDiarioStudio'
import Camara from './Camara'
import Calendario from './Calendario'
import Pagos from './Pagos'
import Nomina from './Nomina'
import nominaPorcentaje from './NominaPorcentaje'
import NominaRecibos from './NominaRecibos'
import NominaRecibosPlanilla from './NominaRecibosPlanilla'
import NominaRecibosMonitores from './NominaRecibosMonitores'
import MultaTotal from './MultaTotal'
import MultaTotalMonitores from './MultaTotalMonitores'
import MultasAgregar from './MultasAgregar'
import MultasAgregarMonitor from './MultasAgregarMonitor'
import MultasgGananciasAgregarMonitor from './MultasgGananciasAgregarMonitor'
import PerfilVer from './PerfilVEr'
import PerfilVErMonitores from './PerfilVErMonitores'
import ModificarInfo from './PerfilModificarInfo'
import PerfilModificarInfoMonitor from './PerfilModificarInfoMonitor'
import AgregarInformacionModelo from './AgregarInformacionModelo'
import AgregarInformacionContableModelo from './AgregarInformacionContableModelo'
import AgregarInformacionMonitor from './AgregarInformacionMonitor'
import AgregarICorreoModelo from './AgregarICorreoModelo'
import AgregarFirmaModelo from './AgregarFirmaModelo'
import CorreosPlataforma from './CorreosPlataforma'
import HabitacionesAgregar from './HabitacionesAgregar'
import HabitacionesView360 from './HabitacionesView360'
import HabitacionesVer from './HabitacionesVer'
import HabitacionesVerTurnos from './HabitacionesVerTurnos'
import HabitacionesVerReportes from './HabitacionesVerReportes'
import InventarioRoomModelo from './InventarioRoomModelo'
import NominaRecibosMonitoresPlanilla from './NominaRecibosMonitoresPlanilla'
import ReportesGananciaLugares from './ReportesGananciaLugares'
import AgregarMonitor from './AgregarMonitor'
import tokenAgregar from './tokenAgregar'
import LugarAgregar from './LugarAgregar'
import ServiciosAgregar from './ServicioAgregar'
import ReporteTotalDeudaModelo from './ReporteTotalDeudaModelo'
import PermisoUsuarios from './PermisoUsuarios'
import PermisoPagos from './PermisoPagos'
import PermisoUsuariosModificar from './PermisoUsuariosModificar'
import PermisoPagosUsuariosModificar from './PermisoPagosUsuariosModificar'
import correosReportes from './correosReportes'
import correosReportesExtension from './correosReportesExtension'
import correosReportesModificar from './correosReportesModificar'
import palabrasAlertas from './PalabrasAlertas'
import AlertasPlataforma from './AlertasPlataforma'
import SistemaServicios from './SistemaServicio'
import ArchivoPagos from './ArchivoPagos'
import Gastos from './gastosPlataforma'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import miPerfil from './PerfilMonitor'
import Trm from './Trm'




class App extends Component {

  componentDidMount() {
    const { comprobartoken } = this.props
    comprobartoken()


  }

  render() {


    return (
      <BrowserRouter>

        {/*divpara las dar stilos a el menu*/}
       

          {

            this.props.isAuth
              ? <Route >
                <Menu />
                <Route exact path='/Home' component={Home} />
                <Route exact path='/Cuentas/:model_id' component={Cuentas} />
                <Route exact path='/Prestamopequeno' component={Prestamoglobal} />
                <Route exact path='/PrestamosPequenoMonitores' component={PrestamosPequenoMonitores} />
                <Route exact path='/Prestamogrande' component={Prestamogrande} />
                <Route exact path='/PrestamosGrandesMonitores' component={PrestamosGrandesMonitores} />
                <Route exact path='/Nomina' component={Nomina} />
                <Route exact path='/HabitacionesAgregar' component={HabitacionesAgregar} />
                <Route exact path='/HabitacionesVer' component={HabitacionesVer} />
                <Route exact path='/HabitacionesVerTurnos' component={HabitacionesVerTurnos} />
                <Route exact path='/HabitacionesVerReportes' component={HabitacionesVerReportes} />
                <Route exact path='/CorreosPlataforma' component={CorreosPlataforma} />
                <Route exact path='/NominaRecibos/:fecha_id' component={NominaRecibos} />
                <Route exact path='/PermisoUsuarios' component={PermisoUsuarios} />
                <Route exact path='/PermisoPagos' component={PermisoPagos} />
                <Route exact path='/archivoPagos' component={ArchivoPagos} />
                <Route exact path='/Trm' component={Trm} />
                <Route exact path='/SistemaServicios' component={SistemaServicios} />
                <Route exact path='/correosReportes' component={correosReportes} />
                <Route exact path='/correosReportesExtension' component={correosReportesExtension} />
                <Route exact path='/correosReportesModificar/:id' component={correosReportesModificar} />
                <Route exact path='/PermisoUsuariosModificar/:permiso_id' component={PermisoUsuariosModificar} />
                <Route exact path='/PermisoPagosUsuariosModificar/:permiso_id' component={PermisoPagosUsuariosModificar} />
                <Route exact path='/NominaRecibosPlanilla/:fecha_id' component={NominaRecibosPlanilla} />
                <Route exact path='/NominaRecibosMonitoresPlanilla/:fecha_id' component={NominaRecibosMonitoresPlanilla} />
                <Route exact path='/tokenAgregar' component={tokenAgregar} />
                <Route exact path='/NominaRecibosMonitores/:fecha_id' component={NominaRecibosMonitores} />
                <Route exact path='/nominaPorcentaje' component={nominaPorcentaje} />
                <Route exact path='/MultaTotal' component={MultaTotal} />
                <Route exact path='/MultaTotalMonitores' component={MultaTotalMonitores} />
                <Route exact path='/MultasAgregar' component={MultasAgregar} />
                <Route exact path='/LugarAgregar' component={LugarAgregar} />
                <Route exact path='/ServiciosAgregar' component={ServiciosAgregar} />
                <Route exact path='/MultasAgregarMonitor' component={MultasAgregarMonitor} />
                <Route exact path='/MultasgGananciasAgregarMonitor' component={MultasgGananciasAgregarMonitor} />
                <Route exact path='/PerfilVer' component={PerfilVer} />
                <Route exact path='/PerfilVErMonitores' component={PerfilVErMonitores} />
                <Route exact path='/AgregarInformacionModelo' component={AgregarInformacionModelo} />
                <Route exact path='/PerfilUser' component={AgregarInformacionMonitor} />
                <Route exact path='/miPerfil' component={miPerfil} />
                <Route exact path='/palabrasAlertas' component={palabrasAlertas} />
                <Route exact path='/AgregarInformacionContableModelo' component={AgregarInformacionContableModelo} />
                <Route exact path='/AgregarICorreoModelo' component={AgregarICorreoModelo} />
                <Route exact path='/AgregarFirmaModelo' component={AgregarFirmaModelo} />    
                <Route exact path='/AgregarMonitor' component={AgregarMonitor} /> 
                <Route exact path='/AlertasPlataforma' component={AlertasPlataforma} />              
                <Route exact path='/ModificarInfo/:model_id' component={ModificarInfo} />
                <Route exact path='/PerfilModificarInfoMonitor/:model_id' component={PerfilModificarInfoMonitor} />
                <Route exact path='/Modelo/Agregar' component={Modelo} />
                <Route exact path='/Modelo/Editar/:model_id' component={Modelo} />
                <Route exact path='/HabitacionesView360/:key' component={HabitacionesView360} />
                <Route exact path='/InventarioRoomModelo/:key/:key2' component={InventarioRoomModelo} />
                <Route exact path='/ReportesModelos' component={ReportesModelos} />
                <Route exact path='/ReportesModelosDia' component={ReportesModelosDia} />
                <Route exact path='/ReporteModelosPagos' component={ReporteModelosPagos} />
                <Route exact path='/ReportesDiariosActionStudio' component={ReportesDiariosActionStudio} />               
                <Route exact path='/Reportes' component={Reportes} />
                <Route exact path='/ReporteTotal' component={ReporteTotal} />
                <Route exact path='/ReporteTotalDeudaModelo' component={ReporteTotalDeudaModelo} />
                <Route exact path='/ReporteTotalSinDeducciones' component={ReporteTotalSinDeducciones} /> 
                <Route exact path='/ReporteRetencion' component={ReporteRetencion} />
                <Route exact path='/ReportesGananciaLugares' component={ReportesGananciaLugares} />
                <Route exact path='/Camara' component={Camara} />
                <Route exact path='/Calendario' component={Calendario} />
                <Route exact path='/Pagos' component={Pagos} />
                <Route exact path='/Gastos' component={Gastos} />
                {/*      <Route exact path='/publicaciones/:key' component={Publicaciones}/>
                <Route exact path='/tareas/guardar' component={TareasGuardar}/>
                <Route exact path='/tareas/guardar/:usu_id/:tar_id' component={TareasGuardar}/> */}
              </Route>
              : <Route>
               <Route exact path='/' component={Login} />
              </Route>



          }

          {

            this.props.isAuth ? <Redirect to='/Home' /> : <Redirect to='/' />
          }
   


      </BrowserRouter>

    )
  }
}

const mapStateToProps = ({ LoginReducer }) => LoginReducer
//conectar tareas al reducer y traer las acciones del login actions
export default connect(mapStateToProps, LoginAction)(App)