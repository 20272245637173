import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import ChaturIconImg from "../../img/ChaturIconImg.png";
import StipchatIconImg from "../../img/StipchatIconImg.png";
import BongaIconImg from "../../img/BongaIconImg.png";
import Cam4IconImg from "../../img/Cam4IconImg.png";
import StreamateIconImg from "../../img/StreamateIconImg.png";
import FlitIconImg from "../../img/Flirt4freeIconImg.png";
import fotoCuentasImg from "../../img/fotoCuentasImg.png";
import biografiaCuentasImg from "../../img/biografiaCuentasImg.png";
import perfilCuentasImg from "../../img/perfilCuentasImg.png";
import cuentasBackImg from "../../img/cuentasBackImg.png";
import addBtnImg from "../../img/addBtnImg.png";
import * as cuentasaction from "../../actions/CuentasAction";
import "./Styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";

import { BiTransfer } from "react-icons/bi";
import { AiOutlineReload, AiOutlineMail } from "react-icons/ai";
import { GoEye } from "react-icons/go";
export class Cuentas extends Component {
  state = {
    model: 0,
    show: false,
    elminarFlagModal: false.valueOf,
    cuentaId: "",
    text: "",
    id: "",
    valueAccount:"",
    modelo_id:"",
    plataforma_id: "",
    passwordAccount: "",
    accountName:"",
    modalModule: 0,
    cuentaModeloId: "",
  };

  async componentDidMount() {
    const {
      match: {
        params: { model_id },
      },
      consultar_modelo_cuentas,
    } = this.props;

    await consultar_modelo_cuentas(model_id);
    this.setState({ model: model_id });
  }
  async componentDidUpdate() {
    const {
      consultar_todas_cuentas_modelo,
      match: {
        params: { model_id },
      },
    } = this.props;

    console.log(this.state.model);

    if (!this.props.buscarcuentas) {
      consultar_todas_cuentas_modelo(model_id, this.props.cuentasCreada);
    }

    console.log(this.props);
  }
  componentWillUnmount() {
    const { researchtrue } = this.props;
    researchtrue();
  }

  ponerCorreosVinculados = () => {
    const { cuentasvinculadas } = this.props;
    const ponerInfo = () =>
      cuentasvinculadas.map((correo, key) => (
        <tr key={key}>
          <td>{`${correo.correo.nombre}`}</td>
          <td>{`${correo.correo.passwordc}`}</td>
        </tr>
      ));

    return ponerInfo();
  };

  guardar = () => {};

  habilitar = () => {
    this.setState({ editlable: false });
  };

  ConsultarTodasLasCuentas(modelo_id, plataforma_id, e) {
    e.preventDefault();

    const { consultar_todas_cuentas_modelo } = this.props;
    consultar_todas_cuentas_modelo(modelo_id, plataforma_id);
    this.setState({ model: modelo_id });
  }

  CrearCuenta() {
    const { CrearCuenta } = this.props;
    const {  modelo_id,
          plataforma_id,
          passwordAccount,
          valueAccount } = this.state;
    const data = {
      modelos_id: modelo_id,
      plataformas_id: plataforma_id,
      name:valueAccount,
      password:passwordAccount,
      correo_id: this.state.id,
    };
    CrearCuenta(data);
  }

  
  TraferirCuentaModelo(e) {
    e.preventDefault();
    const { transferirCuenta,   match: {
      params: { model_id },
    }, } = this.props;
    
    const {  
          plataforma_id, 
          cuentaModeloId} = this.state;
    const data = {
      modelos_id: this.state.id,
      plataformas_id: plataforma_id,
      cuentaModeloId: cuentaModeloId,
      actualModelId:model_id
    };
 transferirCuenta(data);

  }

  vincularCorreo = () => {
    const { vincularCuenta } = this.props;
    const data = {
      correo_id: this.state.id,
      cuenta_id: this.state.cuentaId,
    };

    vincularCuenta(data);
  };

  onTextChanged = (e) => {
    const value = e.target.value;
    let suggestions = [];

    const { consultarSuggestions } = this.props;
    const dataSuggestion = {
      data: value,
    };
    if (value.length > 2) {
      this.setState(() => ({ suggestVar: true }));
      consultarSuggestions(dataSuggestion);
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      suggestions =
        inputLength === 0
          ? []
          : this.props.SuggestionDatabase.filter(
              (lang) =>
                lang.name.toLowerCase().slice(0, inputLength) === inputValue
            );
    } else {
      this.setState(() => ({ suggestVar: false }));
    }
    this.setState(() => ({ suggestions, text: value }));
  };

  onTextChangedModel = (e) => {
    const value = e.target.value;
    let suggestions = [];

    const { consultarSuggestionsModel } = this.props;
    const dataSuggestion = {
      data: value,
    };
    if (value.length > 2) {
      this.setState(() => ({ suggestVar: true }));
      consultarSuggestionsModel(dataSuggestion);
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      suggestions =
        inputLength === 0
          ? []
          : this.props.SuggestionDatabase.filter(
              (lang) =>
                lang.name.toLowerCase().slice(0, inputLength) === inputValue
            );
    } else {
      this.setState(() => ({ suggestVar: false }));
    }
    this.setState(() => ({ suggestions, text: value }));
  };

  suggestionSelected(value) {
    this.setState(() => ({
      text: value.name,
      id: value.id,
      suggestVar: false,
    }));
  }

  renderSuggestions() {
    if (!this.state.suggestVar) {
      return null;
    }
    return (
      <div className="srchList">
        {this.props.SuggestionDatabase.map((item, key) => (
          <li key={key} onClick={() => this.suggestionSelected(item)}>
            {item.name}
          </li>
        ))}
      </div>
    );
  }

  hideModal = () => {
    this.setState({ show: false, modalModule:0,  text: "",
    id: ""});
  };

  handleEditar = (id, plataform_id, e, estado) => {
    const {
      editar,
      match: {
        params: { model_id },
      },
    } = this.props;
    const UploadDatos = {
      plataformas_id: plataform_id,
      modelos_id: model_id,
      nombre: document.getElementById(`cuentamodelo${id}`).value,
      passwordc: document.getElementById(`passwordModelo${id}`).value,
      option1: document.getElementById(`option1Checkbox${id}`).checked,
      option2: document.getElementById(`option2Checkbox${id}`).checked,
      option3: document.getElementById(`option3Checkbox${id}`).checked,
      visibilidad: estado,
    };
    e.preventDefault();

    editar(UploadDatos, id);
  };

  crearCuentaNueva = () => {

    const { text } = this.state;

    return (
      <div style={{display:"flex", justifyContent:"center"}}>
        <form onSubmit={(e) => this.handleSubmit(e)}>
        <input
                style={{ width: "500px" }}
                required={true}
                type="text"
                className="div__checkbox__input_cuentas"
                placeholder="Nombre de la cuenta"
                value={this.state.valueAccount}
                onChange={(e) => this.setState({valueAccount: e.target.value}) }
              />
            <input
                className="div__checkbox__input_cuentas"
                type="text"
                required={true}
                style={{ width: "98%"}}
                placeholder="password de la cuenta"
                value={this.state.passwordAccount}
                onChange={(e) => this.setState({passwordAccount: e.target.value}) }
              />
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col-md-12 input">
                  <input
                   
                    className="div__checkbox__input_cuentas"
                    value={text}
                    style={{ width: "98%"}}
                    onChange={this.onTextChanged}
                    type="text"
                    placeholder="Buscar Correo"
                    required
                  />
                </div>
                <div className="col-md-12 justify-content-md-center input__autocomplete__SuggestionText">
                  {this.renderSuggestions()}
                </div>
              </div>
            </div>
        </form>

        <button
              type="submit"
              className="btn__reportes_page_cuentas"
              onClick={() => this.CrearCuenta()}
            >
              Crear
            </button>
      </div>
    );
  };

  transferirCuenta = () => {

    const { text } = this.state;

    return (
      <div style={{display:"flex", justifyContent:"center"}}>
        <form onSubmit={(e) => this.handleSubmit(e)}  style={{width:"85%"}}>
        <div className="" style={{width:"100%"}}>
                <div className="" style={{width:"100%"}}>
                  <input  style={{width:"100%"}}
                   
                    className="div__checkbox__input"
                    value={text}
                    onChange={this.onTextChangedModel}
                    type="text"
                    placeholder="Buscar Modelo"
                    required
                  />
                </div>
                <div className="col-md-12 justify-content-md-center input__autocomplete__SuggestionText">
                  {this.renderSuggestions()}
                </div>
              </div>
            <button
              type="submit"
              className="btn__reportes_page_transfer"
              onClick={(e) => this.TraferirCuentaModelo(e)}
            >
              Transferir
            </button>
        </form>

  
      </div>
    );
  };

  Ponerlfechasreporte = () => {
    const { text } = this.state;

    return (
      <div>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <fieldset className="form-fieldset">
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col-md-12 input">
                  <input
                    style={{ width: "500px" }}
                    className="input__autocomplete__Text prestamosGlobar__text"
                    value={text}
                    onChange={this.onTextChanged}
                    type="text"
                    placeholder="Buscar Correo"
                    required
                  />
                </div>
                <div className="col-md-12 justify-content-md-center input__autocomplete__SuggestionText">
                  {this.renderSuggestions()}
                </div>
              </div>
            </div>
            <div></div>
          </fieldset>
        </form>

        <button
              type="submit"
              className="btn__reportes_page"
              onClick={() => this.vincularCorreo()}
            >
              vincular
            </button>

            <table className="table table-striped tabla-global">
              <thead>
                <tr>
                  <th>CUENTA</th>
                  <th>PASSWORD</th>
                </tr>
              </thead>
              <tbody>{this.ponerCorreosVinculados()}</tbody>
            </table>
      </div>
    );
  };

  eliminar = (id, e) => {
    const { eliminar } = this.props;

    eliminar(id);
  };

  openModalEmail = (id, e) => {
    const { getEmailsAccount } = this.props;

    this.setState({ show: true });
    this.setState({ cuentaId: id });

    getEmailsAccount(id);
  };

  handleChangeCuentas(e, index, dataType, value, cuentaPlataforma) {
    let target = e.target;

    if (
      dataType === "option1" ||
      dataType === "option2" ||
      dataType === "option3"
    ) {
      value = target.checked;
    }

    this.props.handleChangeCuentas(index, dataType, value, cuentaPlataforma);
  }

  deshabilitar = () => {
    const { user_cuenta, password_cuenta, cargando } = this.props;
    if (cargando) {
      return true;
    }

    if (!user_cuenta || !password_cuenta) {
      return true;
    }
    return false;
  };

  ponerInformeModelo = () => {
    const { nombreModelo } = this.props;
    const ponerInfo = () =>
      nombreModelo.map((infoModelo, key) => (
        <div className="nombre__reporte__container__cuentas__modelo" key={key}>
          {" "}
          <div className="nombre__div__reporte">CUENTAS</div>{" "}
          <div className="triangulo__report__container"> </div>{" "}
          <span className="nombre__modelo__div">
            {" "}
            {`${infoModelo.nombres} ${infoModelo.apellidos}`}{" "}
          </span>{" "}
        </div>
      ));

    return ponerInfo();
  };

  ponerCuentasChaturbateModelo = () => {
    const { cuentasChaturbate } = this.props;
    let cuentaPlataformaChatur = 1;
    const ponerInfo = () =>
      cuentasChaturbate.map((infoModelo, key) => (
        <Fragment key={key}>
          <div className="container__div__cuentas__modelo">
            <div className="circulo">
              <h2>{infoModelo.numeroCuenta || ""} </h2>
            </div>
            <div className="check_box_acc">
              <div>
                <img
                  alt="cuenta imgagne foto"
                  src={fotoCuentasImg}
                  className="Img__Checkboxs"
                  title="Fotos Modelo"
                />

                <img
                  alt="cuenta imgagne Cuentas"
                  src={biografiaCuentasImg}
                  className="Img__Checkboxs"
                  title="Biografia Modelo"
                />

                <img
                  alt="cuenta imgagne Perfil"
                  src={perfilCuentasImg}
                  className="Img__Checkboxs"
                  title="Perfil Modelo"
                />
              </div>
              <div>
                <input
                  className="checkbox"
                  name={`option1Checkbox${infoModelo.cuentamodeloId}`}
                  id={`option1Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasChaturbate[key].option1}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option1",
                      e.target.value,
                      cuentaPlataformaChatur
                    )
                  }
                />
                <input
                  className="checkbox"
                  id={`option2Checkbox${infoModelo.cuentamodeloId}`}
                  name={`option2Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasChaturbate[key].option2}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option2",
                      e.target.value,
                      cuentaPlataformaChatur
                    )
                  }
                />
                <input
                  className="checkbox"
                  id={`option3Checkbox${infoModelo.cuentamodeloId}`}
                  name={`option3Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasChaturbate[key].option3}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option3",
                      e.target.value,
                      cuentaPlataformaChatur
                    )
                  }
                />
              </div>

              <div className="d-flex" style={{ marginLeft: "-10px" }}>
                {" "}
                <span>
                  <AiOutlineReload
                    className="Home_Work_Stage__Button size__acc__action"
                    onClick={(e) =>
                      this.handleEditar(
                        infoModelo.cuentamodeloId,
                        cuentaPlataformaChatur,
                        e,
                        this.props.cuentasChaturbate[key].visibilidad
                      )
                    }
                  />
                </span>
                <span>
                <AiOutlineMail
                
                className="Home_Work_Stage__Button size__acc__action"
                onClick={(e) =>
                  this.openModalEmail(infoModelo.cuentamodeloId, e)
                }
              />
                </span>
                
                <span>
                  <GoEye
                    id={`visibilidad${infoModelo.cuentamodeloId}`}
                    name={`visibilidad${infoModelo.cuentamodeloId}`}
                    title="Visibilidad de la cuenta"
                    className="Home_Work_Stage__Button size__acc__action"
                    style={
                      this.props.cuentasChaturbate[key].visibilidad
                        ? { color: "red" }
                        : { color: "green" }
                    }
                    onClick={(e) =>
                      this.handleChangeCuentas(
                        e,
                        key,
                        "visibilidad",
                        !this.props.cuentasChaturbate[key].visibilidad,
                        cuentaPlataformaChatur
                      )
                    }
                  />
                </span>

                <span style={{position: "absolute", marginTop:"1.5vw"}}>
                <BiTransfer
                
                className="Home_Work_Stage__Button size__acc__action"
                onClick={(e) =>  this.setState({ modalModule:2, show: true,
                  cuentaModeloId:this.props.cuentasChaturbate[key].cuentamodeloId,plataforma_id:1, accountName:"Chaturbate" })                 
                }
              />
                </span>
              </div>

           
            </div>

            <div className="div__checkbox__input">
              <input
                type="text"
                className="input__cuentas__modelos"
                id={`cuentamodelo${infoModelo.cuentamodeloId}`}
                placeholder="Nombre de la cuenta"
                readonly={true}
                value={this.props.cuentasChaturbate[key].cuentamodelo || ""}
              />
            </div>
            <div className="div__checkbox__input">
              <input
                type="text"
                className="input__cuentas__modelos"
                id={`passwordModelo${infoModelo.cuentamodeloId}`}
                placeholder="password de la cuenta"
                value={
                  this.props.cuentasChaturbate[key].passwordCuentaModelo || ""
                }
                onChange={(e) =>
                  this.handleChangeCuentas(
                    e,
                    key,
                    "passwordCuentaModelo",
                    e.target.value,
                    cuentaPlataformaChatur
                  )
                }
              />
            </div>
          </div>
        </Fragment>
      ));

    return ponerInfo();
  };

  ponerMensajeError = () => {
    const { modeloRepetido, consultar_modelo_cuentas } = this.props;
    return (
      <div
        onClick={(e) => {
          consultar_modelo_cuentas(modeloRepetido);
        }}
        className="btn__perfil"
      >
        Ver Cuentas
      </div>
    );
  };

  ponerCuentasBongaModelo = () => {
    const { cuentasBonga } = this.props;
    let cuentaPlataformaBonga = 2;
    const ponerInfo = () =>
      cuentasBonga.map((infoModelo, key) => (
        <Fragment key={key}>
          <div className="container__div__cuentas__modelo">
            <div className="circulo">
              <h2>{infoModelo.numeroCuenta || ""} </h2>
            </div>
            <div className="check_box_acc">
              <div>
                <img
                  alt="cuenta imgagne foto"
                  src={fotoCuentasImg}
                  className="Img__Checkboxs"
                  title="Fotos Modelo"
                />

                <img
                  alt="cuenta imgagne Cuentas"
                  src={biografiaCuentasImg}
                  className="Img__Checkboxs"
                  title="Biografia Modelo"
                />

                <img
                  alt="cuenta imgagne Perfil"
                  src={perfilCuentasImg}
                  className="Img__Checkboxs"
                  title="Perfil Modelo"
                />
              </div>
              <div>
                <input
                  className="checkbox"
                  name={`option1Checkbox${infoModelo.cuentamodeloId}`}
                  id={`option1Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasBonga[key].option1}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option1",
                      e.target.value,
                      cuentaPlataformaBonga
                    )
                  }
                />
                <input
                  className="checkbox"
                  id={`option2Checkbox${infoModelo.cuentamodeloId}`}
                  name={`option2Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasBonga[key].option2}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option2",
                      e.target.value,
                      cuentaPlataformaBonga
                    )
                  }
                />
                <input
                  className="checkbox"
                  id={`option3Checkbox${infoModelo.cuentamodeloId}`}
                  name={`option3Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasBonga[key].option3}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option3",
                      e.target.value,
                      cuentaPlataformaBonga
                    )
                  }
                />
              </div>

              <div className="d-flex" style={{ marginLeft: "-10px" }}>
                {" "}
                <span>
                  <AiOutlineReload
                    className="Home_Work_Stage__Button size__acc__action"
                    onClick={(e) =>
                      this.handleEditar(
                        infoModelo.cuentamodeloId,
                        cuentaPlataformaBonga,
                        e,
                        this.props.cuentasBonga[key].visibilidad
                      )
                    }
                  />
                </span>
                <span>
                <AiOutlineMail
                
                className="Home_Work_Stage__Button size__acc__action"
                onClick={(e) =>
                  this.openModalEmail(infoModelo.cuentamodeloId, e)
                }
              />
                </span>
                <span>
                  <GoEye
                    id={`visibilidad${infoModelo.cuentamodeloId}`}
                    name={`visibilidad${infoModelo.cuentamodeloId}`}
                    title="Visibilidad de la cuenta"
                    className="Home_Work_Stage__Button size__acc__action"
                    style={
                      this.props.cuentasBonga[key].visibilidad
                        ? { color: "red" }
                        : { color: "green" }
                    }
                    onClick={(e) =>
                      this.handleChangeCuentas(
                        e,
                        key,
                        "visibilidad",
                        !this.props.cuentasBonga[key].visibilidad,
                        cuentaPlataformaBonga
                      )
                    }
                  />
                </span>
                <span style={{position: "absolute", marginTop:"1.5vw"}}>
                <BiTransfer
                
                className="Home_Work_Stage__Button size__acc__action"
                onClick={(e) =>  this.setState({ modalModule:2, show: true, 
                  cuentaModeloId:this.props.cuentasBonga[key].cuentamodeloId,plataforma_id:2, accountName:"BongaCams" })                 
                }
              />
                </span>
              </div>

            </div>

            <div className="div__checkbox__input">
              <input
                type="text"
                className="input__cuentas__modelos"
                id={`cuentamodelo${infoModelo.cuentamodeloId}`}
                placeholder="Nombre de la cuenta"
                readonly={true}
                value={this.props.cuentasBonga[key].cuentamodelo || ""}
              />
            </div>
            <div className="div__checkbox__input">
              <input
                type="text"
                className="input__cuentas__modelos"
                id={`passwordModelo${infoModelo.cuentamodeloId}`}
                placeholder="password de la cuenta"
                value={this.props.cuentasBonga[key].passwordCuentaModelo || ""}
                onChange={(e) =>
                  this.handleChangeCuentas(
                    e,
                    key,
                    "passwordCuentaModelo",
                    e.target.value,
                    cuentaPlataformaBonga
                  )
                }
              />
            </div>
          </div>
        </Fragment>
      ));

    return ponerInfo();
  };

  ponerCuentasStripchatModelo = () => {
    const { cuentasStripchat } = this.props;
    let cuentaPlataformaStrip = 5;
    const ponerInfo = () =>
      cuentasStripchat.map((infoModelo, key) => (
        <Fragment key={key}>
          <div className="container__div__cuentas__modelo">
            <div className="circulo">
              <h2>{infoModelo.numeroCuenta || ""} </h2>
            </div>
            <div className="check_box_acc">
              <div>
                <img
                  alt="cuenta imgagne foto"
                  src={fotoCuentasImg}
                  className="Img__Checkboxs"
                  title="Fotos Modelo"
                />

                <img
                  alt="cuenta imgagne Cuentas"
                  src={biografiaCuentasImg}
                  className="Img__Checkboxs"
                  title="Biografia Modelo"
                />

                <img
                  alt="cuenta imgagne Perfil"
                  src={perfilCuentasImg}
                  className="Img__Checkboxs"
                  title="Perfil Modelo"
                />
              </div>
              <div>
                <input
                  className="checkbox"
                  name={`option1Checkbox${infoModelo.cuentamodeloId}`}
                  id={`option1Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasStripchat[key].option1}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option1",
                      e.target.value,
                      cuentaPlataformaStrip
                    )
                  }
                />
                <input
                  className="checkbox"
                  id={`option2Checkbox${infoModelo.cuentamodeloId}`}
                  name={`option2Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasStripchat[key].option2}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option2",
                      e.target.value,
                      cuentaPlataformaStrip
                    )
                  }
                />
                <input
                  className="checkbox"
                  id={`option3Checkbox${infoModelo.cuentamodeloId}`}
                  name={`option3Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasStripchat[key].option3}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option3",
                      e.target.value,
                      cuentaPlataformaStrip
                    )
                  }
                />
              </div>

              <div className="d-flex" style={{ marginLeft: "-10px" }}>
                {" "}
                <span>
                  <AiOutlineReload
                    className="Home_Work_Stage__Button size__acc__action"
                    onClick={(e) =>
                      this.handleEditar(
                        infoModelo.cuentamodeloId,
                        cuentaPlataformaStrip,
                        e,
                        this.props.cuentasStripchat[key].visibilidad
                      )
                    }
                  />
                </span>
                <span>
                <AiOutlineMail
                
                className="Home_Work_Stage__Button size__acc__action"
                onClick={(e) =>
                  this.openModalEmail(infoModelo.cuentamodeloId, e)
                }
              />
                </span>
                <span>
                  <GoEye
                    id={`visibilidad${infoModelo.cuentamodeloId}`}
                    name={`visibilidad${infoModelo.cuentamodeloId}`}
                    title="Visibilidad de la cuenta"
                    className="Home_Work_Stage__Button size__acc__action"
                    style={
                      this.props.cuentasStripchat[key].visibilidad
                        ? { color: "red" }
                        : { color: "green" }
                    }
                    onClick={(e) =>
                      this.handleChangeCuentas(
                        e,
                        key,
                        "visibilidad",
                        !this.props.cuentasStripchat[key].visibilidad,
                        cuentaPlataformaStrip
                      )
                    }
                  />
                </span>
                <span style={{position: "absolute", marginTop:"1.5vw"}}>
                <BiTransfer
                
                className="Home_Work_Stage__Button size__acc__action"
                onClick={(e) =>  this.setState({ modalModule:2, show: true,
                  cuentaModeloId:this.props.cuentasStripchat[key].cuentamodeloId,plataforma_id:5, accountName:"StripChat" })                 
                }
              />
                </span>
              </div>
            </div>

            <div className="div__checkbox__input">
              <input
                type="text"
                className="input__cuentas__modelos"
                id={`cuentamodelo${infoModelo.cuentamodeloId}`}
                placeholder="Nombre de la cuenta"
                readonly={true}
                value={this.props.cuentasStripchat[key].cuentamodelo || ""}
              />
            </div>
            <div className="div__checkbox__input">
              <input
                type="text"
                className="input__cuentas__modelos"
                id={`passwordModelo${infoModelo.cuentamodeloId}`}
                placeholder="password de la cuenta"
                value={
                  this.props.cuentasStripchat[key].passwordCuentaModelo || ""
                }
                onChange={(e) =>
                  this.handleChangeCuentas(
                    e,
                    key,
                    "passwordCuentaModelo",
                    e.target.value,
                    cuentaPlataformaStrip
                  )
                }
              />
            </div>
          </div>
        </Fragment>
      ));

    return ponerInfo();
  };

  ponerCuentasCam4Modelo = () => {
    const { cuentasCam4 } = this.props;
    let cuentaPlataformacam4 = 4;
    const ponerInfo = () =>
      cuentasCam4.map((infoModelo, key) => (
        <Fragment key={key}>
          <div className="container__div__cuentas__modelo">
            <div className="circulo">
              <h2>{infoModelo.numeroCuenta || ""} </h2>
            </div>
            <div className="check_box_acc">
              <div>
                <img
                  alt="cuenta imgagne foto"
                  src={fotoCuentasImg}
                  className="Img__Checkboxs"
                  title="Fotos Modelo"
                />

                <img
                  alt="cuenta imgagne Cuentas"
                  src={biografiaCuentasImg}
                  className="Img__Checkboxs"
                  title="Biografia Modelo"
                />

                <img
                  alt="cuenta imgagne Perfil"
                  src={perfilCuentasImg}
                  className="Img__Checkboxs"
                  title="Perfil Modelo"
                />
              </div>
              <div>
                <input
                  className="checkbox"
                  name={`option1Checkbox${infoModelo.cuentamodeloId}`}
                  id={`option1Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasCam4[key].option1}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option1",
                      e.target.value,
                      cuentaPlataformacam4
                    )
                  }
                />
                <input
                  className="checkbox"
                  id={`option2Checkbox${infoModelo.cuentamodeloId}`}
                  name={`option2Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasCam4[key].option2}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option2",
                      e.target.value,
                      cuentaPlataformacam4
                    )
                  }
                />
                <input
                  className="checkbox"
                  id={`option3Checkbox${infoModelo.cuentamodeloId}`}
                  name={`option3Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasCam4[key].option3}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option3",
                      e.target.value,
                      cuentaPlataformacam4
                    )
                  }
                />
              </div>

              <div className="d-flex" style={{ marginLeft: "-10px" }}>
                {" "}
                <span>
                  <AiOutlineReload
                    className="Home_Work_Stage__Button size__acc__action"
                    onClick={(e) =>
                      this.handleEditar(
                        infoModelo.cuentamodeloId,
                        cuentaPlataformacam4,
                        e,
                        this.props.cuentasCam4[key].visibilidad
                      )
                    }
                  />
                </span>
                <span>
                <AiOutlineMail
                
                className="Home_Work_Stage__Button size__acc__action"
                onClick={(e) =>
                  this.openModalEmail(infoModelo.cuentamodeloId, e)
                }
              />
                </span>
                <span>
                  <GoEye
                    id={`visibilidad${infoModelo.cuentamodeloId}`}
                    name={`visibilidad${infoModelo.cuentamodeloId}`}
                    title="Visibilidad de la cuenta"
                    className="Home_Work_Stage__Button size__acc__action"
                    style={
                      this.props.cuentasCam4[key].visibilidad
                        ? { color: "red" }
                        : { color: "green" }
                    }
                    onClick={(e) =>
                      this.handleChangeCuentas(
                        e,
                        key,
                        "visibilidad",
                        !this.props.cuentasCam4[key].visibilidad,
                        cuentaPlataformacam4
                      )
                    }
                  />
                </span>

                <span style={{position: "absolute", marginTop:"1.5vw"}}>
                <BiTransfer
                
                className="Home_Work_Stage__Button size__acc__action"
                onClick={(e) =>  this.setState({ modalModule:2, show: true, 
                  cuentaModeloId:this.props.cuentasCam4[key].cuentamodeloId,plataforma_id:4, accountName:"Cam4" })                 
                }
              />
                </span>
              </div>
            </div>

            <div className="div__checkbox__input">
              <input
                type="text"
                className="input__cuentas__modelos"
                id={`cuentamodelo${infoModelo.cuentamodeloId}`}
                placeholder="Nombre de la cuenta"
                readonly={true}
                value={this.props.cuentasCam4[key].cuentamodelo || ""}
          
              />
            </div>
            <div className="div__checkbox__input">
              <input
                type="text"
                className="input__cuentas__modelos"
                id={`passwordModelo${infoModelo.cuentamodeloId}`}
                placeholder="password de la cuenta"
                value={this.props.cuentasCam4[key].passwordCuentaModelo || ""}
                onChange={(e) =>
                  this.handleChangeCuentas(
                    e,
                    key,
                    "passwordCuentaModelo",
                    e.target.value,
                    cuentaPlataformacam4
                  )
                }
              />
            </div>
          </div>
        </Fragment>
      ));

    return ponerInfo();
  };

  ponerCuentasStreamateModelo = () => {
    const { cuentasStreamate } = this.props;
    let cuentaPlataformaStreamate = 3;
    const ponerInfo = () =>
      cuentasStreamate.map((infoModelo, key) => (
        <Fragment key={key}>
          <div className="container__div__cuentas__modelo">
            <div className="circulo">
              <h2>{infoModelo.numeroCuenta || ""} </h2>
            </div>
            <div className="check_box_acc">
              <div>
                <img
                  alt="cuenta imgagne foto"
                  src={fotoCuentasImg}
                  className="Img__Checkboxs"
                  title="Fotos Modelo"
                />

                <img
                  alt="cuenta imgagne Cuentas"
                  src={biografiaCuentasImg}
                  className="Img__Checkboxs"
                  title="Biografia Modelo"
                />

                <img
                  alt="cuenta imgagne Perfil"
                  src={perfilCuentasImg}
                  className="Img__Checkboxs"
                  title="Perfil Modelo"
                />
              </div>
              <div>
                <input
                  className="checkbox"
                  name={`option1Checkbox${infoModelo.cuentamodeloId}`}
                  id={`option1Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasStreamate[key].option1}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option1",
                      e.target.value,
                      cuentaPlataformaStreamate
                    )
                  }
                />
                <input
                  className="checkbox"
                  id={`option2Checkbox${infoModelo.cuentamodeloId}`}
                  name={`option2Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasStreamate[key].option2}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option2",
                      e.target.value,
                      cuentaPlataformaStreamate
                    )
                  }
                />
                <input
                  className="checkbox"
                  id={`option3Checkbox${infoModelo.cuentamodeloId}`}
                  name={`option3Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasStreamate[key].option3}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option3",
                      e.target.value,
                      cuentaPlataformaStreamate
                    )
                  }
                />
              </div>

              <div className="d-flex" style={{ marginLeft: "-10px" }}>
                {" "}
                <span>
                  <AiOutlineReload
                    className="Home_Work_Stage__Button size__acc__action"
                    onClick={(e) =>
                      this.handleEditar(
                        infoModelo.cuentamodeloId,
                        cuentaPlataformaStreamate,
                        e,
                        this.props.cuentasStreamate[key].visibilidad
                      )
                    }
                  />
                </span>
                <span>
                <AiOutlineMail
                
                className="Home_Work_Stage__Button size__acc__action"
                onClick={(e) =>
                  this.openModalEmail(infoModelo.cuentamodeloId, e)
                }
              />
                </span>
                <span>
                  <GoEye
                    id={`visibilidad${infoModelo.cuentamodeloId}`}
                    name={`visibilidad${infoModelo.cuentamodeloId}`}
                    title="Visibilidad de la cuenta"
                    className="Home_Work_Stage__Button size__acc__action"
                    style={
                      this.props.cuentasStreamate[key].visibilidad
                        ? { color: "red" }
                        : { color: "green" }
                    }
                    onClick={(e) =>
                      this.handleChangeCuentas(
                        e,
                        key,
                        "visibilidad",
                        !this.props.cuentasStreamate[key].visibilidad,
                        cuentaPlataformaStreamate
                      )
                    }
                  />
                </span>

                <span style={{position: "absolute", marginTop:"1.5vw"}}>
                <BiTransfer
                
                className="Home_Work_Stage__Button size__acc__action"
                onClick={(e) =>  this.setState({ modalModule:2, show: true,
                  cuentaModeloId:this.props.cuentasStreamate[key].cuentamodeloId,plataforma_id:3, accountName:"streamate" })                 
                }
              />
                </span>
                
              </div>
            </div>

            <div className="div__checkbox__input">
              <input
                type="text"
                className="input__cuentas__modelos"
                id={`cuentamodelo${infoModelo.cuentamodeloId}`}
                placeholder="Nombre de la cuenta"
                readonly={true}
                value={this.props.cuentasStreamate[key].cuentamodelo || ""}
              />
            </div>
            <div className="div__checkbox__input">
              <input
                type="text"
                className="input__cuentas__modelos"
                id={`passwordModelo${infoModelo.cuentamodeloId}`}
                placeholder="password de la cuenta"
                value={
                  this.props.cuentasStreamate[key].passwordCuentaModelo || ""
                }
                onChange={(e) =>
                  this.handleChangeCuentas(
                    e,
                    key,
                    "passwordCuentaModelo",
                    e.target.value,
                    cuentaPlataformaStreamate
                  )
                }
              />
            </div>
          </div>
        </Fragment>
      ));

    return ponerInfo();
  };

  ponerCuentasFlirt = () => {
    const { cuentasFlirt } = this.props;
    let cuentaPlataformaFlirt = 7;
    const ponerInfo = () =>
      cuentasFlirt.map((infoModelo, key) => (
        <Fragment key={key}>
          <div className="container__div__cuentas__modelo">
            <div className="circulo">
              <h2>{infoModelo.numeroCuenta || ""} </h2>
            </div>
            <div className="check_box_acc">
              <div>
                <img
                  alt="cuenta imgagne foto"
                  src={fotoCuentasImg}
                  className="Img__Checkboxs"
                  title="Fotos Modelo"
                />

                <img
                  alt="cuenta imgagne Cuentas"
                  src={biografiaCuentasImg}
                  className="Img__Checkboxs"
                  title="Biografia Modelo"
                />

                <img
                  alt="cuenta imgagne Perfil"
                  src={perfilCuentasImg}
                  className="Img__Checkboxs"
                  title="Perfil Modelo"
                />
              </div>
              <div>
                <input
                  className="checkbox"
                  name={`option1Checkbox${infoModelo.cuentamodeloId}`}
                  id={`option1Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasFlirt[key].option1}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option1",
                      e.target.value,
                      cuentaPlataformaFlirt
                    )
                  }
                />
                <input
                  className="checkbox"
                  id={`option2Checkbox${infoModelo.cuentamodeloId}`}
                  name={`option2Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasFlirt[key].option2}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option2",
                      e.target.value,
                      cuentaPlataformaFlirt
                    )
                  }
                />
                <input
                  className="checkbox"
                  id={`option3Checkbox${infoModelo.cuentamodeloId}`}
                  name={`option3Checkbox${infoModelo.cuentamodeloId}`}
                  type="checkbox"
                  checked={this.props.cuentasFlirt[key].option3}
                  onChange={(e) =>
                    this.handleChangeCuentas(
                      e,
                      key,
                      "option3",
                      e.target.value,
                      cuentaPlataformaFlirt
                    )
                  }
                />
              </div>

              <div className="d-flex" style={{ marginLeft: "-10px" }}>
                {" "}
                <span>
                  <AiOutlineReload
                    className="Home_Work_Stage__Button size__acc__action"
                    onClick={(e) =>
                      this.handleEditar(
                        infoModelo.cuentamodeloId,
                        cuentaPlataformaFlirt,
                        e,
                        this.props.cuentasFlirt[key].visibilidad
                      )
                    }
                  />
                </span>
                <span>
                <AiOutlineMail
                
                className="Home_Work_Stage__Button size__acc__action"
                onClick={(e) =>
                  this.openModalEmail(infoModelo.cuentamodeloId, e)
                }
              />
                </span>
                <span>
                  <GoEye
                    id={`visibilidad${infoModelo.cuentamodeloId}`}
                    name={`visibilidad${infoModelo.cuentamodeloId}`}
                    title="Visibilidad de la cuenta"
                    className="Home_Work_Stage__Button size__acc__action"
                    style={
                      this.props.cuentasFlirt[key].visibilidad
                        ? { color: "red" }
                        : { color: "green" }
                    }
                    onClick={(e) =>
                      this.handleChangeCuentas(
                        e,
                        key,
                        "visibilidad",
                        !this.props.cuentasFlirt[key].visibilidad,
                        cuentaPlataformaFlirt
                      )
                    }
                  />
                </span>

                <span style={{position: "absolute", marginTop:"1.5vw"}}>
                <BiTransfer
                
                className="Home_Work_Stage__Button size__acc__action"
                onClick={(e) =>  this.setState({ modalModule:2, show: true,
                  cuentaModeloId:this.props.cuentaPlataformaFlirt[key].cuentamodeloId,plataforma_id:8, accountName:"Flirt4Free" })                 
                }
              />
                </span>
              </div>
            </div>

            <div className="div__checkbox__input">
              <input
                type="text"
                className="input__cuentas__modelos"
                id={`cuentamodelo${infoModelo.cuentamodeloId}`}
                placeholder="Nombre de la cuenta"
                readonly={true}
                value={this.props.cuentasFlirt[key].cuentamodelo || ""}

              />
            </div>
            <div className="div__checkbox__input">
              <input
                type="text"
                className="input__cuentas__modelos"
                id={`passwordModelo${infoModelo.cuentamodeloId}`}
                placeholder="password de la cuenta"
                value={this.props.cuentasFlirt[key].passwordCuentaModelo || ""}
                onChange={(e) =>
                  this.handleChangeCuentas(
                    e,
                    key,
                    "passwordCuentaModelo",
                    e.target.value,
                    cuentaPlataformaFlirt
                  )
                }
              />
            </div>
          </div>
        </Fragment>
      ));

    return ponerInfo();
  };

  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} modeloRepetido={this.ponerMensajeError} />;
    }
    return false;
  };

  render() {
    return (
      <Fragment>
        {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
        <div className="main-content fade-in">
          <h1 className="tituloPaginas">
            PERFIL <strong className="tituloPaginasPequeño">PLATAFORMAS</strong>
          </h1>

          {this.ponerInformeModelo()}
          {this.mostrarAccion()}
          <div className="reporte__div__principal__cuentas">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 chaturbate__cuenta__div">
                  <img
                    alt="cuenta imgagn chatur"
                    src={ChaturIconImg}
                    className="Cuentas__back__img__icon"
                  />
                  <img
                    alt="cuenta imgagn cuentas"
                    src={cuentasBackImg}
                    className="Cuentas__back__img"
                  />
                  <div></div>
                  <div className="contenedor__cuentas__Modelo">
                    {this.ponerCuentasChaturbateModelo()}

                    <div className="btn__cuentas__modelo__div">
                      <button
                        className="btn__Cuentas__ver__todas"
                        type="submit"
                        onClick={(e) =>
                          this.ConsultarTodasLasCuentas(
                            this.props.nombreModelo[0].id,
                            1,
                            e
                          )
                        }
                      >
                        Ver Todas
                      </button>

                      <img
                        alt="cuenta imgagne add boton"
                        src={addBtnImg}
                        className="addBtnImg"
                        onClick={(e) => { 
                          
                          this.setState({ modalModule:1, show: true, modelo_id:this.props.nombreModelo[0].id, plataforma_id:1, accountName:"Chaturbate" });
                        
                         
                        }}
                      />
                    </div>

                    {/*     {this.Ponerlfechasreporte()}
                                    {this.mostrarAccion()} */}
                  </div>
                </div>

                <div className="col-md-6 chaturbate__cuenta__div">
                  <img
                    alt="cuenta imgagne strip"
                    src={StipchatIconImg}
                    className="Cuentas__back__img__icon__strip"
                  />
                  <img
                    alt="cuenta imgagne cuentas"
                    src={cuentasBackImg}
                    className="Cuentas__back__img"
                  />
                  <div></div>
                  <div className="contenedor__cuentas__Modelo">
                    {this.ponerCuentasStripchatModelo()}

                    <div className="btn__cuentas__modelo__div">
                      <button
                        className="btn__Cuentas__ver__todas"
                        type="submit"
                        onClick={(e) =>
                          this.ConsultarTodasLasCuentas(
                            this.props.nombreModelo[0].id,
                            5,
                            e
                          )
                        }
                      >
                        Ver Todas
                      </button>

                      <img
                        alt="cuenta imgagne add"
                        src={addBtnImg}
                        className="addBtnImg"
                        onClick={(e) =>this.setState({ modalModule:1, show: true, modelo_id:this.props.nombreModelo[0].id, plataforma_id:5, accountName:"StripChat" }) }
                      />
                    </div>

                    {/*     {this.Ponerlfechasreporte()}
                                    {this.mostrarAccion()} */}
                  </div>
                </div>
                <div className="col-md-6 chaturbate__cuenta__div">
                  <img
                    alt="cuenta imgagne strip"
                    src={BongaIconImg}
                    className="Cuentas__back__img__icon__strip"
                  />
                  <img
                    alt="cuenta imgagne cuentas"
                    src={cuentasBackImg}
                    className="Cuentas__back__img"
                  />
                  <div></div>
                  <div className="contenedor__cuentas__Modelo">
                    {this.ponerCuentasBongaModelo()}

                    <div className="btn__cuentas__modelo__div">
                      <button
                        className="btn__Cuentas__ver__todas"
                        type="submit"
                        onClick={(e) =>
                          this.ConsultarTodasLasCuentas(
                            this.props.nombreModelo[0].id,
                            2,
                            e
                          )
                        }
                      >
                        Ver Todas
                      </button>

                      <img
                        alt="cuenta imgagne add"
                        src={addBtnImg}
                        className="addBtnImg"
                        onClick={(e) => this.setState({modalModule:1, show: true, modelo_id:this.props.nombreModelo[0].id, plataforma_id:2, accountName:"BongaCams" }) }
                      />
                    </div>

                    {/*     {this.Ponerlfechasreporte()}
                                    {this.mostrarAccion()} */}
                  </div>
                </div>

                <div className="col-md-6 chaturbate__cuenta__div">
                  <img
                    alt="cuenta imgagne cam4"
                    src={Cam4IconImg}
                    className="Cuentas__back__img__icon__cam4"
                  />
                  <img
                    alt="cuenta imgagne cuentas"
                    src={cuentasBackImg}
                    className="Cuentas__back__img"
                  />
                  <div></div>
                  <div className="contenedor__cuentas__Modelo">
                    {this.ponerCuentasCam4Modelo()}

                    <div className="btn__cuentas__modelo__div">
                      <button
                        className="btn__Cuentas__ver__todas"
                        type="submit"
                        onClick={(e) =>
                          this.ConsultarTodasLasCuentas(
                            this.props.nombreModelo[0].id,
                            4,
                            e
                          )
                        }
                      >
                        Ver Todas
                      </button>

                      <img
                        alt="cuenta imgagne add"
                        src={addBtnImg}
                        className="addBtnImg"
                        onClick={(e) =>this.setState({ modalModule:1, show: true, modelo_id:this.props.nombreModelo[0].id, plataforma_id:4, accountName:"Cam4" })
                        }
                      />
                    </div>

                    {/*     {this.Ponerlfechasreporte()}
                                    {this.mostrarAccion()} */}
                  </div>
                </div>

                <div className="col-md-6 chaturbate__cuenta__div">
                  <img
                    alt="cuenta imgagne streamate"
                    src={StreamateIconImg}
                    className="Cuentas__back__img__icon__streamate"
                  />
                  <img
                    alt="cuenta imgagne cuentas"
                    src={cuentasBackImg}
                    className="Cuentas__back__img"
                  />
                  <div></div>
                  <div className="contenedor__cuentas__Modelo">
                    {this.ponerCuentasStreamateModelo()}

                    <div className="btn__cuentas__modelo__div">
                      <button
                        className="btn__Cuentas__ver__todas"
                        type="submit"
                        onClick={(e) =>
                          this.ConsultarTodasLasCuentas(
                            this.props.nombreModelo[0].id,
                            3,
                            e
                          )
                        }
                      >
                        Ver Todas
                      </button>

                      <img
                        alt="cuenta imgagne add"
                        src={addBtnImg}
                        className="addBtnImg"
                        onClick={(e) =>this.setState({ modalModule:1, show: true, modelo_id:this.props.nombreModelo[0].id, plataforma_id:3, accountName:"Streamate" })
                        }
                      />
                    </div>

                    {/*     {this.Ponerlfechasreporte()}
                                    {this.mostrarAccion()} */}
                  </div>
                </div>

                <div className="col-md-6 chaturbate__cuenta__div">
                  <img
                    alt="cuenta imgagne streamate"
                    src={FlitIconImg}
                    className="Cuentas__back__img__icon__streamate"
                  />
                  <img
                    alt="cuenta imgagne cuentas"
                    src={cuentasBackImg}
                    className="Cuentas__back__img"
                  />
                  <div></div>
                  <div className="contenedor__cuentas__Modelo">
                    {this.ponerCuentasFlirt()}

                    <div className="btn__cuentas__modelo__div">
                      <button
                        className="btn__Cuentas__ver__todas"
                        type="submit"
                        onClick={(e) =>
                          this.ConsultarTodasLasCuentas(
                            this.props.nombreModelo[0].id,
                            7,
                            e
                          )
                        }
                      >
                        Ver Todas
                      </button>

                      <img
                        alt="cuenta imgagne add"
                        src={addBtnImg}
                        className="addBtnImg"
                        onClick={(e) =>this.setState({ modalModule:1, show: true, modelo_id:this.props.nombreModelo[0].id, plataforma_id:7, accountName:"flirt4Free" })
                        }
                      />
                    </div>

                    {/*     {this.Ponerlfechasreporte()}
                                    {this.mostrarAccion()} */}
                  </div>
                </div>

                {/*         <div className="col-md-2 center__reportes__botones">
                                    <div className="btn__reportes_div">
                                        <button className="btn__reportes_page" type="submit"
                                            onClick={''} >Filtrar</button>
                                    </div>
                                    <div className="btn__reportes_div">
                                        <button className="btn__reportes_page" type="submit"
                                          onClick={''} >Ver Historial</button>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.show}
          onHide={this.hideModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
          {this.state.modalModule ===1&& <div style={{width:"100%", textAlign:"center"}}>{this.state.accountName}</div>}
          {this.state.modalModule ===2&& <div style={{width:"100%", textAlign:"center"}}>{`transferir cuenta ${this.state.accountName}`}</div>}
          </Modal.Header>
          <Modal.Body>
          {this.state.modalModule ===1&& this.crearCuentaNueva()}
          {this.state.modalModule ===2&& this.transferirCuenta()}
          
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}
const mapStateToProps = (reducers) => {
  return reducers.CuentasReducer;
};

export default connect(mapStateToProps, cuentasaction)(Cuentas);
