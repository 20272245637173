import axios from 'axios'
import { CARGANDO, ERROR,  GUARDAR,RESEARCHTRUE,PAGINAR_MODELOS,PAGINACION_MODELOS,
    SUCCESS,FILTRAR_MODELO_PAGO,CONSULTARS,CONSULTAR_FECHAS_PAGOS,CAMBIO_FECHA_PAGOS,
    ID_MODELOS,TEXT_MODELOS} from '../types/PerfilVerTypes'
    import {URL} from '../../src/General/url'





export const editar = (Uploaddatoscuenta,model_id) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(Uploaddatoscuenta)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateAccountsModel/${model_id}`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
}

if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
        
 /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

 
}




export const agregar = (prestamomodelo) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(prestamomodelo)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'createFines', params,{
            headers: headers
        })
    
      
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })


        if(respuesta.data.status==="success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const filtrarPagoModelo = (reprotesmodelo) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(reprotesmodelo)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'paymentModelSearch', params,{
            headers: headers
        })
    
      
        dispatch({
            type: FILTRAR_MODELO_PAGO,
            payload:respuesta.data.data
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }

        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}



export const eliminar = (id) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.delete(URL+`destroyAccountsModel/${id}`,{
            headers: headers
        })
    
    
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
        
    } catch (error) {

        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const consultar = (model_id) => async(dispatch)=>{
    

}

export const cambioFechaPagos = (id_pago) => (dispatch) => {
    dispatch({
        type: CAMBIO_FECHA_PAGOS,
        payload: id_pago

    })
}

export const consultar_fechas_pagos = () => async (dispatch) => {


    try {



        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getPaymentDate', {
            headers: headers
        })




        dispatch({
            type: CONSULTAR_FECHAS_PAGOS,
            payload: respuesta.data.data
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}


export const researchtrue  = () => (dispatch,) => {
    dispatch({
        type: RESEARCHTRUE   
         
    })   
};


export const cambio_id_busqueda  = (value) => (dispatch,) => {
    dispatch({
        type: ID_MODELOS  ,
        payload: value
    })   
};



export const cambio_texto_busqueda  = (value) => (dispatch,) => {
    dispatch({
        type: TEXT_MODELOS ,
        payload:value 
    })   
};


export const consultarSuggestions = (data) => async(dispatch)=>{
    


    try {

     
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
    console.log(params)
        const respuesta = await axios.post(URL+`getSuggestionModels`, params,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTARS,
            payload:respuesta.data.data,
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        dispatch({
        
            type: ERROR,
            payload: error.message
            
        
        })
    }
}


export const buscarModelo = (data) => async(dispatch)=>{
    
    
    dispatch({

        type: CARGANDO
        
    
    })


    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`getModelsId/${data}`,{
            headers: headers
        })
    
  
        dispatch({
            type: PAGINAR_MODELOS,
            payload: respuesta.data.data
        })
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: 'Modelo Requerido'
                
            
            })
        }
    }
}
export const paginarModelo = (page,estado) => async(dispatch)=>{
    
    dispatch({

        type: CARGANDO
        
    
    })

    
    try {


    

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + `AllModels/${page}/${estado}`, {
            headers: headers
        })




        dispatch({
            type: PAGINAR_MODELOS,
            payload: respuesta.data.data
        })

        dispatch({
            type: PAGINACION_MODELOS,
            payload: respuesta.data.contador_paginas
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}