import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as reportesModelosPagosaction from '../../actions/ReportesModeloPagoAction'
import Spinner from '../../General/Spinner'
import Fatal from '../../General/Fatal'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles.scss'
import Success from '../../General/Success'
import { Redirect } from 'react-router-dom'
import { FaLongArrowAltRight } from "react-icons/fa";
import defaultLogo from "../../img/defaultLogo.png";
import {URL} from '../../../src/General/url'

/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */









export class ReportesGananciaLugares extends Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: "",
      id: "",
      text: "",
      suggestVar: false,
      id_lugar:'',
      nombre_lugar:'',
    };
  }


  componentDidMount() {

    const {
      consultar_fechas_pagos,
      consultar_lugares,
    } = this.props
    consultar_fechas_pagos();
    consultar_lugares();
 
    
  }


  componentDidUpdate(prevState) {
    console.log(this.props)


  }

  componentWillUnmount() {

    const {
      researchtrue,
    } = this.props

    researchtrue()
  }


  filtrarPagoGananciaLugar=()=>{

    const {
      consultar_pago
    } = this.props

    consultar_pago(this.state.id,this.state.id_lugar)

  }

  cambioFechaPagos = (event) => {

    this.setState({
      id: event.target.value,
    })

  }


  ponerPagoModeloArriendo = () => {
    const URL_LOGO=`${URL}getImageOriginal/${this.props.logo}`
  const { pagosLugaresModelos,

  } = this.props
  const ponerInfoArriendo = () => pagosLugaresModelos.map((infoModelo, key) => (
    <div className="col-md-6 justify-content-md-center" key={key}>
      <div className='informe__modelo__div__container_pagonomina'>
        <div className='div__one__informe__modelo'>
          <img className='logoReportImg' alt='logoReportPagos' src={this.props.logo?URL_LOGO:defaultLogo}></img>
          <span>{infoModelo.cedula} </span>
          <span>{`${infoModelo.nombres} ${infoModelo.apellidos}`}</span>
          <span>{infoModelo.fecha} </span>
          <span> {infoModelo.cuentabancaria} </span>
        </div>
        <div>
          <table className="table table-striped tabla-global-repore-modelo"  >

            <tbody>
              <tr>
                <td>Chaturbate</td>
                <td>${infoModelo.suma_chaturbate}</td>
              </tr>
              <tr>
                <td>Stripchat</td>
                <td>${infoModelo.suma_strip}</td>
              </tr>
              <tr>
                <td>Cam4</td>
                <td>${infoModelo.suma_cam4}</td>
              </tr>
              <tr>
                <td>Streamate</td>
                <td>${infoModelo.suma_stremate}</td>
              </tr>
              <tr>
                <td>Paypal</td>
                <td>${infoModelo.suma_paypal}</td>
              </tr>
              <tr>
                <td>Total Dólares</td>
                <td>${infoModelo.suma_total_plataformas}</td>
              </tr>
              <tr>
                <td>Multas</td>
                <td>${infoModelo.suma_multas ? - infoModelo.suma_multas : 0}</td>
              </tr>
              <tr>
                <td>Porcentaje {infoModelo.porcentaje_pago * 100}%</td>
                <td>${infoModelo.dolares_modelo}</td>
              </tr>
              <tr>
                <td>Total Pesos</td>
                <td>${infoModelo.dolares_modelo * infoModelo.dolar}</td>
              </tr>
              <tr>
                <td>Arriendo</td>
                <td>$-{infoModelo.arriendo_nomina}</td>
              </tr>

              {infoModelo.suma_prestamos ? <tr>
                <td colspan="2"><strong>Préstamos</strong></td>
              </tr>:''}
             
              {infoModelo.suma_prestamos ?infoModelo.Prestamo_modelo.map((prestamo_modelo, key) => (
                   <Fragment>
                       <tr>
              <td>{prestamo_modelo.concepto}</td>
                <td>{prestamo_modelo.cantidad}</td>
              </tr>
                   </Fragment>
              )) : ''}

              {infoModelo.suma_prestamos ? <tr>
                <td>Total Préstamos</td>
                <td>${infoModelo.suma_prestamos ? - infoModelo.suma_prestamos : 0}</td>
              </tr>:''}
             
            

              {infoModelo.prestamog_nomina > 0 ?
                <Fragment>
                  <tr>
                    <td>Deuda restante</td>
                    <td>${infoModelo.prestamog_nomina ? infoModelo.prestamog_nomina : 0}</td>
                  </tr>
                  <tr>
                    <td>Cuota</td>
                    <td>${infoModelo.cuota ? -infoModelo.cuota : 0}</td>
                  </tr>
                  <tr>
                    <td>Debe de la deuda</td>
                    <td>${infoModelo.prestamog_nomina - infoModelo.cuota}</td>
                  </tr>
                </Fragment>
                : ''}

              <tr>
                <td>Total</td>
                <td>$ {Math.round(infoModelo.total_pesos)}</td>
              </tr>


            </tbody>
          </table>
        </div>
        <div className='div__third__informe__modelo'>
          <div>
            <div><FaLongArrowAltRight size={"3rem"} className="informes__pago__arrow" /> {`${infoModelo.suma_chaturbate * 20} Tokens`}</div>
            <div><FaLongArrowAltRight size={"3rem"} className="informes__pago__arrow" />  {`${infoModelo.suma_strip * 20} Tokens`} </div>
          </div>
          <div className='div__third__informe__modelo__dolar__div'>

            <div className='div__third__informe__modelo__dolar__div__dolar__signo'>
              $
          </div>
            <div className='div__third__informe__modelo__dolar__div__dolar__detalle'>

              <span>{infoModelo.dolar}</span>
              <span>TRM</span>

            </div>

          </div>
        </div>
      </div>
    </div>

  ))

  return (ponerInfoArriendo())
}



  listaLugares = () => {
    const { ListaLugares } = this.props;

    const poneropcioneslugaress = () =>

      ListaLugares.map((lugares, key) => (
        <option className="option-values" key={key} value={key}>
          {lugares.nombre}
        </option>
      ));

    return (
      <Fragment>
        <select
          className="right-submenu"
          name="select"
          style={{marginTop:'10px'}}
          required
          onChange={(e) => {
            this.setState({
              id_lugar: this.props.ListaLugares[e.target.value].id,
              nombre_lugar: this.props.ListaLugares[e.target.value].nombre,
            })
           
          }}
        >
           
          <option className="option-values">Lugares</option>
          {poneropcioneslugaress()}
        </select>
    
      </Fragment>
    );
  };
  
  handleSubmit(e) {
    e.preventDefault()
  }

  onTextChanged = e => {
    const value = e.target.value;
    let suggestions = [];

    const { consultarSuggestions } = this.props;
    const dataSuggestion = {
      data: value
    };
    if (value.length > 2) {
      this.setState(() => ({ suggestVar: true }));
      consultarSuggestions(dataSuggestion);
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      suggestions =
        inputLength === 0
          ? []
          : this.props.SuggestionDatabase.filter(
            lang =>
              lang.name.toLowerCase().slice(0, inputLength) === inputValue
          );
    } else {
      this.setState(() => ({ suggestVar: false }));
    }
    this.setState(() => ({ suggestions, text: value }));
  };

  suggestionSelected(value) {
    this.setState(() => ({
      text: value.name,
      id: value.id,
      suggestVar: false
    }));
  }

  renderSuggestions() {
    if (!this.state.suggestVar) {
      return null;
    }
    return (
      <div className="srchList">
        {this.props.SuggestionDatabase.map((item, key) => (
          <li key={key} onClick={() => this.suggestionSelected(item)}>
            {item.name}
          </li>
        ))}
      </div>
    );
  }



  listaFechasPagos = () => {
    const { ListaFechasPagos } = this.props;

    const ponerOpcionesFechas = () =>
      ListaFechasPagos.map((fechas, key) => (
        <option className='option-values' key={key} value={fechas.id}>
          {fechas.fecha}
        </option>
      ));

    return (
      <select
        className="right-submenu_reporte_pago"
        style={{height:'26px'}}
        name="select"
        onChange={this.cambioFechaPagos}
        required
      >
        <option className='option-values' value=''>
          Fechas De Pagos
            </option>
        {ponerOpcionesFechas()}
      </select>
    );
  };


  ponerPagoTotales = () => {
    const { gananciasLugar,

    } = this.props
    const ponerTotales = () => gananciasLugar.map((infoModelo, key) => (
      <div   style={{textAlign:'center',
      display:'flex',
      width:'100%',
      justifyContent:'center'}} key={key}>
      <div className="col-md-3 justify-content-md-center">
        <span>TOTAL A PAGAR:{infoModelo.total_pagar}</span>

      </div>

      <div className="col-md-3 justify-content-md-center">
        <span>TOTAL DÓLARES MODELOS:{infoModelo.dolares_modelos }</span>

      </div>
      <div className="col-md-3 justify-content-md-center" >
        <span>TOTAL DOLARES ESTUDIO:{infoModelo.dolares_studio}</span>

      </div>

      </div>
    ))

    return (ponerTotales())
  }

  ponerInformeModelo = () => {
    const URL_LOGO=`${URL}/getImageOriginal/${this.props.logo}`
    const { ModeloPago,

    } = this.props
    const ponerInfo = () => ModeloPago.map((infoModelo, key) => (

      <div className='informe__modelo__div__container' key={key}>
        <div className='div__one__informe__modelo'>
          <img className='logoReportImg' alt='logoReportPagos' src={this.props.logo?URL_LOGO:defaultLogo}></img>
          <span>{infoModelo.cedula} </span>
          <span>{`${infoModelo.nombres} ${infoModelo.apellidos}`}</span>
          <span>{infoModelo.fecha} </span>
          <span> {infoModelo.cuentabancaria} </span>
        </div>
        <div>
          <table className="table table-striped tabla-global-repore-modelo"  >

            <tbody>
              <tr>
                <td>Chaturbate</td>
                <td>${infoModelo.suma_chaturbate}</td>
              </tr>
              <tr>
                <td>Stripchat</td>
                <td>${infoModelo.suma_strip}</td>
              </tr>
              <tr>
                <td>Cam4</td>
                <td>${infoModelo.suma_cam4}</td>
              </tr>
              <tr>
                <td>Streamate</td>
                <td>${infoModelo.suma_stremate}</td>
              </tr>
              <tr>
                <td>Paypal</td>
                <td>${infoModelo.suma_paypal}</td>
              </tr>
              <tr>
                <td>Total Dólares</td>
                <td>${infoModelo.suma_total_plataformas}</td>
              </tr>
              <tr>
                <td>Porcentaje {infoModelo.porcentaje_pago * 100}%</td>
                <td>${infoModelo.dolares_modelo}</td>
              </tr>
              <tr>
                <td>Total Pesos</td>
                <td>${infoModelo.dolares_modelo * infoModelo.dolar}</td>
              </tr>
              <tr>
                <td>Retención (4%)</td>
                <td>$-{infoModelo.retencion}</td>
              </tr>
              <tr>
                <td>Arriendo</td>
                <td>$-{infoModelo.arriendo_nomina}</td>
              </tr>
              {infoModelo.suma_prestamos ? <tr>
                  <td colspan="2"><strong>Préstamos</strong></td>
                </tr>:''}
               
                {infoModelo.suma_prestamos ?infoModelo.Prestamo_modelo.map((prestamo_modelo, key) => (
                     <Fragment>
                         <tr>
                <td>{prestamo_modelo.concepto}</td>
                  <td>{prestamo_modelo.cantidad}</td>
                </tr>
                     </Fragment>
                )) : ''}

                {infoModelo.suma_prestamos ? <tr>
                  <td>Total Préstamos</td>
                  <td>${infoModelo.suma_prestamos ? - infoModelo.suma_prestamos : 0}</td>
                </tr>:''}
              <tr>
                <td>Multas</td>
                <td>${infoModelo.suma_multas ? - infoModelo.suma_multas : 0}</td>
              </tr>

              {infoModelo.prestamog_nomina>0 ? 
              <Fragment>
              <tr>
                <td>Deuda restante</td>
                <td>${infoModelo.prestamog_nomina ? infoModelo.prestamog_nomina : 0}</td>
              </tr>
               <tr>
               <td>Cuota</td>
               <td>${infoModelo.cuota ? -infoModelo.cuota : 0}</td>
             </tr>
              <tr>
              <td>Debe de la deuda</td>
              <td>${infoModelo.prestamog_nomina - infoModelo.cuota }</td>
            </tr>
            </Fragment>
              : ''}
             
              <tr>
                <td>Total</td>
                <td>$ {infoModelo.total_pesos}</td>
              </tr>


            </tbody>
          </table>
        </div>
        <div className='div__third__informe__modelo'>
          <div>
          <div className='arrow_chaturbate'><FaLongArrowAltRight size={"3rem"} className="informes__pago__arrow" /> {`${infoModelo.suma_chaturbate * 20} Tokens`}</div>
          <div className='arrow_strip'><FaLongArrowAltRight size={"3rem"} className="informes__pago__arrow" />  {`${infoModelo.suma_strip * 20} Tokens`} </div>
          </div>
          <div className='div__third__informe__modelo__dolar__div'>

             <div className='div__third__informe__modelo__dolar__div__dolar__signo'>
          $
            </div>
            <div className='div__third__informe__modelo__dolar__div__dolar__detalle'>

              <span>{infoModelo.dolar}</span>
              <span>TRM</span>
            
            </div>
          
          </div>
        </div>
      </div>


    ))

    return (ponerInfo())
  }



  mostrarAccion = () => {


    const { error, cargando, success } = this.props
    if (cargando) {
      return <Spinner />;
    }


    if (success) {
      return <Success mensaje={success} />;
    }


    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  }




  render() {
    console.log(this.state)


    return (



      <Fragment>
        {(!window.localStorage.getItem('token')) ? <Redirect to='/' /> : ''}
        <div className='main-content fade-in'>
          <h1 className="tituloPaginas">
            REPORTES <strong className="tituloPaginasPequeño">PAGOS</strong>
          </h1>
          <div className='reporte__div__principal'>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 ">
                  {this.listaLugares()}
                  {this.mostrarAccion()}
                </div>

                <div className="col-md-6 Selecbox_fechas" >
                  {this.listaFechasPagos()}

                </div>

                <div className="col-md-12 center__reportes__botones">
                  <div className="btn__reportes_div">
                    <button className="Boton__reportes__pagos__modelos" type="submit"
                      onClick={this.filtrarPagoGananciaLugar} >Buscar</button>
                  </div>
                </div>

              </div>
            </div>


          </div>
          <div className=''>
          <div className="reporte__div__principal_pagonomina">
            <div className="container-fluid">
              <div className="row">
                
              {this.ponerPagoTotales()}
                

                {this.ponerPagoModeloArriendo()}
  

                {

                }
              </div>
              {this.mostrarAccion()}
            </div>
          </div>


          </div>
        </div>
      </Fragment>
    )
  }
}
const mapStateToProps = (reducers) => {
  return reducers.ReportesModeloPagoReducer
}

export default connect(mapStateToProps, reportesModelosPagosaction)(ReportesGananciaLugares)