export const CONSULTAR = 'reportes_traer_todas';
export const CARGANDO = 'reportes_cargando';
export const ERROR = 'reportes_error';
export const GUARDAR = 'reportes_guardada';
export const ACTUALIZAR = 'reportes_actualizar';
export const LIMPIAR = 'reportes_limpiar';
export const RESEARCHTRUE = 'reportes_research';
export const SUCCESS = 'reportes_succes';
export const CAMBIO_FECHAPRESTAMO = 'reportes_fecha';
export const CAMBIO_CANTIDAD = 'reportes_cantidad';
export const CAMBIO_CONCEPTO = 'reportes_concepto';
export const CAMBIO_FECHA1 = 'reportes_fecha1';
export const CAMBIO_FECHA2 = 'reportes_fecha2';
export const FILTRAR_PRESTAMOS_MINIMOS = 'reportes_minimos_filtrar';
export const CAMBIO_LUGARES = 'reportes_cambio_lugares';
export const CONSULTAR_LUGARES = 'reportes_consultar_lugares';
export const FLAG_REPORTE_LUGARES = 'flag_Reportes_lugares_todos';
export const TOTAL_REPORTE = 'reportes_consultar_total_reporte';
export const FLAG_REPORTE_LUGARES_TABLA = 'flag_Reportes_lugares_todos_tabla';







