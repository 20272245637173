export const CONSULTAR = 'perfil_modificar_traer_todas';
export const CARGANDO = 'perfil_modificar_cargando';
export const ERROR = 'perfil_modificar_error';
export const GUARDAR = 'perfil_modificar_guardada';
export const ACTUALIZAR = 'perfil_modificar_actualizar';
export const LIMPIAR = 'perfil_modificar_limpiar';
export const RESEARCHTRUE = 'perfil_modificar_research';
export const SUCCESS = 'perfil_modificar_succes';
export const CAMBIO_LUGAR_ID = 'perfil_cambio_lugares';
export const CAMBIO_TURNO_ID = 'perfil_cambio_turnos';
export const CONSULTAR_LUGARES = 'perfil_consultar_lugares';
export const CONSULTAR_TURNOS = 'perfil_consultar_horario';
export const RELOAD = 'perfil_modificar_reload';
export const IMAGEN_PERFIL = 'perfil_imagen_perfil';
export const GALERIA_MODELO = 'perfil_galeria_modelo';
export const GALERIA_FLAG = 'perfil_galeria_modelo_flag';
export const CONTABLE_FLAG = 'perfil_galeria_modelo_flag_contable';
export const INFORMACION_CONTABLE_DULICADA = 'perfil_modificar_duplicada';
export const INFORMACION_CONTABLE_DULICADA_ID = 'perfil_modificar_duplicada_id';
export const IMAGEN_FIRMA = 'firma_modelo';
export const CAMBIO_NOMBRE_FIRMA = 'firma_modelo_nombre';
export const CAMBIO_BANCO_ID = 'cambio_tipo_banco';
export const CONSULTAR_BANCOS = 'consultar_listado_bancos';













