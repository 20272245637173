import axios from 'axios'
import { CARGANDO, ERROR,  GUARDAR, CONSULTAR,CAMBIO_HORARIOS,RESEARCHTRUE,CAMBIO_FECHA1,
    SUCCESS,FILTRAR_REPORTES_DIARIOS,CONSULTAR_HORARIOS,CONSULTAR_NOVEDADES,CREAR_NOVEDADES} from '../types/ReportesDiarioStudioTypes'


    import {URL} from '../../src/General/url'





export const editar = (UploaddDatosReporte,reporte_id) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(UploaddDatosReporte)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateReportDealyStudio/${reporte_id}`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload:  respuesta.data.message 
        
    
    })
}

if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
        
 /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

 
}


export const consultar_horarios = () => async (dispatch) => {


    try {


  
        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getSchedules', {
            headers: headers
        })




        dispatch({
            type: CONSULTAR_HORARIOS,
            payload: respuesta.data.data
        })

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}



export const consultar_novedades_fechas = (data) => async(dispatch)=>{
    

    try {

    

        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'getNoveltyTurn', params,{
            headers: headers
        })
    
      
        dispatch({
            type: CONSULTAR_NOVEDADES,
            payload:respuesta.data.data
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }

        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}




export const crearRporteNovedad = (data) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'createNoveltyTurn', params,{
            headers: headers
        })
    
      

        if(respuesta.data.status==="success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })


        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }

        
        dispatch({
            type: CREAR_NOVEDADES,
        })
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const filtrarReportesDiarios = (porpsReportesDiarios) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(porpsReportesDiarios)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'getReportDealyStudio', params,{
            headers: headers
        })
    
      
        dispatch({
            type: FILTRAR_REPORTES_DIARIOS,
            payload:respuesta.data.data
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}



export const eliminar = (id) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {


   
        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.delete(URL+`destroyAccountsModel/${id}`,{
            headers: headers
        })
    
    
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {

        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const consultar = (model_id) => async(dispatch)=>{
    


    try {

     

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`searchModelsfinesReport/${model_id}`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTAR,
            payload:respuesta.data.data,
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const cambioHorarios= (horarios_id) => (dispatch) => {
    dispatch({
        type: CAMBIO_HORARIOS,
        payload: horarios_id

    })
}



export const cambiofecha1  = (fecha1) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA1,
        payload: fecha1
        
    
    })
    
};





export const researchtrue  = () => (dispatch,) => {
    dispatch({

        type: RESEARCHTRUE
        
    
    })


    

   
};

export const handleChange  = (index, dataType, value) => (dispatch,getState) => {

    const {ReporteDiario} = getState().ReportesDiarioStudioReducer


    const newReporteDiario = ReporteDiario.map((item, i) => {
        if (i === index) {
          return {...item, [dataType]: value};
        }
        return item;
      });
  

      dispatch({
        type: FILTRAR_REPORTES_DIARIOS,
        payload:newReporteDiario
    })


    

   
};