import {
  CARGANDO,
  ERROR,
  CONSULTAR,
  RESEARCHTRUE,
  RELOAD,
  SUCCESS,
  IMAGEN_PERFIL,
  IMAGEN_360,
  NEW_HABITACIONES,
  CONSULTARS,
  CONSULTAR_TURNOS,
  CAMBIO_TURNO_ID,
  CONSULTAR_MODELOS_HABITACION,
  RESEARCH_MODELOS_HABITACION,
  CONSULTAR_TODAS_HABITACIONES,
  CONSULTAR_TODAS_HABITACIONES_REPORTE,
  CONSULTAR_LUGARES,
  CONSULTAR_PERMISOS,
  CONSULTAR_PERMISOS_UPDATE,
  CONSULTAR_PERMISOS_UPDATE_LUGARES,
  RESEARCH_CORREO
} from "../types/correosReportesExtensionTypes";

const INITIAL_STATE = {
  habitaciones: [],
  ListaLugares: [],
  ListaPermisos: [],
  ListaPermisosUpdate: [],
  permisosLugares: [],
  SuggestionDatabase: [],
  modeloHabitacion: [],
  todasHabitaciones: [],
  todasHabitacionesReportes: [],
  foto_perfil: "",
  imagen_360: "",
  firma_modelo: "",
  numRoom: "",
  descripcion: "",
  inventario: "",
  cargando: false,
  estadoFlag: false,
  error: "",
  research: "",
  success: "",
  turnos: [],
  turno_id: "",
  correos_consulta:true
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CARGANDO:
      return { ...state, cargando: true };

    case ERROR:
      return { ...state, error: action.payload, cargando: false, success: "" };

    case SUCCESS:
      return { ...state, success: action.payload, cargando: false, error: "" };

    case IMAGEN_PERFIL:
      return {
        ...state,
        foto_perfil: action.payload,
        cargando: false,
        research: false,
      };

    case IMAGEN_360:
      return {
        ...state,
        imagen_360: action.payload,
        cargando: false,
        research: false,
      };
    case CONSULTAR_TODAS_HABITACIONES_REPORTE:
      return {
        ...state,
        todasHabitacionesReportes: action.payload,
        cargando: false,
        research: false,
      };
    case CONSULTAR:
      return {
        ...state,
        habitaciones: action.payload,
        cargando: false,
        research: false,
      };

    case CONSULTAR_MODELOS_HABITACION:
      return {
        ...state,
        modeloHabitacion: action.payload,
        cargando: false,
        research: false,
      };
      
      case RESEARCH_CORREO:
        return {
          ...state,
          correos_consulta:false
        };
    case CONSULTAR_LUGARES:
      return {
        ...state,
        ListaLugares: action.payload,
      };
    case CONSULTAR_PERMISOS:
      return {
        ...state,
        ListaPermisos: action.payload,
        correos_consulta:true
      };

    case CONSULTAR_PERMISOS_UPDATE:
      return {
        ...state,
        ListaPermisosUpdate: action.payload,
      };

      case CONSULTAR_PERMISOS_UPDATE_LUGARES:
        return {
          ...state,
          permisosLugares: action.payload,
        };
  
    case NEW_HABITACIONES:
      return {
        ...state,
        habitaciones: action.payload,
        cargando: false,
        research: false,
      };

    case CONSULTAR_TODAS_HABITACIONES:
      return {
        ...state,
        todasHabitaciones: action.payload,
        cargando: false,
        research: false,
      };

    case CONSULTAR_TURNOS:
      return {
        ...state,
        turnos: action.payload,
        cargando: false,
        research: false,
      };
    case CAMBIO_TURNO_ID:
      return {
        ...state,
        turno_id: action.payload,
      };
    case RESEARCH_MODELOS_HABITACION:
      return {
        ...state,
        estadoFlag: action.payload,
      };

    case CONSULTARS:
      return {
        ...state,
        SuggestionDatabase: action.payload,
      };

    case RESEARCHTRUE:
      return {
        ...state,
        foto_perfil: "",
        imagen_360: "",
        firma_modelo: "",
        num_room: "",
        descripcion: "",
        inventario: "",
        cargando: false,
        estadoFlag: false,
        error: "",
        research: "",
        success: "",
      };

    case RELOAD:
      return {
        ...state,

        cargando: false,
        research: true,
      };
    default:
      return state;
  }
};
