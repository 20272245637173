export const CONSULTAR = 'correo_reportes_consultar_todas_correo_reporteses';
export const CARGANDO = 'correo_reportes_agregar_cargando';
export const ERROR = 'correo_reportes_agregar_error';
export const GUARDAR = 'correo_reportes_agregar_guardada';
export const ACTUALIZAR = 'correo_reportes_agregar_actualizar';
export const LIMPIAR = 'correo_reportes_agregar_limpiar';
export const RESEARCHTRUE = 'correo_reportes_agregar_research';
export const SUCCESS = 'correo_reportes_agregar_succes';
export const RELOAD = 'correo_reportes_agregar_reload';
export const IMAGEN_PERFIL = 'correo_reportes_agregar_imagen_perfil';
export const IMAGEN_360 = 'correo_reportes_agregar_imagen_360';
export const NEW_HABITACIONES = 'correo_reportes_update_inventario';
export const CONSULTARS = 'correo_reportes_consular_suggestion';
export const CONSULTAR_TURNOS = 'correo_reportes_consular_horario';
export const CONSULTAR_MODELOS_HABITACION = 'correo_reportes_modelos_permisos';
export const CAMBIO_TURNO_ID = 'correo_reportes_cambiar_turno';
export const RESEARCH_MODELOS_HABITACION = 'correo_reportes_estado_flag';
export const CONSULTAR_TODAS_HABITACIONES = 'correo_reportes_consultar_todas';
export const CONSULTAR_TODAS_HABITACIONES_REPORTE = 'correo_reportes_consultar_todas_reporte';
export const CONSULTAR_LUGARES = 'correo_reportes_consultar_lugar';
export const CONSULTAR_PERMISOS = 'correo_reportes_consultar_permisos';
export const CONSULTAR_PERMISOS_UPDATE = 'correo_reportes_consultar_permisos_updates';
export const CONSULTAR_PERMISOS_UPDATE_LUGARES = 'correo_reportesconsultar_permisos_updates_lugares';
export const RESEARCH_CORREO = 'correo_reportes_consultar_de_nuevo';



















