import {
    CARGANDO, ERROR, CONSULTAR, RESEARCHTRUE
    , SUCCESS, CAMBIO_FECHA1, CAMBIO_FECHA2,DEUDA_MODELOS 
} from '../types/ReporteTotalType'

const INITIAL_STATE = {
    ReporteTotal: [],
    reporteTotalDeuda:[],
    cargando: false,
    error: '',
    fecha1: '',
    fecha2: '',
    research: '',
    success: ''

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTAR:
            return {
                ...state,
                ReporteTotal: action.payload,
                cargando: false,
                research: false,

            }




        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false, success: '' }


        case SUCCESS:
            return { ...state, success: action.payload, cargando: false, error: '' }


        case CAMBIO_FECHA1:
            return {
                ...state,
                fecha1: action.payload,

            }

            
        case DEUDA_MODELOS:
            return {
                ...state,
                reporteTotalDeuda: action.payload,
                error: '',
                success: '',
                cargando: false,
            }

            
        case CAMBIO_FECHA2:
            return {
                ...state,
                fecha2: action.payload,

            }

        case RESEARCHTRUE
            :
            return {
                ...state,
                cargando: false,
                error: '',
                success: '',
                research: '',



            }


        default: return state
    }

}