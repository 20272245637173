export const CONSULTAR = 'multa_agregar_motinor__traer_todas';
export const CONSULTARS = 'multa_agregar_motinor__traer_todas_suggestions';
export const CONSULTAR_TOTALES = 'multa_agregar_motinor__traer_todas_prestamos';
export const CARGANDO = 'multa_agregar_motinor__cargando';
export const ERROR = 'multa_agregar_motinor__error';
export const GUARDAR = 'multa_agregar_motinor__guardada';
export const ACTUALIZAR = 'multa_agregar_motinor__actualizar';
export const LIMPIAR = 'multa_agregar_motinor__limpiar';
export const RESEARCHTRUE = 'multa_agregar_motinor__research';
export const RESEARPRESTAMO = 'multa_agregar_motinor__research_prestamo';
export const SUCCESS = 'multa_agregar_motinor__succes';
export const CAMBIO_FECHAPRESTAMO = 'multa_agregar_motinor__fecha';
export const CAMBIO_CANTIDAD = 'multa_agregar_motinor__cantidad';
export const CAMBIO_CONCEPTO = 'multa_agregar_motinor__concepto';
export const CAMBIO_FECHA1 = 'multa_agregar_motinor__fecha1';
export const CAMBIO_FECHA2 = 'multa_agregar_motinor__fecha2';
export const CONSULTAR_MULTAS_MODELOS = 'multa_agregar_motinor__minimos_filtrar';
export const CONSULTAR_GANANCIAS_MULTAS_MONITOR = 'multa_agregar_motinor_ganancias_filtrar';
export const CONSULTAR_GANANCIAS_MULTA = 'multa_consultar_motinor_ganancias_filtrar';








