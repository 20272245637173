import {
  CARGANDO,
  ERROR,
  CONSULTAR,
  RESEARCHTRUE,
  CAMBIO_LUGAR_ID,
  CAMBIO_TURNO_ID,
  RELOAD,
  SUCCESS,
  IMAGEN_PERFIL,
  CONSULTAR_LUGARES,
  CONSULTAR_TURNOS,
  GALERIA_MODELO,
  GALERIA_FLAG
} from "../types/PerfilModificarInfoMonitorTypes";

const INITIAL_STATE = {
  ListaFechasPagos: [],
  lugares: [],
  turnos: [],
  galeriaModelo: [],
  pagosNominaSuma: [],
  fechas_pagos_id: '',
  informacionModelo: [],
  perfilImg: '',
  lugares_id: '',
  turno_id: '',
  cargando: false,
  galeriaFlag:true,
  error: "",
  research: "",
  success: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONSULTAR:
      return {
        ...state,
        informacionModelo: action.payload,
        cargando: false,
        research: false
      };

    case CARGANDO:
      return { ...state, cargando: true };

    case ERROR:
      return { ...state, error: action.payload, cargando: false, success: "" };

    case SUCCESS:
      return { ...state, success: action.payload, cargando: false, error: "" };


    case IMAGEN_PERFIL:
      return {
        ...state,
        perfilImg: action.payload,
        cargando: false,
        research: false,
      }

    case GALERIA_MODELO:
      return {
        ...state,
        galeriaModelo: action.payload,
        cargando: false,
        research: false,
        galeriaFlag:true
      }

      case GALERIA_FLAG:
        return {
          ...state,
          galeriaFlag:action.payload,
        }
      


    case CONSULTAR_LUGARES:
      return {
        ...state,
        lugares: action.payload,
        cargando: false,
        research: false,
      }
    case CONSULTAR_TURNOS:
      return {
        ...state,
        turnos: action.payload,
        cargando: false,
        research: false,
      }

    case CAMBIO_LUGAR_ID:
      return {
        ...state,
        lugares_id: action.payload,
      }

    case CAMBIO_TURNO_ID:
      return {
        ...state,
        turno_id: action.payload,
      }





    case RESEARCHTRUE:
      return {
        ...state,

        ListaFechasPagos: [],
        lugares: [],
        turnos: [],
        galeriaModelo: [],
        pagosNominaSuma: [],
        fechas_pagos_id: '',
        informacionModelo: [],
        perfilImg: '',
        lugares_id: '',
        turno_id: '',
        cargando: false,
        galeriaFlag:true,
        error: "",
        research: "",
        success: ""
      };

    case RELOAD:
      return {
        ...state,

        cargando: false,
        research: true
      };
    default:
      return state;
  }
};
