import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as reporteTotalActionsindeducciones from '../../actions/ReporteTotalSinDeduccionesAction'
import Spinner from '../../General/Spinner'
import Fatal from '../../General/Fatal'
import Success from '../../General/Success'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles.scss'
import { Redirect } from 'react-router-dom'
import DatePicker from 'react-date-picker';
import moment from 'moment'


/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */









export class ReporteTotalSinDeducciones extends Component {

    state = {
        dateStart: new Date(),
        dateEnd: new Date(),
    }

    componentDidMount() {


        if(!this.props.fecha1){

            this.cambiofecha1(new Date())
        }
        if(!this.props.fecha2){

            this.cambiofecha2(new Date())
        }

    }


    componentDidUpdate(prevState) {
        console.log(this.props)


    }

    componentWillUnmount() {

        const {
            researchtrue,
        } = this.props

        researchtrue()
    }


   
    filtrReporteTotal = () => {

        const {
            fecha1,
            fecha2,
      
            consultarTotal,

        } = this.props
        const fechas_reporte = {
            fecha_inicial: fecha1,
            fecha_final: fecha2,
    


        }



        consultarTotal(fechas_reporte)



    }

    handleSubmit(e) {
        e.preventDefault()
    }


  
    cambiofecha1 = date => {    
        this.props.cambiofecha1((moment(date).format('YYYY-MM-DD') ));
    };

    cambiofecha2 = date => {

        var fechaFinalString = ''
        if (date) {
            fechaFinalString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        } else {
            fechaFinalString = ''
        }
        this.props.cambiofecha2(fechaFinalString);
    };

    descargarExcelModelosReportTotal=(e)=>{
        e.preventDefault();
        const {
            fecha1,
            fecha2,
        } = this.props
        const data = {
            fecha_inicial: fecha1,
            fecha_final: fecha2,


        }
        this.props.descargarExcelModelosReportTotal(data)
      }
    ponerReporte = () => {
        const { ReporteTotalNoDeductions,

        } = this.props
        const ponerInfo = () => ReporteTotalNoDeductions.map((reporte, key) => (


            <tr key={key}>
                <td>{reporte.cedula}</td>
                <td>{reporte.nombres} {reporte.apellidos}</td>
                <td>{new Intl.NumberFormat().format(reporte.total_pago)}</td>
                
      

            </tr>

        ))

        return (ponerInfo())
    }




    Ponerlfechasreporte = () => {


        return <div><form onSubmit={(e) => this.handleSubmit(e)}>
            <fieldset className="form-fieldset">
                <div>


                    <DatePicker
                        onChange={this.cambiofecha1}
                        value={this.props.fecha1?new Date(this.props.fecha1):''}
                    />
                </div>
                <div>
                    <DatePicker
                        onChange={this.cambiofecha2}
                        value={this.props.fecha2?new Date(this.props.fecha2):''}
                    />

                </div>
                <div className=" form-input select__places ">
                    {/*    <input  className="form-element-field -hasvalue" placeholder=" " type="number" value={this.props.lugares_id}
            onChange={this.cambiocantidad}  /> */}
                  
                </div>
            </fieldset>

        </form>
        </div>
    }




    mostrarAccion = () => {


        const { error, cargando, success } = this.props
        if (cargando) {
            return <Spinner />;
        }


        if (success) {
            return <Success mensaje={success} />;
        }


        if (error) {
            return <Fatal mensaje={error} />;
        }
        return false;
    }




    render() {



        return (



            <Fragment>
                {(!window.localStorage.getItem('token')) ? <Redirect to='/' /> : ''}
                <div className='main-content fade-in'>
                <h1 className="tituloPaginas">
                REPORTES <strong className="tituloPaginasPequeño">TOTAL PAGADO SIN DEDUCCIONES</strong>
          </h1>
                    <div className='reporte__div__principal'>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 ">
                                    {this.Ponerlfechasreporte()}
                                    {this.mostrarAccion()}
                                </div>

                                <div className="col-md-2 center__reportes__botones">
                                    <div className="btn__reportes_div">
                                        <button className="btn__reportes_page" type="submit"
                                            onClick={this.filtrReporteTotal} >Filtrar</button>
                                    </div>
                                    <div className="btn__reportes_div">
                                        <button className="btn__reportes_page" type="submit"
                                            onClick={this.descargarExcelModelosReportTotal} >Descargar Excel</button>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                    <div className=''>
                        <div className="container-fluid">
                            <div className="row">
                           
                                <div className="col-md-12 ">

                                    <table  className="table table-striped tabla-global" >
                                        <thead>
                                            <tr>
                                                <th>CÉDULA </th>
                                                <th>NOMBRE</th>
                                                <th>TOTAL</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.ponerReporte()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = (reducers) => {
    return reducers.ReporteTotalSinDeduccionesReducer
}

export default connect(mapStateToProps, reporteTotalActionsindeducciones)(ReporteTotalSinDeducciones)