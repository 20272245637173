import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as perfilverMonitoraction from '../../actions/PerfilVerMonitorAction'
import { Link } from "react-router-dom";
import Spinner from '../../General/Spinner'
import Fatal from '../../General/Fatal'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles.scss'
import Success from '../../General/Success'
import { Redirect } from 'react-router-dom'
import cedulaImg from "../../img/cedulaImg.png";
import modeloNombresImg from "../../img/modeloNombresImg.png";
import cuentaBancariaIcon from "../../img/cuentaBancariaIcon.png";
import moneyIcon from "../../img/moneyIcon.png";

import searchImg from "../../img/searchImg.png";

import {URL} from '../../../src/General/url'


/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */









export class PerfilVerMonitor extends Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: "",
      id: "",
      text: "",
      suggestVar: false,
      estado: 1
    };
  }


  componentDidMount() {

    const {

      paginarMonitores



    } = this.props

    paginarMonitores()
  }


  componentDidUpdate(prevState) {
    console.log(this.state)


  }

  componentWillUnmount() {
    const { researchtrue } = this.props;

    researchtrue();

  }


  buscarMontior = () => {

    const {
      buscarMontior,
    } = this.props

    buscarMontior(this.state.id)
  }

  handleChangeEstado = event => {
    this.setState({estado: event.target.value});
  }

  handleSubmit(e) {
    e.preventDefault()
  }

  onTextChanged = e => {
    const value = e.target.value;
    let suggestions = [];

    const { consultarSuggestions } = this.props;
    const dataSuggestion = {
      data: value,
      estado:this.state.estado
    };
    if (value.length > 2) {
      this.setState(() => ({ suggestVar: true }));
      consultarSuggestions(dataSuggestion);
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      suggestions =
        inputLength === 0
          ? []
          : this.props.SuggestionDatabase.filter(
            lang =>
              lang.name.toLowerCase().slice(0, inputLength) === inputValue
          );
    } else {
      this.setState(() => ({ suggestVar: false }));
    }
    this.setState(() => ({ suggestions, text: value }));
  };

  suggestionSelected(value) {
    this.setState(() => ({
      text: value.name,
      id: value.id,
      suggestVar: false
    }));
  }

  renderSuggestions() {
    if (!this.state.suggestVar) {
      return null;
    }
    return (
      <div className="srchList">
        {this.props.SuggestionDatabase.map((item, key) => (
          <li key={key} onClick={() => this.suggestionSelected(item)}>
            {item.name}
          </li>
        ))}
      </div>
    );
  }





  ponerInformeModelo = () => {

    const { Monitores,

    } = this.props
    const ponerInfo = () => Monitores.map((Monitores, key) => (


      <div className="col-md-6 "  key={key}>
      <table className="table table-striped tabla-global">
        <thead>
          <tr>
            <th>FOTO</th>
            <th>DATOS PERSONALES </th>
            <th>ACCIONES </th>
          </tr>
        </thead>
        <tbody>
        <tr>
        <td className='perfiltd'>
          <div className='avatarModeloPerfil'>
            <img alt="avartar perfil" className='avatarModeloPerfilimg' src={`${URL}getImage/${Monitores.profile_picture ? Monitores.profile_picture : 'defaultLogo.png'}`} />
          </div>
        </td>

        <td >
          <div className='informacion_personal_td'>

            <div><img alt='perfil cedulaImg' src={cedulaImg} />{Monitores.cedula}</div>
            <div><img alt='perfil nombresImg' src={modeloNombresImg} />{`${Monitores.nombre} ${Monitores.apellidos}`}</div>
            <div><img alt='perfil moneyImg' src={moneyIcon} />{Monitores.salario} </div>
            <div><img alt='perfil BancoImg' src={cuentaBancariaIcon} />{Monitores.cuentabancaria}</div>
          </div>
        </td>
        <td >
          <div className='informacion_personal_td'>
            <div className="btn__reportes__div__pagos">
              <Link
                to={`/PerfilModificarInfoMonitor/${Monitores.id}`}
                className="btn__perfil">Modificar Info</Link>
            </div>
      
          </div>
        </td>

      </tr>
        </tbody>
      </table>
    </div>

    ))

    return (ponerInfo())
  }
  Ponerlfechasreporte = () => {
    const { text } = this.state;

    return <div><form onSubmit={(e) => this.handleSubmit(e)}>
      <fieldset className="form-fieldset">

        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-12 input">
            <img alt="busqueda de imagen" className='searchImg' src={searchImg} /> 
              <input
                className="input__autocomplete__Text perfil__Search__text"
                value={text}
                onChange={this.onTextChanged}
                type="text"
                placeholder="Buscar Modelo"
                required
              />
            </div>
            <div className="col-md-12 justify-content-md-center input__autocomplete__SuggestionText">
              {this.renderSuggestions()}
            </div>
          </div>
        </div>

      </fieldset>

    </form>
    </div>
  }




  mostrarAccion = () => {


    const { error, cargando, success } = this.props
    if (cargando) {
      return <Spinner />;
    }


    if (success) {
      return <Success mensaje={success} />;
    }


    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  }




  render() {
    console.log(this.state)


    return (



      <Fragment>
        {(!window.localStorage.getItem('token')) ? <Redirect to='/' /> : ''}
        <div className='main-content fade-in'>
          <h1 className="tituloPaginas">
            PERFIL <strong className="tituloPaginasPequeño">VER</strong>
          </h1>
          <div className='reporte__div__principal__perfiil'>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 Selecbox_estado">
                  <div className='nombre__estado__perfil__div'> <div className='nombre__div__reporte'> ESTADO </div>
                    <div className='triangulo__report__container'>
                    </div>
                    <select onChange={this.handleChangeEstado}>
                      <option value="1">Activas</option>
                      <option value="0">Inactivas</option>
                    </select> </div>

                </div>
                <div className="col-md-4 ">
                  {this.Ponerlfechasreporte()}

                </div>

                <div className="col-md-4 center__reportes__botones">
                  <div className="btn__perfil_div">
                    <button className="Boton__buscar__perfil__modelos" type="submit"
                      onClick={this.buscarMontior} >Buscar</button>
                  </div>
                </div>




              </div>
              {this.mostrarAccion()}
            </div>


          </div>
          <div className="container-fluid">
            <div className="row">

        
              {this.ponerInformeModelo()}
 
            </div>
          </div>

        </div>
      </Fragment>
    )
  }
}
const mapStateToProps = (reducers) => {
  return reducers.PerfilVerMonitorReducer
}

export default connect(mapStateToProps, perfilverMonitoraction)(PerfilVerMonitor)