import {
  CARGANDO,
  ERROR,
  CONSULTAR,
  RESEARCHTRUE,
  CAMBIO_FECHA1,
  CAMBIO_FECHA2,
  RELOAD,
  CAMBIO_DOLAR,
  SUCCESS,
  CAMBIO_PAGOID,
  CONSULTAR_FECHAS_PAGOS,
  CAMBIO_FECHA_PAGOS,
  CONSULTAR_FECHAS_PAGOS_MONITORES,
  CAMBIO_FECHA_PAGOS_MONITORES,
  CONSULTAR_PAGO_NOMINA,
  CONSULTAR_PAGO_NOMINA_MONITORES,
  CONSULTAR_PAGO_NOMINA_NO_ARRIENDO,
  CONSULTAR_PAGO_NOMINA_TOTALES,
  CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA,
  CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA_NO_BANCARIA,
  CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA_SUMA,
  CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA_NO_BANCARIA_SUMA,
  IMAGEN_PERFIL_LOGO,
  CONSULTAR_YEAR,
  CONSULTAR_GANANCIAS_PLATAFORMA,
  CONSULTAR_GANANCIAS_PLATAFORMA_TOTAL,
  CONSULTAR_LUGARES

} from "../types/NominaTypes";

const INITIAL_STATE = {
  ListaFechasPagos: [],
  ListaFechasPagosMonitores:[],
  pagosNominaMonitores:[],
  pagosNominaArriendo: [],
  pagosNominaNoArriendo: [],
  ListaLugares: [],  
  pagosNominaModelosBancaria: [],
  pagosNominaModelosNoBancaria: [],
  years:[],
  ganaciasPlataformaTotal:0,
  ganaciasPlataforma:[],
  pagosNominaModelosBancariaSum: 0,
  pagosNominaModelosNoBancariaSum: 0,
  pagosNominaSuma: [],
  fechas_pagos_id: '',
  fechas_pagos_monitores_id:'',
  fecha1: "",
  fecha2: "",
  pago_id: null,
  cargando: false,
  dolar: 0,
  error: "",
  research: "",
  success: "",
  logo:''


};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONSULTAR:
      return {
        ...state,
        tareas: action.payload,
        cargando: false,
        research: false
      };

    case CARGANDO:
      return { ...state, cargando: true };

    case ERROR:
      return { ...state, error: action.payload, cargando: false, success: "" };

    case SUCCESS:
      return { ...state, success: action.payload, cargando: false, error: "" };

    case CAMBIO_FECHA1:
      return {
        ...state,
        fecha1: action.payload
      };

    case CAMBIO_FECHA2:
      return {
        ...state,
        fecha2: action.payload
      };

    case CAMBIO_DOLAR:
      return {
        ...state,
        dolar: action.payload
      };

      case CAMBIO_PAGOID:
        return {
          ...state,
          pago_id: action.payload
        };


        case CONSULTAR_FECHAS_PAGOS:
          return {
              ...state,
              ListaFechasPagos: action.payload,
              cargando: false,
              research: false,
          }

          case CONSULTAR_GANANCIAS_PLATAFORMA:
            return {
                ...state,
                ganaciasPlataforma: action.payload,
                cargando: false,
                research: false,
            }
            case CONSULTAR_GANANCIAS_PLATAFORMA_TOTAL:
              return {
                  ...state,
                  ganaciasPlataformaTotal: action.payload,
                  cargando: false,
                  research: false,
              }
            
              case CONSULTAR_LUGARES:
                return {
                  ...state,
                  ListaLugares: action.payload,
                };

          case CONSULTAR_YEAR:
            return {
                ...state,
                years: action.payload,
                cargando: false,
                research: false,
            }
  

          case CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA:
            return {
                ...state,
                pagosNominaModelosBancaria:action.payload ,
                cargando: false,
                research: false,
            }

            
            case CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA_NO_BANCARIA:
              return {
                  ...state,
                  pagosNominaModelosNoBancaria: action.payload,
                  cargando: false,
                  research: false,
              }


              case CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA_SUMA:
                return {
                    ...state,
                    pagosNominaModelosBancariaSum:action.payload ,
                    cargando: false,
                    research: false,
                }
    
                
                case CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA_NO_BANCARIA_SUMA:
                  return {
                      ...state,
                      pagosNominaModelosNoBancariaSum: action.payload,
                      cargando: false,
                      research: false,
                  }
    
                  
                  case IMAGEN_PERFIL_LOGO:
                    return {
                        ...state,
                        logo: action.payload,
  
                    }
          case CONSULTAR_FECHAS_PAGOS_MONITORES:
            return {
                ...state,
                ListaFechasPagosMonitores: action.payload,
                cargando: false,
                research: false,
            }

            case CONSULTAR_PAGO_NOMINA_MONITORES:
            return {
                ...state,
                pagosNominaMonitores: action.payload,
                cargando: false,
                research: false,
            }
          case CONSULTAR_PAGO_NOMINA:
            return {
                ...state,
                pagosNominaArriendo: action.payload,
                cargando: false,
                research: false,
            }
            case CONSULTAR_PAGO_NOMINA_NO_ARRIENDO:
              return {
                  ...state,
                  pagosNominaNoArriendo: action.payload,
              
              }
            case CONSULTAR_PAGO_NOMINA_TOTALES:
              return {
                  ...state,
                  pagosNominaSuma: action.payload,
              
              }

            


          case CAMBIO_FECHA_PAGOS:
            return {
                ...state,
                fechas_pagos_id: action.payload,
                cargando: false,
                research: false,


            }
            case CAMBIO_FECHA_PAGOS_MONITORES:
              return {
                  ...state,
                  fechas_pagos_monitores_id: action.payload,
                  cargando: false,
                  research: false,
  
  
              }
            

    case RESEARCHTRUE:
      return {
        ...state,

        fecha1: "",
        fecha2: "",
        modelos_id: "",
        cargando: false,
        error: "",
        research: "",
        success: ""
      };

    case RELOAD:
      return {
        ...state,

        cargando: false,
        research: true
      };
    default:
      return state;
  }
};
