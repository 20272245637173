import axios from 'axios'
import { CARGANDO, ERROR,  GUARDAR, CONSULTAR,RESEARCHTRUE,CAMBIO_CONCEPTO,CONSULTAR_TAREAS_REALIZADAS,RELOAD,CONTAR_TAREAS,
    SUCCESS,CAMBIO_FECHA_INCIO,CAMBIO_FECHA_FINAL,CAMBIO_EVENTO_TEXT} from '../types/CalendarioTypes'

    import {URL} from '../../src/General/url'

//acciones para tareas 







export const editar = (model_id) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify()
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateHomework/${model_id}`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
    dispatch({

        type: RELOAD,
        
    
    })
   
}
        
 /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: 'Intente mas tarde - Cuenta Ocupada'
        
    
    })
    }
}

 
}


export const eliminarEvento = (id) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.delete(URL+`deleteEvents/${id}`,{
            headers: headers
        })
    
        if(respuesta.data.status==="Success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })
           
        }
        
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })
           
        }
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}

export const editarEventoDragAndDrop = (id,data) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateEvents/${id}`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
   
}

if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })
   
}
        
 /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

 
}

export const crearEvento = (homework) => async(dispatch)=>{
    
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(homework)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'createCalendarEvent', params,{
            headers: headers
        })
    
    


        if(respuesta.data.status==="Success"){
       
            dispatch({
                type: GUARDAR,
                payload:respuesta.data.data
            })

        }


        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}

export const consultarEventos = () => async(dispatch)=>{
    


    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`getCalendarEvent`,{
            headers: headers
        })
    
  console.log(respuesta.data.data)
        dispatch({
            type: CONSULTAR,
            payload:respuesta.data.data,
        })

        if(respuesta.data.status==="Success"){

        /*     dispatch({

                type: RESEARCHCALENDARIO,
                payload:true
            
            })
 */
        }

        
        
    } catch (error) {
        
    dispatch({

        type: ERROR,
        payload: 'Intente mas tarde - Cuenta Ocupada'
        
    
    })
    }
}

export const contartareas = () => async(dispatch)=>{
    


    try { 

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`countHomework`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONTAR_TAREAS,
            payload:respuesta.data.data,
        })

        
        
    } catch (error) {
        
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

export const consultarTareasRealizadas = () => async(dispatch)=>{
    


    try {

    
        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`searchHomeworkDone`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTAR_TAREAS_REALIZADAS,
            payload:respuesta.data.data,
        })

        
        
    } catch (error) {
        
    dispatch({

        type: ERROR,
        payload: 'Intente mas tarde - Cuenta Ocupada'
        
    
    })
    }
}

export const cambioTareas  = (value) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_CONCEPTO,
        payload: value
        
    
    })
    
};

export const cambioFechaInicio = (fechainicial) => (dispatch) => {
    dispatch({
        type: CAMBIO_FECHA_INCIO,
        payload: fechainicial

    })
}

export const cambioFechaFinal = (fechafinal) => (dispatch) => {
    dispatch({
        type: CAMBIO_FECHA_FINAL,
        payload: fechafinal

    })
}
export const cambioEventoText = (text) => (dispatch) => {
    dispatch({
        type: CAMBIO_EVENTO_TEXT,
        payload: text

    })
}

export const researhCalendario  = () => (dispatch,) => {
    dispatch({

        type: RESEARCHTRUE
        
    
    })


    

   
};