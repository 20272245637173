import {
  CARGANDO,
  ERROR,
  CONSULTAR,
  RESEARCHTRUE,
  CAMBIO_FECHA1,
  CAMBIO_FECHA2,
  RELOAD,
  CAMBIO_DOLAR,
  SUCCESS,
  CAMBIO_PAGOID,
  CAMBIO_FECHA_END,
  NOT_FOUND,
  CAMBIO_FECHA_END_EMAILS
} from "../types/PagosTypes";

const INITIAL_STATE = {
  fecha1: "",
  fecha2: "",
  fecha_end: "",
  fecha_emails:"",
  pago_id: null,
  cargando: false,
  dolar: 0,
  error: "",
  research: "",
  success: "",
  modelosNoEncontrados:[]
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONSULTAR:
      return {
        ...state,
        tareas: action.payload,
        cargando: false,
        research: false
      };

    case CARGANDO:
      return { ...state, cargando: true };

    case ERROR:
      return { ...state, error: action.payload, cargando: false, success: "" };

    case SUCCESS:
      return { ...state, success: action.payload, cargando: false, error: "" };

    case CAMBIO_FECHA1:
      return {
        ...state,
        fecha1: action.payload
      };

    case CAMBIO_FECHA2:
      return {
        ...state,
        fecha2: action.payload
      };

    case CAMBIO_DOLAR:
      return {
        ...state,
        dolar: action.payload
      };

      case CAMBIO_PAGOID:
        return {
          ...state,
          pago_id: action.payload
        };
        case CAMBIO_FECHA_END:
          return {
            ...state,
            fecha_end: action.payload
          };

          case CAMBIO_FECHA_END_EMAILS:
            return {
              ...state,
              fecha_emails: action.payload
            };
          
          case NOT_FOUND:
            return {
              ...state,
              modelosNoEncontrados: action.payload
            };
        
          
    case RESEARCHTRUE:
      return {
        ...state,
        modelos_id: "",
        cargando: false,
        error: "",
        research: "",
        success: "",
        modelosNoEncontrados:[]
      };

    case RELOAD:
      return {
        ...state,

        cargando: false,
        research: true
      };
    default:
      return state;
  }
};
