import {
    CARGANDO, ERROR, GUARDAR, CONSULTAR, CAMBIO_FECHAPRESTAMO, RESEARCHTRUE
    , SUCCESS, CAMBIO_FECHA1, CAMBIO_FECHA2, FILTRAR_GANANCIAS,CONSULTARS,REPORTE_MODELO,REPORTE_MODELO_PERMIISO
} from '../types/ReportesModeloTypes'

const INITIAL_STATE = {
    ganancias: [],
    ListaLugares: [],
    SuggestionDatabase: [],
    reporteModelos: [],
    cargando: false,
    error: '',
    lugares_id: '',
    fecha1: '',
    fecha2: '',
    research: '',
    success: '',
    permiso:''

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTAR:
            return {
                ...state,
                multas: action.payload,
                cargando: false,
                research: false,
                cantidad: 0,
                fechaprestamo: '',
                concepto: '',

            }

        case FILTRAR_GANANCIAS:
            return {
                ...state,
                ganancias: action.payload,
                cargando: false,
                research: false,


            }

            case REPORTE_MODELO_PERMIISO:
                return {
                    ...state,
                    permiso: action.payload,

    
    
                }

            case REPORTE_MODELO:
                return {
                    ...state,
                    reporteModelos: action.payload,
                    cargando: false,
                    research: false,
    
    
                }


        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false, success: '' }


        case SUCCESS:
            return { ...state, success: action.payload, cargando: false, error: '' }


        case GUARDAR:
            return {
                ...state,
                cargando: false,
                error: '',
                cantidad: 0,
                fechaprestamo: '',
                modelos_id: '',
                concepto: '',
                research: true,
                success: ''


            }

            case CONSULTARS:
            return {
                ...state,
                SuggestionDatabase: action.payload,

            }



        case CAMBIO_FECHAPRESTAMO:
            return {
                ...state,
                fechaprestamo: action.payload,

            }

        case CAMBIO_FECHA1:
            return {
                ...state,
                fecha1: action.payload,

            }

        case CAMBIO_FECHA2:
            return {
                ...state,
                fecha2: action.payload,

            }

        case RESEARCHTRUE
            :
            return {
                ...state,

                multas: [],
                vermultas: [],
                cargando: false,
                error: '',
                success: '',
                research: '',



            }


        default: return state
    }

}