import axios from "axios";
import {
    CARGANDO,
    ERROR,
    RESEARCHTRUE,
    SUCCESS,
    IMAGEN_PERFIL,
    CAMBIO_CEDULA,
    CAMBIO_NOMBRES,
    CAMBIO_APELLIDOS,
    CAMBIO_SUELDO,
    CAMBIO_CUENTA_BANCARIA,
    CAMBIO_TIPO,
    CAMBIO_ESTADO_INICIAL,
} from "../types/crearMonitorTypes";

//acciones para tareas
import {URL} from '../../src/General/url'


export const restaurar_cambio = () => async(dispatch)=>{
    dispatch({

        type: CAMBIO_ESTADO_INICIAL,
        payload: false
        
    
    })

}

export const guardarimagen = (data) => async (dispatch) => {




  dispatch({

      type: CARGANDO


  })

  try {


      let params = data
      let headers = {
          'Content-Type': 'multipart/from-data',
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.post(URL + 'StoreImage', params, {
          headers: headers
      })

      dispatch({
        type: IMAGEN_PERFIL,
        payload: respuesta.data.imagename
      });
    

    /*   dispatch({
          type: CAMBIO_FOTO_PERFIL,
          payload: respuesta.data.imagename
      }) */

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}

export const handleChange  = (dataType, value) => (dispatch) => {


    if(dataType==='CAMBIO_CEDULA'){
        dataType=CAMBIO_CEDULA
    }else if(dataType==='CAMBIO_NOMBRES'){
        dataType=CAMBIO_NOMBRES
    }
    else if(dataType==='CAMBIO_APELLIDOS'){
        dataType=CAMBIO_APELLIDOS
    }
    if(dataType==='CAMBIO_SUELDO'){
        dataType=CAMBIO_SUELDO
    }else if(dataType==='CAMBIO_CUENTA_BANCARIA'){
        dataType=CAMBIO_CUENTA_BANCARIA
    }
    else if(dataType==='CAMBIO_TIPO'){
        dataType=CAMBIO_TIPO
    }
    dispatch({
      type: dataType,
      payload:value
  })


  

 
};

export const crearMonitor = (data) => async (dispatch) => {



    dispatch({

        type: CARGANDO


    })

    try {

        let json = JSON.stringify(data)
        let params = json;

        

      let headers = {
          "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }


        const respuesta = await axios.post(URL + 'crearMonitor', params, {
            headers: headers
        })
       
        if (respuesta.data.status === "Success") {
            dispatch({

                type: SUCCESS,
                payload: respuesta.data.message


            })

            
        }


        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }



    } catch (error) {

        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}
export const researchtrue = () => dispatch => {
  dispatch({
    type: RESEARCHTRUE
  });
};

  export const cambioTipo = (data) => (dispatch)=>{

    dispatch({
        type: CAMBIO_TIPO,
        payload: data
        
    })


}
