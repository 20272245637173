export const CONSULTAR = 'prestamo_grande_monitor_traer_todas';
export const CONSULTARS = 'prestamo_grande_monitor_traer_todas_suggestions';
export const CONSULTAR_TOTALES = 'prestamo_grande_monitor_traer_todas_prestamos';
export const CARGANDO = 'prestamo_grande_monitor_cargando';
export const ERROR = 'prestamo_grande_monitor_error';
export const GUARDAR = 'prestamo_grande_monitor_guardada';
export const ACTUALIZAR = 'prestamo_grande_monitor_actualizar';
export const LIMPIAR = 'prestamo_grande_monitor_limpiar';
export const RESEARCHTRUE = 'prestamo_grande_monitor_research';
export const RESEARPRESTAMO = 'prestamo_grande_monitor_research_prestamo';
export const SUCCESS = 'prestamo_grande_monitor_succes';
export const CAMBIO_FECHAPRESTAMO = 'prestamo_grande_monitor_fecha';
export const CAMBIO_CANTIDAD = 'prestamo_grande_monitor_cantidad';
export const CAMBIO_CONCEPTO = 'prestamo_grande_monitor_concepto';
export const CAMBIO_FECHA1 = 'prestamo_grande_monitor_fecha1';
export const CAMBIO_FECHA2 = 'prestamo_grande_monitor_fecha2';
export const FILTRAR_PRESTAMOS_MINIMOS = 'prestamo_grande_monitor_minimos_filtrar';







