import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as crearaction from "../../actions/CrearAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import btnAnteriorImg from "../../img/btnAnteriorImg.png";
import btnSiguienteImg from "../../img/btnSiguienteImg.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import { createBrowserHistory } from 'history';
import {URL} from '../../../src/General/url'
/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */
const history = createBrowserHistory();
export class AgregarInformacionContableModelo extends Component {


  state = {
    dateStart: new Date(),
    dateEnd: new Date(),
    InfoContableFlagState:0
  }

  componentDidMount() {
    const { restaurar_cambio,idInformacionContableModeloCreado } = this.props;

    restaurar_cambio()
    if(!idInformacionContableModeloCreado){
      this.props.handleChange('CAMBIO_CEDULA_CONTABLE', this.props.cedulaModelo)
    }
   
  }

  componentDidUpdate() {
    if(this.props.estadoFlag){
      this.props.history.push('/AgregarICorreoModelo')
      console.log(this.props)
    }

  }


  
  cambioBancoId = (event) => {
    this.props.cambioBancoId(event.target.value)
  }


  listaLugares = () => {
    const { ListaBancos } = this.props;

    const ponerOpcionesLugares = () =>
    ListaBancos.map((lugar, key) => (
        <option className='option-values' key={key} value={lugar.id}>
          {lugar.nombre}{}
        </option>
      ));

    return (
      <select
        style={
           {
            border: 'none',
            borderRadius: '5px',
            fontWeight: '500',
            width: '100%',
               }
        }
        name="select"
        onChange={this.cambioBancoId}
        required
      >
        <option className='option-values' value={1} >
          Bancolombia
        </option>
        {ponerOpcionesLugares()}
      </select>
    );
  };

  componentWillUnmount() {

  }

  deshabilitar = () => {
    const {
      cedulaContable,
      nombresContables,
      apellidosContable,
    } = this.props;

    if (
      !cedulaContable ||
      !nombresContables ||
      !apellidosContable
    ) {
     
      return true
    }

    return false
    
  };

  handleSubmit(e) {
    e.preventDefault();
  }
  putModeloInformation=(e)=>{
    e.preventDefault()

    const {
      llenar_informacion_contable,
    } = this.props;

    const data = {
      idInformacionContableModeloCreado:this.props.informacionContableModeloCreado.id,
      cedulaContable:this.props.informacionContableModeloCreado.documento_contable,
      nombresContables:this.props.informacionContableModeloCreado.nombres,
      apellidosContable:this.props.informacionContableModeloCreado.apellidos,
      direccion:this.props.informacionContableModeloCreado.direccion,
      cuentaBancaria:this.props.informacionContableModeloCreado.cuentabancaria,
      banco:this.props.informacionContableModeloCreado.tipo_cuenta_id
    }
    llenar_informacion_contable(data)
  }

  handleChange(dataType, value) {
    this.props.handleChange(dataType, value)
  }

  
    

  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  handleNextStep(e) {
    e.preventDefault()

    const { validar_creacion_modelos,idInformacionContableModeloCreado } = this.props;

    if(idInformacionContableModeloCreado){
      this.props.history.push('/AgregarICorreoModelo')
    }else{
      const datos={
        tipo:'informacioncontablemodelo',
        dato:this.props.cedulaContable
      }
      validar_creacion_modelos(datos)
    }


      }

  back = (event) =>{
    event.preventDefault()
    history.goBack()
  }
  ponerInformacionModelo = () => {

 
    const ponerInfo = () => (
      <Fragment >
        <div className="col-md-3 ">

          <img
            className="avatarModeloimg"
            alt="imgagerfil"
            src={`${URL}/getImage/${
              this.props.foto_perfil
                ? this.props.foto_perfil
                : "defaultLogo.png"
              }`}
          />
       
          <input

            className="form-element-field -hasvalue"
            id={`foto_perfil_modelo`}
            type="file"
            onChange={this.cambioFotoPerfil}
          />

        </div>

        <div className="col-md-4 ">
          <div className='informacion_personal_modificar_info_modelo'>
            <div className='div__personal__modificar__info'><div className='text__rigth'>Cédula  </div>   <input
              type="number"
              className='input__modificiar_informacion_modelo'
              value={this.props.cedulaContable}
              onChange={(e) => this.handleChange('CAMBIO_CEDULA_CONTABLE', e.target.value)}
            /></div>

            <div className='div__personal__modificar__info'><div className='text__rigth'>Nombre  </div>   <input
              type="text"
              className='input__modificiar_informacion_modelo'
              value={this.props.nombresContables}
              onChange={(e) => this.handleChange('CAMBIO_NOMBRES_CONTABLE', e.target.value)}
            /></div>

            <div className='div__personal__modificar__info'><div className='text__rigth'>Apellidos  </div>   <input
              type="text"
              className='input__modificiar_informacion_modelo'
              value={this.props.apellidosContable}
              onChange={(e) => this.handleChange('CAMBIO_APELLIDOS_CONTABLE', e.target.value)}
            /></div >
          </div>
        </div>

        <div className="col-md-4 ">
          <div className='informacion_personal_modificar_info_modelo'>
          <div className='div__personal__modificar__info__derecha'><div className='text__rigth'>dirección </div> <input
              type="text"
              className='input__modificiar_informacion_modelo'
              value={this.props.direccion}
              onChange={(e) => this.handleChange('CAMBIO_DIRECCION', e.target.value)}
            /></div>

         <div className='div__personal__modificar__info__derecha'><div className='text__rigth'>Banco </div> {this.listaLugares()}</div>

            <div className='div__personal__modificar__info__derecha'><div className='text__rigth'>Cuenta Bancaria </div> <input
              type="number"
              className='input__modificiar_informacion_modelo'
              value={this.props.cuentaBancaria}
              onChange={(e) => this.handleChange('CAMBIO_CUENTA_BANCARIA', e.target.value)}
            /></div>


          </div>

   
        </div>

      </Fragment>
    )

    return (ponerInfo())
  }





  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {

    console.log(this.props);
    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            PERFIL <strong className="tituloPaginasPequeño">AGREGAR</strong>
          </h1>
          <div className='Paso__paso__div'>
            <h2>PASO</h2>
            <div className="circuloPasoPaso">
              <h2>2</h2>
            </div>
            <h2>INFORMACIÓN CONTABLE</h2>
          </div>

          <div className="reporte__div__principal">

            <div className="container-fluid">
              <div className="row">

                {this.ponerInformacionModelo()}


              </div>



            </div>
          </div>
          {this.mostrarAccion()}
          {this.props.idInformacionContableModeloCreadoFlag?          
                 <div class="alert alert-danger" role="alert">
  
                Quieres ingresar la informacion contable del modelo  <strong>
                  { `${this.props.informacionContableModeloCreado.nombres} ${this.props.informacionContableModeloCreado.apellidos} `}</strong>
                 cualquien modificacion realizada a este perfil afectara a las cuentas asociadas al mismo<Link
                               to={`/#`}
                               onClick={e =>this.putModeloInformation(e)}
                               className="btn__perfil">Insertar</Link>
               </div>
          
          
          :''}

   
          <div className="paso__paso__div__control">
         <div className="paso__paso__div__central">
         <div className="circuloPasoPaso__control">
              <h2>1</h2>
            </div>
          <img src={btnAnteriorImg}  onClick={e =>this.back(e)} alt="Click aquí para ir al siguiente paso" title="Click aquí para ir al siguiente paso" />
          <div className="circuloPasoPaso__control">
              <h2>2</h2>
            </div>
            <button
            type='button'
            disabled={this.deshabilitar()}
            className='boton__control__paso__paso'
            onClick={e =>this.handleNextStep(e)}>
            <img src={btnSiguienteImg}  style={this.deshabilitar()?{opacity:'30%'}:{opacity:'100%'}} alt="Click aquí para subir tu foto" title="Click aquí para ir al anterior paso" />
            </button>
            <div className="circuloPasoPaso__control">
              <h2>3</h2>
            </div>
            <div className="circuloPasoPaso__control">
              <h2>4</h2>
            </div>
          </div>
          </div>

        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.crearReducer;
};

export default connect(mapStateToProps, crearaction)(AgregarInformacionContableModelo);
