import axios from 'axios'
import {
    CARGANDO, ERROR, CONSUTARL_MODELO, CAMBIO_FOTO_PERFIL, RESEARCHTRUE, SUCCESS, CONSULTAR_HORARIOS, CONSULTAR_LUGARES, CAMBIO_LUGARES,
    /* CAMBIO_INFORMACION_CONTABLE, */ CAMBIO_HORARIOS, CAMBIO_CEDULA, CAMBIO_NOMBRES, CAMBIO_APELLIDOS, CAMBIO_TELEFONO, CAMBIO_PASSWORDM, CAMBIO_NICK_NAME, CAMBIO_ARRIENDO,
    CAMBIO_NOMBRE_RECIBO, CAMBIO_ESTADO, RESET_MODELOINFO, CAMBIO_NOMBRE_HORARIOS, CAMBIO_NOMBRE_LUGARES,CONSULTAR_DOCUMENTO,CAMBIO_CEDULA_CONTABLES,CAMBIO_NOMBRES_CONTABLES,
    CAMBIO_APELLIDOS_CONTBLES,CAMBIO_DOCUMENTO_CONTBLES, CAMBIO_DIRECCION_CONTBLES, CAMBIO_CUENTABANCARIA_CONTBLES,CAMBIO_NOMBRE_DOCUMENTO_CONTBLES,CAMBIO_ID_CONTBLES
} from '../types/ModeloTypes'
import {URL} from '../../src/General/url'


export const cambioCedula = (cedula) => (dispatch) => {
    dispatch({
        type: CAMBIO_CEDULA,
        payload: cedula

    })
}

export const cambioCedulacontable = (cedula) => (dispatch) => {
    dispatch({
        type: CAMBIO_CEDULA_CONTABLES,
        payload: cedula

    })
}



export const cambioNombres = (nombres) => (dispatch) => {
    dispatch({
        type: CAMBIO_NOMBRES,
        payload: nombres

    })
}


export const cambioNombrescontable = (nombres) => (dispatch) => {
    dispatch({
        type: CAMBIO_NOMBRES_CONTABLES,
        payload: nombres

    })
}

export const cambioApellidos = (apellidos) => (dispatch) => {
    dispatch({
        type: CAMBIO_APELLIDOS,
        payload: apellidos

    })
}

export const cambioApellidoscontable = (apellidos) => (dispatch) => {
    dispatch({
        type: CAMBIO_APELLIDOS_CONTBLES,
        payload: apellidos

    })
}


export const cambioTipodocumento = (tipodocumento) => (dispatch) => {
    dispatch({
        type: CAMBIO_DOCUMENTO_CONTBLES,
        payload: tipodocumento

    })
}

export const cambioidcontable = (idcontable) => (dispatch) => {
    dispatch({
        type: CAMBIO_ID_CONTBLES,
        payload: idcontable

    })
}

export const cambionombreTipodocumento = (nombredocumento) => (dispatch) => {
    dispatch({
        type: CAMBIO_NOMBRE_DOCUMENTO_CONTBLES,
        payload: nombredocumento

    })
}




export const cambiodireccion = (dirreccion) => (dispatch) => {
    dispatch({
        type: CAMBIO_DIRECCION_CONTBLES,
        payload: dirreccion

    })
}

export const cambiocuentabancaria = (cuentabancaria) => (dispatch) => {
    dispatch({
        type: CAMBIO_CUENTABANCARIA_CONTBLES,
        payload: cuentabancaria

    })
}




export const cambioTelefono = (telefono) => (dispatch) => {
    dispatch({
        type: CAMBIO_TELEFONO,
        payload: telefono

    })
}

export const cambioHorario = (horario) => (dispatch) => {
    dispatch({
        type: CAMBIO_HORARIOS,
        payload: horario

    })
}

export const cambioLugares = (lugares) => (dispatch) => {
    dispatch({
        type: CAMBIO_LUGARES,
        payload: lugares

    })
}

export const cambioPasswordm = (passwordm) => (dispatch) => {
    dispatch({
        type: CAMBIO_PASSWORDM,
        payload: passwordm

    })
}
export const cambioFotoPerfil = (nombrefotoperfil) => (dispatch) => {
    dispatch({
        type: CAMBIO_FOTO_PERFIL,
        payload: nombrefotoperfil

    })
}

export const cambioArriendo = (arriendo) => (dispatch) => {
    dispatch({
        type: CAMBIO_ARRIENDO,
        payload: arriendo

    })
}

export const cambioNick = (nick) => (dispatch) => {
    dispatch({
        type: CAMBIO_NICK_NAME,
        payload: nick

    })
}

export const cambiNombreRecibo = (nombre_recibo) => (dispatch) => {
    dispatch({
        type: CAMBIO_NOMBRE_RECIBO,
        payload: nombre_recibo

    })
}

export const cambioEstado = (estado) => (dispatch) => {
    dispatch({
        type: CAMBIO_ESTADO,
        payload: estado

    })
}

export const cambioNombreLugares = (nombre_lugares) => (dispatch) => {
    dispatch({
        type: CAMBIO_NOMBRE_LUGARES,
        payload: nombre_lugares

    })
}
export const cambioNombreHorarios = (nombre_horarios) => (dispatch) => {
    dispatch({
        type: CAMBIO_NOMBRE_HORARIOS,
        payload: nombre_horarios

    })
}





export const guardarimagen = (data) => async (dispatch) => {



    dispatch({

        type: CARGANDO


    })

    try {


        let params = data
        let headers = {
            'Content-Type': 'multipart/from-data',
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL + 'StoreImage', params, {
            headers: headers
        })

      


        dispatch({
            type: CAMBIO_FOTO_PERFIL,
            payload: respuesta.data.imagename
        })

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}

export const consultar_horarios = () => async (dispatch) => {



    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getSchedules', {
            headers: headers
        })




        dispatch({
            type: CONSULTAR_HORARIOS,
            payload: respuesta.data.data
        })

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}

export const consultar_lugares = () => async (dispatch) => {


    try {

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getPlaces', {
            headers: headers
        })




        dispatch({
            type: CONSULTAR_LUGARES,
            payload: respuesta.data.data
        })

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}


export const consultar_tipo_documento = () => async (dispatch) => {


    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getTipeDocument', {
            headers: headers
        })


        dispatch({
            type: CONSULTAR_DOCUMENTO,
            payload: respuesta.data.data
        })

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}

export const agregar = (nuevomodelo) => async (dispatch) => {



    dispatch({

        type: CARGANDO


    })

    try {

        let json = JSON.stringify(nuevomodelo)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }


        const respuesta = await axios.post(URL + 'createModels', params, {
            headers: headers
        })
       
        if (respuesta.data.status === "Success") {
            dispatch({

                type: SUCCESS,
                payload: respuesta.data.message


            })
        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }



    } catch (error) {

        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}


export const researchtrue = () => (dispatch, ) => {
    dispatch({

        type: RESEARCHTRUE

    })
};

export const resetmodeloinfo = () => (dispatch, ) => {
    dispatch({

        type: RESET_MODELOINFO

    })
};

export const consultar_modelo = (id) => async (dispatch) => {


    try {



        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }


        const respuesta = await axios.get(URL + `getModelsId/${id}`, {
            headers: headers
        })
    
        dispatch({

            type: CONSUTARL_MODELO,
            payload: respuesta.data.data


        })




    } catch (error) {

        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}


export const editar = (Uploaddatoscuenta, model_id) => async (dispatch) => {


    dispatch({

        type: CARGANDO


    })

    try {

        const json = JSON.stringify(Uploaddatoscuenta)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL + `userModels/${model_id}`, params, {
            headers: headers
        })

 

        if (respuesta.data.status === "Success") {
            dispatch({

                type: SUCCESS,
                payload: respuesta.data.message


            })
        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }



    } catch (error) {

        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }

}


export const editarcontable = (Uploaddatoscuentacontable, idcontable) => async (dispatch) => {


    dispatch({

        type: CARGANDO


    })

    try {

        const json = JSON.stringify(Uploaddatoscuentacontable)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
         await axios.put(URL + `updateAccountantInformation/${idcontable}`, params, {
            headers: headers
        })

 
/* 
        if (respuesta.data.status === "Success") {
            dispatch({

                type: SUCCESS,
                payload: respuesta.data.message


            })
        }
 */
        /*    
               */



    } catch (error) {

        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }

}
