import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import okImgInventory from "../../img/okImgInventory.png";
import xImgInventory from "../../img/xImgInventory.png";
import noneImgInventory from "../../img/noneImgInventory.png";
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
import * as habitacionagregaraction from "../../actions/HabitacionesAgregarAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import DatePicker from 'react-date-picker';
import {URL} from '../../../src/General/url'
import moment from "moment";
/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */
export class HabitacionesView360 extends Component {
  constructor(props) {
    super(props)
    this.viewer = React.createRef();


    this.state = {
      firma_modelo: "",
      dateStart: new Date(),
      fecha_reporte:'',
      camaraValue:'',
      camaraText:'',
      computadorValue:'',
      computadorText:'',
      monitorValue:'',
      monitorText:'',
      tvValue:'',
      tvText:'',
      tecladoValue:'',
      tecladoText:'',
      mouseValue:'',
      mouseText:'',
      parlantesValue:'',
      parlantesText:'',
      lamparaValue:'',
      lamparaText:'',
      tripodeValue:'',
      tripodeText:'',
      num_x:0,
      num_ok:0,
      num_no_aplica:9

    }
  }



  componentDidMount() {

    const {
      match: {
        params: { key, key2 },
      },
      todasHabitaciones

    } = this.props;

    this.setState({ firma_modelo: todasHabitaciones[key][key2].firma_modelo })

    this.cambiofecha1(this.state.dateStart)

    let flagCheckInicial = document.getElementsByClassName('active_initial_check')
      if(flagCheckInicial){
       for (let index = 0; index < flagCheckInicial.length; index++) {
         flagCheckInicial[index].checked = true;
         
       } 
       this.setState({camaraValue:2})
        this.setState({computadorValue:2})
        this.setState({monitorValue:2})
        this.setState({tvValue:2})
        this.setState({tecladoValue:2})
        this.setState({mouseValue:2})
        this.setState({parlantesValue:2})
        this.setState({lamparaValue:2})
        this.setState({tripodeValue:2})

     
/*    */
  }
}
  componentDidUpdate() {
    console.log(this.state)
  }


  componentWillUnmount() {
    this.props.researchtrue()
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  cambiofecha1 = date => {
    this.setState({ dateStart: date })

    this.setState({ fecha_reporte: (moment(date).format('YYYY/MM/DD') ) })
   
};

  contador_x_ok_no(){
    let xCount=0
    let okCount=0
    let noCount=0
    const dataCount = [
      { "value": this.state.camaraValue },
      { "value": this.state.computadorValue },
      { "value": this.state.monitorValue },
      { "value": this.state.tvValue },
      { "value": this.state.tecladoValue },
      { "value": this.state.mouseValue },
      { "value": this.state.parlantesValue },
      { "value": this.state.lamparaValue },
      { "value": this.state.tripodeValue },

    ] 

    for (let index = 0; index < dataCount.length; index++) {
      if(dataCount[index].value === 0){
        xCount+=1
      }else if(dataCount[index].value === 1){
        okCount+=1
      }else{
        noCount+=1
      }
      
    }
    this.setState({num_x:xCount})
    this.setState({num_ok:okCount})
    this.setState({num_no_aplica:noCount})
  }



  sendCheckBoxValue(e, tipe){
    let flagCheck = document.getElementsByName(tipe)

     
    for (let index = 0; index < flagCheck.length; index++) {
      const element = flagCheck[index];
      if (element.value === e) {
        element.checked = true
      } else {
        element.checked = false
      }
    }


    if(tipe==='cameraFlag[]'){
     this.setState({camaraValue:e})
    }else if(tipe==='computadorFlag[]'){
      this.setState({computadorValue:e})
    }else if(tipe==='monitorFlag[]'){
      this.setState({monitorValue:e})
    }else if(tipe==='tvFlag[]'){
      this.setState({tvValue:e})
    }else if(tipe==='tecladoFlag[]'){
      this.setState({tecladoValue:e})
    }else if(tipe==='mouseFlag[]'){
      this.setState({mouseValue:e})
    }else if(tipe==='parlantesFlag[]'){
      this.setState({parlantesValue:e})
    }else if(tipe==='lamparaFlag[]'){
      this.setState({lamparaValue:e})
    }else if(tipe==='tripodeFlag[]'){
      this.setState({tripodeValue:e})
    }
    
  
  }
  async handleChangeCheckBox(e, tipe) {
 
 await this.sendCheckBoxValue(e, tipe)
 await this.contador_x_ok_no()
   
  }


  handleTextBox(e, tipe) {
  console.log(e,tipe)
    if(tipe==='novedadesCamara'){
     this.setState({camaraText:e})
    }else if(tipe==='novedadescomputador'){
      this.setState({computadorText:e})
    }else if(tipe==='novedadesmonitor'){
      this.setState({monitorText:e})
    }else if(tipe==='novedadestv'){
      this.setState({tvText:e})
    }else if(tipe==='novedadesteclado'){
      this.setState({tecladoText:e})
    }else if(tipe==='novedadesmouse'){
      this.setState({mouseText:e})
    }else if(tipe==='novedadesparlantes'){
      this.setState({parlantesText:e})
    }else if(tipe==='novedadeslampara'){
      this.setState({lamparaText:e})
    }else if(tipe==='novedadestripode'){
      this.setState({tripodeText:e})
    }
    


  }

  ponerHabitaciones = () => {

    const ponerInfo = () =>

      <Fragment>

        <tr>
          <td>Cámara</td>
          <td><input value={0} type="checkbox" name="cameraFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'cameraFlag[]') }} /></td>
          <td><input value={1} type="checkbox" name="cameraFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'cameraFlag[]') }} /></td>
          <td><input value={2} type="checkbox" className='active_initial_check' name="cameraFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'cameraFlag[]') }} /></td>
          <td><input style={{ width: '100%' }} type="text" name="novedadesCamara" onChange={(e) => { this.handleTextBox(e.target.value, 'novedadesCamara') }} /></td>

        </tr>
        <tr>
          <td>Computador</td>
          <td><input value={0} type="checkbox" name="computadorFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'computadorFlag[]') }} /></td>
          <td><input value={1} type="checkbox" name="computadorFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'computadorFlag[]') }} /></td>
          <td><input value={2} type="checkbox" className='active_initial_check' name="computadorFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'computadorFlag[]') }} /></td>
          <td><input style={{ width: '100%' }} type="text" name="novedadescomputador" onChange={(e) => { this.handleTextBox(e.target.value, 'novedadescomputador') }} /></td>

        </tr>

        <tr>
          <td>Monitor</td>
          <td><input value={0} type="checkbox" name="monitorFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'monitorFlag[]') }} /></td>
          <td><input value={1} type="checkbox" name="monitorFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'monitorFlag[]') }} /></td>
          <td><input value={2} type="checkbox" className='active_initial_check' name="monitorFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'monitorFlag[]') }} /></td>
          <td><input style={{ width: '100%' }} type="text" name="novedadesmonitor" onChange={(e) => { this.handleTextBox(e.target.value, 'novedadesmonitor') }} /></td>

        </tr>


        <tr>
          <td>Tv</td>
          <td><input value={0} type="checkbox" name="tvFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'tvFlag[]') }} /></td>
          <td><input value={1} type="checkbox" name="tvFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'tvFlag[]') }} /></td>
          <td><input value={2} type="checkbox" className='active_initial_check' name="tvFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'tvFlag[]') }} /></td>
          <td><input style={{ width: '100%' }} type="text" name="novedadestv" onChange={(e) => { this.handleTextBox(e.target.value, 'novedadestv') }} /></td>

        </tr>

        <tr>
          <td>Teclado</td>
          <td><input value={0} type="checkbox" name="tecladoFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'tecladoFlag[]') }} /></td>
          <td><input value={1} type="checkbox" name="tecladoFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'tecladoFlag[]') }} /></td>
          <td><input value={2} type="checkbox" className='active_initial_check' name="tecladoFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'tecladoFlag[]') }} /></td>
          <td><input style={{ width: '100%' }} type="text" name="novedadesteclado" onChange={(e) => { this.handleTextBox(e.target.value, 'novedadesteclado') }} /></td>

        </tr>

        <tr>
          <td>Mouse</td>
          <td><input value={0} type="checkbox" name="mouseFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'mouseFlag[]') }} /></td>
          <td><input value={1} type="checkbox" name="mouseFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'mouseFlag[]') }} /></td>
          <td><input value={2} type="checkbox" className='active_initial_check' name="mouseFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'mouseFlag[]') }} /></td>
          <td><input style={{ width: '100%' }} type="text" name="novedadesmouse" onChange={(e) => { this.handleTextBox(e.target.value, 'novedadesmouse') }} /></td>

        </tr>

        <tr>
          <td>Parlantes</td>
          <td><input value={0} type="checkbox" name="parlantesFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'parlantesFlag[]') }} /></td>
          <td><input value={1} type="checkbox" name="parlantesFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'parlantesFlag[]') }} /></td>
          <td><input value={2} type="checkbox" className='active_initial_check' name="parlantesFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'parlantesFlag[]') }} /></td>
          <td><input style={{ width: '100%' }} type="text" name="novedadeparlantes" onChange={(e) => { this.handleTextBox(e.target.value, 'novedadeparlantes') }} /></td>

        </tr>

        <tr>
          <td>Lámpara</td>
          <td><input value={0} type="checkbox" name="lamparaFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'lamparaFlag[]') }} /></td>
          <td><input value={1} type="checkbox" name="lamparaFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'lamparaFlag[]') }} /></td>
          <td><input value={2} type="checkbox" className='active_initial_check' name="lamparaFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'lamparaFlag[]') }} /></td>
          <td><input style={{ width: '100%' }} type="text" name="novedadeslampara" onChange={(e) => { this.handleTextBox(e.target.value, 'novedadeslampara') }} /></td>

        </tr>

        <tr>
          <td>Tripode</td>
          <td><input value={0} type="checkbox" name="tripodeFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'tripodeFlag[]') }} /></td>
          <td><input value={1} type="checkbox" name="tripodeFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'tripodeFlag[]') }} /></td>
          <td><input value={2} type="checkbox" className='active_initial_check' name="tripodeFlag[]" onClick={(e) => { this.handleChangeCheckBox(e.target.value, 'tripodeFlag[]') }} /></td>
          <td><input style={{ width: '100%' }} type="text" name="novedadestripode" onChange={(e) => { this.handleTextBox(e.target.value, 'novedadestripode') }} /></td>

        </tr>
      </Fragment>
    return ponerInfo();


  }



  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }



  crearReporte=(e)=>{
    e.preventDefault()
  
     const {
      match: {
        params: { key, key2 },
      },
      todasHabitaciones,
      crear_reporte_inventario_room
    } = this.props;

    const data = {
      modelos_id: todasHabitaciones[key][key2].modelos_id,
      habitacion_id: todasHabitaciones[key][key2].habitacion_id,
      horarios_id: todasHabitaciones[key][key2].horarios_id,
      camara_check: this.state.camaraValue,
      camara_novedades: this.state.camaraText,
      computador_check: this.state.computadorValue,
      computador_novedades: this.state.computadorText,
      monitor_check: this.state.monitorValue,
      monitor_novedades: this.state.monitorText,
      tv_check: this.state.tvValue,
      tv_novedades: this.state.tvText,
      teclado_check: this.state.tecladoValue,
      teclado_novedades: this.state.tecladoText,
      mouse_check: this.state.mouseValue,
      mouse_novedades: this.state.tecladoText,
      parlante_check: this.state.parlantesValue,
      parlante_novedades: this.state.parlantesText,
      lampara_check: this.state.lamparaValue,
      lampara_novedades: this.state.lamparaText,
      tripode_check: this.state.tripodeValue,
      tripode_novedades: this.state.tripodeText,
      firma_modelo: this.state.firma_modelo,
      fecha_creacion: this.state.fecha_reporte,
      num_x: this.state.num_x,
      num_ok: this.state.num_ok,
      num_no_aplica: this.state.num_no_aplica,
    };

    
    crear_reporte_inventario_room(data) 
  }


  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {
    const {
      match: {
        params: { key, key2 },
      },
      todasHabitaciones
    } = this.props;

    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            ROOM  {todasHabitaciones[key][key2].numero_habitacion} <strong className="tituloPaginasPequeño">{todasHabitaciones[key][key2].nombre} </strong>  <strong className="tituloPaginasPequeño">INVENTARIO </strong>
          </h1>

          <div className="container-fluid">
              <div className="row">
              <div className='col-md-8'>
              <div className='nombre__reporte__container'> <div className='nombre__div__reporte'> NOMBRE </div> <div className='triangulo__report__container'>  </div> <span className='nombre__modelo__div'> {`${todasHabitaciones[key][key2].nombres.toUpperCase()} ${todasHabitaciones[key][key2].apellidos.toUpperCase()} `} </span>   </div>

              </div>
              <div className='col-md-2'>
              <DatePicker
                        onChange={this.cambiofecha1}
                        value={this.state.dateStart}
                    />
              </div>
            
               
      
                </div></div>
        
          <div className="reporte__div__principal">


            {this.mostrarAccion()}
            <div className="container-fluid">
              <div className="row">

                <div className='col-md-10 mx-auto'>

                  <table className="table table-striped tabla-global">
                    <thead>
                      <tr>
                        <th>ARTICULO</th>
                        <th><img alt='x imagen de inventario' src={xImgInventory} />  </th>
                        <th><img alt='ok imagen de inventario' src={okImgInventory} /></th>
                        <th><img alt='none imagen de inventario' src={noneImgInventory} /></th>
                        <th>NOVEDADES</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.ponerHabitaciones()}
                    </tbody>
                  </table>
                </div>

              </div>



            </div>
          </div>

          <div className="container-fluid">
            <div className="row">

              <div className='col-md-3 '>

                <div><img alt='x imagen de inventario faltante' src={xImgInventory} />  Faltante </div>
                <div><img alt='x imagen de inventario correcto'src={okImgInventory} />   Correcto</div>
                <div><img alt='x imagen de inventario no aplica' src={noneImgInventory} />  No aplica </div>
              </div>

              <div className='col-md-3 '>

           <button className='invetario__btn' onClick={this.crearReporte}>Crear Reporte</button>
              </div>


              <div className="col-md-6 ">
                {this.state.firma_modelo ? (
                  <img
                    src={
                      `${URL}getImageOriginal/${
                      this.state.firma_modelo
                        ? this.state.firma_modelo
                        : "defaultLogo.png"
                      }`
                    }
                    alt="mi firma"
                    style={{
                      display: "block",
                      margin: "0 auto",
                      objectFit: "contain",
                      width: "100%"
                    }}
                  />
                ) : null}
              </div>

            </div>



          </div>


        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.HabitacionesAgregarReducer;
};

export default connect(mapStateToProps, habitacionagregaraction)(HabitacionesView360);
