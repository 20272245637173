export const CONSULTAR = 'permisos_consultar_todas_permisoses';
export const CARGANDO = 'permisos_agregar_cargando';
export const ERROR = 'permisos_agregar_error';
export const GUARDAR = 'permisos_agregar_guardada';
export const ACTUALIZAR = 'permisos_agregar_actualizar';
export const LIMPIAR = 'permisos_agregar_limpiar';
export const RESEARCHTRUE = 'permisos_agregar_research';
export const SUCCESS = 'permisos_agregar_succes';
export const RELOAD = 'permisos_agregar_reload';
export const IMAGEN_PERFIL = 'permisos_agregar_imagen_perfil';
export const IMAGEN_360 = 'permisos_agregar_imagen_360';
export const NEW_HABITACIONES = 'permisos_update_inventario';
export const CONSULTARS = 'permisos_consular_suggestion';
export const CONSULTAR_TURNOS = 'permisos_consular_horario';
export const CONSULTAR_MODELOS_HABITACION = 'permisos_modelos_permisos';
export const CAMBIO_TURNO_ID = 'permisos_cambiar_turno';
export const RESEARCH_MODELOS_HABITACION = 'permisos_estado_flag';
export const CONSULTAR_TODAS_HABITACIONES = 'permisos_consultar_todas';
export const CONSULTAR_TODAS_HABITACIONES_REPORTE = 'permisos_consultar_todas_reporte';
export const CONSULTAR_LUGARES = 'permisos_consultar_lugar';
export const CONSULTAR_PERMISOS = 'permisos_consultar_permisos';
export const CONSULTAR_PERMISOS_UPDATE = 'permisos_consultar_permisos_updates';
export const CONSULTAR_PERMISOS_UPDATE_LUGARES = 'permisos_consultar_permisos_updates_lugares';
export const CONSULTAR_PERMISOS_PAGOS = 'permisos_consultar_permisos_pagos';
export const CONSULTAR_PERMISOS_PAGO_UPDATE = 'permisos_consultar_permisos_pagos_pagos';


















