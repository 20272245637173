import axios from "axios";
import {
  CARGANDO,
  ERROR,
  CONSULTAR,
  RESEARCHTRUE,
  CAMBIO_LUGAR_ID,
  CAMBIO_TURNO_ID,
  SUCCESS,
  IMAGEN_PERFIL,
  GALERIA_MODELO,
  
} from "../types/PerfilModificarInfoMonitorTypes";

import {URL} from '../../src/General/url'
export const editar = (data,model_id) => async(dispatch)=>{
   
    
  dispatch({

      type: CARGANDO
      
  
  })

  try {
    
      let json = JSON.stringify(data)
      let params = json;

        

      let headers = {
          "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.put(URL+`updateMonitor/${model_id}`, params,{
          headers: headers
      })
   
if(respuesta.data.status==="Success"){
  dispatch({

      type: SUCCESS,
      payload: respuesta.data.message
      
  
  })
}

if(respuesta.data.status==="Error"){
  dispatch({

      type: ERROR,
      payload: respuesta.data.message
      
  
  })

}
      
/*    
      */
      
      
      
  } catch (error) {
      
      if(error.message==="Request failed with status code 401"){
          window.localStorage.setItem('token','')
          window.localStorage.setItem('userData','')
      }else{
  dispatch({

      type: ERROR,
      payload: error.message
      
  
  })
  }
}


}

export const editarInformacionContable = (data,model_id) => async(dispatch)=>{
   
    
  dispatch({

      type: CARGANDO
      
  
  })

  try {
    
      let json = JSON.stringify(data)
      let params = json;

        

      let headers = {
          "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.put(URL+`updateAccountantInformation/${model_id}`, params,{
          headers: headers
      })
   
if(respuesta.data.status==="Success"){
  dispatch({

      type: SUCCESS,
      payload: respuesta.data.message
      
  
  })
}

if(respuesta.data.status==="Error"){
  dispatch({

      type: ERROR,
      payload: respuesta.data.message
      
  
  })

}
      
/*    
      */
      
      
      
  } catch (error) {
      
      if(error.message==="Request failed with status code 401"){
          window.localStorage.setItem('token','')
          window.localStorage.setItem('userData','')
      }else{
  dispatch({

      type: ERROR,
      payload: error.message
      
  
  })
  }
}


}


export const consultarModeloInformacion = (id) => async (dispatch) => {

  dispatch({
    type: CARGANDO
  });

  try {

    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL +  `getMonitorIdModify/${id}`, {
          headers: headers
      })


      if (respuesta.data.status === "success") {
        dispatch({
          type: CONSULTAR,
          payload: respuesta.data.data
        });
        dispatch({
          type: IMAGEN_PERFIL,
          payload: respuesta.data.data[0].profile_picture
        });
        
      }

      if(respuesta.data.status==="Error"){
        dispatch({
    
            type: ERROR,
            payload: respuesta.data.message
            
        
        })

    }
  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}

export const guardarimagen = (data) => async (dispatch,getState) => {

  const {informacionModelo} = getState().PerfilModificarInfoMonitorReducer


  dispatch({

      type: CARGANDO


  })

  try {


      let params = data
      let headers = {
          'Content-Type': 'multipart/from-data',
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.post(URL + 'StoreImage', params, {
          headers: headers
      })

    

      const newInfomodelo = informacionModelo.map((item, i) => {
        if (i === 0) {
          return {...item, 'profile_picture': respuesta.data.imagename};
        }
        return item;
      });
    
        dispatch({
          type: CONSULTAR,
          payload:newInfomodelo
      })

      dispatch({
        type: IMAGEN_PERFIL,
        payload: respuesta.data.imagename
      });
    

    /*   dispatch({
          type: CAMBIO_FOTO_PERFIL,
          payload: respuesta.data.imagename
      }) */

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}





export const handleChange  = (index, dataType, value) => (dispatch,getState) => {

  const {informacionModelo} = getState().PerfilModificarInfoMonitorReducer


  const newInfomodelo = informacionModelo.map((item, i) => {
      if (i === index) {
        return {...item, [dataType]: value};
      }
      return item;
    });


    dispatch({
      type: CONSULTAR,
      payload:newInfomodelo
  })


  

 
};


export const researchtrue = () => dispatch => {
  dispatch({
    type: RESEARCHTRUE
  });
};


export const consultar_galeria_modelo = (id) => async (dispatch) => {


  try {

    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL + `getGalleryModel/${id}`, {
          headers: headers
      })



 if(respuesta.data.status==="Success"){
        dispatch({
      
            type: GALERIA_MODELO,
            payload: respuesta.data.data
            
        
        })
      }
      if(respuesta.data.status==="Error"){
        dispatch({
    
            type: ERROR,
            payload: respuesta.data.message
            
        
        })

    }

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}

export const cambioLugarId = (data) => (dispatch)=>{

  dispatch({
      type: CAMBIO_LUGAR_ID,
      payload: data
      
  })
}
  export const cambioTurnoId = (data) => (dispatch)=>{

    dispatch({
        type: CAMBIO_TURNO_ID,
        payload: data
        
    })


}
