import axios from "axios";
import FileDownload  from "js-file-download";
import {
  CARGANDO,
  ERROR,
  CAMBIO_FECHA1,
  RESEARCHTRUE,
  CAMBIO_FECHA2,
  CAMBIO_DOLAR,
  CAMBIO_PAGOID,
  SUCCESS,
  CONSULTAR_FECHAS_PAGOS,
  CONSULTAR_FECHAS_PAGOS_MONITORES,
  CAMBIO_FECHA_PAGOS_MONITORES,
  CAMBIO_FECHA_PAGOS,
  CONSULTAR_PAGO_NOMINA,
  CONSULTAR_PAGO_NOMINA_MONITORES,
  CONSULTAR_PAGO_NOMINA_NO_ARRIENDO,
  CONSULTAR_PAGO_NOMINA_TOTALES,
  CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA,
  CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA_NO_BANCARIA,
  CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA_SUMA,
  CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA_NO_BANCARIA_SUMA,
  IMAGEN_PERFIL_LOGO,
  CONSULTAR_YEAR,
  CONSULTAR_GANANCIAS_PLATAFORMA_TOTAL,
  CONSULTAR_GANANCIAS_PLATAFORMA,CONSULTAR_LUGARES
  
} from "../types/NominaTypes";

import {URL} from '../../src/General/url'



export const pagar = infopagos => async dispatch => {
  dispatch({
    type: CARGANDO
  });

  try {
    let json = JSON.stringify(infopagos);
    let params = "json=" + json;
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: window.localStorage.getItem("token")
    };
    const respuesta = await axios.post(URL + "modelsPayments", params, {
      headers: headers
    });
    

    dispatch({
      type: CAMBIO_PAGOID,
      payload: respuesta.data.data
    });

    if (respuesta.data.status === "success") {
      dispatch({
        type: SUCCESS,
        payload: respuesta.data.message
      });
    }

    if (respuesta.data.status === "Error") {
      dispatch({
        type: ERROR,
        payload: respuesta.data.message
      });
    }
  } catch (error) {
    if (error.message === "Request failed with status code 401") {
      window.localStorage.setItem("token", "");
      window.localStorage.setItem("userData", "");
    } else {
      dispatch({
        type: ERROR,
        payload: error.message
      });
    }
  }
};

export const cambiofecha1  = (fecha1) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA1,
        payload: fecha1
        
    
    })
    
};


export const consultarInfoPlataforma = () => async(dispatch)=>{
    


  try {

   
   

    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL+`getPlataformaMasterInfo`,{
          headers: headers
      })
  

      dispatch({
          type: IMAGEN_PERFIL_LOGO,
          payload:respuesta.data.data[0].logo,
      })

      if(respuesta.data.status==="Error"){
        dispatch({
    
            type: ERROR,
            payload: respuesta.data.message
            
        
        })

    }
      
  } catch (error) {
      
  dispatch({

      type: ERROR,
      payload: error.message
      
  
  })
  }
}

export const cambiofecha2  = (fecha2) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA2,
        payload: fecha2
        
    
    })
    
};
export const cambiodolar  = (dolar) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_DOLAR,
        payload: dolar
        
    
    })
    
};


export const researchtrue = () => dispatch => {
  dispatch({
    type: RESEARCHTRUE
  });
};
export const cambioFechaPagos = (id_pago) => (dispatch) => {
  dispatch({
      type: CAMBIO_FECHA_PAGOS,
      payload: id_pago

  })
}


export const cambioFechaPagosMonitores = (id_pago) => (dispatch) => {
  dispatch({
      type: CAMBIO_FECHA_PAGOS_MONITORES,
      payload: id_pago

  })
}

export const consultar_year = () => async (dispatch) => {


    try {
  
  

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getYearProfits', {
            headers: headers
        })
  
  
  
  
        dispatch({
            type: CONSULTAR_YEAR,
            payload: respuesta.data.data
        })


        if(respuesta.data.status==="Error"){
          dispatch({
      
              type: ERROR,
              payload: respuesta.data.message
              
          
          })

      }
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }
export const consultar_fechas_pagos = () => async (dispatch) => {


  try {

    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL + 'getPaymentDate', {
          headers: headers
      })




      dispatch({
          type: CONSULTAR_FECHAS_PAGOS,
          payload: respuesta.data.data
      })

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}
export const consultar_fechas_pagos_monitores = () => async (dispatch) => {


  try {


    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL + 'getMonitorPaymentDate', {
          headers: headers
      })




      dispatch({
          type: CONSULTAR_FECHAS_PAGOS_MONITORES,
          payload: respuesta.data.data
      })

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}

export const consultar_lugares = () => async (dispatch) => {


  try {



    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL + 'getPaymentPlaces', {
          headers: headers
      })




      dispatch({
          type: CONSULTAR_LUGARES,
          payload: respuesta.data.data
      })

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}



export const consultar_ganancias = (year,month,lugar) => async (dispatch) => {


    try {
  
  
        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + `getProfitsPlataform/${year}/${month}/${lugar}`, {
            headers: headers
        })
  
  
  
  
        dispatch({
            type: CONSULTAR_GANANCIAS_PLATAFORMA,
            payload: respuesta.data.data
        })

        
        dispatch({
            type: CONSULTAR_GANANCIAS_PLATAFORMA_TOTAL,
            payload: respuesta.data.total[0].total_ganancia
        })

        if(respuesta.data.status==="Error"){
          dispatch({
      
              type: ERROR,
              payload: respuesta.data.message
              
          
          })

      }
        
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }

export const consultar_nomina = (id) => async (dispatch) => {

  dispatch({
    type: CARGANDO
  });

  try {


    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL +  `paymentSearch/${id}`, {
          headers: headers
      })


      

      dispatch({
          type: CONSULTAR_PAGO_NOMINA,
          payload: respuesta.data.dataArriendo
      })
      dispatch({
        type: CONSULTAR_PAGO_NOMINA_NO_ARRIENDO,
        payload: respuesta.data.dataNoArriendo
    })


      dispatch({
        type: CONSULTAR_PAGO_NOMINA_TOTALES,
        payload: respuesta.data.dataSuma
    })

    if(respuesta.data.status==="Error"){
      dispatch({
  
          type: ERROR,
          payload: respuesta.data.message
          
      
      })

  }

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}


export const consultar_nomina_modelo_planilla = (id) => async (dispatch) => {

  dispatch({
    type: CARGANDO
  });

  try {

    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL +  `paymentSearchPlanilla/${id}`, {
          headers: headers
      })


      

      dispatch({
          type: CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA,
          payload: respuesta.data.dataCuentaBancaria
      })
      dispatch({
        type: CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA_NO_BANCARIA,
        payload: respuesta.data.dataNoCuentaNoBancaria
    })
    dispatch({
      type: CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA_SUMA,
      payload: respuesta.data.dataCuentaBancariaSuma[0].suma
  })
  dispatch({
    type: CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA_NO_BANCARIA_SUMA,
    payload: respuesta.data.dataCuentaNoBancariaSuma[0].suma
})


if(respuesta.data.status==="Error"){
  dispatch({

      type: ERROR,
      payload: respuesta.data.message
      
  
  })

}
  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}

export const consultar_nomina_monitores = (id) => async (dispatch) => {

  dispatch({
    type: CARGANDO
  });

  try {


    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL +  `monitorPaymentSearch/${id}`, {
          headers: headers
      })


      dispatch({
          type: CONSULTAR_PAGO_NOMINA_MONITORES,
          payload: respuesta.data.data
      })
      if(respuesta.data.status==="Error"){
        dispatch({
    
            type: ERROR,
            payload: respuesta.data.message
            
        
        })

    }

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}


export const descargarExcel = (id) => async (dispatch) => {

  try {


      let headers = {
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL +  `excelPaymentMonitors/${id}`, {
          headers: headers,
          responseType: 'arraybuffer'
      })
    
      var blob = new Blob([respuesta.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileDownload(blob, 'reportPagoMonitor.xlsx')


  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}
export const descargarExcelModelos = (id) => async (dispatch) => {

  try {


      let headers = {
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL +  `excelPaymentModels/${id}`, {
          headers: headers,
          responseType: 'arraybuffer'
      })
    
      var blob = new Blob([respuesta.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileDownload(blob, 'reportPagoModelos.xlsx')


  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}