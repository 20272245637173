import {
  CARGANDO,
  ERROR,
  CONSULTAR,
  RESEARCHTRUE,
  RELOAD,
  SUCCESS,
  IMAGEN_PERFIL,
  IMAGEN_360,
  CAMBIO_ROOM,
  CAMBIO_DESCRIPCION,
  CAMBIO_INVENTARIO,
  NEW_HABITACIONES,
  CONSULTARS,
  CONSULTAR_TURNOS,
  CAMBIO_TURNO_ID,
  CONSULTAR_MODELOS_HABITACION,
  RESEARCH_MODELOS_HABITACION,
  CONSULTAR_TODAS_HABITACIONES,
  CONSULTAR_TODAS_HABITACIONES_REPORTE,
  CONSULTAR_SEDES,
  OBTERNER_NUMERO_TOTAL_HABITACION


} from "../types/HabitacionesAgregarTypes";

const INITIAL_STATE = {
  habitaciones:[],
  SuggestionDatabase:[],
  modeloHabitacion:[],
  todasHabitaciones:[],
  todasHabitacionesReportes:[],
  foto_perfil: '',
  imagen_360: '',
  firma_modelo: '',
  numRoom:'',
  descripcion:'',
  inventario:'',
  cargando: false,
  estadoFlag: false,
  error: "",
  research: "",
  success: "",
  turnos: [],
  turno_id: '',
  sedes:[],
  totalHab:[]
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {


    case CARGANDO:
      return { ...state, cargando: true };

    case ERROR:
      return { ...state, error: action.payload, cargando: false, success: "" };

    case SUCCESS:
      return { ...state, success: action.payload, cargando: false, error: "" };


    case IMAGEN_PERFIL:
      return {
        ...state,
        foto_perfil: action.payload,
        cargando: false,
        research: false,
      }


      case CONSULTAR_SEDES:
        return {
          ...state,
          sedes: action.payload,
          cargando: false,
          research: false,
        }

        case OBTERNER_NUMERO_TOTAL_HABITACION:
          return {
            ...state,
            totalHab: action.payload,
            cargando: false,
            research: false,
          }
  
        

    case IMAGEN_360:
      return {
        ...state,
        imagen_360: action.payload,
        cargando: false,
        research: false,
      }
      case CONSULTAR_TODAS_HABITACIONES_REPORTE:
        return {
          ...state,
          todasHabitacionesReportes: action.payload,
          cargando: false,
          research: false,
        }
      case   CONSULTAR:
        return {
          ...state,
          habitaciones: action.payload,
          cargando: false,
          research: false,
        }

        case   CONSULTAR_MODELOS_HABITACION:
          return {
            ...state,
            modeloHabitacion: action.payload,
            cargando: false,
            research: false,
          }



    case CAMBIO_ROOM:
      return {
        ...state,
        numRoom: action.payload,
      }

    case CAMBIO_DESCRIPCION:
      return {
        ...state,
        descripcion: action.payload,
 
      }
    case CAMBIO_INVENTARIO:
      return {
        ...state,
        inventario: action.payload,
      }
      case NEW_HABITACIONES:
        return {
          ...state,
          habitaciones: action.payload,
          cargando: false,
          research: false,
        }

        case CONSULTAR_TODAS_HABITACIONES:
          return {
            ...state,
            todasHabitaciones: action.payload,
            cargando: false,
            research: false,
          }

        
        case CONSULTAR_TURNOS:
          return {
            ...state,
            turnos: action.payload,
            cargando: false,
            research: false,
          }
          case CAMBIO_TURNO_ID:
      return {
        ...state,
        turno_id: action.payload,
      }
      case RESEARCH_MODELOS_HABITACION:
        return {
          ...state,
          estadoFlag:  action.payload,
        }
      

        case CONSULTARS:
            return {
                ...state,
                SuggestionDatabase: action.payload,

            }

    case RESEARCHTRUE:
      return {
        ...state,
        foto_perfil: '',
        imagen_360: '',
        firma_modelo: '',
        num_room:'',
        descripcion:'',
        inventario:'',
        cargando: false,
        estadoFlag: false,
        error: "",
        research: "",
        success: ""
      };

    case RELOAD:
      return {
        ...state,

        cargando: false,
        research: true
      };
    default:
      return state;
  }
};
