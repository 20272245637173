export const CONSULTAR = 'palabras_alertas_consultar_todas_correo_reporteses';
export const CARGANDO = 'palabras_alertas_agregar_cargando';
export const ERROR = 'palabras_alertas_agregar_error';
export const GUARDAR = 'palabras_alertas_agregar_guardada';
export const ACTUALIZAR = 'palabras_alertas_agregar_actualizar';
export const LIMPIAR = 'palabras_alertas_agregar_limpiar';
export const RESEARCHTRUE = 'palabras_alertas_agregar_research';
export const SUCCESS = 'palabras_alertas_agregar_succes';
export const RELOAD = 'palabras_alertas_agregar_reload';
export const IMAGEN_PERFIL = 'palabras_alertas_agregar_imagen_perfil';
export const IMAGEN_360 = 'palabras_alertas_agregar_imagen_360';
export const NEW_HABITACIONES = 'palabras_alertas_update_inventario';
export const CONSULTARS = 'palabras_alertas_consular_suggestion';
export const CONSULTAR_TURNOS = 'palabras_alertas_consular_horario';
export const CONSULTAR_MODELOS_HABITACION = 'palabras_alertas_modelos_permisos';
export const CAMBIO_TURNO_ID = 'palabras_alertas_cambiar_turno';
export const RESEARCH_MODELOS_HABITACION = 'palabras_alertas_estado_flag';
export const CONSULTAR_TODAS_HABITACIONES = 'palabras_alertas_consultar_todas';
export const CONSULTAR_TODAS_HABITACIONES_REPORTE = 'palabras_alertas_consultar_todas_reporte';
export const CONSULTAR_LUGARES = 'palabras_alertas_consultar_lugar';
export const CONSULTAR_PERMISOS = 'palabras_alertas_consultar_permisos';
export const CONSULTAR_PERMISOS_UPDATE = 'palabras_alertas_consultar_permisos_updates';
export const CONSULTAR_PERMISOS_UPDATE_LUGARES = 'palabras_alertasconsultar_permisos_updates_lugares';
export const RESEARCH_CORREO = 'palabras_alertas_consultar_de_nuevo';



















