import {
    CARGANDO, ERROR, CONSULTAR, RESEARCHTRUE
    , SUCCESS, FILTRAR_MODELO_PAGO,CONSULTARS,CONSULTAR_FECHAS_PAGOS,CAMBIO_FECHA_PAGOS,PAGINAR_MONITORES
} from '../types/PerfilVerMonitorTypes'

const INITIAL_STATE = {
    ModeloPago: [],
    ListaFechasPagos: [],
    SuggestionDatabase: [],
    Monitores: [],
    cargando: false,
    error: '',
    fechas_pagos_id: '',
    research: '',
    success: ''

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTAR:
            return {
                ...state,
                multas: action.payload,
                cargando: false,
                research: false,
                cantidad: 0,
                fechaprestamo: '',
                concepto: '',

            }

        case FILTRAR_MODELO_PAGO:
            return {
                ...state,
                ModeloPago: action.payload,
                cargando: false,
                research: false,


            }

            case CONSULTAR_FECHAS_PAGOS:
                return {
                    ...state,
                    ListaFechasPagos: action.payload,
                    cargando: false,
                    research: false,
    
    
                }
                case PAGINAR_MONITORES:
                    return {
                        ...state,
                        Monitores: action.payload,
                        cargando: false,
                        research: false,
        
        
                    }

                
                case CAMBIO_FECHA_PAGOS:
                    return {
                        ...state,
                        fechas_pagos_id: action.payload,
                        cargando: false,
                        research: false,
        
        
                    }
                
            
        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false, success: '' }


        case SUCCESS:
            return { ...state, success: action.payload, cargando: false, error: '' }


            case CONSULTARS:
            return {
                ...state,
                SuggestionDatabase: action.payload,

            }


        case RESEARCHTRUE
            :
            return {
                ...state,

                ModeloPago: [],
                ListaFechasPagos: [],
                SuggestionDatabase: [],
                Monitores: [],
                cargando: false,
                error: '',
                fechas_pagos_id: '',
                research: '',
                success: ''



            }


        default: return state
    }

}