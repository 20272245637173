import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as PrestamoGrandeMonitorAction from "../../actions/PrestamoGrandeMonitorAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import { FaTimes } from "react-icons/fa";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import { MdFileUpload } from "react-icons/md";


// Imagine you have a list of languages that you'd like to autosuggest.

// Teach Autosuggest how to calculate suggestions for any given input value.

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.

export class PrestamoGrandeMonitores extends Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: "",
      id: "",
      text: "",
      suggestVar: false,
      date: new Date()
    };
  }
  componentDidMount() {
    this.props.consultarPrestamosGrandes();
   
  }

  componentDidUpdate() {
    const { researchPrestamo, } = this.props;
   
    if(!researchPrestamo){
      this.props.consultarPrestamosGrandes();
    }
  }
  componentWillUnmount() {
    const { researchtrue, } = this.props;

    researchtrue();
  }

  /* 
          getSuggestions2 = value => {
            const inputValue = value.trim().toLowerCase();
            const inputLength = inputValue.length;
    
            var ArrayVar = inputLength === 0 ? [] : this.props.SuggestionDatabase.filter(lang =>
              lang.name.toLowerCase()=== inputValue
            );
           return ArrayVar[0] ? ArrayVar[0].id: []
           
          }; 
     */
  cambioprestamo = event => {
    this.props.cambioprestamo(event.target.value);
  };

  cambiocuota = event => {
    this.props.cambiocuota(event.target.value);
  };

  guardar = () => {
    const {
   /*    match: {
        params: { model_id }
      }, */
      cuota,
      prestamo,
      agregarPrestamoGrande
    } = this.props;
    const prestaGrandeMomodelo = {
      
      datos_id: this.state.id,
      prestamo: prestamo,
      cuota: cuota
    };

    /*  if (model_id  && plataform_id) {
 
           const tarea = tareas[usu_id][tar_id];
             const tarea_editada = {
                 ...nueva_tarea,
                 completed: tarea.completed,
                 id: tarea.id
             }
 
             editar (tarea_editada)
             
         }else{
            
         } */
         agregarPrestamoGrande(prestaGrandeMomodelo);
  };

  consultarPrestamosGrandeModelo = () => {
    const { consultar } = this.props;

    consultar(this.state.id);
  };


   handleEditar = (id, e) => {
    const { editar } = this.props;
    const UploadDatosPrestamoGrande = {
        prestamo: document.getElementById(`prestamo${id}`).value,
        cuota: document.getElementById(`cuota${id}`).value,
       
    };
    e.preventDefault();

    editar(UploadDatosPrestamoGrande, id);


}; 


handleEditarPrestamoHistorial = (id, e) => {
  const { editarPrestamoGrandeHostorial } = this.props;
  const UploadDatosPrestamoGrandeHistorial = {
      prestamo: document.getElementById(`prestamoHistorial${id}`).value,
      cuota: document.getElementById(`cuotaHistorial${id}`).value,
     
  };
  e.preventDefault();

  editarPrestamoGrandeHostorial(UploadDatosPrestamoGrandeHistorial, id);


}; 

  handleSubmit(e) {
    e.preventDefault();
  }

  onTextChanged = e => {
    const value = e.target.value;
    let suggestions = [];

    const { consultarSuggestions } = this.props;
    const dataSuggestion = {
      data: value
    };
    if (value.length > 2) {
      this.setState(() => ({ suggestVar: true }));
      consultarSuggestions(dataSuggestion);
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      suggestions =
        inputLength === 0
          ? []
          : this.props.SuggestionDatabase.filter(
              lang =>
                lang.name.toLowerCase().slice(0, inputLength) === inputValue
            );
    } else {
      this.setState(() => ({ suggestVar: false }));
    }
    this.setState(() => ({ suggestions, text: value }));
  };

  suggestionSelected(value) {
    this.setState(() => ({
      text: value.name,
      id: value.id,
      suggestVar: false
    }));
  }

  renderSuggestions() {
    if (!this.state.suggestVar) {
      return null;
    }
    return (
      <div className="srchList">
        {this.props.SuggestionDatabase.map((item, key) => (
          <li key={key} onClick={() => this.suggestionSelected(item)}>
            {item.name}
          </li>
        ))}
      </div>
    );
  }

  handledelete(id, e) {
    e.preventDefault();
    const { eliminar } = this.props;

    eliminar(id);
  }

  handleChange(index, dataType, value) {


    this.props.handleChange(index, dataType, value)

}


handleChangePrestamoHistorial(index, dataType, value){
  
  this.props.handleChangePrestamoHistorial(index, dataType, value)

}

  renderPrestamos = () => {
    const { verperstamos } = this.props;
    const ponerInfo = () =>
      verperstamos.map((prestamo, key) => (
        <tr key={key}>
          <td>{`${prestamo.cedula}`}</td>
          <td>{`${prestamo.nombre} ${prestamo.apellidos}`}</td>
          <td>
          <input
                        type="text"
                        id={`prestamo${prestamo.id}`}
                        value={this.props.verperstamos[key].prestamo || ''}
                        onChange={(e) => this.handleChange(key, 'prestamo', e.target.value)}
                    />
            </td>
          <td>
          <input
                        type="text"
                        id={`cuota${prestamo.id}`}
                        value={this.props.verperstamos[key].cuota || ''}
                        onChange={(e) => this.handleChange(key, 'cuota', e.target.value)}
                    />
                    </td>
          <td>
            { <Fragment>
    
             <span
                className="loans__icon__x"
                onClick={e => this.handleEditar(prestamo.id, e)}
              >
                <MdFileUpload
                 
                  className="Home_Work_Stage__insideDiv__icon__x btn_action"
                />
              </span>

            
              <span
                className="loans__icon__x"
                onClick={e => this.handledelete(prestamo.id, e)}
              >
                <FaTimes
                
                  className="Home_Work_Stage__insideDiv__icon__x btn_action"
                />
              </span>
              </Fragment>
            }
          </td>
        </tr>
      ));

    return ponerInfo();
  };

  renderPrestamosModelo = () => {
    const { prestamos } = this.props;
    const ponerInfo = () => (
      <div>
        <table className="table table-striped tabla-global">
          <thead>
            <tr>
            <th>CEDULA</th>
              <th>NOMBRE</th>
              <th>PRESTAMO </th>
              <th>CUOTA </th>
              <th>FECHA </th>
              <th>ACCIÓN </th>
            </tr>
          </thead>
          <tbody>
            {prestamos.map((prestamo, key) => (
              <tr key={key}>
                <td>{`${prestamo.cedula}`}</td>
                <td>{`${prestamo.nombre} ${prestamo.apellidos}`}</td>
                <td>
          <input
                        type="text"
                        id={`prestamoHistorial${prestamo.id}`}
                        value={this.props.prestamos[key].prestamo || ''}
                        onChange={(e) => this.handleChangePrestamoHistorial(key, 'prestamo', e.target.value)}
                    />
                    </td>
                <td>
          <input
                        type="text"
                        id={`cuotaHistorial${prestamo.id}`}
                        value={this.props.prestamos[key].cuota || ''}
                        onChange={(e) => this.handleChangePrestamoHistorial(key, 'cuota', e.target.value)}
                    />
                    </td>
                <td>{prestamo.fecha}</td>
                <td>
                 

                    <span
                className="loans__icon__x"
                onClick={e => this.handleEditarPrestamoHistorial(prestamo.id, e)}
              >
                <MdFileUpload
                
                  className="Home_Work_Stage__insideDiv__icon__x btn_action"
                />
              </span>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );

    if (prestamos.length > 0) {
      return ponerInfo();
    } else {
      return "";
    }
  };

  Ponerlabelguardar = () => {
    const { text } = this.state;
    // Autosuggest will pass through all these props to the input.

    return (
      <form className="" onSubmit={e => this.handleSubmit(e)}>
        <fieldset className="form-fieldset">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-12 input">
                <input
                  className="input__autocomplete__Text prestamosGlobar__text"
                  value={text}
                  onChange={this.onTextChanged}
                  type="text"
                  placeholder="Buscar Monitor"
                  required
                />
              </div>
              <div className="col-md-12 justify-content-md-center input__autocomplete__SuggestionText">
                {this.renderSuggestions()}
              </div>
            </div>
          </div>

          <input
          
            className="prestamosGlobar__text"
            placeholder="Prestamo"
            type="number"
            value={this.props.prestamo}
            onChange={this.cambioprestamo}
          />

<input

            className="prestamosGlobar__text"
            placeholder="cuota"
            type="number"
            value={this.props.cuota}
            onChange={this.cambiocuota}
          />

        </fieldset>
      </form>
    );
  };

  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {
    //   const { match: { params: {modelname } },   } = this.props

    console.log(this.props);

    return (
      <Fragment>
        <div className="main-content fade-in">
          <h1 className="tituloPaginas">
            PRÉSTAMOS <strong className="tituloPaginasPequeño">GRANDES</strong>
          </h1>
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <div className="reporte__div__principal">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 ">
                  {this.Ponerlabelguardar()}
                  {this.mostrarAccion()}
                </div>

                <div className="col-md-2 center__reportes__botones">
                  <div className="btn__reportes_div">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={this.consultarPrestamosGrandeModelo}
                    >
                      Filtrar
                    </button>
                  </div>
                  <div className="btn__reportes_div">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={this.guardar}
                    >
                      Guardar
                    </button>
                  </div>
                </div>
                <div className="col-md-6 todos__prestamos__div ">
                  <table className="table table-striped tabla-global">
                    <thead>
                      <tr>
                      <th>CEDULA</th>
                        <th>NOMBRE</th>
                        <th>PRESTAMO </th>
                        <th>CUOTA </th>
                        <th>ACCIÓN </th>
                      </tr>
                    </thead>
                    <tbody>{this.renderPrestamos()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {this.renderPrestamosModelo()}
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.PrestamoGrandeMonitorReducer;
};

export default connect(mapStateToProps, PrestamoGrandeMonitorAction)(PrestamoGrandeMonitores);
