import { CARGANDO, ERROR,  GUARDAR, CONSULTAR,RESEARCHTRUE,SUCCESS} from '../types/CamaraTypes'

const INITIAL_STATE = {
    cam: [],
    cargando: false,
    error: '',
    user_cuenta: '',
    password_cuenta: '',
    modelos_id:'',
    research:true,
    success:''

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTAR:
            return {
                ...state,
                cam: action.payload,
                cargando: false,
                research:false,

                
            }
          
        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false,success:'' }
            

            case SUCCESS:
                return { ...state, success: action.payload, cargando: false,error: '' }


        case GUARDAR:
            return {
                ...state,
                cam: [],
                cargando: false,
                user_cuenta: '',
                password_cuenta: '',
                research:true
             

            }



            case RESEARCHTRUE
            :
                return {
                    ...state,
                    cam: [],
                    cargando: false,
                    error: '',
                    research:true,
                    success:''
                  
    
                }


        default: return state
    }
  
}