export const CONSULTAR = 'ver_pago_traer_todas';
export const CARGANDO = 'ver_pago_cargando';
export const ERROR = 'ver_pago_error';
export const GUARDAR = 'ver_pago_guardada';
export const ACTUALIZAR = 'ver_pago_actualizar';
export const LIMPIAR = 'ver_pago_limpiar';
export const RESEARCHTRUE = 'ver_pago_research';
export const SUCCESS = 'ver_pago_succes';
export const CAMBIO_FECHAPRESTAMO = 'ver_pago_fecha';
export const CAMBIO_CANTIDAD = 'ver_pago_cantidad';
export const CAMBIO_CONCEPTO = 'ver_pago_concepto';
export const CAMBIO_FECHA1 = 'ver_pago_fecha1';
export const CAMBIO_FECHA2 = 'ver_pago_fecha2';
export const FILTRAR_MODELO_PAGO = 'ver_pago_ganancias_filtrar';
export const CAMBIO_FECHA_PAGOS = 'ver_pago_cambio_fecha_pagos';
export const CONSULTAR_LUGARES = 'ver_pago_consultar_lugares';
export const CONSULTARS = 'ver_pago_traer_todas_suggestions';
export const CONSULTAR_FECHAS_PAGOS = 'ver_pago_traer_todas_fechas_pagos';
export const PAGINAR_MODELOS = 'ver_paginacion_modelos';
export const PAGINACION_MODELOS = 'ver_paginaciones_modelos';
export const ID_MODELOS = 'id_modelos_busqueda';
export const TEXT_MODELOS = 'nombre_modelo_busqueda';





