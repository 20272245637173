import axios from 'axios'
import {CARGANDO,ERROR,CONSULTAR,RESEARCHTRUE} from '../types/CamaraTypes'

import {URL} from '../../src/General/url'

//acciones para tareas 



export const consultar = (model_id) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

     

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`getChaturRoomStatus`,{
            headers: headers
        })
   
        dispatch({
            type: CONSULTAR,
            payload:respuesta.data.data,
        })

        
        
    } catch (error) {
        
     
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}





export const researchtrue  = () => (dispatch,) => {
    dispatch({

        type: RESEARCHTRUE
        
    
    })


    

   
};