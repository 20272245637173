import axios from 'axios'
import { CARGANDO, ERROR,  GUARDAR, CONSULTAR, CAMBIO_FECHAPRESTAMO,CAMBIO_CANTIDAD,RESEARCHTRUE,CAMBIO_FECHA1,CAMBIO_CONCEPTO,
    SUCCESS,CAMBIO_FECHA2,CONSULTAR_MULTAS_MODELOS,CONSULTARS,RESEARPRESTAMO} from '../types/MultasAgregarTypes'
    import {URL} from '../../src/General/url'





export const editar = (Uploaddatoscuenta,model_id) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(Uploaddatoscuenta)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateFines/${model_id}`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
}

if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
        
 /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

 
}

export const agregar = (prestamomodelo) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(prestamomodelo)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'createFines', params,{
            headers: headers
        })
    
      
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })


        if(respuesta.data.status==="success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

            dispatch({
        
                type: RESEARPRESTAMO,
                payload: false
                
            
            })

        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const consultarMultas = () => async(dispatch)=>{
    
    try {

 
        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+'finesReportModels' ,{
            headers: headers
        })
      
        dispatch({
            type: CONSULTAR_MULTAS_MODELOS,
            payload:respuesta.data.data
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }

        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}





export const consultar_multas_modelo = (model_id) => async(dispatch)=>{
    
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

     
    
        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`searchModelsfinesReport/${model_id}`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTAR,
            payload:respuesta.data.data,
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

export const consultarSuggestions = (data) => async(dispatch)=>{
    


    try {

     
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
    
        const respuesta = await axios.post(URL+`getSuggestionModels`, params,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTARS,
            payload:respuesta.data.data,
        })

        
        
    } catch (error) {
        
    }
}
export const eliminar = (id) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.delete(URL+`destroyMultaModel/${id}`,{
            headers: headers
        })
    
    
        if(respuesta.data.status==="Success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

            dispatch({
        
                type: RESEARPRESTAMO,
                payload: false
                
            
            })

        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
        
    } catch (error) {

        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}

export const cambioconcepto = (concepto_prestamo) => (dispatch)=>{

    dispatch({
        type: CAMBIO_CONCEPTO,
        payload: concepto_prestamo
        
    })


}


export const cambiocantidad = (cantidad_prestamo) => (dispatch)=>{
    dispatch({
        type: CAMBIO_CANTIDAD,
        payload: cantidad_prestamo
        
    })
}

export const cambiofechaprestamo  = (fecha_prestamo) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHAPRESTAMO,
        payload: fecha_prestamo
        
    
    })
    
};

export const cambiofecha1  = (fecha1) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA1,
        payload: fecha1
        
    
    })
    
};

export const cambiofecha2  = (fecha2) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA2,
        payload: fecha2
        
    
    })
    
};



export const researchtrue  = () => (dispatch,) => {
    dispatch({

        type: RESEARCHTRUE
        
    
    })


    

   
};


export const handleChange  = (index, dataType, value) => (dispatch,getState) => {

    const {multasModelos} = getState().MultasAgregarReducer


    const newMulta = multasModelos.map((item, i) => {
        if (i === index) {
          return {...item, [dataType]: value};
        }
        return item;
      });
  

      dispatch({
        type: CONSULTAR_MULTAS_MODELOS,
        payload:newMulta
    })


    

   
};


export const handleChangeMultaHistorial  = (index, dataType, value) => (dispatch,getState) => {

    const {MultasModelo} = getState().MultasAgregarReducer


    const newMultaHistorial = MultasModelo.map((item, i) => {
        if (i === index) {
          return {...item, [dataType]: value};
        }
        return item;
      });
  

      dispatch({
        type: CONSULTAR,
        payload:newMultaHistorial
    })


    

   
};
