import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
import * as permisosaction from "../../actions/PermisosUsuariosAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import btonAgregarPermiso from "../../img/btonAgregarPermiso.png";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import { FiDelete } from "react-icons/fi";
//import {URL} from '../../../src/General/url'
/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */
export class permisosPagosUsuarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permisosArray: [],
      id_lugar: "",
      nombre_lugar: "",
      nombre_permiso: "",
    };
  }

  async componentDidMount() {
    const { consultar_Permiso_pagos,
      match: {
        params: { permiso_id },
    }, } = this.props;
    await consultar_Permiso_pagos(permiso_id);
   
  }
  componentDidUpdate() {
    console.log(this.state);
  }

  componentWillUnmount() {
    this.props.researchtrue();
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  setLugar(e) {

    this.props.handleAddPermisos(this.state.id_lugar,this.state.nombre_lugar)
    this.setState({
      id_lugar: '',
      nombre_lugar: '',
    })

  }

  listaLugares = () => {
    const { ListaLugares } = this.props;

    const poneropcioneslugaress = () =>

      ListaLugares.map((lugares, key) => (
        <option className="option-values" key={key} value={key}>
          {lugares.nombre}
        </option>
      ));

    return (
      <Fragment>
        <select
          className="right-submenu"
          name="select"
          required
          onChange={(e) => {
            e.target.value==='todo'? this.setState({
              id_lugar: '0',
              nombre_lugar: 'Todos',
            }): this.setState({
              id_lugar: this.props.ListaLugares[e.target.value].id,
              nombre_lugar: this.props.ListaLugares[e.target.value].nombre,
            })
           
          }}
        >
           
          <option className="option-values">Lugares</option>
          <option className="option-values" value='todo'>
    Todos
  </option>
          {poneropcioneslugaress()}
        </select>
   
      </Fragment>
    );
  };

  deletePermiso(id) {
this.props.handleDeletePermisos(id)
  }

  listaPermisos = () => {
    const ponerPermisos = () =>
      this.props.permisosLugares.map((permiso, key) => (
        <tr key={key}>
          <td>{permiso.nombre}</td>
          <td>
            {" "}
            <span
              className="Permisos_Usuarios_Stage__insideDiv__icon__add"
              onClick={(e) => {
                this.deletePermiso(permiso.id);
              }}
            >
              <FiDelete size={"32px"} />
            </span>
          </td>
        </tr>
      ));

    return ponerPermisos();
  };

  handleChange(dataType, value) {

    this.props.handleChangePagos(dataType, value);
}
  ponerHabitaciones = () => {
    const ponerInfo = () => (
      <Fragment>

        <span className="pageTitulos">PAGOS</span>
        <tr>
        <td>nomina-pagos-pago</td>
          <td>
            <input type="checkbox" className="checkbox" id="nomina_pagos_pago" 
            checked={this.props.ListaPermisosPagosUpdate.nomina_pagos_pago}
               onChange={(e) =>
                this.handleChange( "nomina_pagos_pago", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>nomina-pagos-subir-archivo</td>
          <td>
            <input type="checkbox" className="checkbox" id="nomina_pagos_subir_archivo" 
             checked={this.props.ListaPermisosPagosUpdate.nomina_pagos_subir_archivo}
               onChange={(e) =>
                this.handleChange( "nomina_pagos_subir_archivo", e.target.checked)
            }/>
          </td>
        </tr>

        <tr>
        <td>nomina-pagos-enviar-correo</td>
          <td>
            <input type="checkbox" className="checkbox" id="nomina_pagos_enviar_correo" 
             checked={this.props.ListaPermisosPagosUpdate.nomina_pagos_enviar_correo}
               onChange={(e) =>
                this.handleChange( "nomina_pagos_enviar_correo", e.target.checked)
            }/>
          </td>
        </tr>
        <tr>
        <td>nomina-pagos-recibos-modelos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="ReportesModelos"
              checked={this.props.ListaPermisosPagosUpdate.nomina_pagos_recibos_modelos}
              onChange={(e) =>
                this.handleChange( "nomina_pagos_recibos_modelos", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>nomina-pagos-recibos-ganancias</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="ReportesModelos"
              checked={this.props.ListaPermisosPagosUpdate.nomina_pagos_recibos_ganancias}
              onChange={(e) =>
                this.handleChange( "nomina_pagos_recibos_ganancias", e.target.checked)
            }
            />
          </td>
        </tr>
        
        <tr>
        <td>nomina-pagos-recibos-monitores</td>
          <td>
            <input type="checkbox" className="checkbox" id="nomina_pagos_recibos_monitores"
              checked={this.props.ListaPermisosPagosUpdate.nomina_pagos_recibos_monitores}
              onChange={(e) =>
                this.handleChange( "nomina_pagos_recibos_monitores", e.target.checked)
            }/>
          </td>
        </tr>
        <tr>
        <td>nomina-pagos-procentaje</td>
          <td>
            <input type="checkbox" className="checkbox" id="nomina_pagos_procentaje"
              checked={this.props.ListaPermisosPagosUpdate.nomina_pagos_procentaje}
              onChange={(e) =>
                this.handleChange( "nomina_pagos_procentaje", e.target.checked)
            }/>
          </td>
        </tr>
        
        <tr>
        <td>nomina-pagos-archivos</td>
          <td>
            <input type="checkbox" className="checkbox" id="nomina_pagos_archivos" 
             checked={this.props.ListaPermisosPagosUpdate.nomina_pagos_archivos}
             onChange={(e) =>
               this.handleChange( "nomina_pagos_archivos", e.target.checked)
           }/>
          </td>
        </tr>
        

      </Fragment>
    );
    return ponerInfo();
  };

  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  ListaPermisosBaseDatos = () => {
    const { ListaPermisosPagosUpdate } = this.props;
    const ponerPermisosBaseDatos = () =>
    ListaPermisosPagosUpdate.map((permiso, key) => (
        <tr key={key}>
          <td>{permiso.nombre}</td>
          <td>
            {" "}
            <span
              className="Permisos_Usuarios_Stage__insideDiv__icon__add"
              onClick={(e) => {
                this.deletePermiso(permiso.id);
              }}
            >
              <FiDelete size={"32px"} />
            </span>
          </td>
        </tr>
      ));

    return ponerPermisosBaseDatos();
  };
  
  updatePermiso = (e) => {
    e.preventDefault();

    const {
      update_permiso_pago,

      match: {
        params: { permiso_id }
    } }= this.props;

    let arrayPer = this.props.permisosLugares;
    let StrigPer = "";
    for (let index = 0; index < arrayPer.length; index++) {
      const element = arrayPer[index];

      if (arrayPer.length > 1) {
        index+1===arrayPer.length?StrigPer = StrigPer + `${element.id}`:StrigPer = StrigPer + `${element.id},`;
      } else {
        StrigPer = StrigPer + `${element.id}`;
      }
    }

    
    const data = {
      nombre: this.props.ListaPermisosPagosUpdate.nombre,
      lugares_id: StrigPer,
      nomina_pagos_archivos:this.props.ListaPermisosPagosUpdate.nomina_pagos_archivos,
      nomina_pagos_enviar_correo: this.props.ListaPermisosPagosUpdate.nomina_pagos_enviar_correo,
      nomina_pagos_pago:this.props.ListaPermisosPagosUpdate.nomina_pagos_pago,
      nomina_pagos_procentaje:this.props.ListaPermisosPagosUpdate.nomina_pagos_procentaje,
      nomina_pagos_recibos_ganancias:this.props.ListaPermisosPagosUpdate.nomina_pagos_recibos_ganancias,
      nomina_pagos_recibos_modelos:this.props.ListaPermisosPagosUpdate.nomina_pagos_recibos_modelos,
      reportesTotalSinDescuento:this.props.ListaPermisosPagosUpdate.reportesTotalSinDescuento,
      nomina_pagos_recibos_monitores:this.props.ListaPermisosPagosUpdate.nomina_pagos_recibos_monitores,
      nomina_pagos_subir_archivo:this.props.ListaPermisosPagosUpdate.nomina_pagos_subir_archivo,
      
    };

    update_permiso_pago(data,permiso_id);
  };

  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {


    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">PERMISOS</h1>

          <div className="reporte__div__principal__permisos">
            {this.mostrarAccion()}
            <div className="container-fluid">
              <div className="row">
              <div className="col-md-12 mx-auto">
                  <div className="row">
                    <div
                      className="col-md-6 mx-auto"
                      style={{ display: "flex",paddingTop: "20px" }}
                    >
                      <div style={{ width: "100%" }}>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="Nombre Permiso"
                          value={this.props.ListaPermisosPagosUpdate.nombre || ""}
                          onChange={(e) =>
                            this.handleChange( "nombre", e.target.value)
                        }
                        />
                            <div style={{ width: "100%" }}>{this.listaLugares()}</div>
                      </div>

                      <span
          className="Permisos_Usuarios_Stage__insideDiv__icon__add_permisos"
          onClick={(e) => {
            this.setLugar();
          }}
        >
          <img alt='img para aagregar permisos a la lista' src={btonAgregarPermiso}/>
        </span>
                    </div>
                    <div className="col-md-6 mx-auto">
                      <table className="table table-striped tabla-global" style={{textAlign:"center"}}>
                        <tbody>
                          <tr>
                
                          </tr>
                          {this.listaPermisos()}
                        </tbody>
                      </table>
                    </div>
                  </div>
          
                </div>

                
              </div>

         
            </div>
          </div>


          <div className="row reporte__div__secundario__permisos" >  
      
            <div className="col-md-8 mx-auto">
            <table className="table table-striped tabla-global">
                  <span className="pageTitulos">MENÚ</span>
                    <tbody>
                     
                      {this.ponerHabitaciones()}
                    </tbody>
                  </table>
            </div>
            <div className="col-md-8 mx-auto ">
                <button className="invetario__btn" onClick={this.updatePermiso}>
                  update Permiso
                </button>
              </div>
            </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (reducers) => {
  return reducers.PermisosUsuariosReducer;
};

export default connect(mapStateToProps, permisosaction)(permisosPagosUsuarios);
