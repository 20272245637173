import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as tareasaction from "../../actions/TareasAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import cambiarFotoImg from "../../img/cambiarFotoImg.png";
import defaultLogo from "../../img/defaultLogo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import {URL} from '../../../src/General/url'


/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */
export class miPerfil extends Component {


  state = {
    opacity: '30%'
  }

  componentDidMount() {

    const { consultarInfoPlataforma } = this.props;
    consultarInfoPlataforma()
  }

  componentDidUpdate() {

    console.log(this.props)

  }



  componentWillUnmount() {

    const { researchtrue } = this.props;
    researchtrue()
  }

  handleSubmit(e) {
    e.preventDefault();
  }
  deshabilitar = () => {
    const {
  
      password,
      newPassword
    } = this.props;

    if (
      !password ||
      !newPassword 
    ) {

      return true
    }

    return false

  };

  cambioFotoPerfilLogo = event => {
    const { guardarimagenlogo } = this.props;
    const files = event.target.files[0];
    if (files) {
      const data = new FormData();
      data.append("file0", files);
      guardarimagenlogo(data);
    }

  };


  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  cambioTrm = (event) => {
    this.props.cambioTrm(event)
  }


  updateTrm(e) {
    e.preventDefault()
    const {
      updateTrm,
      trm,

    } = this.props;
    const data = {
      trm: trm,
    };

    updateTrm(data);
  }


  updataeProfileLogo(e) {
    e.preventDefault()
    const {
      updataeProfileLogo,
      logo,
    } = this.props;
    const data = {
      logo: logo,
     
    };

    updataeProfileLogo(data);
  }




  ponerInformacionModelo = () => {

    const ponerInfo = () => (
      <Fragment >
        <div className="col-md-12 ">
          <div className="row">
            <div className="col-md-3 mx-auto">

              <img
                className="avatarModeloimg"
                alt="imgagerfil"
                src={  this.props.logo
                  ? `${URL}getImageOriginal/${this.props.logo}`
                  : defaultLogo}
              />
              <label className='foto_perfil_modelo_label' htmlFor="foto_perfil_modelo">
                <img src={cambiarFotoImg} alt="Click aquí para subir tu foto" title="Click aquí para subir tu foto" />
              </label>
              <input

                className="form-element-field -hasvalue"
                id={`foto_perfil_modelo`}
                type="file"
                onChange={this.cambioFotoPerfilLogo}
              />
                   <button
                type='button'
            
                className='btn_crear_monitor'
                onClick={e => this.updataeProfileLogo(e)}
                >

                Actualizar Logo
            </button>
            </div>

            <div className="col-md-4 mx-auto">
              <div className='informacion_personal_modificar_info_modelo'>

                <div className='div__personal__modificar__info__mi__perfil'><div className='text__rigth'>Trm  </div>   <input
                  type="text"
                  className='input__modificiar_informacion_modelo'
                  value={this.props.trm}
                  onChange={(e) => this.cambioTrm( e.target.value)}
                /></div>

              </div>

              <button
                type='button'
                className='btn_crear_monitor'
                onClick={e => this.updateTrm(e)}
                >

Actualizar Trm
            </button>
            </div>
          </div>
        </div>

      </Fragment>
    )

    return (ponerInfo())
  }





  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {

    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
           TRM Y LOGO
          </h1>

          <div className="reporte__div__principal">

            <div className="container-fluid">

            {this.mostrarAccion()}
              <div className="row">

                {this.ponerInformacionModelo()}
             
    

              </div>



            </div>
          </div>
         


        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.TareasReducer;
};

export default connect(mapStateToProps, tareasaction)(miPerfil);
