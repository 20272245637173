import axios from 'axios'
import FileDownload  from "js-file-download";
import { CARGANDO, ERROR,  GUARDAR, CONSULTAR,CAMBIO_LUGARES,RESEARCHTRUE,CAMBIO_FECHA1,
    SUCCESS,CAMBIO_FECHA2,FILTRAR_PRESTAMOS_MINIMOS,CONSULTAR_LUGARES} from '../types/ArchivoPagostypes'


    import {URL} from '../../src/General/url'



export const descargarArchivoPago = (data) => async (dispatch) => {

    try {
  
  
        let headers = {
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL +  `createPagymentFile/${data.fecha_inicial}/${data.fecha_final}`, {
            headers: headers,
            responseType: 'arraybuffer'
        })
      
        var blob = new Blob([respuesta.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileDownload(blob, 'texto_pago.txt')
  
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }

  export const descargarArchivoPInscripcion= (data) => async (dispatch) => {

    try {
  
  
        let headers = {
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL +  `createInscriptionFile/${data.fecha_final}`, {
            headers: headers,
            responseType: 'arraybuffer'
        })
      
        var blob = new Blob([respuesta.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileDownload(blob, 'texto_inscripcion.txt')
  
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }


export const editar = (Uploaddatoscuenta,model_id) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(Uploaddatoscuenta)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateAccountsModel/${model_id}`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
}
if(respuesta.data.status==="Error"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
}
        
 /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: 'Intente mas tarde - Cuenta Ocupada'
        
    
    })
    }
}

 
}


export const consultar_banco = () => async (dispatch) => {


    try {


        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getTipeAccount', {
            headers: headers
        })




        dispatch({
            type: CONSULTAR_LUGARES,
            payload: respuesta.data.data
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })
        }

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}


export const agregar = (prestamomodelo) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(prestamomodelo)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'createFines', params,{
            headers: headers
        })
    
      
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })


        if(respuesta.data.status==="success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })
        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const filtrapretamosminimos = (reprotesmodelo) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(reprotesmodelo)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'modelsReports', params,{
            headers: headers
        })
    
      
        dispatch({
            type: FILTRAR_PRESTAMOS_MINIMOS,
            payload:respuesta.data.data
        })


        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })
        }

        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}



export const eliminar = (id) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.delete(URL+`destroyAccountsModel/${id}`,{
            headers: headers
        })
    
    
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })
        }
        
    } catch (error) {

        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const consultar = (model_id) => async(dispatch)=>{
    


    try {


        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`searchModelsfinesReport/${model_id}`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTAR,
            payload:respuesta.data.data,
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })
        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const cambioLugares = (lugares) => (dispatch) => {
    dispatch({
        type: CAMBIO_LUGARES,
        payload: lugares

    })
}



export const cambiofecha1  = (fecha1) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA1,
        payload: fecha1
        
    
    })
    
};

export const cambiofecha2  = (fecha2) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA2,
        payload: fecha2
        
    
    })
    
};



export const researchtrue  = () => (dispatch,) => {
    dispatch({

        type: RESEARCHTRUE
        
    
    })


    

   
};