import axios from "axios";
import {
  CARGANDO,
  ERROR,
  CONSULTAR,
  IMAGEN_360,
  RESEARCHTRUE,
  SUCCESS,
  IMAGEN_PERFIL,
  NEW_HABITACIONES,
  CONSULTARS,
  CONSULTAR_TURNOS,
  CAMBIO_TURNO_ID,
  CONSULTAR_MODELOS_HABITACION,
  RESEARCH_MODELOS_HABITACION,
  CONSULTAR_TODAS_HABITACIONES,
  CONSULTAR_TODAS_HABITACIONES_REPORTE,
  CONSULTAR_LUGARES,
  CONSULTAR_PERMISOS,
  CONSULTAR_PERMISOS_UPDATE,
  CONSULTAR_PERMISOS_UPDATE_LUGARES,
  RESEARCH_CORREO
  
} from "../types/correosReportesTypes";

//acciones para tareas

import {URL} from '../../src/General/url'



export const editarRooms = (data,model_id) => async(dispatch)=>{
   
    
  dispatch({

      type: CARGANDO
      
  
  })

  try {
    
      let json = JSON.stringify(data)
      let params = json;

        

      let headers = {
          "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.put(URL+`updateRoom/${model_id}`, params,{
          headers: headers
      })
   
if(respuesta.data.status==="Success"){
  dispatch({

      type: SUCCESS,
      payload: respuesta.data.message
      
  
  })
}

if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
      
/*    
      */
      
      
      
  } catch (error) {
      
      if(error.message==="Request failed with status code 401"){
          window.localStorage.setItem('token','')
          window.localStorage.setItem('userData','')
      }else{
  dispatch({

      type: ERROR,
      payload: 'Intente mas tarde - Cuenta Ocupada'
      
  
  })
  }
}


}

export const update_permiso = (data,id) => async(dispatch)=>{
   
    
    dispatch({
  
        type: CARGANDO
        
    
    })
  
    try {
      
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateEmailReport/${id}`, params,{
            headers: headers
        })
     
  if(respuesta.data.status==="Success"){
    dispatch({
  
        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
  }
        
  if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
  /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({
  
        type: ERROR,
        payload: 'Intente mas tarde - Cuenta Ocupada'
        
    
    })
    }
  }
  
  
  }

export const consultarRooms = () => async (dispatch) => {

  dispatch({
    type: CARGANDO
  });

  try {


    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL +  `getRooms`, {
          headers: headers
      })


      if (respuesta.data.status === "success") {
        dispatch({
          type: CONSULTAR,
          payload: respuesta.data.data
        });
        
      }

      if(respuesta.data.status==="Error"){
        dispatch({
    
            type: ERROR,
            payload: respuesta.data.message
            
        
        })

    }
  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}

export const consultar_lugares = () => async (dispatch) => {


    try {


  
        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getPlaces', {
            headers: headers
        })




        dispatch({
            type: CONSULTAR_LUGARES,
            payload: respuesta.data.data
        })


    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}


export const consultarRoomsReports = () => async (dispatch) => {

    dispatch({
      type: CARGANDO
    });
  
    try {
  
        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL +  `getAllInventoryReport`, {
            headers: headers
        })
  
  
        if (respuesta.data.status === "Success") {
          dispatch({
            type: CONSULTAR_TODAS_HABITACIONES_REPORTE,
            payload: respuesta.data.data
          });
          
        }
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }
  


export const consultarAllRooms = () => async (dispatch) => {

    dispatch({
      type: CARGANDO
    });
  
    try {
  

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL +  `getAllRoomModels`, {
            headers: headers
        })
  
  
        if (respuesta.data.status === "Success") {
          dispatch({
            type: CONSULTAR_TODAS_HABITACIONES,
            payload: respuesta.data.data
          });
          
        }
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }
export const consultar_modelo_habitacion = (id) => async (dispatch) => {


    try {

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + `getRoomModels/${id}`, {
            headers: headers
        })
  
  
  
   if(respuesta.data.status==="Success"){
          dispatch({
        
              type: CONSULTAR_MODELOS_HABITACION,
              payload: respuesta.data.data
              
          
          })
        }
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }
  
export const guardarimagen = (data) => async (dispatch) => {




  dispatch({

      type: CARGANDO


  })

  try {


      let params = data
      let headers = {
          'Content-Type': 'multipart/from-data',
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.post(URL + 'StoreImage', params, {
          headers: headers
      })

      dispatch({
        type: IMAGEN_PERFIL,
        payload: respuesta.data.imagename
      });
    

      if(respuesta.data.status==="Error"){
        dispatch({
    
            type: ERROR,
            payload: respuesta.data.message
            
        
        })

    }

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}
export const guardarimagen360 = (data) => async (dispatch) => {

  
    try {
  
  
        let params = data
        let headers = {
            'Content-Type': 'multipart/from-data',
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL + 'StoreImage', params, {
            headers: headers
        })
  
        dispatch({
          type: IMAGEN_360,
          payload: respuesta.data.imagename
        });
  
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }

  export const guardarimagenUpdate = (data,index,dataType) => async (dispatch,getState) => {


    const {habitaciones} = getState().HabitacionesAgregarReducer

    dispatch({
  
        type: CARGANDO
  
  
    })
  
    try {
  
  
        let params = data
        let headers = {
            'Content-Type': 'multipart/from-data',
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL + 'StoreImage', params, {
            headers: headers
        })
  




        const newhabitaciones = habitaciones.map((item, i) => {
            if (i === index) {
              return {...item, [dataType]: respuesta.data.imagename};
            }
            return item;
          });
      
    
          dispatch({
            type: NEW_HABITACIONES,
            payload:newhabitaciones
        })
    
   
      
  
      /*   dispatch({
            type: CAMBIO_FOTO_PERFIL,
            payload: respuesta.data.imagename
        }) */
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }
  export const guardarimagen360Update = (data,index,dataType) => async (dispatch,getState) => {
  
    const {habitaciones} = getState().HabitacionesAgregarReducer
      try {
    
    
          let params = data
          let headers = {
            'Content-Type': 'multipart/from-data',
              'Authorization': window.localStorage.getItem('token')
          }
          const respuesta = await axios.post(URL + 'StoreImage', params, {
              headers: headers
          })
    


          const newhabitaciones = habitaciones.map((item, i) => {
            if (i === index) {
              return {...item, [dataType]: respuesta.data.imagename};
            }
            return item;
          });
      
    
          dispatch({
            type: NEW_HABITACIONES,
            payload:newhabitaciones
        })
    
    
        /*   dispatch({
              type: CAMBIO_FOTO_PERFIL,
              payload: respuesta.data.imagename
          }) */
    
      } catch (error) {
    
    
          if (error.message === "Request failed with status code 401") {
              window.localStorage.setItem('token', '')
              window.localStorage.setItem('userData', '')
          } else {
              dispatch({
    
                  type: ERROR,
                  payload: error.message
    
    
              })
          }
      }
    }


export const crearHabitacion = (data) => async (dispatch) => {



    dispatch({

        type: CARGANDO


    })

    try {

        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }


        const respuesta = await axios.post(URL + 'createRoom', params, {
            headers: headers
        })
       
        if (respuesta.data.status === "Success") {
            dispatch({

                type: SUCCESS,
                payload: respuesta.data.message


            })

            dispatch({

                type: RESEARCHTRUE


            })

            
        }


        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }



    } catch (error) {

        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}
export const researchtrue = () => dispatch => {
  dispatch({
    type: RESEARCHTRUE
  });
};


export const consultarSuggestions = (data) => async(dispatch)=>{
    


    try {

     
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
    
        const respuesta = await axios.post(URL+`getSuggestionModels`, params,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTARS,
            payload:respuesta.data.data,
        })

        
        
    } catch (error) {
        
    }
}

export const consultar_turnos = () => async (dispatch) => {


    try {
  
        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getSchedules', {
            headers: headers
        })
  
  
  
  
        dispatch({
            type: CONSULTAR_TURNOS,
            payload: respuesta.data.data
        })
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }

  export const cambioTurnoId = (data) => (dispatch)=>{

    dispatch({
        type: CAMBIO_TURNO_ID,
        payload: data
        
    })


}

export const asignar_habitacion = (data) => async (dispatch) => {

    dispatch({

        type: RESEARCH_MODELOS_HABITACION,
        payload: true


    })

    dispatch({

        type: CARGANDO


    })

    try {

        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }


        const respuesta = await axios.post(URL + 'assignModelRoom', params, {
            headers: headers
        })
       
        if (respuesta.data.status === "Success") {
            dispatch({

                type: SUCCESS,
                payload: respuesta.data.message


            })


            dispatch({

                type: RESEARCH_MODELOS_HABITACION,
                payload: false


            })

            
        }


        if (respuesta.data.status === "Error") {
            dispatch({

                type: ERROR,
                payload: respuesta.data.message


            })

            dispatch({

                type: RESEARCH_MODELOS_HABITACION,
                payload: false


            })

        }



    } catch (error) {

        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}
export const crear_reporte_inventario_room = (data) => async (dispatch) => {



    dispatch({

        type: CARGANDO


    })

    try {

        let json = JSON.stringify(data)
        let params = json;

        

      let headers = {
          "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }


        const respuesta = await axios.post(URL + 'createInventoryRoomReport', params, {
            headers: headers
        })
       
        if (respuesta.data.status === "Success") {
            dispatch({

                type: SUCCESS,
                payload: respuesta.data.message


            })




            
        }else if(respuesta.data.status === "Error") {
            dispatch({

                type: ERROR,
                payload: respuesta.data.message


            })


        }






    } catch (error) {

        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}


export const consultar_Permiso = (id) => async (dispatch) => {


    try {
        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + `getEmailReportById/${id}`, {
            headers: headers
        })

        dispatch({
            type: CONSULTAR_PERMISOS_UPDATE,
            payload: respuesta.data.data
        })

        dispatch({
            type: CONSULTAR_PERMISOS_UPDATE_LUGARES,
            payload: respuesta.data.permisos
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}
export const consultar_correos = () => async (dispatch) => {


    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getEmailsReport', {
            headers: headers
        })




        dispatch({
            type: CONSULTAR_PERMISOS,
            payload: respuesta.data.data
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}
export const crear_permiso = (data) => async (dispatch) => {



    dispatch({

        type: CARGANDO


    })

    try {

        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }


        const respuesta = await axios.post(URL + 'createEmailReport', params, {
            headers: headers
        })
       
        if (respuesta.data.status === "Success") {
            dispatch({

                type: SUCCESS,
                payload: respuesta.data.message


            })

            dispatch({

                type: RESEARCH_CORREO,
                payload: false


            })
      
      

            
        }else if(respuesta.data.status === "Error") {
            dispatch({

                type: ERROR,
                payload: respuesta.data.message


            })


        }






    } catch (error) {

        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}

export const handleChange = (dataType, value) => (dispatch,getState) => {

    const {ListaPermisosUpdate} = getState().PermisosUsuariosReducer

  console.log(value)

    const newinfo = {...ListaPermisosUpdate, [dataType]: value};
  console.log(newinfo)

      dispatch({
        type: CONSULTAR_PERMISOS_UPDATE,
        payload:newinfo
    })


    

   
};


export const handleDeletePermisos = (id) => (dispatch,getState) => {

    const {permisosLugares} = getState().correosReportesReducer
  

    const arrayPer = permisosLugares;
    const newArrayPer = Object.values(
      Object.fromEntries(
        Object.entries(arrayPer).filter(([key, val]) => val.id !== id)
      )
    );
    dispatch({
        type: CONSULTAR_PERMISOS_UPDATE_LUGARES,
        payload:newArrayPer
    })

   
};


export const handleAddPermisos = (id, nombre) => (dispatch,getState) => {

    const {permisosLugares} = getState().correosReportesReducer
    const found = permisosLugares.find(
        (element) => element.id === id
      );
      if (!found) {
        if (id) {
            permisosLugares.push({
            id: id,
            nombre: nombre,
          });

          dispatch({
            type: CONSULTAR_PERMISOS_UPDATE_LUGARES,
            payload:permisosLugares
        })
    
        }
      }



    

   
};
