import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as habitacionagregaraction from "../../actions/HabitacionesAgregarAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import iconRooms from "../../img/iconRooms.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import {URL} from '../../../src/General/url'

export class HabitacionesVer extends Component {


  state = {
    opacity: '30%'
  }

  componentDidMount() {
    const {
      consultarRooms
    } = this.props;
    consultarRooms()
  }

  componentDidUpdate() {
    console.log(this.props)
  }



  componentWillUnmount() {

  }

  handleSubmit(e) {
    e.preventDefault();
  }





  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }





  ListaHabitaciones = () => {

    const { habitaciones } = this.props;

    const ponerHabitaciones = () =>
      habitaciones.map((habitaciones, key) => (

        <Fragment key={key}>

          <div className="col-md-3 margin__box__rooms ">

            <img 
              className="avatarModeloimg"
              alt="imgagerfil"
              src={`${URL}getImage/${
                this.props.habitaciones[key].foto1
                  ? this.props.habitaciones[key].foto1
                  : "defaultLogo.png"
                }`}
            />
            <img   alt="imgagerfil" src={iconRooms} className='icom__room_img'/>
           <div className='div__number__container'><span>{ this.props.habitaciones[key].numero_habitacion}</span></div>
          </div>
        </Fragment>
      ));

    return (ponerHabitaciones()
    );
  };






  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {

    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            ROOMS <strong className="tituloPaginasPequeño">VER</strong>
          </h1>

          <div className="reporte__div__principal">

            <div className="container-fluid">
              <div className="row">

                {this.ListaHabitaciones()}

              </div>



            </div>
          </div>

          {this.mostrarAccion()}





        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.HabitacionesAgregarReducer;
};

export default connect(mapStateToProps, habitacionagregaraction)(HabitacionesVer);
