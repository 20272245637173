import React, { Component } from "react";
import { connect } from "react-redux";
import * as modeloaction from "../../actions/ModeloAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "./Styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Redirect } from "react-router-dom";
import Success from "../../General/Success";
import {URL} from '../../../src/General/url'
export class Cuentas extends Component {
  componentDidMount() {
    const {
      match: {
        params: { model_id }
      },
      consultar_horarios,
      consultar_lugares,
      consultar_modelo,
      consultar_tipo_documento
    } = this.props;

    if (model_id) {
      consultar_modelo(model_id);
    }
    consultar_horarios();
    consultar_lugares();
    consultar_tipo_documento();

    /*    if (usu_id && tar_id) {
               const tarea = tareas[usu_id][tar_id];
               cambioUsuarioId(tarea.userId);
               cambioTitulo(tarea.title);
           }else{
               limpiarForma()
           } */
  }

  componentDidUpdate() {
    console.log(this.props);
    const {
      match: {
        params: { model_id }
      },
      inicial,
      resetmodeloinfo,
      cambioCedula,
      cambioNombres,
      cambioApellidos,
      cambioTelefono,
      cambioHorario,
      cambioLugares,
      cambioNombreLugares,
      cambioNombreHorarios,
      cambioPasswordm,
      cambioArriendo,
      cambioNick,
      cambiNombreRecibo,
      cambioEstado,
      cambioFotoPerfil,
      cambioCedulacontable,
      cambioNombrescontable,
      cambioApellidoscontable,
      cambionombreTipodocumento,
      cambiocuentabancaria,
      cambiodireccion,
      cambioTipodocumento,
      cambioidcontable
    } = this.props;

    if (this.props.ModeloInfo.length && model_id && !inicial) {
      resetmodeloinfo();
      for (let modeloupdatedata of this.props.ModeloInfo) {
        cambioCedula(modeloupdatedata.cedula);
        cambioNombres(modeloupdatedata.nombres);
        cambioApellidos(modeloupdatedata.apellidos);
        cambioTelefono(modeloupdatedata.telefono);
        cambioHorario(modeloupdatedata.horario.id);
        cambioLugares(modeloupdatedata.lugares.id);
        cambioNombreHorarios(modeloupdatedata.horario.nombre);
        cambioNombreLugares(modeloupdatedata.lugares.nombre);
        cambioPasswordm(modeloupdatedata.passwordm);
        cambioArriendo(modeloupdatedata.arriendo);
        cambioNick(modeloupdatedata.nick_name);
        cambiNombreRecibo(modeloupdatedata.nombre_recibo);
        cambioEstado(modeloupdatedata.estado);
        cambioFotoPerfil(modeloupdatedata.foto_perfil);
        cambioCedulacontable(modeloupdatedata.informacion_contable.documento_contable
        );
        cambioNombrescontable(modeloupdatedata.informacion_contable.nombres);
        cambioApellidoscontable(
          modeloupdatedata.informacion_contable.apellidos
        );
        cambioTipodocumento(
          modeloupdatedata.informacion_contable.tipo_documento_id
        );
        cambiocuentabancaria(
          modeloupdatedata.informacion_contable.cuentabancaria
        );
        cambiodireccion(modeloupdatedata.informacion_contable.direccion);
        cambionombreTipodocumento(
          modeloupdatedata.informacion_contable.nombredocumento
        );
        cambioidcontable(modeloupdatedata.informacion_contable.id);
      }
    }
  }

  componentWillUnmount() {
    const { researchtrue } = this.props;
    researchtrue();
  }

  /* mandar informacion a el esado global de modelo*/
  cambioFotoPerfil = event => {
    const { guardarimagen } = this.props;
    const files = event.target.files[0];

    const data = new FormData();
    data.append("file0", files);
    guardarimagen(data);
  };

  cambioCedula = event => {
    this.props.cambioCedula(event.target.value);
  };

  cambioNombres = event => {
    this.props.cambioNombres(event.target.value);
  };

  cambioApellidos = event => {
    this.props.cambioApellidos(event.target.value);
  };

  cambioTelefono = event => {
    this.props.cambioTelefono(event.target.value);
  };

  cambioHorario = event => {
    this.props.cambioHorario(event.target.value);
  };
  cambioLugares = event => {
    this.props.cambioLugares(event.target.value);
  };

  cambioPasswordm = event => {
    this.props.cambioPasswordm(event.target.value);
  };

  cambioArriendo = event => {
    this.props.cambioArriendo(event.target.value);
  };

  cambioNick = event => {
    this.props.cambioNick(event.target.value);
  };

  cambiNombreRecibo = event => {
    this.props.cambiNombreRecibo(event.target.value);
  };

  cambioEstado = event => {
    this.props.cambioEstado(event.target.value);
  };

  listaHorarios = () => {
    const { ListaHorarios } = this.props;

    const poneropcioneshorarios = () =>
      ListaHorarios.map((horario, key) => (
        <option key={key} value={horario.id}>
          {" "}
          {horario.nombre}
        </option>
      ));

    return (
      <select
        className="form-element-field -hasvalue"
        name="select"
        onChange={this.cambioHorario}
        required
      >
        <option value={this.props.horarios_id}>
          {this.props.horarios_nombre}
        </option>
        {poneropcioneshorarios()}
      </select>
    );
  };

  listaTipoCedula = () => {
    const { tipodocumento } = this.props;

    const poneropcionesdocumento = () =>
      tipodocumento.map((documento, key) => (
        <option key={key} value={documento.id}>
          {" "}
          {documento.nombre}
        </option>
      ));

    return (
      <select
        className="form-element-field -hasvalue"
        name="select"
        onChange={this.cambioTipodocumento}
        required
      >
        <option value={this.props.documento_id}>
          {this.props.documento_nombre}
        </option>
        {poneropcionesdocumento()}
      </select>
    );
  };

  listaLugares = () => {
    const { ListaLugares } = this.props;

    const poneropcioneslugaress = () =>
      ListaLugares.map((lugares, key) => (
        <option key={key} value={lugares.id}>
          {" "}
          {lugares.nombre}
        </option>
      ));

    return (
      <select
        className="form-element-field -hasvalue"
        name="select"
        onChange={this.cambioLugares}
        required
      >
        <option value={this.props.lugares_id}>
          {this.props.lugares_nombres}
        </option>
        {poneropcioneslugaress()}
      </select>
    );
  };

  /* mandar informacion contable a el esado global de modelo*/
  cambioCedulacontable = event => {
    this.props.cambioCedulacontable(event.target.value);
  };

  cambioNombrescontable = event => {
    this.props.cambioNombrescontable(event.target.value);
  };

  cambioApellidoscontable = event => {
    this.props.cambioApellidoscontable(event.target.value);
  };

  cambiocuentabancaria = event => {
    this.props.cambiocuentabancaria(event.target.value);
  };
  cambiodireccion = event => {
    this.props.cambiodireccion(event.target.value);
  };

  cambioTipodocumento = event => {
    this.props.cambioTipodocumento(event.target.value);
  };

  /* mandar informacion contable a el esado global de modelo*/

  guardar = () => {
    const {
      lugares_id,
      plataforma_master_id,
      informacion_contable_id,
      horarios_id,
      cedula,
      nombres,
      apellidos,
      telefono,
      passwordm,
      nick_name,
      arriendo,
      nombre_recibo,
      foto_perfil,
      estado,
      agregar,
      cedulacontable,
      nombrescontable,
      apellidoscontable,
      cuentabancaria,
      direccion,
      documento_id
    } = this.props;
    const nuevomodelo = {
      horario_id: horarios_id,
      lugares_id: lugares_id,
      informacion_contable_id: informacion_contable_id,
      plataforma_master_id: plataforma_master_id,
      cedula: cedula,
      nombres: nombres,
      apellidos: apellidos,
      telefono: telefono,
      passwordm: passwordm,
      nick_name: nick_name,
      arriendo: arriendo,
      nombre_recibo: nombre_recibo,
      foto_perfil: foto_perfil,
      estado: estado,
      documento_contable: cedulacontable,
      nombrescontables: nombrescontable,
      apellidoscontables: apellidoscontable,
      tipo_documento_id: documento_id,
      cuentabancaria: cuentabancaria,
      direccion: direccion
    };
    /*  if (model_id  && plataform_id) {
 
           const tarea = tareas[usu_id][tar_id];
             const tarea_editada = {
                 ...nueva_tarea,
                 completed: tarea.completed,
                 id: tarea.id
             }
 
             editar (tarea_editada)
             
         }else{
            
         } */
    agregar(nuevomodelo);
  };

  habilitar = () => {
    this.setState({ editlable: false });
  };

  deshabilitar = () => {
    const {
      nombres,
      apellidos,
      cedula,
      lugares_id,
      horarios_id,
      estado
    } = this.props;

    if (
      !nombres ||
      !apellidos ||
      !lugares_id ||
      !horarios_id ||
      !estado ||
      !cedula
    ) {
      return true;
    }
    return false;
  };

  actualizar = e => {
    e.preventDefault();
    const {
      match: {
        params: { model_id }
      }
    } = this.props;

    const {
      lugares_id,
      plataforma_master_id,
      horarios_id,
      cedula,
      nombres,
      apellidos,
      telefono,
      passwordm,
      nick_name,
      arriendo,
      nombre_recibo,
      foto_perfil,
      estado,
      editar,
      editarcontable,
      cedulacontable,
      nombrescontable,
      apellidoscontable,
      cuentabancaria,
      direccion,
      documento_id,
      idcontable
    } = this.props;
    const Uploaddatoscuenta = {
      horario_id: horarios_id,
      lugares_id: lugares_id,
      plataforma_master_id: plataforma_master_id,
      informacion_contable_id: idcontable,
      cedula: cedula,
      nombres: nombres,
      apellidos: apellidos,
      telefono: telefono,
      passwordm: passwordm,
      arriendo: arriendo,
      nombre_recibo: nombre_recibo,
      nick_name: nick_name,
      foto_perfil: foto_perfil,
      estado: estado
    };

    const Uploaddatoscuentacontable = {
      documento_contable: cedulacontable,
      nombres: nombrescontable,
      apellidos: apellidoscontable,
      tipo_documento_id: documento_id,
      cuentabancaria: cuentabancaria,
      direccion: direccion
    };
    /*  if (model_id  && plataform_id) {
 
           const tarea = tareas[usu_id][tar_id];
             const tarea_editada = {
                 ...nueva_tarea,
                 completed: tarea.completed,
                 id: tarea.id
             }
 
             editar (tarea_editada)
             
         }else{
            
         } */
    editar(Uploaddatoscuenta, model_id);
    editarcontable(Uploaddatoscuentacontable, idcontable);
  };

  handleSubmit(e) {
    e.preventDefault();
  }

  Ponercuentas = () => {
 

    const {
      match: {
        params: { model_id }
      }
    } = this.props;

    return (
      <div className="row">
        <div className="col-md-4">
          <form className="form-card" onSubmit={e => this.handleSubmit(e)}>
            <fieldset className="form-fieldset">
              <div className="form-element form-input">
                <img
                  className="avatarModeloimg"
                  alt="imgagerfil"
                  src={`${URL}getImage/${
                    this.props.foto_perfil
                      ? this.props.foto_perfil
                      : "defaultLogo.png"
                  }`}
                />

                {/* <img className='avatarModeloimg' alt="Logo" src={`${URL}/getImage/defaultLogo.png`} /> */}
                <input
                  className="form-element-field -hasvalue"
                  placeholder=" "
                  type="file"
                  onChange={this.cambioFotoPerfil}
                />
                <div className="form-element-bar"></div>
                <label className="form-element-label">Foto de perfil</label>
              </div>
              {this.mostrarAccion()}
            </fieldset>
          </form>
          </div>

        <div className="col-md-4">
          <form className="form-card" onSubmit={e => this.handleSubmit(e)}>
            <fieldset className="form-fieldset">
              <div className="form-element form-input">
                <input
                  readOnly={model_id ? "" : ""}
                  className="form-element-field -hasvalue"
                  placeholder=" "
                  type="number"
                  defaultValue={this.props.cedulacontable}
                  onChange={this.cambioCedulacontable}
                  required
                />
                <div className="form-element-bar"></div>
                <label className="form-element-label">Cedula contable</label>
              </div>
              <div className="form-element form-input">
                <input
                  className="form-element-field -hasvalue"
                  placeholder=" "
                  type="text"
                  defaultValue={this.props.nombrescontable}
                  onChange={this.cambioNombrescontable}
                  required
                />
                <div className="form-element-bar"></div>
                <label className="form-element-label">Nombres Contables</label>
              </div>

              <div className="form-element form-input">
                <input
                  className="form-element-field -hasvalue"
                  placeholder=" "
                  type="text"
                  defaultValue={this.props.apellidoscontable}
                  onChange={this.cambioApellidoscontable}
                  required
                />
                <div className="form-element-bar"></div>
                <label className="form-element-label">
                  Apellidos Contables
                </label>
              </div>

              <div className="form-element form-input">
                <input
                  className="form-element-field -hasvalue"
                  placeholder=" "
                  type="number"
                  defaultValue={this.props.cuentabancaria}
                  onChange={this.cambiocuentabancaria}
                />
                <div className="form-element-bar"></div>
                <label className="form-element-label">Cuenta Bancaria</label>
              </div>

              <div className="form-element form-input">
                {/*    <input  className="form-element-field -hasvalue" placeholder=" " type="number" value={this.props.lugares_id}
            onChange={this.cambiocantidad}  /> */}
                {this.listaTipoCedula()}

                <div className="form-element-bar"></div>
                <label className="form-element-label">Tipo de Documento</label>
              </div>
              <div className="form-element form-input">
                <input
                  className="form-element-field -hasvalue"
                  placeholder=" "
                  type="text"
                  defaultValue={this.props.direccion}
                  onChange={this.cambiodireccion}
                />
                <div className="form-element-bar"></div>
                <label className="form-element-label">Direccion</label>
              </div>
            </fieldset>
          </form>
          </div>

        <div className="col-md-4">
          <form className="form-card" onSubmit={e => this.handleSubmit(e)}>
            <fieldset className="form-fieldset">
              <div className="form-element form-input">
                <input
                  readOnly={model_id ? "readonly" : ""}
                  className="form-element-field -hasvalue"
                  placeholder=" "
                  type="number"
                  defaultValue={this.props.cedula}
                  onChange={this.cambioCedula}
                  required
                />
                <div className="form-element-bar"></div>
                <label className="form-element-label">Cedula</label>
              </div>
              <div className="form-element form-input">
                <input
                  className="form-element-field -hasvalue"
                  placeholder=" "
                  type="text"
                  defaultValue={this.props.nombres}
                  onChange={this.cambioNombres}
                  required
                />
                <div className="form-element-bar"></div>
                <label className="form-element-label">Nombres</label>
              </div>

              <div className="form-element form-input">
                <input
                  className="form-element-field -hasvalue"
                  placeholder=" "
                  type="text"
                  defaultValue={this.props.apellidos}
                  onChange={this.cambioApellidos}
                  required
                />
                <div className="form-element-bar"></div>
                <label className="form-element-label">Apellidos</label>
              </div>

              <div className="form-element form-input">
                <input
                  className="form-element-field -hasvalue"
                  placeholder=" "
                  type="number"
                  defaultValue={this.props.telefono}
                  onChange={this.cambioTelefono}
                />
                <div className="form-element-bar"></div>
                <label className="form-element-label">Celular</label>
              </div>

              <div className="form-element form-input">
                {/*    <input  className="form-element-field -hasvalue" placeholder=" " type="number" value={this.props.lugares_id}
            onChange={this.cambiocantidad}  /> */}
                {this.listaHorarios()}

                <div className="form-element-bar"></div>
                <label className="form-element-label">Horarios</label>
              </div>

              <div className="form-element form-input">
                {/*    <input  className="form-element-field -hasvalue" placeholder=" " type="number" value={this.props.lugares_id}
            onChange={this.cambiocantidad}  /> */}
                {this.listaLugares()}

                <div className="form-element-bar"></div>
                <label className="form-element-label">Lugares</label>
              </div>

              <div className="form-element form-input">
                <input
                  className="form-element-field -hasvalue"
                  placeholder=" "
                  type="text"
                  defaultValue={this.props.passwordm}
                  onChange={this.cambioPasswordm}
                />
                <div className="form-element-bar"></div>
                <label className="form-element-label">Passwordmodelo</label>
              </div>

              <div className="form-element form-input">
                <input
                  className="form-element-field -hasvalue"
                  placeholder=" "
                  type="number"
                  defaultValue={this.props.arriendo}
                  onChange={this.cambioArriendo}
                />
                <div className="form-element-bar"></div>
                <label className="form-element-label">Arriendo</label>
              </div>
              <div className="form-element form-input">
                <input
                  className="form-element-field -hasvalue"
                  placeholder=" "
                  type="text"
                  defaultValue={this.props.nick_name}
                  onChange={this.cambioNick}
                />
                <div className="form-element-bar"></div>
                <label className="form-element-label">Nick</label>
              </div>

              <div className="form-element form-input">
                <input
                  className="form-element-field -hasvalue"
                  placeholder=" "
                  type="text"
                  defaultValue={this.props.nombre_recibo}
                  onChange={this.cambiNombreRecibo}
                />
                <div className="form-element-bar"></div>
                <label className="form-element-label">Nombre_recibo</label>
              </div>

              <div className="form-element form-input">
                <select
                  className="form-element-field -hasvalue"
                  name="select"
                  onChange={this.cambioEstado}
                  required
                >
                  <option value={this.props.estado}>
                    {this.props.estado ? "Activo" : "Inactivo"}
                  </option>
                  <option value="1">Activo</option>
                  <option value="0">Inactivo</option>
                </select>
                <div className="form-element-bar"></div>
                <label className="form-element-label">Estado</label>
              </div>
            </fieldset>

            {model_id ? (
              <div className="form-actions">
                <button
                  className="btnsaveaccountmodelcard"
                  type="submit"
                  onClick={this.actualizar}
                >
                  ACTUALLIZAR
                </button>
              </div>
            ) : (
              <div className="form-actions">
                <button
                  className="btnsaveaccountmodelcard"
                  type="submit"
                  onClick={this.guardar}
                  disabled={this.deshabilitar()}
                >
                  GUARDAR
                </button>
              </div>
            )}
          </form>
          </div>
      </div>
    );
  };

  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {
    console.log(this.props);
    return (
      <div className="main-content fade-in">
        {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
        <h1>REGISTRO DE MODELO</h1>
        {this.Ponercuentas()}
      </div>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.ModeloReducer;
};

export default connect(mapStateToProps, modeloaction)(Cuentas);
