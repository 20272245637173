import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as reportesModelosaction from '../../actions/ReportesModeloAction'
import Chaturbc from "../../img/chaturReportImg.png";
import Bongabc from "../../img/BongaReportImg.png";
import Stripbc from "../../img/StripchatReportImg.png";
import paypalbc from "../../img/paypalCuentaImg.png";
import flirtbc from "../../img/Flirt4FreeReportImg.png";
import Cam4bc from "../../img/Cam4ReportImg.png";
import Streamatebc from "../../img/StreamateReportImg.png";
import Totalbc from "../../img/TotalReportImg.png";
import Spinner from '../../General/Spinner'
import Fatal from '../../General/Fatal'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles.scss'
import Success from '../../General/Success'
import { Redirect } from 'react-router-dom'
import DatePicker from 'react-date-picker';
import moment from 'moment';

/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */









export class ReporteModelos extends Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: "",
      id: "",
      text: "",
      suggestVar: false,
      date: new Date(),
    };
  }


  componentDidMount() {

    if(!this.props.fecha1){

      this.cambiofecha1(new Date())
  }
  if(!this.props.fecha2){

      this.cambiofecha2(new Date())
  }
  }


  componentDidUpdate(prevState) {
    console.log(this.props)


  }

  componentWillUnmount() {

    const {
      researchtrue,
    } = this.props

    researchtrue()
  }


  filtrGanacias = () => {

    const {
      fecha1,
      fecha2,
      filtrarGananciasModelo,

    } = this.props
    const reprotesmodelo = {
      fecha_inicial: fecha1,
      fecha_final: fecha2,
      id_modelo: this.state.id,


    }



    filtrarGananciasModelo(reprotesmodelo)



  }

  handleSubmit(e) {
    e.preventDefault()
  }

  onTextChanged = e => {
    const value = e.target.value;
    let suggestions = [];

    const { consultarSuggestions } = this.props;
    const dataSuggestion = {
      data: value
    };
    if (value.length > 2) {
      this.setState(() => ({ suggestVar: true }));
      consultarSuggestions(dataSuggestion);
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      suggestions =
        inputLength === 0
          ? []
          : this.props.SuggestionDatabase.filter(
            lang =>
              lang.name.toLowerCase().slice(0, inputLength) === inputValue
          );
    } else {
      this.setState(() => ({ suggestVar: false }));
    }
    this.setState(() => ({ suggestions, text: value }));
  };

  suggestionSelected(value) {
    this.setState(() => ({
      text: value.name,
      id: value.id,
      suggestVar: false
    }));
  }

  renderSuggestions() {
    if (!this.state.suggestVar) {
      return null;
    }
    return (
      <div className="srchList">
        {this.props.SuggestionDatabase.map((item, key) => (
          <li key={key} onClick={() => this.suggestionSelected(item)}>
            {item.name}
          </li>
        ))}
      </div>
    );
  }


  cambiofecha1 = date => {
    this.setState({ dateStart: date })

    this.props.cambiofecha1((moment(date).format('YYYY/MM/DD') ));
  };

  cambiofecha2 = date => {
    this.setState({ dateEnd: date })
    var fechaFinalString = ''
    if (date) {
      fechaFinalString = ` ${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    } else {
      fechaFinalString = ''
    }
    this.props.cambiofecha2(fechaFinalString);
  };



  ponerReporte = () => {
    const { ganancias,

    } = this.props
    const ponerInfo = () => ganancias.map((reporte, key) => (
      <div className="row" key={key}>

        <div className="col-md-12">
          <div className='nombre__reporte__container'> <div className='nombre__div__reporte'> NOMBRE </div> <div className='triangulo__report__container'>  </div> <span className='nombre__modelo__div'> {reporte.nombres.toUpperCase()} </span>   </div>
        </div>
        <div className="col-md-3 chaturbate">

          <img alt='chaturbate img' src={Chaturbc} className='reportes__img__plataformas__Chaturbate'></img>
          <label className="laber__plataforma__1"> {reporte.tokens_plataforma1}  <span className='label__plataforma__text'>Tokens</span></label>
        <label className="laber__plataforma__2"> {reporte.tokens_plataforma1/20}  <span className='label__plataforma__text'>Dolares</span></label>
     
        </div>

        <div className="col-md-3 chaturbate">

<img alt='chaturbate img' src={Bongabc} className='reportes__img__plataformas__Chaturbate'></img>
<label className="laber__plataforma__1"> {reporte.tokens_plataforma2}  <span className='label__plataforma__text'>Tokens</span></label>
<label className="laber__plataforma__2"> {reporte.tokens_plataforma2}  <span className='label__plataforma__text'>Dolares</span></label>

</div>
        <div className="col-md-3 Stripchat">

        <img alt='strip img' src={Stripbc} className='reportes__img__plataformas__Stripchat'></img>
          <label className="laber__plataforma__1"> {reporte.tokens_plataforma5}  <span className='label__plataforma__text'>Tokens</span></label>
        <label className="laber__plataforma__2"> {reporte.tokens_plataforma5/20}  <span className='label__plataforma__text'>Dolares</span></label>
      
        </div>
        <div className="col-md-3 cam4">

        <img alt='cam4 img' src={Cam4bc} className='reportes__img__plataformas__cam4'></img>
          <label className="laber__plataforma__1"> {Math.round(reporte.tokens_plataforma4)}  <span className='label__plataforma__text'>Dolares</span></label>
        <label className="laber__plataforma__2"> {Math.round(reporte.tokens_plataforma4)}  <span className='label__plataforma__text'>Dolares</span></label>
      
        </div>
        <div className="col-md-3 Stremate">
        <img alt='streamate img' src={Streamatebc} className='reportes__img__plataformas__Streamate'></img>
        <label className="laber__plataforma__1"> {Math.round(reporte.tokens_plataforma3)}  <span className='label__plataforma__text'>Dolares</span></label>
        <label className="laber__plataforma__2"> {Math.round(reporte.tokens_plataforma3)}  <span className='label__plataforma__text'>Dolares</span></label>
      
        </div>

        <div className="col-md-3 Stremate">
        <img alt='paypal img' src={paypalbc} className='reportes__img__plataformas__Streamate'></img>
        <label className="laber__plataforma__1"> {Math.round(reporte.tokens_plataforma7)}  <span className='label__plataforma__text'>Dolares</span></label>
        <label className="laber__plataforma__2"> {Math.round(reporte.tokens_plataforma7)}  <span className='label__plataforma__text'>Dolares</span></label>
      
        </div>

        <div className="col-md-3 Stremate">
        <img alt='paypal img' src={flirtbc} className='reportes__img__plataformas__Streamate'></img>
        <label className="laber__plataforma__1"> {Math.round(reporte.tokens_plataforma8)}  <span className='label__plataforma__text'>Dolares</span></label>
        <label className="laber__plataforma__2"> {Math.round(reporte.tokens_plataforma8)}  <span className='label__plataforma__text'>Dolares</span></label>
      
        </div>
        <div className="col-md-12 total">
        <img alt='total img' src={Totalbc} className='reportes__img__plataformas__Total'></img>
        <label className="laber__plataforma__Total__1"> {Math.round(reporte.total)}  <span className='label__plataforma__text'>Dolares</span></label>
        <label className="laber__plataforma__Total__2">  <span className='label__plataforma__text'>Total</span></label>
      
        </div>
        
      </div>


    ))

    return (ponerInfo())
  }




  Ponerlfechasreporte = () => {
    const { text } = this.state;

    return <div><form onSubmit={(e) => this.handleSubmit(e)}>
      <fieldset className="form-fieldset">

        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-12 input">
              <input
                className="input__autocomplete__Text prestamosGlobar__text"
                value={text}
                onChange={this.onTextChanged}
                type="text"
                placeholder="Buscar Modelo"
                required
              />
            </div>
            <div className="col-md-12 justify-content-md-center input__autocomplete__SuggestionText">
              {this.renderSuggestions()}
            </div>
          </div>
        </div>
        <div>


          <DatePicker
            onChange={this.cambiofecha1}
            value={this.props.fecha1?new Date(this.props.fecha1):''}
          />
        </div>
        <div>
          <DatePicker
            onChange={this.cambiofecha2}
            value={this.props.fecha2?new Date(this.props.fecha2):''}
          />

        </div>
      </fieldset>

    </form>
    </div>
  }




  mostrarAccion = () => {


    const { error, cargando, success } = this.props
    if (cargando) {
      return <Spinner />;
    }


    if (success) {
      return <Success mensaje={success} />;
    }


    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  }




  render() {
    console.log(this.state)


    return (



      <Fragment>
        {(!window.localStorage.getItem('token')) ? <Redirect to='/' /> : ''}
        <div className='main-content fade-in'>
          <h1 className="tituloPaginas">
            REPORTES <strong className="tituloPaginasPequeño">MODELOS</strong>
          </h1>
          <div className='reporte__div__principal'>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 ">
                  {this.Ponerlfechasreporte()}
                  {this.mostrarAccion()}
                </div>

                <div className="col-md-2 center__reportes__botones">
                  <div className="btn__reportes_div">
                    <button className="btn__reportes_page" type="submit"
                      onClick={this.filtrGanacias} >Buscar</button>
                  </div>
                </div>

              </div>
            </div>


          </div>
          <div className=''>
            <div className="container-fluid">
             
                {this.ponerReporte()}
             
            </div>


          </div>
        </div>
      </Fragment>
    )
  }
}
const mapStateToProps = (reducers) => {
  return reducers.ReportesModeloReducer
}

export default connect(mapStateToProps, reportesModelosaction)(ReporteModelos)