import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as nominaaction from "../../actions/NominaAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";

/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */

export class NominaRecibos extends Component {


  state = {

  }

  componentDidMount() {

    const {
      match: {
        params: { fecha_id }
      },
      consultar_nomina_modelo_planilla,
    } = this.props
    consultar_nomina_modelo_planilla(fecha_id);


  }

  componentDidUpdate() {
    console.log(this.props);


  }

  componentWillUnmount() {
    const { researchtrue } = this.props;

    researchtrue();
  }

  handleSubmit(e) {
    e.preventDefault();
  }


  pagosNominaModelosBancaria = () => {
    
    const { pagosNominaModelosBancaria,

    } = this.props

  
    const ponerInfoArriendo = () => pagosNominaModelosBancaria.map((pagosNominaModelosBancaria, key) => (

      <tr key={key}>
        <td>{key} </td>
        <td>{pagosNominaModelosBancaria.cedula}</td>
        <td>{pagosNominaModelosBancaria.nombres} {pagosNominaModelosBancaria.apellidos}</td>
        <td>{pagosNominaModelosBancaria.cuentabancaria} </td>
        <td>{`$${pagosNominaModelosBancaria.total_pesos}`} </td>
        <td> </td>
        <td></td>

      


      </tr>
    ))
  
    return (ponerInfoArriendo())
  }


  pagosNominaModelosNoBancaria = () => {
    const { pagosNominaModelosNoBancaria,

    } = this.props

    const ponerInfoArriendo = () => pagosNominaModelosNoBancaria.map((pagosNominaModelosNoBancaria, key) => (

      <tr key={key}>
        <td>{key} </td>
        <td>{pagosNominaModelosNoBancaria.cedula}</td>
        <td>{pagosNominaModelosNoBancaria.nombres} {pagosNominaModelosNoBancaria.apellidos}</td>
        <td>{pagosNominaModelosNoBancaria.cuentabancaria} </td>
        <td>{`$${pagosNominaModelosNoBancaria.total_pesos}`} </td>
        <td> </td>
        <td></td>


     

      </tr>
    ))
    
    return (ponerInfoArriendo())
  }





  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {
    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            NÓMINA <strong className="tituloPaginasPequeño">RECIBOS PLANILLAS</strong>
          </h1>
          <div className="reporte__div__principal_pagonomina">
            <div className="container-fluid">
              <div className="row">



                <div className="col-md-12 ">

                  <table className="table table-bordered tabla-global" >
                    <thead>
                      <tr>
                        <th># </th>
                        <th>Cedula </th>
                        <th>Nombre</th>
                        <th>Cuenta Bancaria</th>
                        <th>Total a Pagar</th>
                        <th>Fecha</th>
                        <th>Firma del modelo</th>


                      </tr>
                    </thead>
                    <tbody>
                      {this.pagosNominaModelosBancaria()}

                      <tr>
                        <td></td>
                        <td><strong >SUBTOTAL</strong></td>
                        <td></td>
                        <td><strong >{this.props.pagosNominaModelosBancariaSum}</strong></td>
                        <td> </td>
                        <td> </td>
                        <td></td>


                      </tr>
                      
                      <tr>
                        <td></td>
                        <td><strong >PENDIENTES</strong></td>
                        <td></td>
                        <td></td>
                        <td> </td>
                        <td> </td>
                        <td></td>


                      </tr>
                      {this.pagosNominaModelosNoBancaria()}


                      <tr>
                        <td></td>
                        <td><strong >SUBTOTAL</strong></td>
                        <td></td>
                        <td><strong >{this.props.pagosNominaModelosNoBancariaSum}</strong></td>
                        <td> </td>
                        <td> </td>
                        <td></td>


                      </tr>

                      <tr>
                        <td></td>
                        <td><strong >TOTAL PAGAR</strong></td>
                        <td></td>
                        <td><strong >{this.props.pagosNominaModelosNoBancariaSum + this.props.pagosNominaModelosBancariaSum}</strong></td>
                        <td> </td>
                        <td> </td>
                        <td></td>


                      </tr>

                    </tbody>
                  </table>
                </div>

              </div>
              {this.mostrarAccion()}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.NominaReducer;
};

export default connect(mapStateToProps, nominaaction)(NominaRecibos);
