import fileDownload from 'js-file-download'
import axios from "axios";
import {
  CARGANDO,
  ERROR,
  CONSULTAR,
  CONSULTAR_LUGARES,
  CONSULTAR_TURNOS,
  IMAGEN_FIRMA,
  RESEARCHTRUE,
  CAMBIO_LUGAR_ID,
  CAMBIO_TURNO_ID,
  SUCCESS,
  IMAGEN_PERFIL,
  CONSULTAR_LISTA_BANCO,
  GALERIA_MODELO,
  GUARDAR,
  CAMBIO_BANCO,
  CAMBIO_CEDULA,
  CAMBIO_NOMBRES,
  CAMBIO_NICK_NAME,
  CAMBIO_TELEFONO,
  CAMBIO_APELLIDOS,
  CAMBIO_ARRIENDO,
  CAMBIO_PASSWORDM,
  CAMBIO_CEDULA_CONTABLE,
  CAMBIO_NOMBRES_CONTABLE,
  CAMBIO_APELLIDOS_CONTABLE,
  CAMBIO_DIRECCION,
  CAMBIO_CUENTA_BANCARIA,
  CAMBIO_CUENTA_CORREO,
  CAMBIO_CUENTA_PASSWORDCORREO,
  CAMBIO_ESTADO_CREAR,
  CAMBIO_ESTADO_INICIAL,
  CAMBIO_NOMBRE_FIRMA,
  ID_INFORMACION_CONTABLE,
  INFORMACION_CONTABLE,
  INFORMACION_CONTABLE_FLAG,
  CAMBIO_LUGAR_TRASNMISION,
  IMAGEN_GASTOS,
  CAMBIO_FECHA_INICIAL,
  CAMBIO_FECHA_FINAL,
  GASTOS_OBTENER,
  TOTAL_GASTOS_OBTENER
} from "../types/crearTypes";

//acciones para tareas
import {URL} from '../../src/General/url'

export const restaurar_cambio = () => async(dispatch)=>{
    dispatch({

        type: CAMBIO_ESTADO_INICIAL,
        payload: false
        
    
    })

}

export const editarInformacionContable = (data,model_id) => async(dispatch)=>{
   
    
  dispatch({

      type: CARGANDO
      
  
  })

  try {
    
      let json = JSON.stringify(data)
      let params = json;

        

      let headers = {
          "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.put(URL+`updateAccountantInformation/${model_id}`, params,{
          headers: headers
      })
   
if(respuesta.data.status==="Success"){
  dispatch({

      type: SUCCESS,
      payload: respuesta.data.message
      
  
  })
}

if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
      
/*    
      */
      
      
      
  } catch (error) {
      
      if(error.message==="Request failed with status code 401"){
          window.localStorage.setItem('token','')
          window.localStorage.setItem('userData','')
      }else{
  dispatch({

      type: ERROR,
      payload: 'Intente mas tarde - Cuenta Ocupada'
      
  
  })
  }
}


}



export const descargarImage = (filename) => async (dispatch) => {

    try {
  
  
        let headers = {
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL +  `getImageOriginal/${filename}`, {
            headers: headers,
            responseType: 'arraybuffer'
        })
      
        fileDownload(respuesta.data, filename)
  
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }


export const successVacio = (id) => async (dispatch) => {

    dispatch({
        type: GUARDAR,
      });
  }

export const consultarModeloInformacion = (id) => async (dispatch) => {

  dispatch({
    type: CARGANDO
  });

  try {

    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL +  `getModelsIdModify/${id}`, {
          headers: headers
      })


      if (respuesta.data.status === "success") {
        dispatch({
          type: CONSULTAR,
          payload: respuesta.data.data
        });
        dispatch({
          type: IMAGEN_PERFIL,
          payload: respuesta.data.data[0].foto_perfil
        });
        
      }

      if(respuesta.data.status==="Error"){
        dispatch({
    
            type: ERROR,
            payload: respuesta.data.message
            
        
        })

    }
  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}


export const consultarGastos = (fecha1,fecha2,id) => async (dispatch) => {

    dispatch({
      type: CARGANDO
    });
  
    try {
  
      let headers = {
          "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL +  `getExpenses/${fecha1}/${fecha2}/${id}`, {
            headers: headers
        })
  
  
        if (respuesta.data.status === "Success") {
          dispatch({
            type: GASTOS_OBTENER,
            payload: respuesta.data.data
          });
    
          dispatch({
            type: TOTAL_GASTOS_OBTENER,
            payload: respuesta.data.total.total
          });
          
        }
  
        if(respuesta.data.status==="Error"){
          dispatch({
      
              type: ERROR,
              payload: respuesta.data.message
              
          
          })
  
      }
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }

export const guardarimagen = (data) => async (dispatch) => {




  dispatch({

      type: CARGANDO


  })

  try {


      let params = data
      let headers = {
          'Content-Type': 'multipart/from-data',
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.post(URL + 'StoreImage', params, {
          headers: headers
      })

      dispatch({
        type: IMAGEN_PERFIL,
        payload: respuesta.data.imagename
      });
    

    /*   dispatch({
          type: CAMBIO_FOTO_PERFIL,
          payload: respuesta.data.imagename
      }) */

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}
export const cambiofecha1  = (fecha1) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA_INICIAL,
        payload: fecha1
        
    
    })
    
};

export const cambiofecha2  = (fecha2) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA_FINAL,
        payload: fecha2
        
    
    })
    
};


export const crearGasto = (data) => async (dispatch) => {




    dispatch({
  
        type: CARGANDO
  
  
    })
  
    try {
  
  
        let params = data
        let headers = {
            'Content-Type': 'multipart/from-data',
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL + 'createExpenses', params, {
            headers: headers
        })
  
        if (respuesta.data.status === "Success") {
            dispatch({

                type: SUCCESS,
                payload: respuesta.data.message


            })

            
        }
      
  
      /*   dispatch({
            type: CAMBIO_FOTO_PERFIL,
            payload: respuesta.data.imagename
        }) */
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }


export const guardarimagenGastos = (data) => async (dispatch) => {




    dispatch({
  
        type: CARGANDO
  
  
    })
  
    try {
  
  
        let params = data
        let headers = {
            'Content-Type': 'multipart/from-data',
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL + 'StoreImage', params, {
            headers: headers
        })
  
        dispatch({
          type: IMAGEN_GASTOS,
          payload: respuesta.data.imagename
        });
      
  
      /*   dispatch({
            type: CAMBIO_FOTO_PERFIL,
            payload: respuesta.data.imagename
        }) */
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }


export const validar_creacion_modelos = (data) => async (dispatch) => {




    dispatch({
  
        type: CARGANDO
  
  
    })
  
    try {
  
  
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL + 'validarCreacionModelo', params, {
            headers: headers
        })
        
        if(respuesta.data.status==="Success"){
            dispatch({
                type: CAMBIO_ESTADO_CREAR,
                payload: respuesta.data.data
              });
        }

        if(respuesta.data.status==="Error"){
            dispatch({
                type: CAMBIO_ESTADO_CREAR,
                payload: respuesta.data.data
              });

              dispatch({
                type: INFORMACION_CONTABLE,
                payload: respuesta.data.dataContable
              });

              dispatch({
                type: INFORMACION_CONTABLE_FLAG,
                payload: 1
              });

              dispatch({
  
                type: ERROR,
                payload: respuesta.data.message
  
  
            })
        }
    
      
  
      /*   dispatch({
            type: CAMBIO_FOTO_PERFIL,
            payload: respuesta.data.imagename
        }) */
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }

  export const consultar_banco = () => async (dispatch) => {


    try {


        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getTipeAccount', {
            headers: headers
        })




        dispatch({
            type: CONSULTAR_LISTA_BANCO,
            payload: respuesta.data.data
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })
        }

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}

  export const llenar_informacion_contable = (data) => async (dispatch) => {




    dispatch({
  
        type: CARGANDO
  
  
    })
  
    dispatch({
        type: CAMBIO_CEDULA_CONTABLE,
        payload: data.cedulaContable
      });

      dispatch({
        type: CAMBIO_NOMBRES_CONTABLE,
        payload: data.nombresContables
      });

      dispatch({
        type: CAMBIO_APELLIDOS_CONTABLE,
        payload: data.apellidosContable
      });
      dispatch({
        type: CAMBIO_DIRECCION,
        payload: data.direccion
      });
      dispatch({
        type: CAMBIO_CUENTA_BANCARIA,
        payload: data.cuentaBancaria
      });
      dispatch({
        type: CAMBIO_BANCO,
        payload: data.banco
      });

      dispatch({
        type: ID_INFORMACION_CONTABLE,
        payload: data.idInformacionContableModeloCreado
      });

      dispatch({
        type: INFORMACION_CONTABLE_FLAG,
        payload: 0
      });
   
  }

export const guardarimagenFirma = (data,filesLoca) => async (dispatch) => {

  
    try {
  
  
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL + 'storeFirma', params, {
            headers: headers
        })
  
        dispatch({
          type: IMAGEN_FIRMA,
          payload: filesLoca
        });

        dispatch({
            type: CAMBIO_NOMBRE_FIRMA,
            payload: respuesta.data.data
          });
      
  
      /*   dispatch({
            type: CAMBIO_FOTO_PERFIL,
            payload: respuesta.data.imagename
        }) */
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }



export const handleChange  = (dataType, value) => (dispatch) => {


if(dataType==='CAMBIO_CEDULA'){
    dataType=CAMBIO_CEDULA
}else if(dataType==='CAMBIO_NOMBRES'){
    dataType=CAMBIO_NOMBRES
}
else if(dataType==='CAMBIO_APELLIDOS'){
    dataType=CAMBIO_APELLIDOS
}
else if(dataType==='CAMBIO_TELEFONO'){
    dataType=CAMBIO_TELEFONO
}
else if(dataType==='CAMBIO_NICK_NAME'){
    dataType=CAMBIO_NICK_NAME
}
else if(dataType==='CAMBIO_ARRIENDO'){
    dataType=CAMBIO_ARRIENDO
}
else if(dataType==='CAMBIO_PASSWORDM'){
    dataType=CAMBIO_PASSWORDM
}
else if(dataType==='CAMBIO_CEDULA_CONTABLE'){
    dataType=CAMBIO_CEDULA_CONTABLE
}
else if(dataType==='CAMBIO_NOMBRES_CONTABLE'){
    dataType=CAMBIO_NOMBRES_CONTABLE
}
else if(dataType==='CAMBIO_APELLIDOS_CONTABLE'){
    dataType=CAMBIO_APELLIDOS_CONTABLE
}
else if(dataType==='CAMBIO_DIRECCION'){
    dataType=CAMBIO_DIRECCION
}
else if(dataType==='CAMBIO_CUENTA_BANCARIA'){
    dataType=CAMBIO_CUENTA_BANCARIA
}
else if(dataType==='CAMBIO_BANCO'){
    dataType=CAMBIO_BANCO
}
else if(dataType==='CAMBIO_CUENTA_CORREO'){
    dataType=CAMBIO_CUENTA_CORREO
}
else if(dataType==='CAMBIO_CUENTA_PASSWORDCORREO'){
    dataType=CAMBIO_CUENTA_PASSWORDCORREO
}

else if(dataType==='CAMBIO_LUGAR_TRASNMISION'){
    dataType=CAMBIO_LUGAR_TRASNMISION
}



    dispatch({
      type: dataType,
      payload:value
  })


  

 
};

export const crearModelo = (nuevomodelo) => async (dispatch) => {



    dispatch({

        type: CARGANDO


    })

    try {

        let json = JSON.stringify(nuevomodelo)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }


        const respuesta = await axios.post(URL + 'createModels', params, {
            headers: headers
        })
       
        if (respuesta.data.status === "Success") {
            dispatch({

                type: SUCCESS,
                payload: respuesta.data.message


            })

            dispatch({

                type: RESEARCHTRUE


            })

            
        }


        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }


    } catch (error) {

        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}
export const researchtrue = () => dispatch => {
  dispatch({
    type: RESEARCHTRUE
  });
};

export const consultar_turnos = () => async (dispatch) => {


  try {


    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL + 'getSchedules', {
          headers: headers
      })




      dispatch({
          type: CONSULTAR_TURNOS,
          payload: respuesta.data.data
      })

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}


export const consultar_lugares = () => async (dispatch) => {


  try {

    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL + 'getPlaces', {
          headers: headers
      })




      dispatch({
          type: CONSULTAR_LUGARES,
          payload: respuesta.data.data
      })

      if(respuesta.data.status==="Error"){
        dispatch({
    
            type: ERROR,
            payload: respuesta.data.message
            
        
        })

    }

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}

export const consultar_galeria_modelo = (id) => async (dispatch) => {


  try {


      let headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL + `getGalleryModel/${id}`, {
          headers: headers
      })



 if(respuesta.data.status==="Success"){
        dispatch({
      
            type: GALERIA_MODELO,
            payload: respuesta.data.data
            
        
        })
      }

      if(respuesta.data.status==="Error"){
        dispatch({
    
            type: ERROR,
            payload: respuesta.data.message
            
        
        })

    }

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}

export const cambioLugarId = (data) => (dispatch)=>{

  dispatch({
      type: CAMBIO_LUGAR_ID,
      payload: data
      
  })
}


export const cambioBancoId = (data) => (dispatch)=>{

    dispatch({
        type: CAMBIO_BANCO,
        payload: data
        
    })
  }

  export const cambioTurnoId = (data) => (dispatch)=>{

    dispatch({
        type: CAMBIO_TURNO_ID,
        payload: data
        
    })


}
