import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
import * as habitacionagregaraction from "../../actions/HabitacionesAgregarAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";

/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */
export class HabitacionesView360 extends Component {
  constructor(props) {
    super(props)
    this.viewer = React.createRef();


    this.state = {
      value: "",
      id: "",
      text: "",
      suggestVar: false,
    }
  }



  componentDidMount() {
    const {
      consultarRoomsReports
    } = this.props

    consultarRoomsReports()



  }

  componentDidUpdate() {
    console.log(this.props)
  }


  componentWillUnmount() {
    this.props.researchtrue()
  }

  handleSubmit(e) {
    e.preventDefault();
  }



  ponerHabitacionesReportes = () => {

    const { todasHabitacionesReportes } = this.props


    const ponerInfo = () =>
    todasHabitacionesReportes.map((habitacion_reporte, key) => (
        <tr key={key}>
          <td>{habitacion_reporte.habitacion_numero}</td>
          <td>{`${habitacion_reporte.nombres} ${habitacion_reporte.apellidos}`}</td>
          <td>{this.MaysPrimera(habitacion_reporte.horario.toLowerCase())}</td>
          <td>{habitacion_reporte.num_x}</td>
          <td>{habitacion_reporte.num_ok}</td>
          <td>{habitacion_reporte.num_no_aplica}</td>
        </tr>
      ));

    return ponerInfo();


  }



  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }






  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {

    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            ROOMS INVENTARIO  <strong className="tituloPaginasPequeño">REPORTES</strong>
          </h1>

          <div className="reporte__div__principal">


            {this.mostrarAccion()}
            <div className="container-fluid">
              <div className="row">

                <div className='col-md-10 mx-auto'>

                  <table className="table table-striped tabla-global">
                    <thead>
                      <tr>
                        <th>ROOM</th>
                        <th>MODELO</th>
                        <th>HORARIO </th>
                        <th>FALTANTE</th>
                        <th>CORRECTO</th>
                        <th>NO APLICA</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.ponerHabitacionesReportes()}
                    </tbody>
                  </table>
                </div>

              </div>



            </div>
          </div>




        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.HabitacionesAgregarReducer;
};

export default connect(mapStateToProps, habitacionagregaraction)(HabitacionesView360);
