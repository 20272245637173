import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as PrestamoPequenoMonitresAction from "../../actions/PrestamoPequenoMonitresAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import { FaTimes } from "react-icons/fa";
import { MdFileUpload } from "react-icons/md";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import DatePicker from "react-date-picker";
import moment from "moment";

// Imagine you have a list of languages that you'd like to autosuggest.

// Teach Autosuggest how to calculate suggestions for any given input value.

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.

export class PrestamosPequenosMonitores extends Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: "",
      id: "",
      text: "",
      suggestVar: false,
      date: new Date()
    };
  }
  componentDidMount() {
    this.props.consultarPrestamos();
    this.cambiofechaprestamo(this.state.date);
  }

  componentDidUpdate() {
    const { researchPrestamo, } = this.props;
   
    if(!researchPrestamo){
      this.props.consultarPrestamos();
    }
  }
  componentWillUnmount() {
    const { researchtrue, } = this.props;

    researchtrue();
  }

  /* 
          getSuggestions2 = value => {
            const inputValue = value.trim().toLowerCase();
            const inputLength = inputValue.length;
    
            var ArrayVar = inputLength === 0 ? [] : this.props.SuggestionDatabase.filter(lang =>
              lang.name.toLowerCase()=== inputValue
            );
           return ArrayVar[0] ? ArrayVar[0].id: []
           
          }; 
     */
  cambiofechaprestamo = date => {
    this.setState({ date: date });
  

    this.props.cambiofechaprestamo((moment(date).format('YYYY/MM/DD') ));
  };

  cambiofecha1 = event => {
    this.props.cambiofecha1(event.target.value);
  };

  cambiocantidad = event => {
    this.props.cambiocantidad(event.target.value);
  };

  cambioconcepto = event => {
    this.props.cambioconcepto(event.target.value);
  };

  guardar = () => {
    const {
   /*    match: {
        params: { model_id }
      }, */
      cantidad,
      fechaprestamo,
      concepto,
      agregar
    } = this.props;
    const prestamomodelo = {
      fecha: fechaprestamo,
      datos_id: this.state.id,
      concepto: concepto,
      cantidad: cantidad
    };

    /*  if (model_id  && plataform_id) {
 
           const tarea = tareas[usu_id][tar_id];
             const tarea_editada = {
                 ...nueva_tarea,
                 completed: tarea.completed,
                 id: tarea.id
             }
 
             editar (tarea_editada)
             
         }else{
            
         } */
    agregar(prestamomodelo);
  };


  handleEditarPrestamoSearch = (key,id, e) => {
    e.preventDefault();
    const { editarPrestamo } = this.props;
    const data = {
        cantidad: this.props.prestamos[key].cantidad,
        concepto: this.props.prestamos[key].concepto
       
    };
 
  
    editarPrestamo(data, id);
  

  }; 


  handleEditarPrestamo = (key,id, e) => {
    e.preventDefault();
    const { editarPrestamo } = this.props;
    const data = {
        cantidad: this.props.verperstamos[key].cantidad,
        concepto: this.props.verperstamos[key].concepto
       
    };
 
  
    editarPrestamo(data, id);
  

  }; 
  consultarPrestamosModelo = () => {
    const { consultar } = this.props;

    consultar(this.state.id);
  };

  handleSubmit(e) {
    e.preventDefault();
  }


  handleChange(index, dataType, value) {
    this.props.handleChange(index, dataType, value)

}


handleChangePrestamo(index, dataType, value){
  
  this.props.handleChangePrestamo(index, dataType, value)

}
  onTextChanged = e => {
    const value = e.target.value;
    let suggestions = [];

    const { consultarSuggestions } = this.props;
    const dataSuggestion = {
      data: value
    };
    if (value.length > 2) {
      this.setState(() => ({ suggestVar: true }));
      consultarSuggestions(dataSuggestion);
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      suggestions =
        inputLength === 0
          ? []
          : this.props.SuggestionDatabase.filter(
              lang =>
                lang.name.toLowerCase().slice(0, inputLength) === inputValue
            );
    } else {
      this.setState(() => ({ suggestVar: false }));
    }
    this.setState(() => ({ suggestions, text: value }));
  };

  suggestionSelected(value) {
    this.setState(() => ({
      text: value.name,
      id: value.id,
      suggestVar: false
    }));
  }

  renderSuggestions() {
    if (!this.state.suggestVar) {
      return null;
    }
    return (
      <div className="srchList">
        {this.props.SuggestionDatabase.map((item, key) => (
          <li key={key} onClick={() => this.suggestionSelected(item)}>
            {item.name}
          </li>
        ))}
      </div>
    );
  }

  handledelete(id, e) {
    e.preventDefault();
    const { eliminar } = this.props;

    eliminar(id);
  }

  renderPrestamos = () => {
    const { verperstamos } = this.props;
    const ponerInfo = () =>
      verperstamos.map((prestamo, key) => (
        <tr key={key}>
          <td>{`${prestamo.cedula}`}</td>
          <td>{`${prestamo.nombre} ${prestamo.apellidos}`}</td>
          <td>
                <textarea
                        type="text"
                        value={prestamo.concepto || ''}
                        onChange={(e) => this.handleChangePrestamo(key, 'concepto', e.target.value)}
                    /></td>
          <td>    <input
                        type="text"
                        value={prestamo.cantidad || ''}
                        onChange={(e) => this.handleChangePrestamo(key, 'cantidad', e.target.value)}
                    /></td>
          <td>{prestamo.fecha}</td>
          <td>
          <span
                className="loans__icon__x"
                onClick={e => this.handleEditarPrestamo(key,prestamo.id, e)}
              >
                <MdFileUpload
                
                  className="Home_Work_Stage__insideDiv__icon__x btn_action"
                />
              </span>
              <span
                className="loans__icon__x"
                onClick={e => this.handledelete(prestamo.id, e)}
              >
                <FaTimes
             
                  className="Home_Work_Stage__insideDiv__icon__x btn_action"
                />
              </span>
            
          </td>
        </tr>
      ));

    return ponerInfo();
  };

  renderPrestamosModelo = () => {
    const { prestamos } = this.props;
    const ponerInfo = () => (
      <div>
        <table className="table table-striped tabla-global">
          <thead>
            <tr>
            <th>CÉDULA</th>
              <th>NOMBRE</th>
              <th>CONCEPTO </th>
              <th>CANTIIDAD </th>
              <th>FECHA </th>
              <th>ACCIÓN </th>
            </tr>
          </thead>
          <tbody>
            {prestamos.map((prestamo, key) => (
              <tr key={key}>
                <td>{`${prestamo.cedula}`}</td>
                <td>{`${prestamo.nombre} ${prestamo.apellidos}`}</td>
                <td>
                <textarea
                        type="text"
                        value={this.props.prestamos[key].concepto || ''}
                        onChange={(e) => this.handleChange(key, 'concepto', e.target.value)}
                    /></td>
                <td>    <input
                        type="text"
                        value={this.props.prestamos[key].cantidad || ''}
                        onChange={(e) => this.handleChange(key, 'cantidad', e.target.value)}
                    /></td>
                <td>{prestamo.fecha}</td>
                <td>
              
                <span
                className="loans__icon__x"
                onClick={e => this.handleEditarPrestamoSearch(key,prestamo.id, e)}
              >
                <MdFileUpload
                
                  className="Home_Work_Stage__insideDiv__icon__x btn_action"
                />
              </span>
            
                    <span
                      className="loans__icon__x"
                      onClick={e => this.handledelete(prestamo.id, e)}
                    >
                      <FaTimes
                       
                        className="Home_Work_Stage__insideDiv__icon__x btn_action"
                      />
                    </span>
            
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );

    if (prestamos.length > 0) {
      return ponerInfo();
    } else {
      return "";
    }
  };

  Ponerlabelguardar = () => {
    const { text } = this.state;
    // Autosuggest will pass through all these props to the input.

    return (
      <form className="" onSubmit={e => this.handleSubmit(e)}>
        <fieldset className="form-fieldset">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-12 input">
                <input
                  className="input__autocomplete__Text prestamosGlobar__text"
                  value={text}
                  onChange={this.onTextChanged}
                  type="text"
                  placeholder="Buscar Monitor"
                  required
                />
              </div>
              <div className="col-md-12 justify-content-md-center input__autocomplete__SuggestionText">
                {this.renderSuggestions()}
              </div>
            </div>
          </div>

          <input
            id="field-x98ezh-s6s2g8-vfrkgb-ngrhef-atfkop"
            className="prestamosGlobar__text"
            placeholder="Cantidad"
            type="number"
            value={this.props.cantidad}
            onChange={this.cambiocantidad}
          />

          <textarea
            id="field-3naeph-0f3yuw-x153ph-dzmahy-qhkmgm"
            rows="6"
            className="prestamosGlobar__text"
            placeholder="Concepto del Préstamo"
            onChange={this.cambioconcepto}
            value={this.props.concepto}
          ></textarea>
          <div>
            <DatePicker
              className="prestamosGlobar__text"
              onChange={this.cambiofechaprestamo}
              value={this.state.date}
            />
          </div>
        </fieldset>
      </form>
    );
  };

  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {
    //   const { match: { params: {modelname } },   } = this.props

    console.log(this.props);

    return (
      <Fragment>
        <div className="main-content fade-in">
          <h1 className="tituloPaginas">
            PRÉSTAMOS <strong className="tituloPaginasPequeño">PEQUEÑOS MONITORES</strong>
          </h1>
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <div className="reporte__div__principal">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 ">
                  {this.Ponerlabelguardar()}
                  {this.mostrarAccion()}
                </div>

                <div className="col-md-2 center__reportes__botones">
                  <div className="btn__reportes_div">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={this.consultarPrestamosModelo}
                    >
                      Filtrar
                    </button>
                  </div>
                  <div className="btn__reportes_div">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={this.guardar}
                    >
                      Guardar
                    </button>
                  </div>
                </div>
                <div className="col-md-6 todos__prestamos__div ">
                  <table className="table table-striped tabla-global">
                    <thead>
                      <tr>
                       <th>CÉDULA</th>
                        <th>NOMBRE</th>
                        <th>CONCEPTO </th>
                        <th>CANTIIDAD </th>
                        <th>FECHA </th>
                        <th>ACCIÓN </th>
                      </tr>
                    </thead>
                    <tbody>{this.renderPrestamos()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {this.renderPrestamosModelo()}
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.PrestamoPequenoMonitoresReducer;
};

export default connect(mapStateToProps, PrestamoPequenoMonitresAction)(PrestamosPequenosMonitores);
