import axios from "axios";
import {
  CARGANDO,
  ERROR,
  CONSULTAR,
  IMAGEN_360,
  RESEARCHTRUE,
  SUCCESS,
  IMAGEN_PERFIL,
  CAMBIO_ROOM,
  CAMBIO_DESCRIPCION,
  CAMBIO_INVENTARIO,
  NEW_HABITACIONES,
  CONSULTARS,
  CONSULTAR_TURNOS,
  CAMBIO_TURNO_ID,
  CONSULTAR_MODELOS_HABITACION,
  RESEARCH_MODELOS_HABITACION,
  CONSULTAR_TODAS_HABITACIONES,
  CONSULTAR_TODAS_HABITACIONES_REPORTE,
  CONSULTAR_SEDES,
  OBTERNER_NUMERO_TOTAL_HABITACION
  
} from "../types/HabitacionesAgregarTypes";

//acciones para tareas

import {URL} from '../../src/General/url'



export const editarRooms = (data,model_id) => async(dispatch)=>{
   
    
  dispatch({

      type: CARGANDO
      
  
  })

  try {
    
      let json = JSON.stringify(data)
      let params = json;

        

        let headers = {
            "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.put(URL+`updateRoom/${model_id}`, params,{
          headers: headers
      })
   
if(respuesta.data.status==="Success"){
  dispatch({

      type: SUCCESS,
      payload: respuesta.data.message
      
  
  })
}

if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
      
/*    
      */
      
      
      
  } catch (error) {
      
      if(error.message==="Request failed with status code 401"){
          window.localStorage.setItem('token','')
          window.localStorage.setItem('userData','')
      }else{
  dispatch({

      type: ERROR,
      payload: error.message
      
  
  })
  }
}


}

export const consultar_sedes = () => async (dispatch) => {


    try {
  
  
      let headers = {
          "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getPlaces', {
            headers: headers
        })
  
  
  
  
        dispatch({
            type: CONSULTAR_SEDES,
            payload: respuesta.data.data
        })
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }

  export const contar_habitacion_sede = () => async (dispatch) => {


    try {
  
  
      let headers = {
          "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'countHabPlataform', {
            headers: headers
        })
  
  
  
   dispatch({
            type: OBTERNER_NUMERO_TOTAL_HABITACION,
            payload: respuesta.data.data
        })
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }



export const consultarRooms = (data) => async (dispatch) => {

  dispatch({
    type: CARGANDO
  });

  try {

 
    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL +  `getRooms/${data}`, {
          headers: headers
      })


      if (respuesta.data.status === "success") {
        dispatch({
          type: CONSULTAR,
          payload: respuesta.data.data
        });
        
      }

      if(respuesta.data.status==="Error"){
        dispatch({
    
            type: ERROR,
            payload: respuesta.data.message
            
        
        })

    }
  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}

export const consultarRoomsReports = () => async (dispatch) => {

    dispatch({
      type: CARGANDO
    });
  
    try {
  
        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL +  `getAllInventoryReport`, {
            headers: headers
        })
  
  
        if (respuesta.data.status === "Success") {
          dispatch({
            type: CONSULTAR_TODAS_HABITACIONES_REPORTE,
            payload: respuesta.data.data
          });
          
        }
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }
  


export const consultarAllRooms = (id) => async (dispatch) => {

    dispatch({
      type: CARGANDO
    });
  
    try {
  
        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL +  `getAllRoomModels/${id}`, {
            headers: headers
        })
  
  
        if (respuesta.data.status === "Success") {
          dispatch({
            type: CONSULTAR_TODAS_HABITACIONES,
            payload: respuesta.data.data
          });
          
        }
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
    
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }
export const consultar_modelo_habitacion = (id) => async (dispatch) => {


    try {
  

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + `getRoomModels/${id}`, {
            headers: headers
        })
  
  
  
   if(respuesta.data.status==="Success"){
          dispatch({
        
              type: CONSULTAR_MODELOS_HABITACION,
              payload: respuesta.data.data
              
          
          })
        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }
  
export const guardarimagen = (data) => async (dispatch) => {




  dispatch({

      type: CARGANDO


  })

  try {


      let params = data
      let headers = {
          'Content-Type': 'multipart/from-data',
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.post(URL + 'StoreImage', params, {
          headers: headers
      })

      dispatch({
        type: IMAGEN_PERFIL,
        payload: respuesta.data.imagename
      });
    

    /*   dispatch({
          type: CAMBIO_FOTO_PERFIL,
          payload: respuesta.data.imagename
      }) */

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}
export const guardarimagen360 = (data) => async (dispatch) => {

  
    try {
  
  
        let params = data
        let headers = {
            'Content-Type':'multipart/from-data',
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL + 'StoreImage', params, {
            headers: headers
        })
  
        dispatch({
          type: IMAGEN_360,
          payload: respuesta.data.imagename
        });
  
      /*   dispatch({
            type: CAMBIO_FOTO_PERFIL,
            payload: respuesta.data.imagename
        }) */
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }

  export const guardarimagenUpdate = (data,index,dataType) => async (dispatch,getState) => {


    const {habitaciones} = getState().HabitacionesAgregarReducer

    dispatch({
  
        type: CARGANDO
  
  
    })
  
    try {
  
  
        let params = data
        let headers = {
            'Content-Type': 'multipart/from-data',
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL + 'StoreImage', params, {
            headers: headers
        })
  




        const newhabitaciones = habitaciones.map((item, i) => {
            if (i === index) {
              return {...item, [dataType]: respuesta.data.imagename};
            }
            return item;
          });
      
    
          dispatch({
            type: NEW_HABITACIONES,
            payload:newhabitaciones
        })
    
   
      
  
      /*   dispatch({
            type: CAMBIO_FOTO_PERFIL,
            payload: respuesta.data.imagename
        }) */
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }
  export const guardarimagen360Update = (data,index,dataType) => async (dispatch,getState) => {
  
    const {habitaciones} = getState().HabitacionesAgregarReducer
      try {
    
    
          let params = data
          let headers = {
              'Content-Type': 'multipart/from-data',
              'Authorization': window.localStorage.getItem('token')
          }
          const respuesta = await axios.post(URL + 'StoreImage', params, {
              headers: headers
          })
    


          const newhabitaciones = habitaciones.map((item, i) => {
            if (i === index) {
              return {...item, [dataType]: respuesta.data.imagename};
            }
            return item;
          });
      
    
          dispatch({
            type: NEW_HABITACIONES,
            payload:newhabitaciones
        })
    
    
        /*   dispatch({
              type: CAMBIO_FOTO_PERFIL,
              payload: respuesta.data.imagename
          }) */
    
      } catch (error) {
    
    
          if (error.message === "Request failed with status code 401") {
              window.localStorage.setItem('token', '')
              window.localStorage.setItem('userData', '')
          } else {
              dispatch({
    
                  type: ERROR,
                  payload: error.message
    
    
              })
          }
      }
    }
export const handleChange  = (dataType, value) => (dispatch) => {


if(dataType==='CAMBIO_ROOM'){
    dataType=CAMBIO_ROOM
}else if(dataType==='CAMBIO_DESCRIPCION'){
    dataType=CAMBIO_DESCRIPCION
}
else if(dataType==='CAMBIO_INVENTARIO'){
    dataType=CAMBIO_INVENTARIO
}

    dispatch({
      type: dataType,
      payload:value
  })


  

 
};

export const crearHabitacion = (data) => async (dispatch) => {



    dispatch({

        type: CARGANDO


    })

    try {

        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }


        const respuesta = await axios.post(URL + 'createRoom', params, {
            headers: headers
        })
       
        if (respuesta.data.status === "Success") {
            dispatch({

                type: SUCCESS,
                payload: respuesta.data.message


            })



            
        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }



    } catch (error) {

        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}
export const researchtrue = () => dispatch => {
  dispatch({
    type: RESEARCHTRUE
  });
};

export const handleChangeUpdate  = (index, dataType, value) => (dispatch,getState) => {

    const {habitaciones} = getState().HabitacionesAgregarReducer

    const newhabitaciones = habitaciones.map((item, i) => {
        if (i === index) {
          return {...item, [dataType]: value};
        }
        return item;
      });
  

      dispatch({
        type: NEW_HABITACIONES,
        payload:newhabitaciones
    })


    

   
};

export const consultarSuggestions = (data) => async(dispatch)=>{
    


    try {

     
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
    
        const respuesta = await axios.post(URL+`getSuggestionModels`, params,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTARS,
            payload:respuesta.data.data,
        })

        
        
    } catch (error) {
        
    }
}

export const consultar_turnos = () => async (dispatch) => {


    try {
  
  

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getSchedules', {
            headers: headers
        })
  
  
  
  
        dispatch({
            type: CONSULTAR_TURNOS,
            payload: respuesta.data.data
        })
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }

  export const cambioTurnoId = (data) => (dispatch)=>{

    dispatch({
        type: CAMBIO_TURNO_ID,
        payload: data
        
    })


}

export const asignar_habitacion = (data) => async (dispatch) => {

    dispatch({

        type: RESEARCH_MODELOS_HABITACION,
        payload: true


    })

    dispatch({

        type: CARGANDO


    })

    try {

        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }


        const respuesta = await axios.post(URL + 'assignModelRoom', params, {
            headers: headers
        })
       
        if (respuesta.data.status === "Success") {
            dispatch({

                type: SUCCESS,
                payload: respuesta.data.message


            })


            dispatch({

                type: RESEARCH_MODELOS_HABITACION,
                payload: false


            })

            
        }


        if (respuesta.data.status === "Error") {
            dispatch({

                type: ERROR,
                payload: respuesta.data.message


            })

            dispatch({

                type: RESEARCH_MODELOS_HABITACION,
                payload: false


            })

        }



    } catch (error) {

        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}
export const crear_reporte_inventario_room = (data) => async (dispatch) => {



    dispatch({

        type: CARGANDO


    })

    try {

        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }


        const respuesta = await axios.post(URL + 'createInventoryRoomReport', params, {
            headers: headers
        })
       
        if (respuesta.data.status === "Success") {
            dispatch({

                type: SUCCESS,
                payload: respuesta.data.message


            })




            
        }else if(respuesta.data.status === "Error") {
            dispatch({

                type: ERROR,
                payload: respuesta.data.message


            })


        }






    } catch (error) {

        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}
export const clear_room = (id) => async (dispatch) => {
    dispatch({

        type: RESEARCH_MODELOS_HABITACION,
        payload: true


    })

    dispatch({

        type: CARGANDO


    })

    try {
        let params = 'json=' 
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': window.localStorage.getItem('token')
        }


        const respuesta = await axios.put(URL+`cleanRoom/${id}`, params,{
            headers: headers
        })
       
        if (respuesta.data.status === "Success") {
            dispatch({

                type: SUCCESS,
                payload: respuesta.data.message


            })

            dispatch({

                type: RESEARCH_MODELOS_HABITACION,
                payload: false
        
        
            })
        }


        if (respuesta.data.status === "Error") {
            dispatch({

                type: ERROR,
                payload: respuesta.data.message


            })

            dispatch({

                type: RESEARCH_MODELOS_HABITACION,
                payload: false
        
        
            })

        }



    } catch (error) {

        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}