import {
  CARGANDO,
  ERROR,
  GUARDAR,
  CONSUTARL_MODELO,
  RESEARCHTRUE,
  CAMBIO_FOTO_PERFIL,
  ACTUALIZAR,
  SUCCESS,
  CONSULTAR_HORARIOS,
  CONSULTAR_LUGARES,
  CAMBIO_LUGARES,
  CAMBIO_INFORMACION_CONTABLE,
  CAMBIO_HORARIOS,
  CAMBIO_CEDULA,
  CAMBIO_NOMBRES,
  CAMBIO_APELLIDOS,
  CAMBIO_TELEFONO,
  CAMBIO_PASSWORDM,
  CAMBIO_NICK_NAME,
  CAMBIO_ARRIENDO,
  CAMBIO_NOMBRE_RECIBO,
  CAMBIO_ESTADO,
  RESET_MODELOINFO,
  CAMBIO_NOMBRE_HORARIOS,
  CAMBIO_NOMBRE_LUGARES,
  CONSULTAR_DOCUMENTO,
  CAMBIO_CEDULA_CONTABLES,
  CAMBIO_NOMBRES_CONTABLES,
  CAMBIO_APELLIDOS_CONTBLES,
  CAMBIO_DOCUMENTO_CONTBLES,
  CAMBIO_DIRECCION_CONTBLES,
  CAMBIO_CUENTABANCARIA_CONTBLES,
  CAMBIO_NOMBRE_DOCUMENTO_CONTBLES,
  CAMBIO_ID_CONTBLES
} from "../types/ModeloTypes";

const INITIAL_STATE = {
  ModeloInfo: [],
  ListaLugares: [],
  ListaHorarios: [],
  tipodocumento: [],
  cargando: false,
  error: "",
  lugares_id: null,
  lugares_nombres: "",
  usuarios_id: "",
  plataforma_master_id: 1,
  informacion_contable_id: null,
  horarios_id: null,
  horarios_nombre: "",
  documento_id: null,
  documento_nombre: "",
  cedula: "",
  nombres: "",
  apellidos: "",
  telefono: "",
  passwordm: "",
  nick_name: "",
  arriendo: null,
  nombre_recibo: "",
  foto_perfil: "",
  estado: "",
  cedulacontable: "",
  nombrescontable: "",
  apellidoscontable: "",
  cuentabancaria: "",
  direccion: "",
  idcontable:null,
  research: true,
  success: "",
  inicial: true
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONSUTARL_MODELO:
      return {
        ...state,
        ModeloInfo: action.payload,
        cargando: false,
        research: false,
        inicial: false
      };

    case CARGANDO:
      return { ...state, cargando: true };

    case ERROR:
      return { ...state, error: action.payload, cargando: false, success: "" };

    case SUCCESS:
      return { ...state, success: action.payload, cargando: false, error: "" };

    case GUARDAR:
      return {
        ...state
      };

    case CAMBIO_LUGARES:
      return {
        ...state,
        lugares_id: action.payload
      };

    case CAMBIO_DOCUMENTO_CONTBLES:
      return {
        ...state,
        documento_id: action.payload
      };

      case CAMBIO_NOMBRE_DOCUMENTO_CONTBLES:
        return {
          ...state,
          documento_nombre: action.payload
        };


      
    case CAMBIO_DIRECCION_CONTBLES:
      return {
        ...state,
        direccion: action.payload
      };

    case CAMBIO_CUENTABANCARIA_CONTBLES:
      return {
        ...state,
        cuentabancaria: action.payload
      };

    case CAMBIO_INFORMACION_CONTABLE:
      return {
        ...state,
        informacion_contable_id: action.payload
      };

    case CAMBIO_HORARIOS:
      return {
        ...state,
        horarios_id: action.payload
      };

    case CAMBIO_NOMBRE_HORARIOS:
      return {
        ...state,
        horarios_nombre: action.payload
      };

      case CAMBIO_ID_CONTBLES:
      return {
        ...state,
        idcontable: action.payload
      };

      

    case CAMBIO_NOMBRE_LUGARES:
      return {
        ...state,
        lugares_nombres: action.payload
      };

    case CONSULTAR_DOCUMENTO:
      return {
        ...state,
        tipodocumento: action.payload
      };

    case CAMBIO_CEDULA:
      return {
        ...state,
        cedula: action.payload,
        inicial: false
      };

    case CAMBIO_CEDULA_CONTABLES:
      return {
        ...state,
        cedulacontable: action.payload,
        inicial: false
      };

    case CAMBIO_NOMBRES:
      return {
        ...state,
        nombres: action.payload
      };

    case CAMBIO_NOMBRES_CONTABLES:
      return {
        ...state,
        nombrescontable: action.payload
      };
    case CAMBIO_APELLIDOS:
      return {
        ...state,
        apellidos: action.payload
      };

    case CAMBIO_APELLIDOS_CONTBLES:
      return {
        ...state,
        apellidoscontable: action.payload
      };

    case CAMBIO_TELEFONO:
      return {
        ...state,
        telefono: action.payload
      };

    case CAMBIO_PASSWORDM:
      return {
        ...state,
        passwordm: action.payload
      };

    case CAMBIO_NICK_NAME:
      return {
        ...state,
        nick_name: action.payload
      };

    case CAMBIO_ARRIENDO:
      return {
        ...state,
        arriendo: action.payload
      };

    case CAMBIO_NOMBRE_RECIBO:
      return {
        ...state,
        nombre_recibo: action.payload
      };

    case CAMBIO_FOTO_PERFIL:
      return {
        ...state,
        foto_perfil: action.payload,
        cargando: false
      };

    case CAMBIO_ESTADO:
      return {
        ...state,
        estado: action.payload
      };

    case CONSULTAR_HORARIOS:
      return {
        ...state,
        ListaHorarios: action.payload
      };

    case CONSULTAR_LUGARES:
      return {
        ...state,
        ListaLugares: action.payload
      };

    case ACTUALIZAR:
      return {
        ...state,
        cuentas: [],
        cargando: false,
        error: "",
        user_cuenta: "",
        password_cuenta: "",
        research: true
      };

    case RESET_MODELOINFO:
      return {
        ...state,
        ModeloInfo: []
      };

    case RESEARCHTRUE:
      return {
        ...state,
        error: "",
        ModeloInfo: [],
        ListaLugares: [],
        ListaHorarios: [],
        lugares_id: "",
        lugares_nombres: "",
        usuarios_id: "",
        plataforma_master_id: 1,
        informacion_contable_id: null,
        horarios_id: "",
        horarios_nombre: "",
        cedula: "",
        nombres: "",
        apellidos: "",
        telefono: "",
        passwordm: "",
        nick_name: "",
        arriendo: null,
        nombre_recibo: "",
        foto_perfil: "",
        estado: "",
        research: true,
        success: "",
        inicial: true,
        cedulacontable: "",
        nombrescontable: "",
        apellidoscontable: "",
        cuentabancaria: "",
        direccion: "",
        idcontable:null,
        documento_id: null,
        documento_nombre:''
     
      };

    default:
      return state;
  }
};
