import {
    CARGANDO, ERROR, GUARDAR, CONSULTAR, CONSULTARS, CONSULTAR_TOTALES, CAMBIO_FECHAPRESTAMO, CAMBIO_CANTIDAD, RESEARCHTRUE, CAMBIO_CONCEPTO
    , SUCCESS, CAMBIO_FECHA1, CAMBIO_FECHA2, FILTRAR_PRESTAMOS_MINIMOS,RESEARPRESTAMO,CONSULTAR_SERVICIOS,CONSULTAR_SERVICIOS_MODELO
} from '../types/PrestamoGlobalTypes'

const INITIAL_STATE = {
    prestamos: [],
    servicios: [],
    ListaServicios:[],
    verperstamos: [],
    verperstamosModelo: [],
    SuggestionDatabase: [],
    cargando: false,
    error: '',
    cantidad: '',
    fechaprestamo: '',
    fecha1: '',
    fecha2: '',
    modelos_id: '',
    concepto: '',
    research: '',
    researchPrestamo: true,
    success: ''

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTAR:
            return {
                ...state,
                prestamos: action.payload,
                cargando: false,
                research: false,
                

            }

            case CONSULTAR_SERVICIOS_MODELO:
                return {
                    ...state,
                    servicios: action.payload,
                    cargando: false,
                    research: false,
                    
    
                }

            case CONSULTAR_SERVICIOS:
                return {
                    ...state,
                    ListaServicios: action.payload,
                    cargando: false,
                    research: false,
                    
    
                }

        case CONSULTAR_TOTALES:
            return {
                ...state,
                verperstamos: action.payload,
                cargando: false,
                research: false,
                cantidad: 0,
                fechaprestamo: '',
                concepto: '',
               
            }

        case CONSULTARS:
            return {
                ...state,
                SuggestionDatabase: action.payload,

            }
            case RESEARPRESTAMO:
                return {
                    ...state,
                    researchPrestamo: action.payload,
    
                }        

            

        case FILTRAR_PRESTAMOS_MINIMOS:
            return {
                ...state,
                verperstamos: action.payload,
                researchPrestamo: true,


            }



        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false, success: '' }


        case SUCCESS:
            return { ...state, success: action.payload, cargando: false, error: '' }


        case GUARDAR:
            return {
                ...state,
                cargando: false,
                error: '',
                research: true,
                success: ''


            }



        case CAMBIO_FECHAPRESTAMO:
            return {
                ...state,
                fechaprestamo: action.payload,

            }

        case CAMBIO_FECHA1:
            return {
                ...state,
                fecha1: action.payload,

            }

        case CAMBIO_FECHA2:
            return {
                ...state,
                fecha2: action.payload,

            }



        case CAMBIO_CANTIDAD:
            return {
                ...state,
                cantidad: action.payload,

            }

        case CAMBIO_CONCEPTO:
            return {
                ...state,
                concepto: action.payload,

            }

        case RESEARCHTRUE
            :
            return {
                ...state,

                prestamos: [],
                verperstamos: [],
                verperstamosModelo:[],
                cargando: false,
                error: '',
                cantidad: 0,
                modelos_id: '',
                concepto: '',
                success: '',
                research: '',
                researchPrestamo: true,



            }


        default: return state
    }

}