import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as archivoPagos from '../../actions/ArchivoPagosAction'
import Spinner from '../../General/Spinner'
import Fatal from '../../General/Fatal'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles.scss'
import Success from '../../General/Success'
import { Redirect } from 'react-router-dom'
import DatePicker from 'react-date-picker';
import moment from 'moment'


/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */









export class ArchivoPagos extends Component {

    state = {
        dateStart: new Date(),
        dateEnd: new Date(),
        banco:''
    }

    componentDidMount() {
        const {

            consultar_banco,
      
          } = this.props
        this.cambiofecha1(this.state.dateStart)
        this.cambiofecha2(this.state.dateEnd)
        consultar_banco()


    }


    componentDidUpdate(prevState) {
        console.log(this.props)


    }


    descargarExcelModelosReport=(e,id)=>{
        e.preventDefault();
        const {
            fecha1,
            fecha2,
            lugares_id,

        } = this.props
        const data = {
            fecha_inicial: fecha1,
            fecha_final: fecha2,
            lugares_id: lugares_id


        }
        this.props.descargarExcelModelosReport(data)
      }

    componentWillUnmount() {

        const {
            researchtrue,
        } = this.props

        researchtrue()
    }


    cambioLugares = (event) => {
        this.props.cambioLugares(event.target.value)
    }

    filtrapretamosminimos = () => {

        const {
            fecha1,
            fecha2,
            descargarArchivoPago,

        } = this.props
        const reprotesmodelo = {
            fecha_inicial: fecha1,
            fecha_final: fecha2,
            banco:this.state.banco
          


        }



        descargarArchivoPago(reprotesmodelo)



    }
    descargarArchivoPInscripcion = () => {

        const {
            fecha1,
            fecha2,
            descargarArchivoPInscripcion,

        } = this.props
        const reprotesmodelo = {
            fecha_inicial: fecha1,
            fecha_final: fecha2,
            banco:this.state.banco
          


        }



        descargarArchivoPInscripcion(reprotesmodelo)



    }

    

    handleSubmit(e) {
        e.preventDefault()
    }


    listaLugares = () => {
        const { ListaLugares } = this.props;

        const poneropcioneslugaress = () =>
            ListaLugares.map((lugares, key) => (
                <option className='option-values' key={key} value={lugares.id}>
                    {lugares.nombre}
                </option>
            ));

        return (
            <select
                className="right-submenu"
                name="select"
                onChange={this.cambioLugares}
                required
            >
                <option className='option-values'>
                    Horarios
                </option>
                {poneropcioneslugaress()}
            </select>
        );
    };

    cambiofecha1 = date => {
        this.setState({ dateStart: date })
            var fechaFinalString = ''
        if (date) {
            fechaFinalString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        } else {
            fechaFinalString = ''
        }
        this.props.cambiofecha1(fechaFinalString);
    };

    cambiofecha2 = date => {
        this.setState({ dateEnd: date })
        var fechaFinalString = ''
        if (date) {
            fechaFinalString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        } else {
            fechaFinalString = ''
        }
        this.props.cambiofecha2(fechaFinalString);
    };

    listaLugares = () => {
        const { ListaLugares } = this.props;
    
        const ponerOpcionesLugares = () =>
        ListaLugares.map((lugar, key) => (
            <option className='option-values' key={key} value={lugar.id}>
              {lugar.nombre}{}
            </option>
          ));
    
        return (
          <select
            style={
               {
                border: 'none',
                borderRadius: '5px',
                fontWeight: '500',
                width: '100%',
                marginTop: '10px',
               }
            }
            name="select"
            onChange={e=>{this.setState({ banco: e.target.value  })}}
            required
          >
            <option className='option-values' >
              Banco
            </option>
            {ponerOpcionesLugares()}
          </select>
        );
      };


    Ponerlfechasreporte = () => {


        return <div><form onSubmit={(e) => this.handleSubmit(e)}>
            <fieldset className="form-fieldset">
                <div>
                  Fehca Aplicación

                    <DatePicker
                        onChange={this.cambiofecha1}
                        value={this.state.dateStart}
                    />
                </div>
                <div>
                Fecha pago
                    <DatePicker
                        onChange={this.cambiofecha2}
                        value={this.state.dateEnd}
                    />

                </div>
        
            </fieldset>

        </form>
        </div>
    }




    mostrarAccion = () => {


        const { error, cargando, success } = this.props
        if (cargando) {
            return <Spinner />;
        }


        if (success) {
            return <Success mensaje={success} />;
        }


        if (error) {
            return <Fatal mensaje={error} />;
        }
        return false;
    }




    render() {



        return (



            <Fragment>
                {(!window.localStorage.getItem('token')) ? <Redirect to='/' /> : ''}
                <div className='main-content fade-in'>
                <h1 className="tituloPaginas">
                PERFIL <strong className="tituloPaginasPequeño">ARCHIVOS</strong>
          </h1>
                    <div className='reporte__div__principal'>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4" style={{marginBottom:"1%"}}>
                                    {this.Ponerlfechasreporte()}
                                    {this.mostrarAccion()}
                                </div>

                                <div className="col-md-2 center__reportes__botones">
                                    <div className="btn__reportes_div">
                                        <button className="btn__reportes_page" type="submit"
                                            onClick={this.filtrapretamosminimos} >Pagos</button>
                                    </div>
                                    <div className="btn__reportes_div">
                                        <button className="btn__reportes_page" type="submit"
                                            onClick={this.descargarArchivoPInscripcion} >Inscripcción</button>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                 
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = (reducers) => {
    return reducers.ArchivoPagosReducer
}

export default connect(mapStateToProps, archivoPagos)(ArchivoPagos)