import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as reportesModelosaction from '../../actions/ReportesModeloAction'
import Spinner from '../../General/Spinner'
import Fatal from '../../General/Fatal'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles.scss'
import Success from '../../General/Success'
import { Redirect } from 'react-router-dom'
import DatePicker from 'react-date-picker';
import { MdFileUpload } from "react-icons/md";
import moment from 'moment'

/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */









export class ReporteModelosDia extends Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: "",
      id: "",
      text: "",
      suggestVar: false,
      date: new Date(),

    };
  }


  componentDidMount() {



  }


  componentDidUpdate(prevState) {
    console.log(this.props)


  }

  componentWillUnmount() {

    const {
      researchtrue,
    } = this.props

    researchtrue()
  }


  filtrarDia = () => {

    const {
      fecha1,
      fecha2,
      filtrarDia,

    } = this.props
    const reprotesmodelo = {
      fecha_inicial: fecha1,
      fecha_final: fecha2,
      id_modelo: this.state.id,


    }
    filtrarDia(reprotesmodelo)
  }

  handleChange(index, dataType, value) {
    this.props.handleChange(index, dataType, value)
  }

  handleSubmit(e) {
    e.preventDefault()
  }

  onTextChanged = e => {
    const value = e.target.value;
    let suggestions = [];

    const { consultarSuggestions } = this.props;
    const dataSuggestion = {
      data: value
    };
    if (value.length > 2) {
      this.setState(() => ({ suggestVar: true }));
      consultarSuggestions(dataSuggestion);
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      suggestions =
        inputLength === 0
          ? []
          : this.props.SuggestionDatabase.filter(
            lang =>
              lang.name.toLowerCase().slice(0, inputLength) === inputValue
          );
    } else {
      this.setState(() => ({ suggestVar: false }));
    }
    this.setState(() => ({ suggestions, text: value }));
  };

  suggestionSelected(value) {
    this.setState(() => ({
      text: value.name,
      id: value.id,
      suggestVar: false
    }));
  }

  renderSuggestions() {
    if (!this.state.suggestVar) {
      return null;
    }
    return (
      <div className="srchList">
        {this.props.SuggestionDatabase.map((item, key) => (
          <li key={key} onClick={() => this.suggestionSelected(item)}>
            {item.name}
          </li>
        ))}
      </div>
    );
  }

  

  handleEditar = (id, e,key) => {
    const { editar } = this.props;
    const data = {
      tokens_plataforma1: this.props.reporteModelos[key].tokens_plataforma1,
      tokens_plataforma2: this.props.reporteModelos[key].tokens_plataforma2,
      tokens_plataforma3: this.props.reporteModelos[key].tokens_plataforma3,
      tokens_plataforma5: this.props.reporteModelos[key].tokens_plataforma5,
      tokens_plataforma4: this.props.reporteModelos[key].tokens_plataforma4,
      tokens_plataforma7: this.props.reporteModelos[key].tokens_plataforma7,
      tokens_plataforma8: this.props.reporteModelos[key].tokens_plataforma8,
    };
    e.preventDefault();

    editar(data, id);


  };
  cambiofecha1 = date => {
    this.setState({ dateStart: date })
  
    this.props.cambiofecha1((moment(date).format('YYYY/MM/DD') ));
  };

  cambiofecha2 = date => {
    this.setState({ dateEnd: date })
    var fechaFinalString = ''
    if (date) {
      fechaFinalString = ` ${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    } else {
      fechaFinalString = ''
    }
    this.props.cambiofecha2(fechaFinalString);
  };



  ponerReporte = () => {
    const { reporteModelos,

    } = this.props
    const ponerInfo = () => reporteModelos.map((reporte, key) => (
      <tr key={key}>
      <td>{`${reporte.fecha}`}</td>
      <td>{`${reporte.nombres} ${reporte.apellidos}`}</td>
      <td>  <input
        type="text"
        value={reporte.tokens_plataforma1 || ''}
        onChange={(e) => this.handleChange(key, 'tokens_plataforma1', e.target.value)}
      /></td>
         <td>  <input
        type="text"
        value={reporte.tokens_plataforma2 || ''}
        onChange={(e) => this.handleChange(key, 'tokens_plataforma2', e.target.value)}
      /></td>
      <td>    <input
        type="text"
        value={reporte.tokens_plataforma5 || ''}
        onChange={(e) => this.handleChange(key, 'tokens_plataforma5', e.target.value)}
      /></td>
       <td>  <input
        type="text"
        value={reporte.tokens_plataforma4 || ''}
        onChange={(e) => this.handleChange(key, 'tokens_plataforma4', e.target.value)}
      /></td>
       <td>  <input
        type="text"
        value={reporte.tokens_plataforma3 || ''}
        onChange={(e) => this.handleChange(key, 'tokens_plataforma3', e.target.value)}
      /></td>
       <td>  <input
        type="text"
        value={reporte.tokens_plataforma7 || ''}
        onChange={(e) => this.handleChange(key, 'tokens_plataforma7', e.target.value)}
      /></td>
     <td>  <input
        type="text"
        value={reporte.tokens_plataforma8 || ''}
        onChange={(e) => this.handleChange(key, 'tokens_plataforma8', e.target.value)}
      /></td>
      <td>
        {<Fragment>
         {this.props.permiso?
                <span
                className="loans__icon__x"
                onClick={e => this.handleEditar(reporte.id, e,key)}
              >
                <MdFileUpload
                  size={"1vw"}
                  className="Home_Work_Stage__insideDiv__icon__x"
                />
              </span>:""}
    
         
        </Fragment>
        }
      </td>
    </tr>

    ))

    return (ponerInfo())
  }

  descargarExcelModelosDiaReport=(e)=>{
    e.preventDefault();
    const {
        fecha1,
        fecha2,
     

    } = this.props
    const data = {
        fecha_inicial: fecha1,
        fecha_final: fecha2,
        modelo_id: this.state.id


    }
    this.props.descargarExcelModelosDiaReport(data)
  }


  Ponerlfechasreporte = () => {
    const { text } = this.state;

    return <div><form onSubmit={(e) => this.handleSubmit(e)}>
      <fieldset className="form-fieldset">

        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-12 input">
              <input
                className="input__autocomplete__Text prestamosGlobar__text"
                value={text}
                onChange={this.onTextChanged}
                type="text"
                placeholder="Buscar Modelo"
                required
              />
            </div>
            <div className="col-md-12 justify-content-md-center input__autocomplete__SuggestionText">
              {this.renderSuggestions()}
            </div>
          </div>
        </div>
        <div>


          <DatePicker
            onChange={this.cambiofecha1}
            value={this.props.fecha1?new Date(this.props.fecha1):''}
          />
        </div>
        <div>
          <DatePicker
            onChange={this.cambiofecha2}
            value={this.props.fecha2?new Date(this.props.fecha2):''}
          />

        </div>
      </fieldset>

    </form>
    </div>
  }




  mostrarAccion = () => {


    const { error, cargando, success } = this.props
    if (cargando) {
      return <Spinner />;
    }


    if (success) {
      return <Success mensaje={success} />;
    }


    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  }




  render() {
    console.log(this.state)


    return (



      <Fragment>
        {(!window.localStorage.getItem('token')) ? <Redirect to='/' /> : ''}
        <div className='main-content fade-in'>
          <h1 className="tituloPaginas">
            REPORTES <strong className="tituloPaginasPequeño">MODELOS</strong>
          </h1>
          <div className='reporte__div__principal'>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 ">
                  {this.Ponerlfechasreporte()}
                  {this.mostrarAccion()}
                </div>

                <div className="col-md-2 center__reportes__botones">
                  <div className="btn__reportes_div">
                    <button className="btn__reportes_page" type="submit"
                      onClick={this.filtrarDia} >Buscar</button>
                  </div>
                  <div className="btn__reportes_div">
                                        <button className="btn__reportes_page" type="submit"
                                            onClick={this.descargarExcelModelosDiaReport} >Descargar Excel</button>
                                    </div>
                </div>

              </div>
            </div>


          </div>
          <div className=''>
                        <div className="container-fluid">
                            <div className="row">
                           
                                <div className="col-md-12 ">

                                    <table  className="table table-striped tabla-global" >
                                        <thead>
                                            <tr>
                                                <th>FECHA </th>
                                                <th>NOMBRE</th>
                                                <th>CHATURBATE</th>
                                                <th>BONGA</th>
                                                <th>STRIPCHAT</th>
                                                <th>CAM4</th>
                                                <th>STREAMATE</th>
                                                <th>PAYPAL</th>
                                                <th>FLIRT4FREE</th>

                                                {this.props.permiso===2? <th>ACCIÓN</th>:""}
                                               
                

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.ponerReporte()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                    </div>
        </div>
      </Fragment>
    )
  }
}
const mapStateToProps = (reducers) => {
  return reducers.ReportesModeloReducer
}

export default connect(mapStateToProps, reportesModelosaction)(ReporteModelosDia)