
export const CARGANDO = 'cuentas_cargando';
export const CARGANDO_CUENTAS = 'cuentas2_cargando';
export const ERROR = 'cuentas_error';
export const CAMBIO_USUARIO_ID = 'cuentas_cambio_usuario_id';
export const CAMBIO_PASSWORD = 'cuentas_cambio_password';
export const GUARDAR = 'cuentas_guardada';
export const ACTUALIZAR = 'cuentas_actualizar';
export const LIMPIAR = 'cuentas_limpiar';
export const RESEARCHTRUE = 'cuentas_research';
export const SUCCESS = 'cuentas_succes';
export const CONSULTAR_NOMBRE_MODELO = 'cuentas_nombre_modelo';
export const CONSULTAR_CUENTAS_CHATURBATE = 'cuentas_chaturbate_modelo';
export const CONSULTAR_CUENTAS_STRIPCHAT = 'cuentas_stripchat_modelo';
export const CONSULTAR_CUENTAS_BONGA = 'cuentas_bonga_cams_modelo';
export const CONSULTARS = 'cuentas_traer_todas_suggestions_emails';
export const CORREOS_CUENTAS = 'cuentas_correos_vinculados';
export const CONSULTAR_CUENTAS_CAM4 = 'cuentas_cam4_modelo';
export const CONSULTAR_CUENTAS_STREAMATE = 'cuentas_stremate_modelo';
export const CONSULTAR_CUENTAS_FLIRT = 'cuentas_flirt_modelo';
export const CUENTA_CREADA = 'cuentas_creada_modelo';
export const MODELO_CUENTA_REPETIDA = 'modelos_cuenta_repetida';
export const FLAG_CUENTAS = 'modelos_cuenta_flag';
export const BUSCAR_CUENTAS = 'buscar_modelos_cuentas';





