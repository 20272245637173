import {
    CARGANDO, ERROR, GUARDAR, CONSULTAR, RESEARCHTRUE, CAMBIO_CONCEPTO, CONSULTAR_TAREAS_REALIZADAS, RELOAD, CONTAR_TAREAS, IMAGEN_PERFIL
    , SUCCESS,CAMBIO_PASSWORD,CAMBIO_NEW_PASSWORD,CAMBIO_IMG_PERFIL,CAMBIO_PERMISOS,CAMBIO_USER_NAME,CAMBIO_TRM,IMAGEN_PERFIL_LOGO,CONSULTAR_PERMISOS_PAGOS,
} from '../types/TareasTypes'

const INITIAL_STATE = {
    permisosM: [],
    permisosPagosM: [],
    vertareas: [],
    tareasc: 0,
    cargando: false,
    error: '',
    concepto: '',
    research: '',
    success: '',
    perfil_img:'',
    logo:'',
    trm:'',
    username:'',
    permisos:'',
    password: '',
    newPassword: ''

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTAR:
            return {
                ...state,
                permisosM: action.payload,


            }

            case CONSULTAR_PERMISOS_PAGOS:
                return {
                    ...state,
                    permisosPagosM: action.payload,
    
    
                }




        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false, success: '' }


        case SUCCESS:
            return { ...state, success: action.payload, cargando: false, error: '' }


        case GUARDAR:
            return {
                ...state,
                cargando: false,
                error: '',
                concepto: '',
                research: true,
                success: ''


            }




        case IMAGEN_PERFIL:
            return {
                ...state,
                perfil_img: action.payload,
                cargando: false,
                research: false,


            }


            
        case IMAGEN_PERFIL_LOGO:
            return {
                ...state,
                logo: action.payload,
                cargando: false,
                research: false,


            }

        case CAMBIO_TRM:
            return {
                ...state,
                trm: action.payload,

            }

            case CAMBIO_PASSWORD:
            return {
                ...state,
                password: action.payload,

            }
        case CAMBIO_NEW_PASSWORD:
            return {
                ...state,
                newPassword: action.payload,

            }

            

            case CAMBIO_IMG_PERFIL:
                return {
                    ...state,
                    perfil_img: action.payload,
    
                }


                case CAMBIO_PERMISOS:
                    return {
                        ...state,
                        permisos: action.payload,
        
                    }

                    case CAMBIO_USER_NAME:
                        return {
                            ...state,
                            username: action.payload,
            
                        }

        case CONSULTAR_TAREAS_REALIZADAS:
            return {
                ...state,
                vertareas: action.payload,
                cargando: false,
                research: false,


            }
        case CONTAR_TAREAS:
            return {
                ...state,
                tareasc: action.payload,
                cargando: false,
                research: false,


            }




        case CAMBIO_CONCEPTO:
            return {
                ...state,
                concepto: action.payload,

            }

        case RESEARCHTRUE
            :
            return {
                ...state,

                tareas: [],
                vertareas: [],
                cargando: false,
                error: '',
                concepto: '',
                research: '',
                success: '',
                perfil_img:'',
                logo:'',
                trm:'',

            }

        case RELOAD
            :
            return {
                ...state,


                cargando: false,
                research: true,



            }
        default: return state
    }

}