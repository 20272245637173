import axios from 'axios'
import {CARGANDO,ERROR,LIMPIAR,RESEARCHTRUE,GUARDARTAREAS,CONTAR_MODELO,CAMBIO_CONCEPTO,CONTAR_TAREAS,
    CONSULTAR_TAREAS,SUCCESS,CAMBIO_SEARCH_TAREAS,CONSULTAR_EVENTO_PROXIMO} from '../types/HomeTypes'
import * as LoginTypes from '../types/LoginTypes'
import {URL} from '../../src/General/url'
const {TOKENVERIFY: LOGOUT} = LoginTypes


//acciones para tareas 



export const consultarmodelos = () => async(dispatch)=>{
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
       
        
        const respuesta = await axios.get(URL+'countModels',{ headers: { Authorization: `${window.localStorage.getItem('token')}` } })
        

        if(respuesta.data.message === 'El usuario no esta identificado'){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')

            dispatch({

                type: ERROR,
                payload: respuesta.data.message
         
            
            })

        }else{
         
    dispatch({
        
        type: CONTAR_MODELO,
        payload:respuesta.data.cuentas,
       
        })
        }
    

        
    } catch (error) {

     
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')

            dispatch({
                type: LOGOUT,
            
            });
            
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
 
    }
}

export const consultarEventosProximos = () => async(dispatch)=>{
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
       
        
        const respuesta = await axios.get(URL+'getNextEvents',{ headers: { Authorization: `${window.localStorage.getItem('token')}` } })
        

        if(respuesta.data.status==="Success"){
    

            dispatch({
        
                type: CONSULTAR_EVENTO_PROXIMO,
                payload:respuesta.data.data,
               
                })

        }
        
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }

        
    } catch (error) {

     
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')

            dispatch({
                type: LOGOUT,
            
            });
            
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
 
    }
}
export const consultartareas = () => async(dispatch)=>{
    


    try {

     


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`searchHomework`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTAR_TAREAS,
            payload:respuesta.data.data,
        })


        if(respuesta.data.status==="Success"){
    

            dispatch({
        
                type: CAMBIO_SEARCH_TAREAS,
                payload: false
                
            
            })

        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }

        
        
    } catch (error) {
        
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}
export const contartareas = () => async(dispatch)=>{
    


    try {

     


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`countHomework`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONTAR_TAREAS,
            payload:respuesta.data.data,
        })

        
        
    } catch (error) {
        
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}
export const crearNuevaTarea = (homework) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(homework)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'createHomework', params,{
            headers: headers
        })
    
      
        dispatch({
            type: GUARDARTAREAS,
            payload:respuesta.data.data
        })


        if(respuesta.data.status==="Success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })


            dispatch({
        
                type: CAMBIO_SEARCH_TAREAS,
                payload: true
                
            
            })

        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}
export const realizarTarea = (model_id) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify()
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateHomework/${model_id}`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){

    dispatch({
        
        type: CAMBIO_SEARCH_TAREAS,
        payload: true
        
    
    })
   
}

if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}



        
 /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

 
}
export const eliminarTarea = (model_id) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
       

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.delete(URL+`deleteHomework/${model_id}`,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){

    dispatch({
        
        type: CAMBIO_SEARCH_TAREAS,
        payload: true
        
    
    })
   
}
if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}



        
 /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    } 
}

 
}
/* export const contarModelos = () => async(dispatch)=>{
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
       
        
        const respuesta = await axios.get(URL+'countModels',{ headers: { Authorization: `${window.localStorage.getItem('token')}` } })
        
        

        if(respuesta.data.message === 'El usuario no esta identificado'){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')

            dispatch({

                type: ERROR,
                payload: respuesta.data.message
         
            
            })

        }else{
         
    dispatch({
        
        type: CONSULTAR,
        payload:respuesta.data.data,
       
        })
        }
    

        
    } catch (error) {

     
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')

            dispatch({
                type: LOGOUT,
            
            });
            
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
 
    }
} */

/* export const buscador = (searchData) => async(dispatch,getState)=>{
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
       
        
        let json = JSON.stringify(searchData)
        let params = 'json=' + json
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': window.localStorage.getItem('token')
        }

          
        const respuesta = await axios.post(URL + 'searchModels', params, {
            headers: headers
        })
  
        if(respuesta.data.message === 'El usuario no esta identificado'){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
            dispatch({

                type: ERROR,
                payload: respuesta.data.message
         
            
            })

        }else{
         
        
    dispatch({
        type: CONSULTAR_MODELO,
        payload:respuesta.data.modelos,
       
        })
        }
    

        
    } catch (error) {
       
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
 
    }
} */

export const editar = (tarea_editada) => async(dispatch)=>{
   
    dispatch({

        type: CARGANDO
        
    
    })

 
}
export const limpiarForma  = () => async(dispatch,) => {
    dispatch({

        type: LIMPIAR
        
    
    })

   
};
export const researchtrue  = () => (dispatch,) => {
    dispatch({

        type: RESEARCHTRUE
        
    
    })
};
export const cambioTareas  = (value) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_CONCEPTO,
        payload: value
        
    
    })
    
};