import axios from 'axios'
import FileDownload  from "js-file-download";
import { CARGANDO, ERROR, CONSULTAR,RESEARCHTRUE,CAMBIO_FECHA1,CAMBIO_FECHA2} from '../types/ReporteTotalSinDeduccionesType'


    import {URL} from '../../src/General/url'


export const descargarExcelModelosReportTotal = (data) => async (dispatch) => {

    try {
  
  
        let headers = {
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL +  `excelModelsReportTotalNodeductions/${data.fecha_inicial}/${data.fecha_final}`, {
            headers: headers,
            responseType: 'arraybuffer'
        })
      
        var blob = new Blob([respuesta.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileDownload(blob, 'ModelsReportTotalsinDeducciones.xlsx')
  
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }


export const consultarTotal = (data) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'getReportTotalProfitsNoDeductions', params,{
            headers: headers
        })
    
      
        dispatch({
            type: CONSULTAR,
            payload:respuesta.data.data
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}








export const cambiofecha1  = (fecha1) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA1,
        payload: fecha1
        
    
    })
    
};

export const cambiofecha2  = (fecha2) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHA2,
        payload: fecha2
        
    
    })
    
};



export const researchtrue  = () => (dispatch,) => {
    dispatch({

        type: RESEARCHTRUE
        
    
    })


    

   
};