import {
    CARGANDO, ERROR, CONSULTAR, RESEARCHTRUE,ID_MODELOS,TEXT_MODELOS
    , SUCCESS, FILTRAR_MODELO_PAGO,CONSULTARS,CONSULTAR_FECHAS_PAGOS,CAMBIO_FECHA_PAGOS,PAGINAR_MODELOS,PAGINACION_MODELOS
} from '../types/PerfilVerTypes'

const INITIAL_STATE = {
    ModeloPago: [],
    ListaFechasPagos: [],
    SuggestionDatabase: [],
    Modelos: [],
    Pag_modelos:0,
    cargando: false,
    error: '',
    fechas_pagos_id: '',
    research: '',
    success: '',
    idTextoBusqueda:'',
    textoBusqueda:''

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTAR:
            return {
                ...state,
                multas: action.payload,
                cargando: false,
                research: false,
                cantidad: 0,
                fechaprestamo: '',
                concepto: '',

            }

        case FILTRAR_MODELO_PAGO:
            return {
                ...state,
                ModeloPago: action.payload,
                cargando: false,
                research: false,


            }

            case CONSULTAR_FECHAS_PAGOS:
                return {
                    ...state,
                    ListaFechasPagos: action.payload,
                    cargando: false,
                    research: false,
    
    
                }
                case PAGINAR_MODELOS:
                    return {
                        ...state,
                        Modelos: action.payload,
                        cargando: false,
                        research: false,
        
        
                    }
                    case PAGINACION_MODELOS:
                        return {
                            ...state,
                            Pag_modelos: action.payload,
                            cargando: false,
                            research: false,
            
            
                        }

                
                case CAMBIO_FECHA_PAGOS:
                    return {
                        ...state,
                        fechas_pagos_id: action.payload,
                        cargando: false,
                        research: false,
        
        
                    }


                    case ID_MODELOS:
                        return {
                            ...state,
                            idTextoBusqueda: action.payload,
            
            
                        }

                        case TEXT_MODELOS:
                            return {
                                ...state,
                                textoBusqueda: action.payload,
                
                
                            }
                
            
        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false, success: '' }


        case SUCCESS:
            return { ...state, success: action.payload, cargando: false, error: '' }


            case CONSULTARS:
            return {
                ...state,
                SuggestionDatabase: action.payload,

            }


        case RESEARCHTRUE
            :
            return {
                ...state,

                multas: [],
                vermultas: [],
                cargando: false,
                error: '',
                success: '',
                research: '',



            }


        default: return state
    }

}