import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as crearactionMonitores from "../../actions/CrearMonitoresAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import cambiarFotoImg from "../../img/cambiarFotoImg.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import { TiDelete } from "react-icons/ti";
import { GrUpdate } from "react-icons/gr";
import {URL} from '../../../src/General/url'


/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */
export class AgregarInformacionMonitores extends Component {


  constructor(props) {
    super(props);

    this.state = {
      permisoPagosid: '',

    };
  }

  state = {
    opacity: '30%'
  }
  

  componentDidMount() {
    const {

      consultar_permisos,
      consultar_todos_monitores,
      consultar_permisos_pagos

    } = this.props

    consultar_permisos()
    consultar_permisos_pagos()
    consultar_todos_monitores()



  }

  componentDidUpdate() {
    console.log(this.props)

    const {

      estadoFlag,
      consultar_todos_monitores

    } = this.props

    if(estadoFlag){
      consultar_todos_monitores()
    }
  }



  componentWillUnmount() {


  }

  handleSubmit(e) {
    e.preventDefault();
  }
  deshabilitar = () => {
    const {
      usuario,
      password_monitor,
      id_permisos
    } = this.props;

    if (
      !usuario ||
      !password_monitor ||
      !id_permisos
    ) {

      return true
    }

    return false

  };

  handleChange(dataType, value) {
    this.props.handleChange(dataType, value)
  }

  cambioFotoPerfil = event => {
    const { guardarimagen } = this.props;
    const files = event.target.files[0];
    if (files) {
      const data = new FormData();
      data.append("file0", files);
      guardarimagen(data);
    }

  };



  cambioPermisosId = (event) => {
    this.props.cambioPermisosId(event.target.value)
  }

  cambioPermisosPagosId = (event) => {
    this.setState({permisoPagosid:event.target.value})
  }


  handleChangeUpdate(index, dataType, value) {


    this.props.handleChangeUpdate(index, dataType, value)

  }

  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  Updaateuser(id, key, e) {
    e.preventDefault()
    const { editarUser } = this.props;
    const datosUpdateUser = {
      userpassword: this.props.usuarios_todos[key].userpassword,
      rol_id: this.props.usuarios_todos[key].rol_id,
      rol_pago_id: this.props.usuarios_todos[key].rol_pago_id,
    };
  editarUser(datosUpdateUser, id);
  }


  desablieUserHandle(id, key, e) {
    e.preventDefault()
    const { desabiltiarUsuario } = this.props;
    const datosDesableUser = {
      estado: 0,
    };
    desabiltiarUsuario(datosDesableUser, id);
  }


  crearMonitor(e) {
    e.preventDefault()
    const {
      id_permisos,
      foto_perfil,
      usuario,
      password_monitor,
      crear_monitor
    } = this.props;
    const nuevoMonitor = {
      rol_id: id_permisos,
      rol_pagos_id: this.state.permisoPagosid,
      plataformas_master_id: 1,
      profile_picture: foto_perfil,
      username: usuario,
      userpassword: password_monitor,
      estado: 1,
    };

    crear_monitor(nuevoMonitor);
  }
  
  listaPermisos = () => {
    const { permisos } = this.props;

    const ponerOpcionesPermisos = () =>
      permisos.map((permisos, key) => (
        <option className='option-values' key={key} value={permisos.id}>
          {this.MaysPrimera(permisos.nombre.toLowerCase())}{}
        </option>
      ));

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        onChange={this.cambioPermisosId}
        required
      >
        <option className='option-values' >
          Permisos
        </option>
        {ponerOpcionesPermisos()}
      </select>
    );
  };


  listaPermisosPagos = () => {
    const { permisosPagos } = this.props;

    const ponerOpcionesPermisos = () =>
    permisosPagos.map((permisos, key) => (
        <option className='option-values' key={key} value={permisos.id}>
          {this.MaysPrimera(permisos.nombre.toLowerCase())}{}
        </option>
      ));

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        onChange={this.cambioPermisosPagosId}
        required
      >
        <option className='option-values' >
          Permisos
        </option>
        {ponerOpcionesPermisos()}
      </select>
    );
  };


  listaPermisosUpdate = (keyUser) => {
    const { permisos } = this.props;

    const ponerOpcionesPermisos = () =>
      permisos.map((permisos, key) => (
        <option className='option-values' key={key} value={permisos.id}>
          {this.MaysPrimera(permisos.nombre.toLowerCase())}{}
        </option>
      ));

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        onChange={(e) => this.handleChangeUpdate(keyUser, 'rol_id', e.target.value)}
        required
      >
        <option className='option-values' value={this.props.usuarios_todos[keyUser].rol.id}>
          {this.props.usuarios_todos[keyUser].rol.nombre}
        </option>
        {ponerOpcionesPermisos()}
      </select>
    );
  };


  listaPermisosPagosUpdate = (keyUser) => {
    const { permisosPagos } = this.props;

    const ponerOpcionesPermisos = () =>
    permisosPagos.map((permisos, key) => (
        <option className='option-values' key={key} value={permisos.id}>
          {this.MaysPrimera(permisos.nombre.toLowerCase())}{}
        </option>
      ));

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        onChange={(e) => this.handleChangeUpdate(keyUser, 'rol_pago_id', e.target.value)}
        required
      >
        <option className='option-values' value={this.props.usuarios_todos[keyUser].rol_pago?this.props.usuarios_todos[keyUser].rol_pago.id:''}>
          {this.props.usuarios_todos[keyUser].rol_pago?this.props.usuarios_todos[keyUser].rol_pago.nombre:''}
        </option>
        {ponerOpcionesPermisos()}
      </select>
    );
  };

  ListaUsuarios = () => {
   
    const { usuarios_todos } = this.props;

    const poneUsuarios = () =>
      usuarios_todos.map((usuarios, key) => (

        <Fragment key={key}>




          <div className="col-md-3  border__monitor">

            <img
              className="avatarModeloimg"
              alt="imgagerfil"
              src={`${URL}getImage/${
                usuarios.profile_picture
                  ? usuarios.profile_picture
                  : "defaultLogo.png"
                }`}
            />
            <div className='informacion_users_modificar_info_monitor'>

              <div className='div__personal__modificar__info'><div className='text__rigth'>Usuario </div>   <input
                type="text"
                readOnly
                className='input__modificiar_informacion_modelo'
                value={usuarios.username || ''}
             >
              </input ></div>

              <div className='div__personal__modificar__info'><div className='text__rigth'>Password  </div>   <input
                type="text"
                className='input__modificiar_informacion_modelo'
                value={usuarios.userpassword || ''}
                onChange={(e) => this.handleChangeUpdate(key, 'userpassword', e.target.value)}
              ></input ></div >

              <div className='div__personal__modificar__info'><div className='text__rigth'>Permisos  </div>
                {this.listaPermisosUpdate(key)}</div >
                <div className='div__personal__modificar__info'><div className='text__rigth'>Permiso pagos  </div>
                {this.listaPermisosPagosUpdate(key)}</div >


            </div>

            <div className="div__btn__rooms__update">
              <TiDelete
                size={"2vw"}
                onClick={(e) => this.desablieUserHandle(usuarios.id, key, e)}
                className="Home_Work_Stage__insideDiv__icon__x"
              />


              <GrUpdate
                size={"2vw"}
                onClick={(e) => this.Updaateuser(usuarios.id, key, e)}
                className="Home_Work_Stage__insideDiv__icon__x"
              />

      
            </div>


          </div>








        </Fragment>
      ));

    return (poneUsuarios()
    );
  };


  ponerInformacionModelo = () => {

  
    const ponerInfo = () => (
      <Fragment >
        <div className="col-md-12 ">
          <div className="row">
            <div className="col-md-3 mx-auto">

              <img
                className="avatarModeloimg"
                alt="imgagerfil"
                src={`${URL}getImage/${
                  this.props.foto_perfil
                    ? this.props.foto_perfil
                    : "defaultLogo.png"
                  }`}
              />
              <label className='foto_perfil_modelo_label' htmlFor="foto_perfil_modelo">
                <img src={cambiarFotoImg} alt="Click aquí para subir tu foto" title="Click aquí para subir tu foto" />
              </label>
              <input

                className="form-element-field -hasvalue"
                id={`foto_perfil_modelo`}
                type="file"
                onChange={this.cambioFotoPerfil}
              />

            </div>

            <div className="col-md-4 mx-auto">
              <div className='informacion_personal_modificar_info_modelo'>
                <div className='div__personal__modificar__info'><div className='text__rigth'>Usuario  </div>   <input
                  type="text"
                  className='input__modificiar_informacion_modelo'
                  value={this.props.usuario}
                  onChange={(e) => this.handleChange('CAMBIO_USUARIO', e.target.value)}
                /></div>

                <div className='div__personal__modificar__info'><div className='text__rigth'>Contraseña  </div>   <input
                  type="text"
                  className='input__modificiar_informacion_modelo'
                  value={this.props.password_monitor}
                  onChange={(e) => this.handleChange('CAMBIO_PASSWORD_MONITOR', e.target.value)}
                /></div>

                <div className='div__personal__modificar__info'><div className='text__rigth'>Permisos  </div>
                  {this.listaPermisos()}</div >
                  <div className='div__personal__modificar__info'><div className='text__rigth'>Permiso pagos  </div>
                  {this.listaPermisosPagos()}</div >

              </div>

              <button
                type='button'
                disabled={this.deshabilitar()}
                className='btn_crear_monitor'
                onClick={e => this.crearMonitor(e)}
                style={this.deshabilitar() ? { opacity: '30%' } : { opacity: '100%' }}>

                Crear Usuario
            </button>
            </div>
          </div>
        </div>

      </Fragment>
    )

    return (ponerInfo())
  }





  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {

    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            PERFIL <strong className="tituloPaginasPequeño">USERS</strong>
          </h1>

          <div className="reporte__div__principal">

            <div className="container-fluid">

            {this.mostrarAccion()}
              <div className="row">

                {this.ponerInformacionModelo()}
             
                {this.ListaUsuarios()}

              </div>



            </div>
          </div>
         


        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.crearMonitoresReducer;
};

export default connect(mapStateToProps, crearactionMonitores)(AgregarInformacionMonitores);
