export const CONSULTAR = 'crear_traer_todas_crear_modelo';
export const CARGANDO = 'crear_cargando_crear_modelo';
export const ERROR = 'crear_error_crear_modelo';
export const GUARDAR = 'crear_guardada_crear_modelo';
export const ACTUALIZAR = 'crear_actualizar_crear_modelo';
export const LIMPIAR = 'crear_limpiar_crear_modelo';
export const RESEARCHTRUE = 'crear_research_crear_modelo';
export const SUCCESS = 'crear_succes_crear_modelo';
export const CAMBIO_CEDULA = 'crear_cambio_cedula_crear_modelo';
export const CAMBIO_NOMBRES = 'crear_cambio_nombres_crear_modelo';
export const CAMBIO_NICK_NAME = 'crear_cambio_apellidos_crear_modelo';
export const CAMBIO_TELEFONO = 'crear_cambio_telefono_crear_modelo';
export const CAMBIO_APELLIDOS = 'crear_cambio_arriendo_crear_modelo';
export const CAMBIO_ARRIENDO = 'crear_cambio_nickname_crear_modelo';
export const CAMBIO_PASSWORDM = 'crear_cambio_passwordm_crear_modelo';
export const CAMBIO_LUGAR_TRASNMISION = 'crear_cambio_lugar_trasmision';
export const CAMBIO_LUGAR_ID = 'crear_cambio_lugares_crear_modelo';
export const CAMBIO_TURNO_ID = 'crear_cambio_turnos_crear_modelo';
export const CONSULTAR_LUGARES = 'crear_consultar_lugares_crear_modelo';
export const CONSULTAR_TURNOS = 'crear_consultar_horario_crear_modelo';
export const CAMBIO_ESTADO_CREAR = 'crear_estado_creacion_crear_modelo';
export const CAMBIO_ESTADO_INICIAL = 'crear_estado_inicial_crear_modelo';
export const RELOAD = 'crear_reload_crear_modelo';
export const IMAGEN_PERFIL = 'perfil_imagen_perfil_crear_modelo';
export const IMAGEN_GASTOS = 'perfil_imagen_perfil_gastos';
export const IMAGEN_FIRMA = 'perfil_imagen_firma_perfil_crear_modelo';
export const GALERIA_MODELO = 'perfil_galeria_modelo_crear_modelo';
export const GALERIA_FLAG = 'perfil_galeria_modelo_flag_crear_modelo';
export const CAMBIO_CEDULA_CONTABLE = 'crear_cambio_cedula_contable_crear_modelo';
export const CAMBIO_NOMBRES_CONTABLE = 'crear_cambio_nombres_contable_crear_modelo';
export const CAMBIO_APELLIDOS_CONTABLE = 'crear_cambio_apellidos_contable_crear_modelo';
export const CAMBIO_DIRECCION = 'crear_cambio_direccion_contable_crear_modelo';
export const CAMBIO_CUENTA_BANCARIA = 'crear_cambio_cuentabancaria_contable';
export const CAMBIO_CUENTA_CORREO = 'crear_cambio_correo_modelo_crear_modelo';
export const CAMBIO_CUENTA_PASSWORDCORREO = 'crear_cambio_password_correo_crear_modelo';
export const CAMBIO_NOMBRE_FIRMA = 'crear_cambio_nombre_firma_crear_modelos';
export const ID_INFORMACION_CONTABLE = 'guardar_id_informacion_contable';
export const INFORMACION_CONTABLE = 'guardar_informacion_contable';
export const INFORMACION_CONTABLE_FLAG = 'guardar_informacion_contable_flag';
export const CAMBIO_BANCO = 'guardar_informacion_contable_bancO';
export const CONSULTAR_LISTA_BANCO = 'crear_consultar_lista_banco_crear_modelo';
export const CAMBIO_FECHA_INICIAL = 'fechas_busqueda_gastos_fecha_inicial';
export const CAMBIO_FECHA_FINAL ='fechas_busqueda_gastos_fecha_final';
export const GASTOS_OBTENER ='obtener_gastos';
export const TOTAL_GASTOS_OBTENER ='obtener_total_gastos';












