export const CONSULTAR = 'prestamoGlobal_traer_todas';
export const CONSULTARS = 'prestamoGlobal_traer_todas_suggestions';
export const CONSULTAR_TOTALES = 'prestamoGlobal_traer_todas_prestamos';
export const CARGANDO = 'prestamoGlobal_cargando';
export const ERROR = 'prestamoGlobal_error';
export const GUARDAR = 'prestamoGlobal_guardada';
export const ACTUALIZAR = 'prestamoGlobal_actualizar';
export const LIMPIAR = 'prestamoGlobal_limpiar';
export const RESEARCHTRUE = 'prestamoGlobal_research';
export const RESEARPRESTAMO = 'prestamoGlobal_research_prestamo';
export const SUCCESS = 'prestamoGlobal_succes';
export const CAMBIO_FECHAPRESTAMO = 'prestamos_fecha';
export const CAMBIO_CANTIDAD = 'prestamoGlobal_cantidad';
export const CAMBIO_CONCEPTO = 'prestamoGlobal_concepto';
export const CAMBIO_FECHA1 = 'prestamoGlobal_fecha1';
export const CAMBIO_FECHA2 = 'prestamoGlobal_fecha2';
export const FILTRAR_PRESTAMOS_MINIMOS = 'prestamoGlobal_minimos_filtrar';
export const CONSULTAR_SERVICIOS = 'prestamoGlobal_listado_servicios';
export const CONSULTAR_SERVICIOS_MODELO = 'prestamoGlobal_listado_servicios_modelo';







