export const CONSULTAR = 'prestamos_traer_todas';
export const CARGANDO = 'prestamos_cargando';
export const ERROR = 'prestamos_error';
export const GUARDAR = 'prestamos_guardada';
export const ACTUALIZAR = 'prestamos_actualizar';
export const LIMPIAR = 'prestamos_limpiar';
export const RESEARCHTRUE = 'prestamos_research';
export const SUCCESS = 'prestamos_succes';
export const CAMBIO_FECHAPRESTAMO = 'prestamos_fecha';
export const CAMBIO_CANTIDAD = 'prestamos_cantidad';
export const CAMBIO_CONCEPTO = 'prestamos_concepto';
export const CAMBIO_FECHA1 = 'prestamos_fecha1';
export const CAMBIO_FECHA2 = 'prestamos_fecha2';
export const FILTRAR_PRESTAMOS_MINIMOS = 'prestamos_minimos_filtrar';





