export const CONSUTARL_MODELO = 'modelo_traer_todas';
export const CARGANDO = 'modelo_cargando';
export const ERROR = 'modelo_error';
export const GUARDAR = 'modelo_guardada';
export const ACTUALIZAR = 'modelo_actualizar';
export const LIMPIAR = 'modelo_limpiar';
export const RESEARCHTRUE = 'modelo_research';
export const SUCCESS = 'modelo_succes';
export const CAMBIO_FECHAPRESTAMO = 'modelo_fecha';
export const CAMBIO_CANTIDAD = 'modelo_cantidad';
export const CAMBIO_CONCEPTO = 'modelo_concepto';
export const CAMBIO_FECHA1 = 'modelo_fecha1';
export const CAMBIO_FECHA2 = 'modelo_fecha2';
export const FILTRAR_PRESTAMOS_MINIMOS = 'modelo_minimos_filtrar';
export const CAMBIO_PASSWORD = 'modelo_cambio_password';
export const CAMBIO_FOTO_PERFIL = 'modelo_cambio_foto_perfil';
export const CONSULTAR_HORARIOS = 'modelo_consultar_horarios';
export const CONSULTAR_LUGARES = 'modelo_consultar_lugares';
export const CONSULTAR_DOCUMENTO = 'modelo_cambio_tipo_documento';


export const CAMBIO_LUGARES = 'modelo_cambio_lugares';
export const CAMBIO_INFORMACION_CONTABLE = 'modelo_cambio_informacion_contable';
export const CAMBIO_HORARIOS = 'modelo_cambio_horario';
export const CAMBIO_CEDULA = 'modelo_cambio_cedula';
export const CAMBIO_NOMBRES = 'modelo_cambio_nombres';
export const CAMBIO_APELLIDOS = 'modelo_cambio_apellidos';
export const CAMBIO_TELEFONO = 'modelo_cambio_telefono';
export const CAMBIO_PASSWORDM = 'modelo_cambio_passwordm';
export const CAMBIO_NICK_NAME = 'modelo_cambio_nick_name';
export const CAMBIO_ARRIENDO = 'modelo_cambio_arriendo';
export const CAMBIO_NOMBRE_RECIBO = 'modelo_cambio_nombre_recibo';
export const CAMBIO_ESTADO = 'modelo_cambio_estado';

export const CAMBIO_CEDULA_CONTABLES = 'modelo_cambio_cedula_contable';
export const CAMBIO_NOMBRES_CONTABLES = 'modelo_cambio_nombre_contable';
export const CAMBIO_APELLIDOS_CONTBLES = 'modelo_cambio_apellidos_contables';
export const CAMBIO_DOCUMENTO_CONTBLES = 'modelo_cambio_documento_contables';
export const CAMBIO_DIRECCION_CONTBLES = 'modelo_cambio_direccion_contables';
export const CAMBIO_CUENTABANCARIA_CONTBLES = 'modelo_cambio_cuentabancaria_contables';
export const CAMBIO_NOMBRE_DOCUMENTO_CONTBLES = 'modelo_cambio_nombre_documento_contables';
export const CAMBIO_ID_CONTBLES = 'modelo_cambio_id_contables';






export const RESET_MODELOINFO = 'modelo_info_reset';
export const CAMBIO_NOMBRE_HORARIOS = 'modelo_cambio_nombre_horario';
export const CAMBIO_NOMBRE_LUGARES = 'modelo_cambio_nombre_lugares';









