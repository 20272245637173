export const CONSULTAR = 'report_total_traer_todas';
export const CARGANDO = 'report_total_cargando';
export const ERROR = 'report_total_error';
export const GUARDAR = 'report_total_guardada';
export const ACTUALIZAR = 'report_total_actualizar';
export const LIMPIAR = 'report_total_limpiar';
export const RESEARCHTRUE = 'report_total_research';
export const SUCCESS = 'report_total_succes';
export const CAMBIO_FECHAPRESTAMO = 'report_total_fecha';
export const CAMBIO_CANTIDAD = 'report_total_cantidad';
export const CAMBIO_CONCEPTO = 'report_total_concepto';
export const CAMBIO_FECHA1 = 'report_total_fecha1';
export const CAMBIO_FECHA2 = 'report_total_fecha2';
export const FILTRAR_PRESTAMOS_MINIMOS = 'report_total_minimos_filtrar';
export const CAMBIO_LUGARES = 'report_total_cambio_lugares';
export const CONSULTAR_LUGARES = 'report_total_consultar_lugares';
export const DEUDA_MODELOS = 'report_total_deuda_modelos';





