export const CONSULTAR = 'perfil_modificar_monitor_traer_todas';
export const CARGANDO = 'perfil_modificar_monitor_cargando';
export const ERROR = 'perfil_modificar_monitor_error';
export const GUARDAR = 'perfil_modificar_monitor_guardada';
export const ACTUALIZAR = 'perfil_modificar_monitor_actualizar';
export const LIMPIAR = 'perfil_modificar_monitor_limpiar';
export const RESEARCHTRUE = 'perfil_modificar_monitor_research';
export const SUCCESS = 'perfil_modificar_monitor_succes';
export const CAMBIO_LUGAR_ID = 'perfil_modificar_monitor_cambio_lugares';
export const CAMBIO_TURNO_ID = 'perfil_modificar_monitor_cambio_turnos';
export const CONSULTAR_LUGARES = 'perfil_modificar_monitor_consultar_lugares';
export const CONSULTAR_TURNOS = 'perfil_modificar_monitor_consultar_horario';
export const RELOAD = 'perfil_modificar_monitor_reload';
export const IMAGEN_PERFIL = 'perfil_modificar_monitor_imagen_perfil';
export const GALERIA_MODELO = 'perfil_modificar_monitor_galeria_modelo';
export const GALERIA_FLAG = 'perfil_modificar_monitor_galeria_modelo_flag';












