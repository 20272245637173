import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as nominaaction from "../../actions/NominaAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import reporteModeloLogo from "../../img/reporteModeloLogo.png";

/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */

export class NominaRecibosMonires extends Component {


  state = {
    dateStart: new Date(),
    dateEnd: new Date(),
  }

  componentDidMount() {

    const {
      match: {
        params: { fecha_id }
      },
      consultar_nomina_monitores,
    } = this.props
    consultar_nomina_monitores(fecha_id);


  }

  componentDidUpdate() {
    console.log(this.props);


  }

  componentWillUnmount() {
    const { researchtrue } = this.props;

    researchtrue();
  }

  handleSubmit(e) {
    e.preventDefault();
  }


  ponerPagoMonitores = () => {
    const { pagosNominaMonitores,

    } = this.props
    const ponerInfoMonitor = () => pagosNominaMonitores.map((infoModelo, key) => (
      <div className="col-md-6 justify-content-md-center" key={key}>
        <div className='informe__modelo__div__container_pagonomina_monitores'>
          <div className='div__one__informe__modelo'>
            <img className='logo__Report__Img__monitores' alt='logoReportPagos' src={reporteModeloLogo}></img>
            <span>{infoModelo.cedula} </span>
            <span>{`${infoModelo.nombre} ${infoModelo.apellidos}`}</span>
            <span>{infoModelo.fecha} </span>
            <span> {infoModelo.cuentabancaria} </span>
          </div>
          <div>
            <table className="table table-striped tabla-global-repore-modelo"  >

              <tbody>
                <tr>
                  <td>Salario</td>
                  <td>${Math.round(infoModelo.salario)}</td>
                </tr>
                <tr>
                  <td>prestamo </td>
                  <td>-${Math.round(infoModelo.sumaprestamosmonitores)}</td>
                </tr>
                <tr>
                  <td>ganancia Multa</td>
                  <td>+${infoModelo.gananciasmultasmonitores}</td>
                </tr>
                <tr>
                  <td>Préstamos</td>
                  <td>${infoModelo.suma_prestamos ? - Math.round(infoModelo.suma_prestamos) : 0}</td>
                </tr>
                <tr>
                  <td>Multas</td>
                  <td>${infoModelo.sumasmultasmonitores ? - Math.round(infoModelo.sumasmultasmonitores) : 0}</td>
                </tr> 

                {infoModelo.prestamosgrande > 0 ?
                  <Fragment>
                    <tr>
                      <td>Deuda restante</td>
                      <td>${infoModelo.prestamosgrande ? Math.round(infoModelo.prestamosgrande) : 0}</td>
                    </tr>
                    <tr>
                      <td>Cuota</td>
                      <td>${infoModelo.cuota ? -Math.round(infoModelo.cuota) : 0}</td>
                    </tr>
                    <tr>
                      <td>Debe de la deuda</td>
                      <td>${Math.round(infoModelo.prestamosgrande - infoModelo.cuota)}</td>
                    </tr>
                  </Fragment>
                  : ''}

                <tr>
                  <td>Total</td>
                  <td>$ {Math.round(infoModelo.total)}</td>
                </tr>


              </tbody>
            </table>
          </div>
 
        </div>
      </div>

    ))

    return (ponerInfoMonitor())
  }




  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {
    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            NÓMINA <strong className="tituloPaginasPequeño">RECIBOS MONITORES</strong>
          </h1>
          <div className="reporte__div__principal_pagonomina">
            <div className="container-fluid">
              <div className="row">
              { this.ponerPagoMonitores() }
                
                {

                }
              </div>
              {this.mostrarAccion()}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.NominaReducer;
};

export default connect(mapStateToProps, nominaaction)(NominaRecibosMonires);
