import React, { Component } from "react"
import { connect } from 'react-redux'
import * as CamaraAction from '../../actions/CaramaAction'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './Styles.scss'
import Spinner from '../../General/Spinner'
import Fatal from '../../General/Fatal'
import Success from '../../General/Success'
import { Redirect } from 'react-router-dom'




class Camara extends Component {

    componentDidMount() {

        const { consultar,


        } = this.props

        consultar()

        this.intervalId = setInterval(function(){ consultar()}, 300000);

    }


    componentWillUnmount() {
        clearInterval(this.intervalId)
    }

    mostrarAccion = () => {


        const { error, cargando, success } = this.props
        if (cargando) {
            return <Spinner />;
        }


        if (success) {
            return <Success mensaje={success} />;
        }


        if (error) {
            return <Fatal mensaje={error} />;
        }
        return false;
    }




    ponercamaras = () => {

        const { cam,

        } = this.props


        const camaras = () => cam.map((cammodel, key) => (


            <Col key={key} sm={6}>
                <iframe title={key} src={`https://es.chaturbate.com/fullvideo/?tour=dU9X&mobileRedirect=never&signup_notice=1&campaign=ECEYG&disable_sound=1&b=${cammodel.nombre}`} height='500px' width='100%' scrolling='yes'></iframe>
                  <div className='center'> 
                  {`modelo : ${cammodel.nombre}`} - {`Timepo en linea: ${cammodel.timeonline} minutos`}
                      </div>
            </Col>

        ))

        return (camaras())
    }




    render() {



        return (
     
            <div className='main-content fade-in'>
                {(!window.localStorage.getItem('token')) ? <Redirect to='/' /> : ''}
                {this.mostrarAccion()}
                <Row>

                    {this.ponercamaras()}
           

                </Row>
            </div>


        )
    }


}




//conectar tareas al reducer y traer las acciones del tareas actions
const mapStateToProps = (reducers) => {
    return reducers.CamaraReducer
}
//conectar tareas al reducer y traer las acciones del tareas actions
export default connect(mapStateToProps, CamaraAction)(Camara)