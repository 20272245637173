import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as pagosaction from "../../actions/PagosAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import ImgFechas from "../../img/ImgFecha.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import DatePicker from "react-date-picker";
import moment from "moment";
/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */

export class Pagos extends Component {
  state = {
    dateEndFiles: new Date(),
    dateEndEmails: new Date(),
    file: "",
    data: "",
  };

  componentDidMount() {


        if(!this.props.fecha1){

          this.cambiofecha1(new Date())
      }
      if(!this.props.fecha2){

          this.cambiofecha2(new Date())
      }
    this.cambiofechaFiles(this.state.dateEndFiles);
    this.cambiofechaEmails(this.state.dateEndEmails);
  }

  componentDidUpdate() {
    console.log(this.props);
  }

  componentWillUnmount() {
    const { researchtrue } = this.props;

    researchtrue();
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  pagarNomina = () => {
    const { fecha1, fecha2, dolar, pagar } = this.props;
    const infopagos = {
      fecha_inicial: fecha1,
      fecha_final: fecha2,
      dolar: dolar,
    };
    pagar(infopagos);
  };

  pagarNominaMonitores = () => {
    const { fecha1, fecha2, dolar, pagarMonitores } = this.props;
    const infopagos = {
      fecha_inicial: fecha1,
      fecha_final: fecha2,
      dolar: dolar,
    };
    pagarMonitores(infopagos);
  };
  cambiodolar = (event) => {
    this.props.cambiodolar(event.target.value);
  };

  cambiofecha1 = (date) => {


    this.props.cambiofecha1((moment(date).format('YYYY/MM/DD') ));
  };

  cambiofecha2 = (date) => {
    var fechaFinalString = ''
    if (date) {
        fechaFinalString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    } else {
        fechaFinalString = ''
    }
    this.props.cambiofecha2(fechaFinalString);
  };

  cambiofechaFiles = (date) => {
    this.setState({ dateEndFiles: date });
    var fechaFinalString = "";
    if (date) {
      fechaFinalString = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;
    } else {
      fechaFinalString = "";
    }
    this.props.cambiofechaFiles(fechaFinalString);
  };


  cambiofechaEmails = (date) => {
    this.setState({ dateEndEmails: date });
    var fechaFinalString = "";
    if (date) {
      fechaFinalString = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;
    } else {
      fechaFinalString = "";
    }
    this.props.cambiofechaEmails(fechaFinalString);
  };

  infoPagos = () => {
    return (
      <form onSubmit={(e) => this.handleSubmit(e)}>
        <div>
          <DatePicker
        onChange={this.cambiofecha1}
        value={this.props.fecha1?new Date(this.props.fecha1):''}
          />
        </div>
        <div>
          <DatePicker  onChange={this.cambiofecha2}
                        value={this.props.fecha2?new Date(this.props.fecha2):''}/>
        </div>
        <div className="">
          <input
            id="inputDolar"
            className="prestamosGlobar__text"
            placeholder="Dolar Hoy"
            type="number"
            value={this.props.dolar}
            onChange={this.cambiodolar}
          />
        </div>
      </form>
    );
  };

  handleChange = (event) => {
    const files = event.target.files[0];
    if (files) {
      let data = new FormData();
      data.append("data", files);

      this.setState({ data: data });
    }
  };

  sendCvs(tipe) {
    const { enviarCsv } = this.props;
    enviarCsv(this.state.data, tipe, this.props.fecha_end);
  }


  
  sendChatur() {
    const { enviarChaturbate } = this.props;
    enviarChaturbate(this.props.fecha_end);
  }

  modelosNoEncontrados = () => {
    const { modelosNoEncontrados } = this.props;

    const noEncontrados = () =>
      modelosNoEncontrados.map((modelosNoEncontrados, key) => (
        <tr key={key}>
          <td>{key + 1} </td>
          <td>{modelosNoEncontrados.nombre}</td>
        </tr>
      ));

    return noEncontrados();
  };
  subirArchivos = () => {
    return (
      <form onSubmit={(e) => this.handleSubmit(e)}>
        <div>
          <DatePicker
            onChange={this.cambiofechaFiles}
            value={this.state.dateEndFiles}
          />
        </div>
        <input
          className="form-element-field -hasvalue"
          type="file"
          onChange={this.handleChange}
        />
      </form>
    );
  };

  enviar_correos = () => {
    const { fecha_emails,enviar_correos } = this.props;
    const data = {
      fecha: fecha_emails,

    };
    enviar_correos(data);
  };
  enviarCorreos = () => {
    return (
     
        <div>
          <DatePicker
            onChange={this.cambiofechaEmails}
            value={this.state.dateEndEmails}
          />
        </div>
     
    );
  };


  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {
    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            NÓMINA <strong className="tituloPaginasPequeño">PAGOS</strong>
          </h1>
          <div className="reporte__div__principal">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 justify-content-md-center paddingPagos">
                  <img
                    alt="imagen fecha de pagos"
                    className="img__fecha_pagos"
                    src={ImgFechas}
                  ></img>
                  {this.infoPagos()}
                  {this.mostrarAccion()}
                </div>

                <div className="col-md-3 center__reportes__botones">
                  <div className="btn__reportes__div__pagos">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={this.pagarNomina}
                    >
                      Pagar Modelos
                    </button>
                  </div>
                  <div className="btn__reportes__div__pagos">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={this.pagarNominaMonitores}
                    >
                      Pagar Monitores
                    </button>
                  </div>
                </div>
                {/*     <div className="col-md-4 ">
                {this.props.pago_id ? <Fragment>
                      <h1>Ver Recibos</h1>
                      <h1>Ver Planilla</h1>
                  </Fragment> : ""}
                </div> */}
              </div>

              <div className="row">
                <div className="col-md-4 justify-content-md-center paddingPagos">
                  <img
                    alt="imagen fecha de pagos"
                    className="img__fecha_pagos"
                    src={ImgFechas}
                  ></img>
                  {this.subirArchivos()}
                </div>
                <div className="col-md-3 center__reportes__botones">
                  <div className="btn__reportes__div__pagos">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={(e) => this.sendCvs("4")}
                    >
                      Subir Cam4
                    </button>
                  </div>
                  <div className="btn__reportes__div__pagos">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={(e) => this.sendCvs("3")}
                    >
                      Subir Streamate
                    </button>
                  </div>

                  <div className="btn__reportes__div__pagos">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={(e) => this.sendCvs("5")}
                    >
                      Subir Stripchat
                    </button>
                  </div>
                  <div className="btn__reportes__div__pagos">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={(e) => this.sendChatur()}
                    >
                      Subir Chaturbate
                    </button>
                  </div>
                </div>
                <div className="col-md-6 mx-auto center__reportes__botones">
                    {this.props.modelosNoEncontrados.length?
                    <Fragment><h1 className="tituloPaginas center text-center">
                    Modelos No Encontrados 
                  </h1>
                    <table className="table table-bordered tabla-global">
                      <thead>
                        <tr>
                          <th># </th>
                          <th>NOMBRE </th>
                        </tr>
                      </thead>
                      <tbody>{this.modelosNoEncontrados()}</tbody>
                    </table></Fragment>:''}
                   
                </div>
                {/*     <div className="col-md-4 ">
                {this.props.pago_id ? <Fragment>
                      <h1>Ver Recibos</h1>
                      <h1>Ver Planilla</h1>
                  </Fragment> : ""}
                </div> */}
              </div>

              <div className="row">
                <div className="col-md-4 justify-content-md-center paddingPagos">
                  <img
                    alt="imagen fecha de pagos"
                    className="img__fecha_pagos"
                    src={ImgFechas}
                  ></img>
                  {this.enviarCorreos()}
                </div>
                <div className="col-md-3 center__reportes__botones">
                  <div className="btn__reportes__div__pagos">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={(e) => this.enviar_correos()}
                    >
                      Enviar correos
                    </button>
                  </div>
            
                </div>
                <div className="col-md-6 mx-auto center__reportes__botones">
                    {this.props.modelosNoEncontrados.length?
                    <Fragment><h1 className="tituloPaginas center text-center">
                    Modelos No Encontrados 
                  </h1>
                    <table className="table table-bordered tabla-global">
                      <thead>
                        <tr>
                          <th># </th>
                          <th>NOMBRE </th>
                        </tr>
                      </thead>
                      <tbody>{this.modelosNoEncontrados()}</tbody>
                    </table></Fragment>:''}
                   
                </div>
                {/*     <div className="col-md-4 ">
                {this.props.pago_id ? <Fragment>
                      <h1>Ver Recibos</h1>
                      <h1>Ver Planilla</h1>
                  </Fragment> : ""}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (reducers) => {
  return reducers.PagosReducer;
};

export default connect(mapStateToProps, pagosaction)(Pagos);
