import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as PrestamoGlobalaction from "../../actions/PrestamoGlobalAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import { FaTimes } from "react-icons/fa";
import { MdFileUpload } from "react-icons/md";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import DatePicker from "react-date-picker";
import moment from "moment";

// Imagine you have a list of languages that you'd like to autosuggest.

// Teach Autosuggest how to calculate suggestions for any given input value.

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.

export class sistemaServicio extends Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: "",
      id: "",
      text: "",
      fecha:'',
      servicioId:'',
      cantidad:'',
      suggestVar: false,
      date: new Date(),
      descripcion:''
    };
  }
  componentDidMount() {
    this.props.consultar_servicios();
    this.props.consultarServicios();
    this.cambiofechaprestamo(this.state.date);
  }

  componentDidUpdate() {
    const { researchPrestamo, } = this.props;
   
    if(!researchPrestamo){
      this.props.consultarServicios();
    }
  }
  componentWillUnmount() {
    const { researchtrue, } = this.props;

    researchtrue();
  }

  /* 
          getSuggestions2 = value => {
            const inputValue = value.trim().toLowerCase();
            const inputLength = inputValue.length;
    
            var ArrayVar = inputLength === 0 ? [] : this.props.SuggestionDatabase.filter(lang =>
              lang.name.toLowerCase()=== inputValue
            );
           return ArrayVar[0] ? ArrayVar[0].id: []
           
          }; 
     */
  cambiofechaprestamo = date => {
    this.setState({ date: date });

    this.setState({ fecha: moment(date).format('YYYY/MM/DD') });
  };


  cambiocantidad = event => {
    this.setState({ cantidad: event.target.value });

  };

  cambioconcepto = event => {
    this.setState({ servicioId: event.target.value });
  };

  guardar = () => {
    const {
      agregar_servicio
    } = this.props;
    const servicio = {
      fecha: this.state.fecha,
      modelos_id: this.state.id,
      servicio_id: this.state.servicioId,
      cantidad: this.state.cantidad,
      descripcion: this.state.descripcion
    };

    /*  if (model_id  && plataform_id) {
 
           const tarea = tareas[usu_id][tar_id];
             const tarea_editada = {
                 ...nueva_tarea,
                 completed: tarea.completed,
                 id: tarea.id
             }
 
             editar (tarea_editada)
             
         }else{
            
         } */
         agregar_servicio(servicio);
  };


  handleEditarPrestamoSearch = (key,id, e) => {
    e.preventDefault();
    const { editarServicio } = this.props;
    const data = {
        cantidad: this.props.servicios[key].cantidad
       
    };
 
  
    editarServicio(data, id);
  

  }; 


  handleEditarService = (key,id, e) => {
    e.preventDefault();
    const { editarServicio } = this.props;
    const data = {
        cantidad: this.props.verperstamos[key].cantidad
       
    };
 
  
    editarServicio(data, id);
  

  }; 
  consultarPrestamosModelo = () => {
    const { consultarServices } = this.props;

    consultarServices(this.state.id);
  };

  handleSubmit(e) {
    e.preventDefault();
  }


  handleChange(index, dataType, value) {
    this.props.handleChange(index, dataType, value)

}

handleChangeServicios(index, dataType, value) {
  this.props.handleChangeServicios(index, dataType, value)

}



handleChangePrestamo(index, dataType, value){
  
  this.props.handleChangePrestamo(index, dataType, value)

}
  onTextChanged = e => {
    const value = e.target.value;
    let suggestions = [];

    const { consultarSuggestions } = this.props;
    const dataSuggestion = {
      data: value
    };
    if (value.length > 2) {
      this.setState(() => ({ suggestVar: true }));
      consultarSuggestions(dataSuggestion);
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      suggestions =
        inputLength === 0
          ? []
          : this.props.SuggestionDatabase.filter(
              lang =>
                lang.name.toLowerCase().slice(0, inputLength) === inputValue
            );
    } else {
      this.setState(() => ({ suggestVar: false }));
    }
    this.setState(() => ({ suggestions, text: value }));
  };

  suggestionSelected(value) {
    this.setState(() => ({
      text: value.name,
      id: value.id,
      suggestVar: false
    }));
  }

  renderSuggestions() {
    if (!this.state.suggestVar) {
      return null;
    }
    return (
      <div className="srchList">
        {this.props.SuggestionDatabase.map((item, key) => (
          <li key={key} onClick={() => this.suggestionSelected(item)}>
            {item.name}
          </li>
        ))}
      </div>
    );
  }


  
  listaServicios = () => {
    const { ListaServicios} = this.props;

    const poneropcioneslugaress = () =>
    ListaServicios.map((lugares, key) => (
            <option className='option-values' key={key} value={lugares.id}>
                {lugares.nombre}
            </option>
        ));

    return (
        <select
            className="right-submenu"
            name="select"
            onChange={this.cambioconcepto}
            required
        >
            <option className='option-values' value=''>
                Servicios
            </option>
            {poneropcioneslugaress()}
        </select>
    );
};

  handledelete(id, e) {
    e.preventDefault();
    const { eliminarServicio } = this.props;

    eliminarServicio(id);
  }

  renderPrestamos = () => {
    const { verperstamos } = this.props;
    const ponerInfo = () =>
      verperstamos.map((prestamo, key) => (
        <tr key={key}>
          <td>{`${prestamo.cedula}`}</td>
          <td>{`${prestamo.nombres} ${prestamo.apellidos}`}</td>
          <td>
          {prestamo.nombre || ''}</td>
          <td>    <input
                        type="number"
                        value={prestamo.cantidad || ''}
                        onChange={(e) => this.handleChangePrestamo(key, 'cantidad', e.target.value)}
                    /></td>
                       <td>    <input
                        type="text"
                        disabled
                        value={prestamo.descripcion || ''}
                        onChange={(e) => this.handleChangePrestamo(key, 'descripcion', e.target.value)}
                    /></td>
          <td>{prestamo.fecha}</td>
          <td>
          <span
                className="loans__icon__x"
                onClick={e => this.handleEditarService(key,prestamo.id, e)}
              >
                <MdFileUpload
                  
                  className="Home_Work_Stage__insideDiv__icon__x btn_action"
                />
              </span>
              <span
                className="loans__icon__x"
                onClick={e => this.handledelete(prestamo.id, e)}
              >
                <FaTimes
                 
                  className="Home_Work_Stage__insideDiv__icon__x btn_action"
                />
              </span>
            
          </td>
        </tr>
      ));

    return ponerInfo();
  };

  renderPrestamosModelo = () => {
    const { servicios } = this.props;
    const ponerInfo = () => (
      <div>
        <table className="table table-striped tabla-global">
          <thead>
            <tr>
            <th>CÉDULA</th>
              <th>NOMBRE</th>
              <th>CONCEPTO </th>
              <th>CANTIIDAD </th>
              <th>DESCRIPCION </th>
              <th>FECHA </th>
              <th>ACCIÓN </th>
            </tr>
          </thead>
          <tbody>
            {servicios.map((servicios, key) => (
              <tr key={key}>
                <td>{`${servicios.cedula}`}</td>
                <td>{`${servicios.nombres} ${servicios.apellidos}`}</td>
                <td>
                {this.props.servicios[key].nombre || ''}</td>
                <td>    <input
                        type="number"
                        value={this.props.servicios[key].cantidad || ''}
                        onChange={(e) => this.handleChangeServicios(key, 'cantidad', e.target.value)}
                    /></td>
                     <td>    <input
                        type="text"
                        value={this.props.servicios[key].descripcion || ''}
                        onChange={(e) => this.handleChangeServicios(key, 'descripcion', e.target.value)}
                    /></td>
                <td>{servicios.fecha}</td>
                <td>
              
                <span
                className="loans__icon__x"
                onClick={e => this.handleEditarPrestamoSearch(key,this.props.servicios[key].id, e)}
              >
                <MdFileUpload
                  
                  className="Home_Work_Stage__insideDiv__icon__x btn_action"
                />
              </span>
            
                    <span
                      className="loans__icon__x"
                      onClick={e => this.handledelete(this.props.servicios[key].id, e)}
                    >
                      <FaTimes
                        
                        className="Home_Work_Stage__insideDiv__icon__x btn_action"
                      />
                    </span>
            
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );

    if (servicios.length > 0) {
      return ponerInfo();
    } else {
      return "";
    }
  };

  Ponerlabelguardar = () => {
    const { text } = this.state;
    // Autosuggest will pass through all these props to the input.

    return (
      <form className="" onSubmit={e => this.handleSubmit(e)}>
        <fieldset className="form-fieldset">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-12 input">
                <input
                  className="input__autocomplete__Text prestamosGlobar__text"
                  value={text}
                  onChange={this.onTextChanged}
                  type="text"
                  placeholder="Buscar Modelo"
                  required
                />
              </div>
              <div className="col-md-12 justify-content-md-center input__autocomplete__SuggestionText">
                {this.renderSuggestions()}
              </div>
            </div>
          </div>

          <input
            id="field-x98ezh-s6s2g8-vfrkgb-ngrhef-atfkop"
            className="prestamosGlobar__text"
            placeholder="Cantidad"
            type="number"
            value={this.state.cantidad}
            onChange={this.cambiocantidad}
          />

            {this.listaServicios()}
          <div>
            <DatePicker
              className="prestamosGlobar__text"
              onChange={this.cambiofechaprestamo}
              value={this.state.date}
            />
          </div>

          <textarea
            id="field-3naeph-0f3yuw-x153ph-dzmahy-qhkmgm"
            rows="6"
            className="prestamosGlobar__text"
            placeholder="descripción"
            onChange={e=>this.setState({descripcion:e.target.value})}
            value={this.state.descripcion}
          ></textarea>
        
        </fieldset>
      </form>
    );
  };

  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {
    //   const { match: { params: {modelname } },   } = this.props

    console.log(this.props);

    return (
      <Fragment>
        <div className="main-content fade-in">
          <h1 className="tituloPaginas">
            SERVICIOS <strong className="tituloPaginasPequeño">MODELOS</strong>
          </h1>
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <div className="reporte__div__principal">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 ">
                  {this.Ponerlabelguardar()}
                  {this.mostrarAccion()}
                </div>

                <div className="col-md-2 center__reportes__botones">
                  <div className="btn__reportes_div">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={this.consultarPrestamosModelo}
                    >
                      Filtrar
                    </button>
                  </div>
                  <div className="btn__reportes_div">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={this.guardar}
                    >
                      Guardar
                    </button>
                  </div>
                </div>
                <div className="col-md-6 todos__prestamos__div ">
                  <table className="table table-striped tabla-global">
                    <thead>
                      <tr>
                       <th>CÉDULA</th>
                        <th>NOMBRE</th>
                        <th>CONCEPTO </th>
                        <th>CANTIIDAD </th>
                        <th>DESCRIPCION </th>
                        <th>FECHA </th>
                        <th>ACCIÓN </th>
                      </tr>
                    </thead>
                    <tbody>{this.renderPrestamos()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {this.renderPrestamosModelo()}
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.PrestamoGlobalReducer;
};

export default connect(mapStateToProps, PrestamoGlobalaction)(sistemaServicio);
