export const CONSULTAR = 'habitacion_consultar_todas_habitaciones';
export const CARGANDO = 'habitacion_agregar_cargando';
export const ERROR = 'habitacion_agregar_error';
export const GUARDAR = 'habitacion_agregar_guardada';
export const ACTUALIZAR = 'habitacion_agregar_actualizar';
export const LIMPIAR = 'habitacion_agregar_limpiar';
export const RESEARCHTRUE = 'habitacion_agregar_research';
export const SUCCESS = 'habitacion_agregar_succes';
export const RELOAD = 'habitacion_agregar_reload';
export const IMAGEN_PERFIL = 'habitacion_agregar_imagen_perfil';
export const IMAGEN_360 = 'habitacion_agregar_imagen_360';
export const CAMBIO_ROOM = 'habitacion_agregar_cambio_room';
export const CAMBIO_DESCRIPCION = 'habitacion_agregar_descripcion';
export const CAMBIO_INVENTARIO = 'habitacion_agregar_inventario';
export const NEW_HABITACIONES = 'habitacion_update_inventario';
export const CONSULTARS = 'habitacion_consular_suggestion';
export const CONSULTAR_TURNOS = 'habitacion_consular_horario';
export const CONSULTAR_MODELOS_HABITACION = 'habitacion_modelos_habitacion';
export const CAMBIO_TURNO_ID = 'habitacion_cambiar_turno';
export const RESEARCH_MODELOS_HABITACION = 'habitacion_estado_flag';
export const CONSULTAR_TODAS_HABITACIONES = 'habitacion_consultar_todas';
export const CONSULTAR_TODAS_HABITACIONES_REPORTE = 'habitacion_consultar_todas_reporte';
export const CONSULTAR_SEDES = 'habitacion_consultar_todas_sedes';
export const OBTERNER_NUMERO_TOTAL_HABITACION = 'habitacion_consultar_todal_numero_hab';

















