import axios from "axios";
import {
    CARGANDO,
    ERROR,
    LIMPIAR,
    CAMBIO_USUARIO_ID,
    CAMBIO_PASSWORD,
    RESEARCHTRUE,
    SUCCESS,
    CONSULTARS,
    CONSULTAR_CUENTAS_FLIRT,
    CONSULTAR_NOMBRE_MODELO,
    CONSULTAR_CUENTAS_CHATURBATE,
    CONSULTAR_CUENTAS_STRIPCHAT,
    CONSULTAR_CUENTAS_CAM4,
    CONSULTAR_CUENTAS_BONGA,
    CONSULTAR_CUENTAS_STREAMATE,
    CUENTA_CREADA,
    CORREOS_CUENTAS,
    MODELO_CUENTA_REPETIDA,
    FLAG_CUENTAS,
} from "../types/CuentasTypes";

//acciones para tareas

import {URL} from '../../src/General/url'

export const consultarSuggestions = (data) => async(dispatch)=>{
    


    try {

     
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
    
        const respuesta = await axios.post(URL+`getSuggestionEmails`, params,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTARS,
            payload:respuesta.data.data,
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        

        dispatch({
        
            type: ERROR,
            payload: error.message
            
        
        })
    }
}

export const consultarSuggestionsModel = (data) => async(dispatch)=>{
    


    try {

     
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
    
        const respuesta = await axios.post(URL+`getSuggestionModels`, params,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTARS,
            payload:respuesta.data.data,
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        

        dispatch({
        
            type: ERROR,
            payload: error.message
            
        
        })
    }
}

export const editar = (Uploaddatoscuenta, model_id) => async (dispatch) => {
    dispatch({
        type: CARGANDO,
    });

    try {
        let json = JSON.stringify(Uploaddatoscuenta);
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            Authorization: window.localStorage.getItem("token"),
        };
        const respuesta = await axios.put(
            URL + `updateAccountsModel/${model_id}`,
            params,
            {
                headers: headers,
            }
        );
        if (respuesta.data.status === "Success") {
            dispatch({
                type: SUCCESS,
                payload: respuesta.data.message,
            });
        }
        if (respuesta.data.status === "Error") {
            dispatch({
                type: ERROR,
                payload: respuesta.data.message,
            });

            dispatch({
                type: MODELO_CUENTA_REPETIDA,
                payload: respuesta.data.data,
            });
        
        }

        /*
         */
    } catch (error) {
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem("token", "");
            window.localStorage.setItem("userData", "");
        } else {
            dispatch({
                type: ERROR,
                payload: error.message,
            });
        }
    }
};



export const getEmailsAccount = (id) => async (dispatch) => {

    try {
         

        let headers = {
            "Content-Type": "application/json",
            Authorization: window.localStorage.getItem("token"),
        };
        const respuesta = await axios.get(URL + `getEmailsAccount/${id}`, {
            headers: headers,
        });
        if (respuesta.data.status === "Success") {
     
            dispatch({
        
                type: CORREOS_CUENTAS,
                payload: respuesta.data.data
                
            
            })

        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
     
    } catch (error) {
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem("token", "");
            window.localStorage.setItem("userData", "");
        } else {
            dispatch({
                type: ERROR,
                payload: error.message,
            });
        }
    }
};

export const vincularCuenta = (datoscuenta) => async (dispatch) => {

    try {
        let json = JSON.stringify(datoscuenta);
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            Authorization: window.localStorage.getItem("token"),
        };
        const respuesta = await axios.post(URL + "createEmailAccount", params, {
            headers: headers,
        });
        if (respuesta.data.status === "Success") {
     
            const respuesta = await axios.get(URL + `getEmailsAccount/${datoscuenta.cuenta_id}`, {
                headers: headers,
            });
            if (respuesta.data.status === "Success") {
         
                dispatch({
            
                    type: CORREOS_CUENTAS,
                    payload: respuesta.data.data
                    
                
                })
    
            }
        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
     
    } catch (error) {
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem("token", "");
            window.localStorage.setItem("userData", "");
        } else {
            dispatch({
                type: ERROR,
                payload: error.message,
            });
        }
    }
};

export const transferirCuenta = (datoscuenta) => async (dispatch) => {

    try {
        let json = JSON.stringify(datoscuenta);
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            Authorization: window.localStorage.getItem("token"),
        };
        const respuesta = await axios.post(URL + "transferAccountsModel", params, {
            headers: headers,
        });
        if (respuesta.data.status === "success") {
            dispatch({
                type: SUCCESS,
                payload: respuesta.data.message,
            });

            dispatch({
                type: CUENTA_CREADA,
                payload: respuesta.data.data,
            });


            try {
          
                let headers = {
                    "Content-Type": "application/json",
                    Authorization: window.localStorage.getItem("token"),
                };
                const respuesta = await axios.get(
                    URL + `getAllAccountsModel/${datoscuenta.actualModelId}/${datoscuenta.plataformas_id}`,
                    {
                        headers: headers,
                    }
                );
            
                
                if (respuesta.data.status === "Success") {
        
        
              
        
                    if(datoscuenta.plataformas_id===1){
                        dispatch({
                            type: CONSULTAR_CUENTAS_CHATURBATE,
                            payload: respuesta.data.data,
                        });
        
                    }
                    else if(datoscuenta.plataformas_id===5){
                        dispatch({
                            type: CONSULTAR_CUENTAS_STRIPCHAT,
                            payload: respuesta.data.data,
                        });
        
        
                    }else if(datoscuenta.plataformas_id===4){
        
                        
                    dispatch({
                        type: CONSULTAR_CUENTAS_CAM4,
                        payload: respuesta.data.data,
                    });
        
                    }else if(datoscuenta.plataformas_id===2){
        
                        
                        dispatch({
                            type: CONSULTAR_CUENTAS_BONGA,
                            payload: respuesta.data.data,
                        });
            
                        }else if(datoscuenta.plataformas_id===7){
        
                        
                        dispatch({
                            type: CONSULTAR_CUENTAS_FLIRT,
                            payload: respuesta.data.data,
                        });
            
                        }else{
                        dispatch({
                            type: CONSULTAR_CUENTAS_STREAMATE,
                            payload: respuesta.data.data,
                        });
        
                    }
        
                 
                }
              
            } catch (error) {
                dispatch({
                    type: ERROR,
                    payload: error.message,
                });
            }
        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
     
    } catch (error) {
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem("token", "");
            window.localStorage.setItem("userData", "");
        } else {
            dispatch({
                type: ERROR,
                payload: error.message,
            });
        }
    }
};


export const CrearCuenta = (datoscuenta) => async (dispatch) => {

    try {
        let json = JSON.stringify(datoscuenta);
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            Authorization: window.localStorage.getItem("token"),
        };
        const respuesta = await axios.post(URL + "createAccountsModel", params, {
            headers: headers,
        });
        if (respuesta.data.status === "success") {
            dispatch({
                type: SUCCESS,
                payload: respuesta.data.message,
            });

            dispatch({
                type: CUENTA_CREADA,
                payload: respuesta.data.data,
            });


            try {
          
                let headers = {
                    "Content-Type": "application/json",
                    Authorization: window.localStorage.getItem("token"),
                };
                const respuesta = await axios.get(
                    URL + `getAllAccountsModel/${datoscuenta.modelos_id}/${datoscuenta.plataformas_id}`,
                    {
                        headers: headers,
                    }
                );
            
                
                if (respuesta.data.status === "Success") {
        
        
              
        
                    if(datoscuenta.plataformas_id===1){
                        dispatch({
                            type: CONSULTAR_CUENTAS_CHATURBATE,
                            payload: respuesta.data.data,
                        });
        
                    }
                    else if(datoscuenta.plataformas_id===5){
                        dispatch({
                            type: CONSULTAR_CUENTAS_STRIPCHAT,
                            payload: respuesta.data.data,
                        });
        
        
                    }else if(datoscuenta.plataformas_id===4){
        
                        
                    dispatch({
                        type: CONSULTAR_CUENTAS_CAM4,
                        payload: respuesta.data.data,
                    });
        
                    }else if(datoscuenta.plataformas_id===2){
        
                        
                        dispatch({
                            type: CONSULTAR_CUENTAS_BONGA,
                            payload: respuesta.data.data,
                        });
            
                        }else if(datoscuenta.plataformas_id===7){
        
                        
                        dispatch({
                            type: CONSULTAR_CUENTAS_FLIRT,
                            payload: respuesta.data.data,
                        });
            
                        }else{
                        dispatch({
                            type: CONSULTAR_CUENTAS_STREAMATE,
                            payload: respuesta.data.data,
                        });
        
                    }
        
                 
                }
              
            } catch (error) {
                dispatch({
                    type: ERROR,
                    payload: error.message,
                });
            }
        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
     
    } catch (error) {
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem("token", "");
            window.localStorage.setItem("userData", "");
        } else {
            dispatch({
                type: ERROR,
                payload: error.message,
            });
        }
    }
};

export const eliminar = (id) => async (dispatch) => {
    dispatch({
        type: CARGANDO,
    });

    try {
  

        let headers = {
            "Content-Type": "application/json",
            Authorization: window.localStorage.getItem("token"),
        };
        const respuesta = await axios.delete(URL + `destroyAccountsModel/${id}`, {
            headers: headers,
        });
        if (respuesta.data.status === "Success") {
            dispatch({
                type: SUCCESS,
                payload: respuesta.data.message,
            });

            dispatch({
                type: FLAG_CUENTAS,
                payload: false,
            });
        }
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
     

    } catch (error) {
        dispatch({
            type: ERROR,
            payload: "Error al intentar realizar esta operación",
        });
    }
};

export const consultar_modelo_cuentas = (model_id) => async (dispatch) => {
    dispatch({
        type: CARGANDO,
    });

    try {


        let headers = {
            "Content-Type": "application/json",
            Authorization: window.localStorage.getItem("token"),
        };
        const respuesta = await axios.get(
            URL + `getAccountsModel/${model_id}`,
            {
                headers: headers,
            }
        );
    
        
        if (respuesta.data.status === "Success") {
            dispatch({
                type: CONSULTAR_NOMBRE_MODELO,
                payload: respuesta.data.modelName,
            });

            dispatch({
                type: CONSULTAR_CUENTAS_CHATURBATE,
                payload: respuesta.data.chaturbateC,
            });

            dispatch({
                type: CONSULTAR_CUENTAS_STRIPCHAT,
                payload: respuesta.data.StripchatC,
            });


            dispatch({
                type: CONSULTAR_CUENTAS_CAM4,
                payload: respuesta.data.cam4C,
            });

            dispatch({
                type: CONSULTAR_CUENTAS_BONGA,
                payload: respuesta.data.bongaC,
            });

            
            dispatch({
                type: CONSULTAR_CUENTAS_STREAMATE,
                payload: respuesta.data.streamateC,
            });
            dispatch({
                type: CONSULTAR_CUENTAS_FLIRT,
                payload: respuesta.data.flirtC,
            });
        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
      
    } catch (error) {
        dispatch({
            type: ERROR,
            payload: "Intente mas tarde - Cuenta Ocupada",
        });
    }
};






export const consultar_todas_cuentas_modelo = (model_id,plataforma_id) => async (dispatch) => {
    dispatch({
        type: CARGANDO,
    });

    try {
 

        let headers = {
            "Content-Type": "application/json",
            Authorization: window.localStorage.getItem("token"),
        };
        const respuesta = await axios.get(
            URL + `getAllAccountsModel/${model_id}/${plataforma_id}`,
            {
                headers: headers,
            }
        );
    
        
        if (respuesta.data.status === "Success") {


      

            if(plataforma_id===1){
                dispatch({
                    type: CONSULTAR_CUENTAS_CHATURBATE,
                    payload: respuesta.data.data,
                });

            }
            else if(plataforma_id===5){
                dispatch({
                    type: CONSULTAR_CUENTAS_STRIPCHAT,
                    payload: respuesta.data.data,
                });


            }else if(plataforma_id===4){

                
            dispatch({
                type: CONSULTAR_CUENTAS_CAM4,
                payload: respuesta.data.data,
            });


            }else if(plataforma_id===2){

                
                dispatch({
                    type: CONSULTAR_CUENTAS_BONGA,
                    payload: respuesta.data.data,
                });
    
    
                }else if(plataforma_id===7){

                
                dispatch({
                    type: CONSULTAR_CUENTAS_FLIRT,
                    payload: respuesta.data.data,
                });
    
    
                }else{
                dispatch({
                    type: CONSULTAR_CUENTAS_STREAMATE,
                    payload: respuesta.data.data,
                });

            }

         
        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
      
    } catch (error) {
        dispatch({
            type: ERROR,
            payload: "Intente mas tarde - Cuenta Ocupada",
        });
    }
};


export const cambioUsuarioId = (usuario_cuenta) => (dispatch) => {
    dispatch({
        type: CAMBIO_USUARIO_ID,
        payload: usuario_cuenta,
    });
};

export const cambioPassword = (password_cuenta) => (dispatch) => {
    dispatch({
        type: CAMBIO_PASSWORD,
        payload: password_cuenta,
    });
};

export const limpiarForma = () => async (dispatch) => {
    dispatch({
        type: LIMPIAR,
    });
};

export const researchtrue = () => (dispatch) => {
    dispatch({
        type: RESEARCHTRUE,
    });
};


export const handleChangeCuentas  = (index, dataType, value,plataforma_id) => (dispatch,getState) => {

    const {cuentasChaturbate} = getState().CuentasReducer
    const {cuentasStripchat} = getState().CuentasReducer
    const {cuentasCam4} = getState().CuentasReducer
    const {cuentasBonga} = getState().CuentasReducer
    const {cuentasStreamate} = getState().CuentasReducer
    const {cuentasFlirt} = getState().CuentasReducer
    let varMap= []
    let dispatchVarialbe= ''
    if(plataforma_id===1){
       varMap=cuentasChaturbate
       dispatchVarialbe=CONSULTAR_CUENTAS_CHATURBATE
    }
    else if(plataforma_id===5){
        varMap=cuentasStripchat
        dispatchVarialbe=CONSULTAR_CUENTAS_STRIPCHAT

    }else if(plataforma_id===4){

        varMap=cuentasCam4
        dispatchVarialbe=CONSULTAR_CUENTAS_CAM4

    }else if(plataforma_id===2){

        varMap=cuentasBonga
        dispatchVarialbe=CONSULTAR_CUENTAS_BONGA

    }else if(plataforma_id===7){

        varMap=cuentasFlirt
        dispatchVarialbe=CONSULTAR_CUENTAS_FLIRT

    }else{
        varMap=cuentasStreamate
        dispatchVarialbe=CONSULTAR_CUENTAS_STREAMATE
    }


    const newinfo = varMap.map((item, i) => {
        if (i === index) {
          return {...item, [dataType]: value};
        }
        return item;
      });
  

      dispatch({
        type: dispatchVarialbe,
        payload:newinfo
    })


    

   
};

