import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
import * as alertasPlataformasAction from "../../actions/AlertasPlataformasAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
//import {URL} from '../../../src/General/url'
/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */
export class AlertasPlataforma extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permisosArray: [],
      id_lugar: "",
      nombre_lugar: "",
      nombre_permiso: "",
    };
  }

  async componentDidMount() {
    const { consultar_correos } = this.props;

    await consultar_correos();
  }
  componentDidUpdate() {
    const { consultar_correos } = this.props;
    console.log(this.state);
    if(!this.props.correos_consulta){
      consultar_correos()
    }
  }

  componentWillUnmount() {
    this.props.researchtrue();
  }

  handleSubmit(e) {
    e.preventDefault();
  }



 

  deletePermiso(id) {
    const { eliminarEmailReport } = this.props;
   
    eliminarEmailReport(id)
  }


  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  ListaPermisosBaseDatos = () => {
    const { ListaPermisos } = this.props;
    const ponerPermisosBaseDatos = () =>
    ListaPermisos.map((permiso, key) => (
      <div
      className="col-md-3" style={{textAlign:'center',
      borderRadius: '8px',
      padding: '5px',
     }} >
        <div style={ {background: '#eeeeee'}}>
          <div><strong>{permiso.plataforma}</strong></div>
      <div><strong>{`${permiso.nombres} ${permiso.apellidos}`} </strong></div>
      <div><strong>{permiso.fecha_creacion}</strong></div>
      <div><strong>{permiso.lugar}</strong></div>
      

      <textarea defaultValue={permiso.mensaje} readOnly style={{width:'80%'}} rows={5}></textarea>
      </div>
    </div>
      ));

    return ponerPermisosBaseDatos();
  };
  
  crearPermiso = (e) => {
    e.preventDefault();

    const {
      crear_permiso_extension,
    } = this.props;
    
    const data = {
      nombre: this.state.nombre_permiso,
    };

    crear_permiso_extension(data);
  };

  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {


    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">ALERTAS PLATAFORMA</h1>

         
            {this.mostrarAccion()}
            <div className="container-fluid">
      
            <div className="row">
                {this.ListaPermisosBaseDatos()}
                </div>

           
            </div>
          </div>
      
       
   
      </Fragment>
    );
  }
}
const mapStateToProps = (reducers) => {
  return reducers.alertasPlataformasReducer;
};

export default connect(mapStateToProps, alertasPlataformasAction)(AlertasPlataforma);
