import {
    CARGANDO, ERROR, GUARDAR, CONSULTAR, RESEARCHTRUE, CONSULTAR_EVENTO_PROXIMO, CAMBIO_SEARCH, CONTAR_MODELO, CAMBIO_CONCEPTO, CONTAR_TAREAS, CONSULTAR_TAREAS
    , GUARDARTAREAS,SUCCESS,CAMBIO_SEARCH_TAREAS,CONSULTAR_MODELO
} from '../types/HomeTypes'

const INITIAL_STATE = {
    eventosProximos: [],
    tareas: [],
    countModels: 0,
    tareasc: 0,
    cargando: false,
    error: '',
    regresar: false,
    search: '',
    concepto: '',
    researchtareas:false,
    success:''
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTAR:
            return {
                ...state,
                eventosProximos: action.payload,
                cargando: false,
                regresar: false

            }

        case CONSULTAR_TAREAS:
            return {
                ...state,
                tareas: action.payload,
                cargando: false,
                research: false,


            }

        case CARGANDO:
            return { ...state, cargando: true }

        case ERROR:
            return { ...state, error: action.payload, cargando: false }

            case SUCCESS:
                return { ...state, success: action.payload, cargando: false, error: '' }

        case CONTAR_MODELO:
            return {
                ...state,
                countModels: action.payload,
                cargando: false,
                regresar: false

            }

        case GUARDARTAREAS:
            return {
                ...state,
                cargando: false,
                error: '',
                concepto: '',
                researchtareas: true,
                success: ''


            }

        case GUARDAR:
            return {
                ...state,
                modelos: action.payload,
                cargando: false,
                error: '',
                isAuth: true


            }
        case CONSULTAR_MODELO:
            return {
                ...state,
                modelos: action.payload,
                cargando: false,
                regresar: false

            }

            case CONSULTAR_EVENTO_PROXIMO:
                return {
                    ...state,
                    eventosProximos: action.payload,
                    cargando: false,
                    regresar: false
    
                }
        case CAMBIO_SEARCH:
            return {
                ...state,
                search: action.payload,

            }

            case CAMBIO_SEARCH_TAREAS:
                return {
                    ...state,
                    researchtareas: action.payload,
    
                }    

        case RESEARCHTRUE
            :
            return {
                ...state,
                eventosProximos: [],
    tareas: [],
    countModels: 0,
    tareasc: 0,
    cargando: false,
    error: '',
    regresar: false,
    search: '',
    concepto: '',
    researchtareas:false,
    success:''


            }

        case CAMBIO_CONCEPTO:
            return {
                ...state,
                concepto: action.payload,

            }
        case CONTAR_TAREAS:
            return {
                ...state,
                tareasc: action.payload,
                cargando: false,
                research: false,


            }
        default: return state
    }

}