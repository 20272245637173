export const CONSULTAR = 'prestamo_grande_traer_todas';
export const CONSULTARS = 'prestamo_grande_traer_todas_suggestions';
export const CONSULTAR_TOTALES = 'prestamo_grande_traer_todas_prestamos';
export const CARGANDO = 'prestamo_grande_cargando';
export const ERROR = 'prestamo_grande_error';
export const GUARDAR = 'prestamo_grande_guardada';
export const ACTUALIZAR = 'prestamo_grande_actualizar';
export const LIMPIAR = 'prestamo_grande_limpiar';
export const RESEARCHTRUE = 'prestamo_grande_research';
export const RESEARPRESTAMO = 'prestamo_grande_research_prestamo';
export const SUCCESS = 'prestamo_grande_succes';
export const CAMBIO_FECHAPRESTAMO = 'prestamo_grande_fecha';
export const CAMBIO_CANTIDAD = 'prestamo_grande_cantidad';
export const CAMBIO_CONCEPTO = 'prestamo_grande_concepto';
export const CAMBIO_FECHA1 = 'prestamo_grande_fecha1';
export const CAMBIO_FECHA2 = 'prestamo_grande_fecha2';
export const FILTRAR_PRESTAMOS_MINIMOS = 'prestamo_grande_minimos_filtrar';
export const ULTIMO_PAGO = 'prestamo_grande_ultimo_pago';








