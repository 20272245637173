export const CONSULTAR = 'pagos_traer_todas';
export const CARGANDO = 'pagos_cargando';
export const ERROR = 'pagos_error';
export const GUARDAR = 'pagos_guardada';
export const ACTUALIZAR = 'pagos_actualizar';
export const LIMPIAR = 'pagos_limpiar';
export const RESEARCHTRUE = 'pagos_research';
export const SUCCESS = 'pagos_succes';
export const CAMBIO_FECHAPRESTAMO = 'pagos_fecha';
export const CAMBIO_CANTIDAD = 'pagos_cantidad';
export const CAMBIO_DOLAR = 'pagos_dolar';
export const CAMBIO_FECHA1 = 'pagos_fecha1';
export const CAMBIO_FECHA2 = 'pagos_fecha2';
export const CAMBIO_PAGOID = 'pagos_pagoid';
export const CONSULTAR_TAREAS_REALIZADAS = 'pagos_traer_realizadas';
export const RELOAD = 'pagos_reload';
export const CONTAR_TAREAS = 'pagos_contar';
export const CAMBIO_FECHA_END = 'pagos_fecha_end'
export const NOT_FOUND = 'modelos_no_encontrados_archivo_excel'
export const CAMBIO_FECHA_END_EMAILS = 'pagos_fecha_end_emails'











