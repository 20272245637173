export const CONSULTAR = 'tokens_agregar_traer_todas';
export const CONSULTARS = 'tokens_agregar_traer_todas_suggestions';
export const CONSULTAR_TOTALES = 'tokens_agregar_traer_todas_prestamos';
export const CARGANDO = 'tokens_agregar_cargando';
export const ERROR = 'tokens_agregar_error';
export const GUARDAR = 'tokens_agregar_guardada';
export const ACTUALIZAR = 'tokens_agregar_actualizar';
export const LIMPIAR = 'tokens_agregar_limpiar';
export const RESEARCHTRUE = 'tokens_agregar_research';
export const RESEARPRESTAMO = 'tokens_agregar_research_prestamo';
export const SUCCESS = 'tokens_agregar_succes';
export const CAMBIO_FECHAPRESTAMO = 'prestamos_fecha';
export const CAMBIO_CANTIDAD = 'tokens_agregar_cantidad';
export const CAMBIO_CONCEPTO = 'tokens_agregar_concepto';
export const CAMBIO_FECHA1 = 'tokens_agregar_fecha1';
export const CAMBIO_FECHA2 = 'tokens_agregar_fecha2';
export const FILTRAR_PRESTAMOS_MINIMOS = 'tokens_agregar_minimos_filtrar';
export const FILTRAR_TOKENS = 'tokens_agregar_tokens_filtrar';
export const OBTENER_LISTA_LUGARES = 'lugar_obtener_todo';
export const AGREGAR_LISTA_LUGARES = 'lugar_agregar_lugar';
export const ELIMINAR_LISTA_LUGARES = 'lugar_eliminar_lugar';
export const CAMBIO_NOMBRE_LUGAR = 'cambio_lugar_nombre';
export const CONSULTAR_SERVICIOS = 'consultar_servicios_plataforma_estuidio';







