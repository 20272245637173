import React, { useState,useRef } from 'react'
import Overlay from 'react-bootstrap/Overlay'
import candadoImg from "../../img/candadoImg.png";
function Example(props) {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    let mensajeImprimir=props.mensaje

    return (
      <>

       {props.data==='cuentas'
       ?<img alt="danger popover img" src={candadoImg} className='popover__Candado__cuentas' variant="danger" ref={target} onClick={() => setShow(!show)}/>
       : <img alt="danger popover img 2" src={candadoImg} className='popover__Candado' variant="danger" ref={target} onClick={() => setShow(!show)}/>
      }
        

        <Overlay target={target.current} show={show} placement="right">
          {({
            placement,
            scheduleUpdate,
            arrowProps,
            outOfBoundaries,
            show: _show,
            ...props
          }) => (
            <div
              {...props}
              style={{
                backgroundColor: 'rgba(64, 35, 74, 0.85)',
                padding: '2px 10px',
                color: 'white',
                borderRadius: 3,
                ...props.style,
              }}
            >
           {mensajeImprimir}
            </div>
          )}

        </Overlay>
      </>
    );
  }


  export default Example