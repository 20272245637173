import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
//import seeBtnImg from "../../img/seeBtnImg.png";
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
import * as habitacionagregaraction from "../../actions/HabitacionesAgregarAction";
import { Link } from "react-router-dom";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import {  BsPersonCheck } from "react-icons/bs";

/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */
export class HabitacionesView360 extends Component {
  constructor(props) {
    super(props)
    this.viewer = React.createRef();


    this.state = {
      value: "",
      id: "",
      text: "",
      suggestVar: false,
    }
  }



  componentDidMount() {
    const {
      contar_habitacion_sede
    } = this.props

    
    contar_habitacion_sede();


  }

  componentDidUpdate() {
    console.log(this.props)
  }


  componentWillUnmount() {
    this.props.researchtrue()
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  ListaTotalHab = () => {
    const { totalHab } = this.props;
    const ponerOpcionesTurnos = () =>
      totalHab.map((habitaciones, key) => (
        <div className="col-md-2 mx-auto " style={{backgroundColor: 'rgba(200, 200, 200, 0.3)',cursor:'pointer',borderRadius:'5px'}} key={key}  onClick={()=>{this.props.consultarAllRooms(habitaciones.lugares_id)}}>
          <h4 style={{ textAlign: "center" }}> {habitaciones.sede_hab} </h4>
          <h4 style={{ textAlign: "center" }}> {habitaciones.total_hab} </h4>
        </div>
      ));

    return ponerOpcionesTurnos();
  };


  ponerHabitaciones = () => {

    const { todasHabitaciones } = this.props


    const ponerInfo = () =>
      todasHabitaciones.map((habitacion, key) => (

      
        <div className='col-md-4 mx-auto' style={{backgroundColor: 'rgba(200, 200, 200, 0.3)',borderRadius:'5px'}} key={key}>
        {habitacion[0].nombres || habitacion[1].nombres || habitacion[2].nombres ?
        <Fragment>
          <div className='d-flex'>
          <Link to={`/HabitacionesView360/${habitacion[0].habitacion_id}`}>
            <h1>{habitacion[0].numero_habitacion}</h1>
          </Link></div>

<div className='d-flex'>
  
  
<table class="default">

<tr>

  <td>{`${habitacion[0].nombre ? habitacion[0].nombre : ''}: ${habitacion[0].nombres ? habitacion[0].nombres : ''} ${habitacion[0].apellidos ? habitacion[0].apellidos : ''}`}</td>
  <td>{habitacion[0].nombres?  <Link to={`/InventarioRoomModelo/${key}/${0}`}> <BsPersonCheck
                size={"1.5vw"}
                className="btn__entregar"
              /></Link>:''}</td>
</tr>

<tr>

  <td>{`${habitacion[1].nombre ? habitacion[1].nombre : ''}: ${habitacion[1].nombres ? habitacion[1].nombres : ''} ${habitacion[1].apellidos ? habitacion[1].apellidos : ''}`}</td>
  <td> {habitacion[1].nombres?  <Link to={`/InventarioRoomModelo/${key}/${1}`}> <BsPersonCheck
                size={"1.5vw"}
                className="btn__entregar"
              /></Link>:''}</td>

</tr>

<tr>

  <td>{`${habitacion[2].nombre ? habitacion[2].nombre : ''}: ${habitacion[2].nombres ? habitacion[2].nombres : ''} ${habitacion[2].apellidos ? habitacion[2].apellidos : ''}`} </td>
  <td> {habitacion[2].nombres?  <Link to={`/InventarioRoomModelo/${key}/${2}`}> <BsPersonCheck
                size={"1.5vw"}
                className="btn__entregar"
              /></Link>:''}</td>

</tr>

{/* <tr>

  <td>{`${habitacion[3].nombre ? habitacion[3].nombre : ''}: ${habitacion[3].nombres ? habitacion[3].nombres : ''} ${habitacion[3].apellidos ? habitacion[3].apellidos : ''}`}  </td>
  <td>  {habitacion[2].nombres?  <Link to={`/InventarioRoomModelo/${key}/${3}`}> <BsPersonCheck
                size={"1.5vw"}
                className="btn__entregar"
              /></Link>:''}</td>

</tr> */}

</table>
        </div>


        </Fragment>:''}

          
   
     
        </div>
      ));

    return ponerInfo();


  }



  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }






  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {

    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            ROOMS <strong className="tituloPaginasPequeño">TURNOS</strong>
          </h1>

          <div className="">


            {this.mostrarAccion()}
            <div className="container-fluid">
              <div className="row">
                {this.ListaTotalHab()}
                <div className="col-md-12 mx-auto">
                <div className="row" style={{marginTop:'10px'}}>{this.ponerHabitaciones()}</div>
              </div>
               
               

              </div>



            </div>
          </div>




        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.HabitacionesAgregarReducer;
};

export default connect(mapStateToProps, habitacionagregaraction)(HabitacionesView360);
