export const CONSULTAR = 'nomina_traer_todas';
export const CARGANDO = 'nomina_cargando';
export const ERROR = 'nomina_error';
export const GUARDAR = 'nomina_guardada';
export const ACTUALIZAR = 'nomina_actualizar';
export const LIMPIAR = 'nomina_limpiar';
export const RESEARCHTRUE = 'nomina_research';
export const SUCCESS = 'nomina_succes';
export const CAMBIO_FECHAPRESTAMO = 'nomina_fecha';
export const CAMBIO_CANTIDAD = 'nomina_cantidad';
export const CAMBIO_DOLAR = 'nomina_dolar';
export const CAMBIO_FECHA1 = 'nomina_fecha1';
export const CAMBIO_FECHA2 = 'nomina_fecha2';
export const CAMBIO_PAGOID = 'nomina_pagoid';
export const CONSULTAR_TAREAS_REALIZADAS = 'nomina_traer_realizadas';
export const RELOAD = 'nomina_reload';
export const CONTAR_TAREAS = 'nomina_contar';
export const CONSULTAR_FECHAS_PAGOS = 'nomina_consultar_nomina_fechas';
export const CAMBIO_FECHA_PAGOS = 'nomina_cambio_nomina_fechas';
export const CAMBIO_FECHA_PAGOS_MONITORES = 'nomina_cambio_nomina_fechas_monitores_id';
export const CONSULTAR_PAGO_NOMINA = 'nomina_pago_fechas';
export const CONSULTAR_PAGO_NOMINA_NO_ARRIENDO = 'nomina_pago_fechas_no_arriendo';
export const CONSULTAR_PAGO_NOMINA_TOTALES = 'nomina_pago_totales_fechas';
export const CONSULTAR_FECHAS_PAGOS_MONITORES = 'nomina_cambio_nomina_fechas_monitores';
export const CONSULTAR_PAGO_NOMINA_MONITORES = 'nomina_pago_fechas_monitores';
export const CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA = 'nomina_pago_fechas_modelos_bancaria';
export const CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA_NO_BANCARIA = 'nomina_pago_fechas_modelos_no_bancaria';
export const CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA_SUMA = 'nomina_pago_fechas_modelos_bancaria_suma';
export const CONSULTAR_PAGO_NOMINA_MODELOS_PLANILLA_NO_BANCARIA_SUMA = 'nomina_pago_fechas_modelos_no_bancaria_suma';
export const IMAGEN_PERFIL_LOGO = 'nomina_pago_logo';
export const CONSULTAR_YEAR = 'nomina_year';
export const CONSULTAR_GANANCIAS_PLATAFORMA = 'nomina_ganancias_plataforma';
export const CONSULTAR_GANANCIAS_PLATAFORMA_TOTAL = 'nomina_ganancias_plataforma_total';
export const CONSULTAR_LUGARES = 'consultar_nomina_lugares';















