import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
import { Link } from "react-router-dom";
import * as permisosaction from "../../actions/PermisosUsuariosAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import btonAgregarPermiso from "../../img/btonAgregarPermiso.png";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import { FiDelete } from "react-icons/fi";
import { FaEye } from "react-icons/fa";
//import {URL} from '../../../src/General/url'
/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */
export class permisosUsuarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permisosArray: [],
      id_lugar: "",
      nombre_lugar: "",
      nombre_permiso: "",
    };
  }

  async componentDidMount() {
    const { consultar_lugares,consultar_roles } = this.props;
    await consultar_lugares();
    await consultar_roles();
  }
  componentDidUpdate() {
    console.log(this.state);
  }

  componentWillUnmount() {
    this.props.researchtrue();
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  setLugar() {
    const found = this.state.permisosArray.find(
      (element) => element.id === this.state.id_lugar
    );
    if (!found) {
      if (this.state.id_lugar) {
        this.state.permisosArray.push({
          id: this.state.id_lugar,
          nombre: this.state.nombre_lugar,
        });
        this.setState({ nombre_lugar: "", id_lugar: "" });
      }
    }
  }

  listaLugares = () => {
    const { ListaLugares } = this.props;

    const poneropcioneslugaress = () =>

      ListaLugares.map((lugares, key) => (
        <option className="option-values" key={key} value={key}>
          {lugares.nombre}
        </option>
      ));

    return (
      <Fragment>
        <select
          className="right-submenu"
          name="select"
          required
          onChange={(e) => {
            e.target.value==='todo'? this.setState({
              id_lugar: '0',
              nombre_lugar: 'Todos',
            }): this.setState({
              id_lugar: this.props.ListaLugares[e.target.value].id,
              nombre_lugar: this.props.ListaLugares[e.target.value].nombre,
            })
           
          }}
        >
           
          <option className="option-values">Lugares</option>
          <option className="option-values" value='todo'>
    Todos
  </option>
          {poneropcioneslugaress()}
        </select>
    
      </Fragment>
    );
  };

  deletePermiso(id) {
    const arrayPer = this.state.permisosArray;
    const newArrayPer = Object.values(
      Object.fromEntries(
        Object.entries(arrayPer).filter(([key, val]) => val.id !== id)
      )
    );

    this.setState({ permisosArray: newArrayPer });
  }

  listaPermisos = () => {
    const ponerPermisos = () =>
      this.state.permisosArray.map((permiso, key) => (
        <tr key={key}>
          <td>{permiso.nombre}</td>
          <td>
            {" "}
            <span
              className="Permisos_Usuarios_Stage__insideDiv__icon__add"
              onClick={(e) => {
                this.deletePermiso(permiso.id);
              }}
            >
              <FiDelete size={"32px"} />
            </span>
          </td>
        </tr>
      ));

    return ponerPermisos();
  };

  ponerHabitaciones = () => {
    const ponerInfo = () => (
      <Fragment>
        
        <tr>
          <td>Calendario</td>
          <td>
            <input type="checkbox" className="checkbox" id="Calendario" />
          </td>
        </tr>
        <span className="pageTitulos">FINANZAS</span>
        <tr>
          <td>Reportes-Ver</td>
          <td>
            <input type="checkbox" className="checkbox" id="ReportesVer" />
          </td>
        </tr>
        <tr>
          <td>Reportes-Dia</td>
          <td>
            <input type="checkbox" className="checkbox" id="ReportesDia" />
          </td>
        </tr>
        <tr>
          <td>Reportes-Dia-Actualizar</td>
          <td>
            <input type="checkbox" className="checkbox" id="reporte_dia_actualizar" />
          </td>
        </tr>
        <tr>
          <td>Reportes-Modelos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="ReportesModelos"
            />
          </td>
        </tr>
        <tr>
          <td>Reportes-Deuda-Modelos</td>
          <td>
            <input type="checkbox" className="checkbox" id="deudaModelos" />
          </td>
        </tr>    
        <tr>
          <td>Reportes-Diario</td>
          <td>
            <input type="checkbox" className="checkbox" id="ReportesDiario" />
          </td>
        </tr>
        <tr>
          <td>Reportes-Total</td>
          <td>
            <input type="checkbox" className="checkbox" id="ReportesTotal" />
          </td>
        </tr>
        <tr>
          <td>Reportes-Total-Sin-Descuento</td>
          <td>
            <input type="checkbox" className="checkbox" id="ReportesTotalSinDescuento" />
          </td>
        </tr>
        <tr>
          <td>Reportes-RTE</td>
          <td>
            <input type="checkbox" className="checkbox" id="ReportesRTE" />
          </td>
        </tr>
        <tr>
          <td>prestamos-Pequeños-Modelos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="prestamosPequenosModelos"
            />
          </td>
        </tr>
        <tr>
          <td>prestamos-Grandes-Modelos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="prestamosGrandesModelos"
            />
          </td>
        </tr>
        <tr>
          <td>nómina-Pagos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="nominaPagos"
  
            />
          </td>
        </tr>
        <tr>
          <td>nómina-Recibos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="nominaRecibos"

            />
          </td>
        </tr>
        <tr>
          <td>nómina-Porcentaje</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="nominaPorcentaje"
            />
          </td>
        </tr>
        <tr>
          <td>multas-Agregar-Modelos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="multasAgregarModelos"
            />
          </td>
        </tr>
        <tr>
          <td>multas-Reporte-Modelos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="multasReporteModelos"
            />
          </td>
        </tr>

        <tr>
          <td>servicios-Reporte-</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="serviciosreporte"
            />
          </td>
        </tr>

        <span className="pageTitulos">MODELOS</span>

        <tr>
          <td>perfil-Modelos-Ver</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="perfilModelosVer"
           
            />
          </td>
        </tr>
        <tr>
          <td> perfil-Modelos-Agregar</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="perfilModelosAgregar"
        
            />
          </td>
        </tr>
        <tr>
          <td>correos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="correos"
  
            />
          </td>
        </tr>
        <tr>
          <td>habitación-Agregar</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="habitacionAgregar"

            />
          </td>
        </tr>
        <tr>
          <td>habitación-Ver-Turnos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="habitacionVerTurnos"

            />
          </td>
        </tr>
        <tr>
          <td>habitación-Ver-Reportes</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="habitaciónVerReportes"
            />
          </td>
        </tr>
        <tr>
          <td>camaras</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="camaras"

            />
          </td>
        </tr>

        <span className="pageTitulos">MONITORES</span>
        <tr>
          <td>perfil-Monitores-Ver</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="perfilMonitoresVer"

            />
          </td>
        </tr>
        <tr>
          <td>perfil-Monitores-Agregar</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="perfilMonitoresAgregar"

            />
          </td>
        </tr>
        <tr>
          <td>préstamos-Pequeños-Monitores</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="prestamosPequenosMonitores"

            />
          </td>
        </tr>
        <tr>
          <td>préstamos-Grandes-Monitores</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="prestamosGrandesMonitores"

            />
          </td>
        </tr>
        <tr>
          <td>multas-Grandes-Monitores</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="multasGrandesMonitores"

            />
          </td>
        </tr>
        <tr>
          <td>multas-Agregar-Monitores</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="multasAgregarMonitores"

            />
          </td>
        </tr>
        <tr>
          <td>multas-Ganancias-Monitores</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="multasGananciasMonitores"
 
            />
          </td>
        </tr>
        <tr>
          <td>multas-Reporte-Monitores</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="monitoresMultasReporte"

            />
          </td>
        </tr>
        <span className="pageTitulos">EXTENSIÓN</span>

        <tr>
          <td>Correo-Alertas</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="correo_alertas"

            />
          </td>
        </tr>
        <tr>
          <td>Palabras-Claves</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="palabras_clave"

            />
          </td>
        </tr>

        <tr>
          <td>Alertas-Plataforma</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="alertasPlataforma"

            />
          </td>
        </tr>
        <span className="pageTitulos">ADMIN</span>
        <tr>
          <td>token</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="token"

            />
          </td>
        </tr>
        <tr>
          <td>lugar</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="lugar"

            />
          </td>
        </tr>
        <tr>
          <td>servicio</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="servicio"

            />
          </td>
        </tr>
        <tr>
          <td>Correo-Reportes</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="correo_reportes"

            />
          </td>
        </tr>
        <tr>
          <td>Usuarios-Agregar</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="UsuariosAgregar"

            />
          </td>
        </tr>
        <tr>
          <td>Usuarios-Permisos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="usuario_permisos"

            />
          </td>
        </tr>
      </Fragment>
    );
    return ponerInfo();
  };

  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  ListaPermisosBaseDatos = () => {
    const { ListaPermisos } = this.props;
    const ponerPermisosBaseDatos = () =>
    ListaPermisos.map((permiso, key) => (
        <tr key={key}>
          <td>{permiso.nombre}</td>
          <td>
            {" "}


            <Link to={`PermisoUsuariosModificar/${permiso.id}`}>
            <span
              className="Permisos_Usuarios_Stage__insideDiv__icon__add"
            >
              <FaEye size={"32px"} />
            </span>
              </Link>
         
          </td>
        </tr>
      ));

    return ponerPermisosBaseDatos();
  };
  
  crearPermiso = (e) => {
    e.preventDefault();

    const {
      crear_permiso,
    } = this.props;

    let arrayPer = this.state.permisosArray;
    let StrigPer = "";
    for (let index = 0; index < arrayPer.length; index++) {
      const element = arrayPer[index];

      if (arrayPer.length > 1) {
        index+1===arrayPer.length?StrigPer = StrigPer + `${element.id}`:StrigPer = StrigPer + `${element.id},`;
      } else {
        StrigPer = StrigPer + `${element.id}`;
      }
    }

    
    const data = {
      nombre: this.state.nombre_permiso,
      lugares_id: StrigPer,
      calendario: document.getElementById(`Calendario`).checked,
      reportesVer: document.getElementById(`ReportesVer`).checked,
      reportesDia: document.getElementById(`ReportesDia`).checked,
      reporte_dia_actualizar:document.getElementById(`reporte_dia_actualizar`).checked,
      reportesModelos: document.getElementById(`ReportesModelos`).checked,
      reportesPagos: '',
      reportesTotalSinDescuento: document.getElementById(`ReportesTotalSinDescuento`).checked,
      reportesDiario:document.getElementById(`ReportesDiario`).checked,
      reportesTotal: document.getElementById(`ReportesTotal`).checked,
      reportesRTE: document.getElementById(`ReportesRTE`).checked,
      modelosPrestamosPequenos: document.getElementById(`prestamosPequenosModelos`).checked,
      modelosPrestamosGrande: document.getElementById(`prestamosGrandesModelos`).checked,
      nominaRecibos: document.getElementById(`nominaRecibos`).checked,
      nominaPagos: document.getElementById(`nominaPagos`).checked,
      nominaPorcentaje: document.getElementById(`nominaPorcentaje`).checked,
      modelosMultasAgregar: document.getElementById(`multasAgregarModelos`).checked,
      modelosMultasReporte: document.getElementById(`multasReporteModelos`).checked,
      modelosPerfilVer: document.getElementById(`perfilModelosVer`).checked,
      modelosPerfilAgregar: document.getElementById(`perfilModelosAgregar`).checked,
      correos: document.getElementById(`correos`).checked,
      habitacionesAgregar: document.getElementById(`habitacionAgregar`).checked,
      habitacionesVerTurnos: document.getElementById(`habitacionVerTurnos`).checked,
      habitacionesVerReportes: document.getElementById(`habitaciónVerReportes`).checked,
      camaras: document.getElementById(`camaras`).checked,
      monitoresPerfilVer: document.getElementById(`perfilMonitoresVer`).checked,
      monitoresPerfilAgregar: document.getElementById(`perfilMonitoresAgregar`).checked,
      monitoresPrestamosPequenos: document.getElementById(`prestamosPequenosMonitores`).checked,
      monitoresPrestamosGrande: document.getElementById(`prestamosGrandesMonitores`).checked,
      monitoresMultasAgregar: document.getElementById(`multasAgregarMonitores`).checked,
      monitoresMultasGanancias: document.getElementById(`multasGananciasMonitores`).checked,
      monitoresMultasReporte: document.getElementById(`monitoresMultasReporte`).checked,
      tokens: document.getElementById(`token`).checked,
      lugares: document.getElementById(`lugar`).checked,
      correo_reportes: document.getElementById(`correo_reportes`).checked,
      Usuarios: document.getElementById(`UsuariosAgregar`).checked,
      usuario_permisos: document.getElementById(`usuario_permisos`).checked,
      correo_alertas: document.getElementById(`correo_alertas`).checked,
      palabras_clave: document.getElementById(`palabras_clave`).checked,
      alertasPlataforma: document.getElementById(`alertasPlataforma`).checked,
      deudaModelos: document.getElementById(`deudaModelos`).checked,
      servicios_reporte: document.getElementById(`serviciosreporte`).checked,
      servicios_rol: document.getElementById(`servicio`).checked,
      
    };

    crear_permiso(data);
  };

  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {


    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">PERMISOS</h1>

          <div className="reporte__div__principal__permisos">
            {this.mostrarAccion()}
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 mx-auto">
                  <div className="row">
                    <div
                      className="col-md-6 mx-auto"
                      style={{ display: "flex",paddingTop: "20px" }}
                    >
                      <div style={{ width: "100%" }}>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="Nombre Permiso"
                          onChange={(e) => {
                            this.setState({ nombre_permiso: e.target.value });
                          }}
                        />
                        <div style={{ width: "100%" }}>{this.listaLugares()}</div>
                      </div>

                      
                      <span
          className="Permisos_Usuarios_Stage__insideDiv__icon__add_permisos"
          onClick={(e) => {
            this.setLugar(e.target.value);
          }}
        >
         <img alt='img para aagregar permisos a la lista' src={btonAgregarPermiso}/>
        </span>
                    </div>
                    <div className="col-md-6 mx-auto">
                      <table className="table table-striped tabla-global" style={{textAlign:"center"}}>
                        <tbody>
                          {this.listaPermisos()}
                        </tbody>
                      </table>
                    </div>
                  </div>
        
                </div>
            
                
              </div>

           
            </div>
          </div>
          <div className="row reporte__div__secundario__permisos" >  
          <div className="col-md-6 mx-auto">
                <table className="table table-striped tabla-global">
                <span className="pageTitulos">MENÚ</span>
                    <tbody>
                   
                      {this.ponerHabitaciones()}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-6 mx-auto">
                <table className="table table-striped tabla-global" >
                        <tbody>
                        <span className="pageTitulos">PERMISOS-CREADOS</span>
                          {this.ListaPermisosBaseDatos()}
                        </tbody>
                      </table>
                </div>

                <div className="col-md-6 mx-auto ">
                <button className="invetario__btn" onClick={this.crearPermiso}>
                  Crear Permiso
                </button>
              </div>
          
          </div>
       
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (reducers) => {
  return reducers.PermisosUsuariosReducer;
};

export default connect(mapStateToProps, permisosaction)(permisosUsuarios);
