import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import SignaturePAd from "react-signature-canvas";
import * as crearaction from "../../actions/CrearAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import firmaIconImg from "../../img/firmaIconImg.png";
import btnAnteriorImg from "../../img/btnAnteriorImg.png";
import btnSiguienteImg from "../../img/btnSiguienteImg.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import defaultLogo from "../../img/defaultLogo.png";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import { createBrowserHistory } from 'history';
import {URL} from '../../../src/General/url'

/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */



const history = createBrowserHistory();
export class AgregarCorreoModelo extends Component {
  constructor(props) {
    super(props);
    this.sigCanvas = React.createRef();
  }

  state = {
    imageCanvasFirma: '',
  }

  componentDidMount() {

  }

  componentDidUpdate() {
    console.log()
  }

  componentWillUnmount() {
    const { researchtrue,successVacio } = this.props;

    researchtrue();
    successVacio()
  }

  handleSubmit(e) {
    e.preventDefault();
  }


  handleChange(dataType, value) {
    this.props.handleChange(dataType, value)
  }

  clear = () => {
    this.sigCanvas.current.clear()
  }
  save = () => {
    const { guardarimagenFirma } = this.props;
    let files = this.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    files = files.replace(/^data:image.+;base64,/, '');
    let filesLocal = this.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    const data = {
      file0: files,

    };
    this.setState({ imageCanvasFirma: filesLocal })
    guardarimagenFirma(data, filesLocal)

  }

  crearModelo = () => {
    const {
      lugares_id,
      turno_id,
      cedulaModelo,
      nombresModelo,
      apellidosModelo,
      telefonoModelo,
      passwordPerfil,
      nickModelo,
      arriendoModelo,
      foto_perfil,
      firma_modelo,
      crearModelo,
      cedulaContable,
      nombresContables,
      apellidosContable,
      cuentaBancaria,
      direccion,
      correo,
      banco,
      passwordCorreo,
      idInformacionContableModeloCreado,
  
    } = this.props;
    const nuevomodelo = {
      id_contable:idInformacionContableModeloCreado,
      horario_id: turno_id,
      lugares_id: lugares_id,
      plataforma_master_id: 1,
      cedula: cedulaModelo,
      nombres: nombresModelo,
      apellidos: apellidosModelo,
      telefono: telefonoModelo,
      passwordm: passwordPerfil,
      nick_name: nickModelo,
      arriendo: arriendoModelo,
      foto_perfil: foto_perfil,
      firma_modelo:firma_modelo,
      documento_contable: cedulaContable,
      nombrescontables: nombresContables,
      apellidoscontables: apellidosContable,
      cuentabancaria: cuentaBancaria,
      direccion: direccion,
      correo:correo,
      passwordc:passwordCorreo,
      banco:banco
      
    };

        crearModelo(nuevomodelo);
  };


  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  deshabilitar = () => {
    const {
      imagen_firma,
    } = this.props;

    if (
      !imagen_firma 
    ) {
     
      return true
    }

    return false
    
  };


  back = (event) => {
    event.preventDefault()
    history.goBack()
  }
  ponerInformacionModelo = () => {

    const ponerInfo = () => (
      <Fragment >
        <div className="col-md-3 ">

          <img
            className="avatarModeloimg"
            alt="imgagerfil"
            src={
              this.props.foto_perfil
                ? `${URL}getImage/${this.props.foto_perfil}`
                : defaultLogo
              }
          />

          <input

            className="form-element-field -hasvalue"
            id={`foto_perfil_modelo`}
            type="file"
            onChange={this.cambioFotoPerfil}
          />

        </div>

        <div className="col-md-9 d-flex">
          {this.props.imagen_firma ? (
            <img
              src={
                this.props.imagen_firma
                  ? this.props.imagen_firma
                  : ""
              }
              alt="mi firma"
              style={{
                display: "block",
                margin: "0 auto",
                objectFit: "contain",
                width: "100%"
              }}
            />
          ) : null}
        </div>
        <div className="col-md-6 mx-auto ">

          <Popup modal

            trigger={<img alt='firma imagen' src={firmaIconImg}
            />}>
            {close => (
              <>
                <SignaturePAd
                  ref={this.sigCanvas}
                  canvasProps={{ className: 'signature__canvas' }} />
                <button onClick={this.save}>Guardar</button>
                <button onClick={this.clear}>Borrar</button>


              </>
            )}


          </Popup>


        </div>

      </Fragment>
    )

    return (ponerInfo())
  }





  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {

    console.log(this.props);
    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            PERFIL <strong className="tituloPaginasPequeño">AGREGAR</strong>
          </h1>
          <div className='Paso__paso__div'>
            <h2>PASO</h2>
            <div className="circuloPasoPaso">
              <h2>4</h2>
            </div>
            <h2>FIRMA</h2>
          </div>

          <div className="reporte__div__principal">

            <div className="container-fluid">
              <div className="row">

                {this.ponerInformacionModelo()}
                {/*  <FirmaModelo/> */}

              </div>



            </div>
          </div>
         {this.mostrarAccion()}
          <div className="paso__paso__div__control">
            <div className="paso__paso__div__central">
              <div className="circuloPasoPaso__control">
                <h2>1</h2>
              </div>

              <div className="circuloPasoPaso__control">
                <h2>2</h2>
              </div>


              <div className="circuloPasoPaso__control">
                <h2>3</h2>
              </div>
              <img src={btnAnteriorImg} onClick={e => this.back(e)} alt="Click aquí para ir al siguiente paso" title="Click aquí para ir al siguiente paso" />
              <div className="circuloPasoPaso__control">
                <h2>4</h2>
              </div>
              <button
            type='button'
            disabled={this.deshabilitar()}
            className='boton__control__paso__paso'
            onClick={e =>this.crearModelo(e)}>
            <img src={btnSiguienteImg}  style={this.deshabilitar()?{opacity:'30%'}:{opacity:'100%'}} alt="Click aquí para subir tu foto" title="Click aquí para ir al anterior paso" />
            </button>
            </div>
          </div>

        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.crearReducer;
};

export default connect(mapStateToProps, crearaction)(AgregarCorreoModelo);
