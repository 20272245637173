export const CONSULTAR = 'crear_traer_todas_monitores';
export const CONSULTAR_TODOS_MONITORES = 'consultar_todos_monitores';
export const CARGANDO = 'crear_cargando_monitores';
export const ERROR = 'crear_error_monitores';
export const GUARDAR = 'crear_guardada_monitores';
export const ACTUALIZAR = 'crear_actualizar_monitores';
export const LIMPIAR = 'crear_limpiar_monitores';
export const RESEARCHTRUE = 'crear_research_monitores';
export const SUCCESS = 'crear_succes_monitores';
export const CAMBIO_USUARIO = 'crear_cambio_cuentabancaria_contable_monitores';
export const CAMBIO_PASSWORD_MONITOR = 'crear_cambio_correo_modelo_monitores';
export const CAMBIO_PERMISOS = 'cambios_permisos';
export const CAMBIO_PERMISOS_PAGOS = 'cambios_permisos_pagos';
export const CAMBIO_PERMISOS_ID = 'crear_cambio_nombre_firma_monitores';
export const CAMBIO_ESTADO_CREAR = 'crear_estado_creacion_monitores';
export const CAMBIO_ESTADO_INICIAL = 'crear_estado_inicial_monitores';
export const RELOAD = 'crear_reload_monitores';
export const IMAGEN_PERFIL = 'perfil_imagen_perfil_monitores';
export const NEW_USERS = 'monitores_new_updates';










