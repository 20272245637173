import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as MultaTotalAction from '../../actions/MultaTotalAction'
import Spinner from '../../General/Spinner'
import Fatal from '../../General/Fatal'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles.scss'
import Success from '../../General/Success'
import { Redirect } from 'react-router-dom'
import DatePicker from 'react-date-picker';
import moment from 'moment'


/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */









export class MultaTotal extends Component {

    state = {
        dateStart: new Date(),
        dateEnd: new Date(),
    }

    componentDidMount() {

        this.cambiofecha1(this.state.dateStart)
        this.cambiofecha2(this.state.dateEnd)


    }


    componentDidUpdate(prevState) {
        console.log(this.props)


    }

    componentWillUnmount() {

        const {
            researchtrue,
        } = this.props

        researchtrue()
    }

filtrMultasEliminadas= () =>{
    const {
        fecha1,
        fecha2,
        consultarMultasEliminadas,

    } = this.props
    const fechas_reporte = {
        fecha_inicial: fecha1,
        fecha_final: fecha2,



    }



    consultarMultasEliminadas(fechas_reporte)
}
    
        filtrReporteTotal = () => {

            const {
                fecha1,
                fecha2,
        
                consultarTotal,

            } = this.props
            const fechas_reporte = {
                fecha_inicial: fecha1,
                fecha_final: fecha2,
        


            }



            consultarTotal(fechas_reporte)



    }



    handleSubmit(e) {
        e.preventDefault()
    }


  
    cambiofecha1 = date => {
        this.setState({ dateStart: date })

        this.props.cambiofecha1((moment(date).format('YYYY/MM/DD') ));
    };

    cambiofecha2 = date => {
        this.setState({ dateEnd: date })
        var fechaFinalString = ''
        if (date) {
            fechaFinalString = ` ${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
        } else {
            fechaFinalString = ''
        }
        this.props.cambiofecha2(fechaFinalString);
    };


    ponerInformeModelo = () => {
        const { MultasTotalSuma,
    
        } = this.props
        const ponerInfo = () => MultasTotalSuma.map((infoModelo, key) => (
          <div className="col-md-12" key={key}>
            <div className='nombre__reporte__container__multas__total'> <div className='nombre__div__reporte'> TOTAL MULTAS </div> <div className='triangulo__report__container'>  </div> <span className='nombre__modelo__div'> {`${infoModelo.total}`} </span>   </div>
          </div>
    
    
        ))
    
        return (ponerInfo())
      }
    

    ponerReporte = () => {
        const { MultasTotal,

        } = this.props
        const ponerInfo = () => MultasTotal.map((reporte, key) => (


            <tr key={key}>
                <td>{reporte.cedula}</td>
                <td>{reporte.nombres} {reporte.apellidos}</td>
                <td>{reporte.motivo} </td>
                <td>{reporte.fecha} </td>
                <td>{reporte.cantidad} </td>
                <td>{reporte.username} </td>
                
      

            </tr>

        ))

        return (ponerInfo())
    }




    Ponerlfechasreporte = () => {


        return <div><form onSubmit={(e) => this.handleSubmit(e)}>
            <fieldset className="form-fieldset">
                <div>


                    <DatePicker
                        onChange={this.cambiofecha1}
                        value={this.state.dateStart}
                    />
                </div>
                <div>
                    <DatePicker
                        onChange={this.cambiofecha2}
                        value={this.state.dateEnd}
                    />

                </div>
                <div className=" form-input select__places ">
                    {/*    <input  className="form-element-field -hasvalue" placeholder=" " type="number" value={this.props.lugares_id}
            onChange={this.cambiocantidad}  /> */}
                  
                </div>
            </fieldset>

        </form>
        </div>
    }




    mostrarAccion = () => {


        const { error, cargando, success } = this.props
        if (cargando) {
            return <Spinner />;
        }


        if (success) {
            return <Success mensaje={success} />;
        }


        if (error) {
            return <Fatal mensaje={error} />;
        }
        return false;
    }




    render() {



        return (



            <Fragment>
                {(!window.localStorage.getItem('token')) ? <Redirect to='/' /> : ''}
                <div className='main-content fade-in'>
                <h1 className="tituloPaginas">
                MULTAS <strong className="tituloPaginasPequeño">REPORTE</strong>
          </h1>
                    <div className='reporte__div__principal'>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4 ">
                                    {this.Ponerlfechasreporte()}
                                    {this.mostrarAccion()}
                                </div>

                                <div className="col-md-2 center__reportes__botones">
                                    <div className="btn__reportes_div">
                                        <button className="btn__reportes_page" type="submit"
                                            onClick={this.filtrReporteTotal} >Filtrar</button>
                                    </div>
                                    <div className="btn__reportes_div">
                                        <button className="btn__reportes_page" type="submit"
                                            onClick={this.filtrMultasEliminadas} >Ver Historial</button>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>

                    {this.ponerInformeModelo()}
                    <div className=''>
                        <div className="container-fluid">
                            <div className="row">
                           
                                <div className="col-md-12 ">

                                    <table  className="table table-striped tabla-global" >
                                        <thead>
                                            <tr>
                                                <th>CÉDULA </th>
                                                <th>NOMBRE</th>
                                                <th>MOTIVO</th>
                                                <th>FECHA</th>
                                                <th>CANTIDAD</th>
                                                <th>MONITOR</th>
                                                <th>ACCIÓN</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.ponerReporte()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = (reducers) => {
    return reducers.MultaTotalReducer
}

export default connect(mapStateToProps, MultaTotalAction)(MultaTotal)