import {
    CARGANDO, ERROR, GUARDAR, CONSULTAR, CAMBIO_FECHAPRESTAMO, CAMBIO_CANTIDAD, RESEARCHTRUE, CAMBIO_CONCEPTO
    , SUCCESS, CAMBIO_FECHA1, CAMBIO_FECHA2, CAMBIO_NOMBRE_LUGAR,RESEARPRESTAMO,FILTRAR_TOKENS,CONSULTAR_SERVICIOS
} from '../types/tokenAgregarTypes'

const INITIAL_STATE = {
    tokenPlataforma:'',
    servicios:[],
    cargando: false,
    error: '',
    nombre: '',
    fechaprestamo: '',
    nombreLugar:'',
    lugar:'',
    fecha1: '',
    fecha2: '',
    modelos_id: '',
    token: '',
    research: '',
    researchPrestamo: true,
    success: ''

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTAR:
            return {
                ...state,
                lugar: action.payload,
                researchPrestamo: true,
                

            }
            case CONSULTAR_SERVICIOS:
                return {
                    ...state,
                    servicios: action.payload,
                    researchPrestamo: true,
                    
    
                }
            

            case RESEARPRESTAMO:
                return {
                    ...state,
                    researchPrestamo: action.payload,
    
                }        

            

        case FILTRAR_TOKENS:
            return {
                ...state,
                tokenPlataforma: action.payload,
                researchPrestamo: true,


            }



        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false, success: '' }


        case SUCCESS:
            return { ...state, success: action.payload, cargando: false, error: '' }


        case GUARDAR:
            return {
                ...state,
                cargando: false,
                error: '',
                research: true,
                success: ''


            }



        case CAMBIO_FECHAPRESTAMO:
            return {
                ...state,
                fechaprestamo: action.payload,

            }

        case CAMBIO_FECHA1:
            return {
                ...state,
                fecha1: action.payload,

            }

        case CAMBIO_FECHA2:
            return {
                ...state,
                fecha2: action.payload,

            }

            case CAMBIO_NOMBRE_LUGAR:
                return {
                    ...state,
                    nombreLugar: action.payload,
    
                }

            

        case CAMBIO_CANTIDAD:
            return {
                ...state,
                nombre: action.payload,

            }

        case CAMBIO_CONCEPTO:
            return {
                ...state,
                token: action.payload,

            }

        case RESEARCHTRUE
            :
            return {
                ...state,

                prestamos: [],
                verperstamos: [],
                verperstamosModelo:[],
                cargando: false,
                error: '',
                cantidad: 0,
                fechaprestamo: '',
                fecha1: '',
                fecha2: '',
                modelos_id: '',
                concepto: '',
                success: '',
                research: '',
                researchPrestamo: true,



            }


        default: return state
    }

}