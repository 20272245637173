import {
    CARGANDO, ERROR, CONSULTAR, RESEARCHTRUE
    , SUCCESS, FILTRAR_MODELO_PAGO,CONSULTARS,CONSULTAR_FECHAS_PAGOS,CAMBIO_FECHA_PAGOS,IMAGEN_PERFIL_LOGO,CONSULTAR_LUGARES,CONSULTAR_PAGO_NOMINA_LUGAR,CONSULTAR_PAGO_NOMINA_TOTALES_LUGAR
} from '../types/ReportesModeloPagoTypes'

const INITIAL_STATE = {
    ModeloPago: [],
    ListaFechasPagos: [],
    SuggestionDatabase: [],
    ListaLugares: [],    
    cargando: false,
    error: '',
    fechas_pagos_id: '',
    research: '',
    success: '',
    logo:'',
    pagosLugaresModelos:[],
    gananciasLugar:[]

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTAR:
            return {
                ...state,
                multas: action.payload,
                cargando: false,
                research: false,
                cantidad: 0,
                fechaprestamo: '',
                concepto: '',

            }

        case FILTRAR_MODELO_PAGO:
            return {
                ...state,
                ModeloPago: action.payload,
                cargando: false,
                research: false,


            }

            case CONSULTAR_PAGO_NOMINA_LUGAR:
                return {
                    ...state,
                    pagosLugaresModelos: action.payload,
                    cargando: false,
                    research: false,
    
    
                }

                case CONSULTAR_PAGO_NOMINA_TOTALES_LUGAR:
                    return {
                        ...state,
                        gananciasLugar: action.payload,
                        cargando: false,
                        research: false,
        
        
                    }

            case CONSULTAR_FECHAS_PAGOS:
                return {
                    ...state,
                    ListaFechasPagos: action.payload,
                    cargando: false,
                    research: false,
    
    
                }

                case CONSULTAR_LUGARES:
                    return {
                      ...state,
                      ListaLugares: action.payload,
                    };
                case CAMBIO_FECHA_PAGOS:
                    return {
                        ...state,
                        fechas_pagos_id: action.payload,
                        cargando: false,
                        research: false,
        
        
                    }
                    case IMAGEN_PERFIL_LOGO:
                    return {
                        ...state,
                        logo: action.payload,
        
        
                    }   
                     
        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false, success: '' }


        case SUCCESS:
            return { ...state, success: action.payload, cargando: false, error: '' }


            case CONSULTARS:
            return {
                ...state,
                SuggestionDatabase: action.payload,

            }


        case RESEARCHTRUE
            :
            return {
                ...state,

                multas: [],
                vermultas: [],
                cargando: false,
                error: '',
                success: '',
                research: '',



            }


        default: return state
    }

}