import axios from 'axios'
import { CARGANDO, ERROR,  GUARDAR, CONSULTAR,RESEARCHTRUE,CAMBIO_PASSWORD_CORREO,CAMBIO_CORREO,
    SUCCESS,CONSULTAR_CORREOS_MODELO,CONSULTARS,CONSULTAR_FECHAS_PAGOS,CAMBIO_FECHA_PAGOS} from '../types/CorreosPlataformaTypes'

    import {URL} from '../../src/General/url'

//acciones para tareas 





export const editar_correo = (data,model_id) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateEmail/${model_id}`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
}

if(respuesta.data.status==="Error"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
}
        
 /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: 'Intente mas tarde - Cuenta Ocupada'
        
    
    })
    }
}

 
}




export const crear_correo = (prestamomodelo) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(prestamomodelo)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'createEmail', params,{
            headers: headers
        })
    
      
        if(respuesta.data.status==="Success"){

            dispatch({
                type: CONSULTAR,
                payload:respuesta
            })
    
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

        }
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}



export const eliminar = (id) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {



        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.delete(URL+`destroyAccountsModel/${id}`,{
            headers: headers
        })
    
    
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })
        }
        
    } catch (error) {

        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}
export const consultar_correo_modelos = (model_id) => async(dispatch)=>{
    
  
    dispatch({

        type: CARGANDO
        
    
    })

    try {

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`getEmails`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTAR_CORREOS_MODELO,
            payload:respuesta.data.data,
        })
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}

export const consultar_correo_modelo = (model_id) => async(dispatch)=>{
    
  
    dispatch({

        type: CARGANDO
        
    
    })

    try {

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`getEmailAccounts/${model_id}`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTAR_CORREOS_MODELO,
            payload:respuesta.data.data,
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}

export const cambioFechaPagos = (id_pago) => (dispatch) => {
    dispatch({
        type: CAMBIO_FECHA_PAGOS,
        payload: id_pago

    })
}


export const cambioCorreo = (value) => (dispatch) => {
    dispatch({
        type: CAMBIO_CORREO,
        payload: value

    })
}

export const cambioPasswordCorreo = (value) => (dispatch) => {
    dispatch({
        type: CAMBIO_PASSWORD_CORREO,
        payload: value

    })
}

export const consultar_fechas_pagos = () => async (dispatch) => {


    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getPaymentDate', {
            headers: headers
        })




        dispatch({
            type: CONSULTAR_FECHAS_PAGOS,
            payload: respuesta.data.data
        })

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}


export const researchtrue  = () => (dispatch,) => {
    dispatch({

        type: RESEARCHTRUE
        
    
    })


    

   
};

export const consultarSuggestions = (data) => async(dispatch)=>{
    


    try {

     
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
    
        const respuesta = await axios.post(URL+`getSuggestionModels`, params,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTARS,
            payload:respuesta.data.data,
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })
        }
        
    } catch (error) {
        
    }
}

export const handleChange  = (index, dataType, value) => (dispatch,getState) => {

    const {correosModelo} = getState().CorreosPlataformaReducer


    const newCorreo = correosModelo.map((item, i) => {
        if (i === index) {
          return {...item, [dataType]: value};
        }
        return item;
      });
  

      dispatch({
        type: CONSULTAR_CORREOS_MODELO,
        payload:newCorreo
    })


    

   
};