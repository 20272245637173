import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
//import { Viewer } from 'photo-sphere-viewer';
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
import * as habitacionagregaraction from "../../actions/HabitacionesAgregarAction";
import { AiOutlineClear } from "react-icons/ai";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import {URL} from '../../../src/General/url'
export class HabitacionesView360 extends Component {
  constructor(props) {
    super(props)
    this.viewer = React.createRef();


    this.state = {
      value: "",
      id: "",
      text: "",
      suggestVar: false,
      foto1:''
    }
  }



  async componentDidMount() {
    const {
      match: {
        params: { key }
      },
      consultar_turnos,
      consultarRooms,
      consultar_modelo_habitacion
    } = this.props
    await consultarRooms(key) 
    await consultar_turnos()
    await consultar_modelo_habitacion(key)

   this.setState({foto1:`${URL}getImageOriginal/${
    this.props.sedes.foto1
      ? this.props.sedes.foto1
      : "defaultLogo.png"
    }`})

  }

  componentDidUpdate() {
    if(this.props.estadoFlag){
      const {
        match: {
          params: { key }
        },
        consultar_modelo_habitacion
      } = this.props
      consultar_modelo_habitacion(key)
    }
    console.log(this.props)
  }


  componentWillUnmount() {
    this.props.researchtrue()
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  asignar_habitacion= (event) => {
    event.preventDefault()

    const {
      match: {
        params: { key }
      },
      asignar_habitacion
    } = this.props

    const data = {
      modelos_id: this.state.id,
      horarios_id: this.props.turno_id,
      habitacion_id: key
    }
    asignar_habitacion(data,key,this.state.text)
  }

  cleanRoom(id) {
    this.props.clear_room(id)
  }

  onTextChanged = e => {
    const value = e.target.value;
    let suggestions = [];

    const { consultarSuggestions } = this.props;
    const dataSuggestion = {
      data: value
    };
    if (value.length > 2) {
      this.setState(() => ({ suggestVar: true }));
      consultarSuggestions(dataSuggestion);
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      suggestions =
        inputLength === 0
          ? []
          : this.props.SuggestionDatabase.filter(
            lang =>
              lang.name.toLowerCase().slice(0, inputLength) === inputValue
          );
    } else {
      this.setState(() => ({ suggestVar: false }));
    }
    this.setState(() => ({ suggestions, text: value }));
  };

  suggestionSelected(value) {
    this.setState(() => ({
      text: value.name,
      id: value.id,
      suggestVar: false
    }));
  }

  renderSuggestions() {
    if (!this.state.suggestVar) {
      return null;
    }
    return (
      <div className="srchList">
        {this.props.SuggestionDatabase.map((item, key) => (
          <li key={key} onClick={() => this.suggestionSelected(item)}>
            {item.name}
          </li>
        ))}
      </div>
    );
  }

  ponerHabitaciones = () => {

    const { modeloHabitacion } = this.props


    const ponerInfo = () =>
      modeloHabitacion.map((habitacion, key) => (
        <tr key={key}>
          <td>{`${this.MaysPrimera(habitacion.horario.nombre.toLowerCase())}`}</td>
          <td>{`${habitacion.modelo ? this.MaysPrimera(habitacion.modelo.nombres) : ''} ${habitacion.modelo ? this.MaysPrimera(habitacion.modelo.apellidos) : ''}`}</td>
          <td> <AiOutlineClear
            size={"1vw"}
            className="clean__button"
            onClick={() => this.cleanRoom(habitacion.id)}
          /></td>
        </tr>
      ));

    return ponerInfo();


  }
  PonerBusquedaDiv = () => {
    const { text } = this.state;

    return <div><form onSubmit={(e) => this.handleSubmit(e)}>
      <fieldset className="form-fieldset">

        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-12 input">
              <input
                className="input__autocomplete__Text prestamosGlobar__text"
                value={text}
                onChange={this.onTextChanged}
                type="text"
                placeholder="Buscar Modelo"
                required
              />
            </div>
            <div className="col-md-12 justify-content-md-center input__autocomplete__SuggestionText">
              {this.renderSuggestions()}
            </div>
          </div>
        </div>

      </fieldset>

    </form>
    </div>
  }


  cambioTurnoId = (event) => {
    this.props.cambioTurnoId(event.target.value)
  }

  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  listaTurnos = () => {
    const { turnos } = this.props;

    const ponerOpcionesTurnos = () =>
      turnos.map((turnos, key) => (
        <option className='option-values' key={key} value={turnos.id}>
          {this.MaysPrimera(turnos.nombre.toLowerCase())}{}
        </option>
      ));

    return (
      <select
        name="select"
        onChange={this.cambioTurnoId}
        className='input__modificiar_informacion_modelo'
        required


      >
        <option className='option-values'>
          Turno
        </option>
        {ponerOpcionesTurnos()}
        <option className='option-values' value={5}>
          {this.MaysPrimera('Todos'.toLowerCase())}{}
        </option>
      </select>
    );
  };





  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {

    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            ROOM
          </h1>

          <div className="reporte__div__principal">


          {this.mostrarAccion()}
            <div className="container-fluid">
              <div className="row">
                <div className='col-md-8'
                 >
  <img
              className=""
              alt="imgagerfil"
              src={this.state.foto1}
            />
                </div>

                <div className='col-md-4'>
                  <div className='div__room__labels'>
                    {this.PonerBusquedaDiv()}
                    {this.listaTurnos()}
                    <button
                      className='assign__rooms__btn'
                      onClick={this.asignar_habitacion}>
                      Asignar habitación
                  </button>
                  </div>

                  <table className="table table-striped tabla-global">
                    <thead>
                      <tr>
                        <th>TURNO</th>
                        <th>MODELO</th>
                        <th>ACCIÓN </th>

                      </tr>
                    </thead>
                    <tbody>
                      {this.ponerHabitaciones()}
                    </tbody>
                  </table>
                </div>

              </div>



            </div>
          </div>

         


        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.HabitacionesAgregarReducer;
};

export default connect(mapStateToProps, habitacionagregaraction)(HabitacionesView360);
