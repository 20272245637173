import axios from 'axios'
import { CARGANDO, ERROR,  GUARDAR, CONSULTAR,RESEARCHTRUE,
    SUCCESS,FILTRAR_MODELO_PAGO,CONSULTARS,CONSULTAR_FECHAS_PAGOS,CAMBIO_FECHA_PAGOS,IMAGEN_PERFIL_LOGO,CONSULTAR_LUGARES,CONSULTAR_PAGO_NOMINA_LUGAR,CONSULTAR_PAGO_NOMINA_TOTALES_LUGAR} from '../types/ReportesModeloPagoTypes'

    import {URL} from '../../src/General/url'


    export const consultar_lugares = () => async (dispatch) => {


        try {
      
      
      
          let headers = {
              "Content-Type": "application/json",
                'Authorization': window.localStorage.getItem('token')
            }
            const respuesta = await axios.get(URL + 'getPlaces', {
                headers: headers
            })
      
      
      
      
            dispatch({
                type: CONSULTAR_LUGARES,
                payload: respuesta.data.data
            })
      
        } catch (error) {
      
      
            if (error.message === "Request failed with status code 401") {
                window.localStorage.setItem('token', '')
                window.localStorage.setItem('userData', '')
            } else {
                dispatch({
      
                    type: ERROR,
                    payload: error.message
      
      
                })
            }
        }
      }



export const editar = (Uploaddatoscuenta,model_id) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(Uploaddatoscuenta)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateAccountsModel/${model_id}`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
}

if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
        
 /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

 
}

export const consultar_pago = (id,lugar_id) => async (dispatch) => {

    dispatch({
      type: CARGANDO
    });
  
    try {
  
  
      let headers = {
          "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL +  `paymentSearchPlace/${id}/${lugar_id}`, {
            headers: headers
        })
  
  
        
  
        dispatch({
            type: CONSULTAR_PAGO_NOMINA_LUGAR,
            payload: respuesta.data.dataArriendo
        })
  
        dispatch({
          type: CONSULTAR_PAGO_NOMINA_TOTALES_LUGAR,
          payload: respuesta.data.dataSuma
      })
  
      if(respuesta.data.status==="Error"){
        dispatch({
    
            type: ERROR,
            payload: respuesta.data.message
            
        
        })
  
    }
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }

export const consultarInfoPlataforma = () => async(dispatch)=>{
    


    try {
  
     
    
    
        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`getPlataformaMasterInfo`,{
            headers: headers
        })
    
  
        dispatch({
            type: IMAGEN_PERFIL_LOGO,
            payload:respuesta.data.data[0].logo,
        })

        
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
    dispatch({
  
        type: ERROR,
        payload: error.message
        
    
    })
    }
  }

export const agregar = (prestamomodelo) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(prestamomodelo)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'createFines', params,{
            headers: headers
        })
    
      
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })


        if(respuesta.data.status==="success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

        }

        
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const filtrarPagoModelo = (reprotesmodelo) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(reprotesmodelo)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'paymentModelSearch', params,{
            headers: headers
        })
    
      
        dispatch({
            type: FILTRAR_MODELO_PAGO,
            payload:respuesta.data.data
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}



export const eliminar = (id) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {



        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.delete(URL+`destroyAccountsModel/${id}`,{
            headers: headers
        })
    
    
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })

        
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {

        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const consultar = (model_id) => async(dispatch)=>{
    


    try {

     
        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`searchModelsfinesReport/${model_id}`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTAR,
            payload:respuesta.data.data,
        })

        
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}

export const cambioFechaPagos = (id_pago) => (dispatch) => {
    dispatch({
        type: CAMBIO_FECHA_PAGOS,
        payload: id_pago

    })
}

export const consultar_fechas_pagos = () => async (dispatch) => {


    try {

  
        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getPaymentDate', {
            headers: headers
        })




        dispatch({
            type: CONSULTAR_FECHAS_PAGOS,
            payload: respuesta.data.data
        })

    } catch (error) {


        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({

                type: ERROR,
                payload: error.message


            })
        }
    }
}


export const researchtrue  = () => (dispatch,) => {
    dispatch({

        type: RESEARCHTRUE
        
    
    })


    

   
};

export const consultarSuggestions = (data) => async(dispatch)=>{
    


    try {

     
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
    
        const respuesta = await axios.post(URL+`getSuggestionModels`, params,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTARS,
            payload:respuesta.data.data,
        })

        
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        dispatch({
        
            type: ERROR,
            payload: error.message
            
        
        })
    }
}
