import {combineReducers} from 'redux'

import LoginReducer from './LoginReducer'
import HomeReducer from './HomeReducer'
import CuentasReducer from './CuentasReducer'
import PrestamosReducer from './PrestamosReducer'
import PrestamoGlobalReducer from './PrestamoGlobalReducer'
import PrestamoPequenoMonitoresReducer from './PrestamoPequenoMonitoresReducer'
import PrestamoGrandeReducer from './PrestamoGrandeReducer'
import PrestamoGrandeMonitorReducer from './PrestamoGrandeMonitorReducer'
import ModeloReducer from './ModeloReducer'
import ReportesModeloReducer from './ReportesModeloReducer'
import ReportesModeloPagoReducer from './ReportesModeloPagoReducer'
import ReportesMultaReducer from './ReportesMultaReducer'
import ReportesReducer from './ReportesReducer'
import ReporteTotalReducer from './ReporteTotalReducer'
import ReporteRetencionReducer from './ReporteRetencionReducer'
import CorreosPlataformaReducer from './CorreosPlataformaReducer'
import CamaraReducer from './CamaraReducer'
import TareasReducer from './TareasReducer'
import PagosReducer from './PagosReducer'
import NominaReducer from './NominaReducer'
import NominaPorcentajeReducer from './NominaPorcentajeReducer'
import CalendarioReducer from './CalendarioReducer'
import ReportesDiarioStudioReducer from './ReportesDiarioStudioReducer'
import MultaTotalReducer from './MultaTotalReducer'
import MultaTotalMonitorReducer from './MultaTotalMonitorReducer'
import MultasAgregarReducer from './MultasAgregarReducer'
import MultasAgregarMonitorReducer from './MultasAgregarMonitorReducer'
import PerfilVerReducer from './PerfilVerReducer'
import PerfilVerMonitorReducer from './PerfilVerMonitorReducer'
import PerfilModificarInfoReducer from './PerfilModificarInfoReducer'
import PerfilModificarInfoMonitorReducer from './PerfilModificarInfoMonitorReducer'
import crearReducer from './crearReducer'
import crearMonitoresReducer from './crearMonitoresReducer'
import crearMonitorReducer from './crearMonitorReducer'
import HabitacionesAgregarReducer from './HabitacionesAgregarReducer'
import tokenAgregarReducer from './tokenAgregarReducer'
import PermisosUsuariosReducer from './PermisosUsuariosReducer'
import ArchivoPagosReducer from './ArchivoPagosReducer'
import correosReportesReducer from './correosReportesReducer'
import ReporteTotalSinDeduccionesReducer from './ReporteTotalSinDeduccionesReducer'
import correosReportesExtensionReducer from './correosReportesExtensionReducer'
import palabrasAlertasReducer from './palabrasAlertasReducer'
import alertasPlataformasReducer from './AlertasPlataformasReducer'









export default combineReducers({
    LoginReducer,
    HomeReducer,
    CuentasReducer,
    PrestamosReducer,
    ModeloReducer,
    CorreosPlataformaReducer,
    ReportesModeloReducer,
    ReportesMultaReducer,
    ReportesReducer,
    CamaraReducer,
    TareasReducer,
    PagosReducer,
    CalendarioReducer,
    PrestamoGlobalReducer,
    ReportesModeloPagoReducer,
    ReportesDiarioStudioReducer,
    ReporteTotalReducer,
    ReporteRetencionReducer,
    PrestamoGrandeReducer,
    NominaReducer,
    NominaPorcentajeReducer,
    MultaTotalReducer,
    MultasAgregarReducer,
    PerfilVerReducer,
    PerfilVerMonitorReducer,
    PerfilModificarInfoReducer,
    crearReducer,
    crearMonitoresReducer,
    crearMonitorReducer,
    HabitacionesAgregarReducer,
    PrestamoPequenoMonitoresReducer,
    PrestamoGrandeMonitorReducer,
    PerfilModificarInfoMonitorReducer,
    MultasAgregarMonitorReducer,
    MultaTotalMonitorReducer,
    tokenAgregarReducer,
    PermisosUsuariosReducer,
    ArchivoPagosReducer,
    correosReportesReducer,
    ReporteTotalSinDeduccionesReducer,
    correosReportesExtensionReducer,
    palabrasAlertasReducer,
    alertasPlataformasReducer
    
})