export const CONSULTAR = 'nominaPorcentaje_pago_traer_todas';
export const CARGANDO = 'nominaPorcentaje_pago_cargando';
export const ERROR = 'nominaPorcentaje_pago_error';
export const GUARDAR = 'nominaPorcentaje_pago_guardada';
export const ACTUALIZAR = 'nominaPorcentaje_pago_actualizar';
export const LIMPIAR = 'nominaPorcentaje_pago_limpiar';
export const RESEARCHTRUE = 'nominaPorcentaje_pago_research';
export const SUCCESS = 'nominaPorcentaje_pago_succes';
export const CAMBIO_FECHAPRESTAMO = 'nominaPorcentaje_pago_fecha';
export const CAMBIO_CANTIDAD = 'nominaPorcentaje_pago_cantidad';
export const CAMBIO_CONCEPTO = 'nominaPorcentaje_pago_concepto';
export const CAMBIO_FECHA1 = 'nominaPorcentaje_pago_fecha1';
export const CAMBIO_FECHA2 = 'nominaPorcentaje_pago_fecha2';
export const FILTRAR_MODELO_PORCENJATE = 'nominaPorcentaje_pago_ganancias_filtrar';
export const CAMBIO_FECHA_PAGOS = 'nominaPorcentaje_pago_cambio_fecha_pagos';
export const CONSULTAR_LUGARES = 'nominaPorcentaje_pago_consultar_lugares';
export const CONSULTARS = 'nominaPorcentaje_pago_traer_todas_suggestions';
export const CONSULTAR_FECHAS_PAGOS = 'nominaPorcentaje_pago_traer_todas_fechas_pagos';






