import React, { Component } from "react"
import { connect } from 'react-redux'
import * as CalendarioAction from "../../actions/CalendarioAction";
import { IoMdAdd, } from "react-icons/io";
import DateTimePicker from 'react-datetime-picker';
import HTML5Backend from "react-dnd-html5-backend";
import './Styles.scss'
import BigCalendar from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import { DragDropContext } from "react-dnd";
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Redirect } from "react-router-dom";
import InputColor from 'react-input-color';
import Modal from "react-bootstrap/Modal";

import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import Success from "../../General/Success";

BigCalendar.momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(BigCalendar);

class Calendario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      searchEvents: true,
      dateStart: new Date(),
      dateEnd: new Date(),
      color: '',
      deleteEVent: [],
      description: '',
      show: false,
      updateParameter: [],
      eventUpdateParameters: [],
      elminarFlagModal: false
    };

    this.moveEvent = this.moveEvent.bind(this);
  }
  async componentDidMount() {

    await this.props.consultarEventos();
    await this.reestructurar(this.props.eventos)
    this.cambioFechaInicio(this.state.dateStart)
    this.cambioFechaFinal(this.state.dateEnd)

  }

  componentDidUpdate() {

    if (!this.state.events.length && this.state.searchEvents) {
      this.setState({ searchEvents: false });

    }

    if (this.props.newSearch) {
      const { researhCalendario, } = this.props;
      researhCalendario()
      this.props.consultarEventos();
      this.setState({ searchEvents: true });


    }


  }

  componentWillUnmount() {
    this.setState({ searchEvents: true });
    //this.setState({ events: events[''] });
    const { researhCalendario } = this.props;
    researhCalendario();
  }

  reestructurar = (arrayState) => {

    arrayState.forEach(element => {
      if (element.start) {
        element.start = new Date(element.start)
      }
      if (element.end) {
        element.end = new Date(element.end)
      }

    });

    this.setState({ events: arrayState });
  }
  onEventClick(event) {
    const data = {
      id: event.id,
      title: event.title
    }
    this.setState({ deleteEVent: data })

  }


  hideModal = () => {
    this.setState({ show: false });

  };

  onEventDobleClick(event) {


    const data = {
      id: event.id,
      start: event.start,
      end: event.end,
      title: event.title,
      description: event.description,
    }


    this.setState({ updateParameter: data })
    this.setState({ show: true })
    this.setState({ eventUpdateParameters: event })
    this.setState({ elminarFlagModal: false })



  }

  handleEliminarEvento = async (e) => {
    e.preventDefault()
    const { eliminarEvento } = this.props;

    const { events } = this.state;
    const idx = events.indexOf(this.state.eventUpdateParameters);
    events.splice(idx, 1);

    const nextEvents = [...events];

    await eliminarEvento(this.state.eventUpdateParameters.id)
    this.setState({
      events: nextEvents
    });

    this.setState({ show: false });

  }


  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };


  handleUpdateEvent(event) {

    let dateStart = event.start
    let dateEnd = event.end
    let starDate = `${dateStart.getFullYear()}-${dateStart.getMonth() + 1}-${dateStart.getDate()} ${dateStart.getHours()}:${dateStart.getMinutes()}:${dateStart.getSeconds()}`
    let endDate = `${dateEnd.getFullYear()}-${dateEnd.getMonth() + 1}-${dateEnd.getDate()} ${dateEnd.getHours()}:${dateEnd.getMinutes()}:${dateEnd.getSeconds()}`

    const {
      editarEventoDragAndDrop,
    } = this.props;

    const data = {
      start: starDate,
      end: endDate,
      title: event.title,
      description: event.description,
    };
    editarEventoDragAndDrop(event.id, data) //Shows the event details provided while booking
  }

  moveEvent({ event, start, end }) {


    const { events } = this.state;

    const idx = events.indexOf(event);
    /// 
    const updatedEvent = { ...event, start, end };

    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);

    this.setState({
      events: nextEvents
    });

    this.handleUpdateEvent(updatedEvent)
  }

  HandleColor = (color) => {
    this.setState({ color: color.hex })
  }
  resizeEvent = (resizeType, { event, start, end }) => {
    const { events } = this.state;
    //console.log(events)
    const nextEvents = events.map(existingEvent => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });

    this.setState({
      events: nextEvents
    });
  };


  Calendario = () => {



    return (

      <div style={{ height: `${100}vh` }} className='calendarioDiv'>
        <DragAndDropCalendar
          selectable
          events={this.state.events}
          onEventDrop={this.moveEvent}
          resizable
          onEventResize={this.resizeEvent}
          defaultView={BigCalendar.Views.MONTH}
          defaultDate={new Date()}
          onDoubleClickEvent={event => this.onEventDobleClick(event)}
          onSelectEvent={event => this.onEventClick(event)}
          eventPropGetter={events => ({
            style: {
              backgroundColor: events.colorEvent,
            },
          })}
          messages={{
            next: "sig",
            previous: "ant",
            today: "Hoy",
            month: "Mes",
            week: "Semana",
            day: "Día"
          }}
        />
      </div>
    );
  };

  async handleCrearEvento() {

    const {
      eventoText,
      fechaInicio,
      fechaFinal,
      crearEvento,

    } = this.props;
    const nuevoEvento = {
      title: eventoText,
      description: this.state.description,
      start: fechaInicio,
      end: fechaFinal,
      allDay: '',
      color: this.state.color
    };
    await crearEvento(nuevoEvento);
    await this.props.consultarEventos();
    await this.reestructurar(this.props.eventos)

  }
  cambioUpdateEvento = (e) => {

    let newData = {
      ...this.state.updateParameter,
    }
    newData[e.target.name] = e.target.value
    this.setState({ updateParameter: newData })
  }

  actualizarEventoModal = () => {

    this.handleUpdateEvent(this.state.updateParameter)

    const { events } = this.state;

    const idx = events.indexOf(this.state.eventUpdateParameters);

    let start = this.state.updateParameter.start
    let end = this.state.updateParameter.end
    let title = this.state.updateParameter.title
    let description = this.state.updateParameter.description
    const updatedEvent = { ...this.state.eventUpdateParameters, start, end, description, title };

    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);

    this.setState({
      events: nextEvents
    });
    this.setState({ show: false });
  }
  cambioUpdateEventoStart = (date) => {

    let newData = {
      ...this.state.updateParameter,
    }
    newData['start'] = date
    this.setState({ updateParameter: newData })
  }

  cambioUpdateEventoEnd = (date) => {



    let newData = {
      ...this.state.updateParameter,
    }
    newData['end'] = date
    this.setState({ updateParameter: newData })
  }

  onChangeStart = date => this.setState({ dateStart: date })
  onChangeEnd = date => this.setState({ dateEnd: date })



  cambioFechaInicio = date => {

    if (date) {
      this.setState({ dateStart: date })

      
 
      this.props.cambioFechaInicio((moment(date).format('YYYY/MM/DD')));
    } else {
      this.setState({ dateStart: '' })
    }

  };

  cambioFechaFinal = date => {

    if (date) {
      this.setState({ dateEnd: date })
      var fechaFinalString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
      this.props.cambioFechaFinal(fechaFinalString);
    } else {
      this.setState({ dateEnd: '' })
    }

  };

  cambioEventoText = event => {
    this.props.cambioEventoText(event.target.value);
  };

  render() {



    return (

      <div className="main-content fade-in">
        {!window.localStorage.getItem("token") ? <Redirect to="/Home" /> : ""}

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 calendarMartingtop">
              <div className='time__Pick__calendar'>
                <h1 className='time__Pick__calendar_ttitle'>Crea un registro personalizado</h1>
                <div className='time__Pick__calendar__divs'>
                  <div>
                    <div className="container">
                      <div className="row">

                        <div className='col-sm-12'>
                          <div className="form-group">
                            <div className='input-group-date-piker date' >
                              <DateTimePicker
                                onChange={this.cambioFechaInicio}
                                value={this.state.dateStart}
                              />

                            </div>
                          </div>
                        </div>

                        <div className='col-sm-12'>
                          <div className="form-group">
                            <div className='input-group-date-piker date' >
                              <DateTimePicker
                                onChange={this.cambioFechaFinal}
                                value={this.state.dateEnd}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>


                <div className='time__Pick__calendar__divs__text'>
                  <input onChange={this.cambioEventoText} value={this.props.eventoText} className='time__Pick__calendar__text' type='text' placeholder='Titulo'></input>
                  <textarea style={{ height: '15vh' }} onChange={(e) => { this.setState({ description: e.target.value }) }} value={this.state.description} className='time__Pick__calendar__text' type='text' placeholder='Descripción'></textarea>

                  <div><button className="Home_Work_Stage__insideDiv__icon__add" style={{ backgroundColor: this.state.color }} onClick={e => this.handleCrearEvento(e)} >Crear Evento<IoMdAdd size={"32px"} />
                  </button>
                    <div style={{
                      position: 'absolute',
                      right: '3.5vh',

                    }}>

                      <InputColor
                        initialValue="#5e72e4"
                        onChange={this.HandleColor}
                        placement="right"
                      />
                      <div
                        style={{
                          width: 0,
                          backgroundColor: this.state.rgba
                        }}
                      />
                    </div></div>

                </div>
              </div>

              {this.mostrarAccion()}
          
            </div>
            <div className="col-md-9">

              {this.Calendario()}
            </div>
          </div>
        </div>

        <Modal show={this.state.show} onHide={this.hideModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>

          </Modal.Header>
          <Modal.Body>
            <div className='time__Pick__calendar'>
              <h1 className='time__Pick__calendar_ttitle'>Actualizar Evento</h1>
              <div className='time__Pick__calendar__divs'>
                <div>
                  <div className="container">
                    <div className="row">

                      <div className='col-sm-12'>
                        <div className="form-group">
                          <div className='input-group-date-piker date' >
                            <DateTimePicker
                              name='start'
                              onChange={this.cambioUpdateEventoStart}
                              value={this.state.updateParameter.start}
                            />

                          </div>
                        </div>
                      </div>

                      <div className='col-sm-12'>
                        <div className="form-group">
                          <div className='input-group-date-piker date' >
                            <DateTimePicker
                              name='end'
                              onChange={this.cambioUpdateEventoEnd}
                              value={this.state.updateParameter.end}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>


              <div className='time__Pick__calendar__divs__text'>
                <input onChange={this.cambioUpdateEvento} name='title' value={this.state.updateParameter.title} className='time__Pick__calendar__text' type='text' placeholder='Titulo'></input>
                <textarea style={{ height: '15vh' }} name='description' onChange={this.cambioUpdateEvento} value={this.state.updateParameter.description ? this.state.updateParameter.description : ''} className='time__Pick__calendar__text' type='text' placeholder='Descripción'></textarea>

              </div>
            </div>

            {this.state.elminarFlagModal ?
              <div style={{ textAlign: 'center', width: '100%', marginTop: '16px' }}>
                <div><h2>Seguro que deseas eliminar este evento?</h2></div>


                <button
                  style={{ marginLeft: '5px' }}
                  onClick={this.handleEliminarEvento}/* this.crearNuevaTarea */ /* onChange={this.cambioTareas} value={this.props.concepto} */
                  className="btn__modal btn__primary__modal"
                >
                  Aceptar
              </button>
                <button
                  style={{ marginLeft: '5px' }}
                  onClick={(e) => { this.setState({ elminarFlagModal: false }) }}
                  className="btn__modal btn__primary__modal"
                >
                  Cancelar
              </button>
              </div> :

              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                <button
                  onClick={this.actualizarEventoModal}/* this.crearNuevaTarea */ /* onChange={this.cambioTareas} value={this.props.concepto} */
                  className="btn__modal btn__primary__modal"
                >
                  Actualizar Evento
              </button>

                <button
                  style={{ marginLeft: '5px' }}
                  onClick={(e) => { this.setState({ elminarFlagModal: true }) }}/* this.crearNuevaTarea */ /* onChange={this.cambioTareas} value={this.props.concepto} */
                  className="btn__modal btn__primary__modal"
                >
                  Eliminar Evento
              </button>
                <button
                  style={{ marginLeft: '5px' }}
                  onClick={this.hideModal}
                  className="btn__modal btn__primary__modal"
                >
                  Cancelar
              </button>
              </div>}

          </Modal.Body>
        </Modal>
      </div>



    )
  }


}




//conectar tareas al reducer y traer las acciones del tareas actions
//const Calendar = DragDropContext(HTML5Backend)(Calendario);
const Calendar = DragDropContext(HTML5Backend)(Calendario);
const mapStateToProps = (reducers) => {
  return reducers.CalendarioReducer
}
//conectar tareas al reducer y traer las acciones del tareas actions
export default connect(mapStateToProps, CalendarioAction)(Calendar)