import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
import * as permisosaction from "../../actions/PermisosUsuariosAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import btonAgregarPermiso from "../../img/btonAgregarPermiso.png";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import { FiDelete } from "react-icons/fi";
//import {URL} from '../../../src/General/url'
/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */
export class permisosUsuarios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permisosArray: [],
      id_lugar: "",
      nombre_lugar: "",
      nombre_permiso: "",
    };
  }

  async componentDidMount() {
    const { consultar_Permiso,
      match: {
        params: { permiso_id },
    }, } = this.props;
    await consultar_Permiso(permiso_id);
   
  }
  componentDidUpdate() {
    console.log(this.state);
  }

  componentWillUnmount() {
    this.props.researchtrue();
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  setLugar(e) {

    this.props.handleAddPermisos(this.state.id_lugar,this.state.nombre_lugar)
    this.setState({
      id_lugar: '',
      nombre_lugar: '',
    })

  }

  listaLugares = () => {
    const { ListaLugares } = this.props;

    const poneropcioneslugaress = () =>

      ListaLugares.map((lugares, key) => (
        <option className="option-values" key={key} value={key}>
          {lugares.nombre}
        </option>
      ));

    return (
      <Fragment>
        <select
          className="right-submenu"
          name="select"
          required
          onChange={(e) => {
            e.target.value==='todo'? this.setState({
              id_lugar: '0',
              nombre_lugar: 'Todos',
            }): this.setState({
              id_lugar: this.props.ListaLugares[e.target.value].id,
              nombre_lugar: this.props.ListaLugares[e.target.value].nombre,
            })
           
          }}
        >
           
          <option className="option-values">Lugares</option>
          <option className="option-values" value='todo'>
    Todos
  </option>
          {poneropcioneslugaress()}
        </select>
   
      </Fragment>
    );
  };

  deletePermiso(id) {
this.props.handleDeletePermisos(id)
  }

  listaPermisos = () => {
    const ponerPermisos = () =>
      this.props.permisosLugares.map((permiso, key) => (
        <tr key={key}>
          <td>{permiso.nombre}</td>
          <td>
            {" "}
            <span
              className="Permisos_Usuarios_Stage__insideDiv__icon__add"
              onClick={(e) => {
                this.deletePermiso(permiso.id);
              }}
            >
              <FiDelete size={"32px"} />
            </span>
          </td>
        </tr>
      ));

    return ponerPermisos();
  };

  handleChange(dataType, value) {

    this.props.handleChange(dataType, value);
}
  ponerHabitaciones = () => {
    const ponerInfo = () => (
      <Fragment>


        <tr>
        <td>Calendario</td>
          <td>
            <input type="checkbox" className="checkbox" id="Calendario" 
            checked={this.props.ListaPermisosUpdate.calendario}
            onChange={(e) =>
              this.handleChange( "calendario", e.target.checked)
          }/>
            
          </td>
        </tr>
        <span className="pageTitulos">FINANZAS</span>
        <tr>
        <td>Reportes-Ver</td>
          <td>
            <input type="checkbox" className="checkbox" id="ReportesVer" 
            checked={this.props.ListaPermisosUpdate.reportesVer}
               onChange={(e) =>
                this.handleChange( "reportesVer", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>Reportes-Dia</td>
          <td>
            <input type="checkbox" className="checkbox" id="ReportesDia" 
             checked={this.props.ListaPermisosUpdate.reportesDia}
               onChange={(e) =>
                this.handleChange( "reportesDia", e.target.checked)
            }/>
          </td>
        </tr>

        <tr>
        <td>Reportes-Dia-Actualizar</td>
          <td>
            <input type="checkbox" className="checkbox" id="ReportesDiaActualizar" 
             checked={this.props.ListaPermisosUpdate.reporte_dia_actualizar}
               onChange={(e) =>
                this.handleChange( "reporte_dia_actualizar", e.target.checked)
            }/>
          </td>
        </tr>
        <tr>
        <td>Reportes-Modelos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="ReportesModelos"
              checked={this.props.ListaPermisosUpdate.reportesModelos}
              onChange={(e) =>
                this.handleChange( "reportesModelos", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>Reportes-Pagos</td>
          <td>
            <input type="checkbox" className="checkbox" id="ReportesPagos"
              checked={this.props.ListaPermisosUpdate.reportesPagos}
              onChange={(e) =>
                this.handleChange( "reportesPagos", e.target.checked)
            }/>
          </td>
        </tr>
        <tr>
        <td>Reportes-Deuda-Modelos</td>
          <td>
            <input type="checkbox" className="checkbox" id="deuda_modelos"
              checked={this.props.ListaPermisosUpdate.deuda_modelos}
              onChange={(e) =>
                this.handleChange( "deuda_modelos", e.target.checked)
            }/>
          </td>
        </tr>
        
        <tr>
        <td>Reportes-Diario</td>
          <td>
            <input type="checkbox" className="checkbox" id="ReportesDiario" 
             checked={this.props.ListaPermisosUpdate.reportesDiario}
             onChange={(e) =>
               this.handleChange( "reportesDiario", e.target.checked)
           }/>
          </td>
        </tr>
        <tr>
        <td>Reportes-Total</td>
          <td>
            <input type="checkbox" className="checkbox" id="ReportesTotal" 
             checked={this.props.ListaPermisosUpdate.reportesTotal}
             onChange={(e) =>
               this.handleChange( "reportesTotal", e.target.checked)
           }/>
          </td>
        </tr>

        <tr>
        <td>Reportes-Total-Sin-Descuento</td>
          <td>
            <input type="checkbox" className="checkbox" id="ReportesTotalSinDescuento" 
             checked={this.props.ListaPermisosUpdate.reportesTotalSinDescuento}
             onChange={(e) =>
               this.handleChange( "reportesTotalSinDescuento", e.target.checked)
           }/>
          </td>
        </tr>
        <tr>
        <td>Reportes-RTE</td>
          <td>
            <input type="checkbox" className="checkbox" id="ReportesRTE"
              checked={this.props.ListaPermisosUpdate.reportesRTE}
              onChange={(e) =>
                this.handleChange( "reportesRTE", e.target.checked)
            } />
          </td>
        </tr>
        <tr>
        <td>prestamos-Pequeños-Modelos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="prestamosPequenosModelos"
              checked={this.props.ListaPermisosUpdate.modelosPrestamosPequenos}
              onChange={(e) =>
                this.handleChange( "modelosPrestamosPequenos", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>prestamos-Grandes-Modelos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="prestamosGrandesModelos"
              checked={this.props.ListaPermisosUpdate.modelosPrestamosGrande}
              onChange={(e) =>
                this.handleChange( "modelosPrestamosGrande", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>nómina-Pagos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="nominaPagos"
              checked={this.props.ListaPermisosUpdate.nominaPagos}
              onChange={(e) =>
                this.handleChange( "nominaPagos", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>nómina-Recibos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="nominaRecibos"
              checked={this.props.ListaPermisosUpdate.nominaRecibos}
              onChange={(e) =>
                this.handleChange( "nominaRecibos", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>nómina-Porcentaje</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="nominaPorcentaje"
              checked={this.props.ListaPermisosUpdate.nominaPorcentaje}
              onChange={(e) =>
                this.handleChange( "nominaPorcentaje", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>multas-Agregar-Modelos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="multasAgregarModelos"
              checked={this.props.ListaPermisosUpdate.modelosMultasAgregar}
              onChange={(e) =>
                this.handleChange( "modelosMultasAgregar", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>multas-Reporte-Modelos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="multasReporteModelos"
              checked={this.props.ListaPermisosUpdate.modelosMultasReporte}
              onChange={(e) =>
                this.handleChange( "modelosMultasReporte", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>servicio-Reporte-Modelos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="multasReporteModelos"
              checked={this.props.ListaPermisosUpdate.servicios_reporte}
              onChange={(e) =>
                this.handleChange( "servicios_reporte", e.target.checked)
            }
            />
          </td>
        </tr>

        <span className="pageTitulos">MODELOS</span>

        <tr>
        <td>perfil-Modelos-Ver</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="perfilModelosVer"
              checked={this.props.ListaPermisosUpdate.modelosPerfilVer}
              onChange={(e) =>
                this.handleChange( "modelosPerfilVer", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td> perfil-Modelos-Agregar</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="perfilModelosAgregar"
              checked={this.props.ListaPermisosUpdate.modelosPerfilAgregar}
              onChange={(e) =>
                this.handleChange( "modelosPerfilAgregar", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>correos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="correos"
              checked={this.props.ListaPermisosUpdate.correos}
              onChange={(e) =>
                this.handleChange( "correos", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>habitación-Agregar</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="habitacionAgregar"
              checked={this.props.ListaPermisosUpdate.habitacionesAgregar}
              onChange={(e) =>
                this.handleChange( "habitacionesAgregar", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>habitación-Ver-Turnos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="habitacionVerTurnos"
              checked={this.props.ListaPermisosUpdate.habitacionesVerTurnos}
              onChange={(e) =>
                this.handleChange( "habitacionesVerTurnos", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>habitación-Ver-Reportes</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="habitaciónVerReportes"
              checked={this.props.ListaPermisosUpdate.habitacionesVerReportes}
              onChange={(e) =>
                this.handleChange( "habitacionesVerReportes", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
          <td>camaras</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="camaras"
              checked={this.props.ListaPermisosUpdate.camaras}
              onChange={(e) =>
                this.handleChange( "camaras", e.target.checked)
            }
            />
          </td>
        </tr>

        <span className="pageTitulos">MONITORES</span>
        <tr>
        <td>perfil-Monitores-Agregar</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="perfilMonitoresVer"
              checked={this.props.ListaPermisosUpdate.monitoresPerfilVer}
              onChange={(e) =>
                this.handleChange( "monitoresPerfilVer", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>préstamos-Pequeños-Monitores</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="perfilMonitoresAgregar"
              checked={this.props.ListaPermisosUpdate.monitoresPerfilAgregar}
              onChange={(e) =>
                this.handleChange( "monitoresPerfilAgregar", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>préstamos-Grandes-Monitores</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="monitoresPrestamosPequenos"
              checked={this.props.ListaPermisosUpdate.monitoresPerfilAgregar}
              onChange={(e) =>
                this.handleChange( "monitoresPrestamosPequenos", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>multas-Grandes-Monitores</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="prestamosGrandesMonitores"
              checked={this.props.ListaPermisosUpdate.monitoresPrestamosGrande}
              onChange={(e) =>
                this.handleChange( "monitoresPrestamosGrande", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>multas-Agregar-Monitores</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="multasAgregarMonitores"
              checked={this.props.ListaPermisosUpdate.monitoresMultasAgregar}
              onChange={(e) =>
                this.handleChange( "monitoresMultasAgregar", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>multas-Ganancias-Monitores</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="monitoresMultasGanancias"
              checked={this.props.ListaPermisosUpdate.monitoresMultasAgregar}
              onChange={(e) =>
                this.handleChange( "monitoresMultasGanancias", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>multas-Reporte-Monitores</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="monitoresMultasReporte"
              checked={this.props.ListaPermisosUpdate.monitoresMultasReporte}
              onChange={(e) =>
                this.handleChange( "monitoresMultasReporte", e.target.checked)
            }
            />
          </td>
        </tr>
        <span className="pageTitulos">EXTENSIÓN</span>
        <tr>
        <td>Correo-Alertas</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="correo_alertas"
              checked={this.props.ListaPermisosUpdate.correo_alertas}
              onChange={(e) =>
                this.handleChange( "correo_alertas", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>Palabras-Clave</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="palabras_clave"
              checked={this.props.ListaPermisosUpdate.palabras_clave}
              onChange={(e) =>
                this.handleChange( "palabras_clave", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>Alertas-Plataforma</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="alertasPlataforma"
              checked={this.props.ListaPermisosUpdate.alertasPlataforma}
              onChange={(e) =>
                this.handleChange( "alertasPlataforma", e.target.checked)
            }
            />
          </td>
        </tr>
        <span className="pageTitulos">ADMIN</span>
        <tr>
          <td>token</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="token"
              checked={this.props.ListaPermisosUpdate.tokens}
              onChange={(e) =>
                this.handleChange( "tokens", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>lugares</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="lugar"
              checked={this.props.ListaPermisosUpdate.lugares}
              onChange={(e) =>
                this.handleChange( "lugares", e.target.checked)
            }
            />
          </td>
        </tr>


        <tr>
        <td>servicios</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="multasReporteModelos"
              checked={this.props.ListaPermisosUpdate.servicios_rol}
              onChange={(e) =>
                this.handleChange( "servicios_rol", e.target.checked)
            }
            />
          </td>
        </tr>
        
        <tr>
        <td>Correo-Reportes</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="correo_reportes"
              checked={this.props.ListaPermisosUpdate.correo_reportes}
              onChange={(e) =>
                this.handleChange( "correo_reportes", e.target.checked)
            }
            />
          </td>
        </tr>

        <tr>
        <td>Usuarios-Agregar</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="UsuariosAgregar"
              checked={this.props.ListaPermisosUpdate.Usuarios}
              onChange={(e) =>
                this.handleChange( "Usuarios", e.target.checked)
            }
            />
          </td>
        </tr>
        <tr>
        <td>Usuarios-Permisos</td>
          <td>
            <input
              type="checkbox"
              className="checkbox"
              id="usuario_permisos"
              checked={this.props.ListaPermisosUpdate.usuario_permisos}
              onChange={(e) =>
                this.handleChange( "usuario_permisos", e.target.checked)
            }
            />
          </td>
        </tr>

      </Fragment>
    );
    return ponerInfo();
  };

  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  ListaPermisosBaseDatos = () => {
    const { ListaPermisos } = this.props;
    const ponerPermisosBaseDatos = () =>
    ListaPermisos.map((permiso, key) => (
        <tr key={key}>
          <td>{permiso.nombre}</td>
          <td>
            {" "}
            <span
              className="Permisos_Usuarios_Stage__insideDiv__icon__add"
              onClick={(e) => {
                this.deletePermiso(permiso.id);
              }}
            >
              <FiDelete size={"32px"} />
            </span>
          </td>
        </tr>
      ));

    return ponerPermisosBaseDatos();
  };
  
  updatePermiso = (e) => {
    e.preventDefault();

    const {
      update_permiso,

      match: {
        params: { permiso_id }
    } }= this.props;

    let arrayPer = this.props.permisosLugares;
    let StrigPer = "";
    for (let index = 0; index < arrayPer.length; index++) {
      const element = arrayPer[index];

      if (arrayPer.length > 1) {
        index+1===arrayPer.length?StrigPer = StrigPer + `${element.id}`:StrigPer = StrigPer + `${element.id},`;
      } else {
        StrigPer = StrigPer + `${element.id}`;
      }
    }

    
    const data = {
      nombre: this.props.ListaPermisosUpdate.nombre,
      lugares_id: StrigPer,
      calendario:this.props.ListaPermisosUpdate.calendario,
      reportesVer: this.props.ListaPermisosUpdate.reportesVer,
      reportesDia:this.props.ListaPermisosUpdate.reportesDia,
      reporte_dia_actualizar:this.props.ListaPermisosUpdate.reporte_dia_actualizar,
      reportesModelos:this.props.ListaPermisosUpdate.reportesModelos,
      reportesPagos:this.props.ListaPermisosUpdate.reportesPagos,
      reportesTotalSinDescuento:this.props.ListaPermisosUpdate.reportesTotalSinDescuento,
      reportesDiario:this.props.ListaPermisosUpdate.reportesDiario,
      reportesTotal:this.props.ListaPermisosUpdate.reportesTotal,
      reportesRTE:this.props.ListaPermisosUpdate.reportesRTE,
      modelosPrestamosPequenos: this.props.ListaPermisosUpdate.modelosPrestamosGrande,
      modelosPrestamosGrande:this.props.ListaPermisosUpdate.modelosPrestamosPequeno,
      nominaRecibos: this.props.ListaPermisosUpdate.nominaRecibos,
      nominaPagos:this.props.ListaPermisosUpdate.nominaPagos,
      nominaPorcentaje: this.props.ListaPermisosUpdate.nominaPorcentaje,
      modelosMultasAgregar: this.props.ListaPermisosUpdate.modelosMultasAgregar,
      modelosMultasReporte:this.props.ListaPermisosUpdate.modelosMultasReporte,
      modelosPerfilVer:this.props.ListaPermisosUpdate.modelosPerfilVer,
      modelosPerfilAgregar:this.props.ListaPermisosUpdate.modelosPerfilAgregar,
      correos: this.props.ListaPermisosUpdate.correos,
      habitacionesVerTurnos:this.props.ListaPermisosUpdate.habitacionesVerTurnos,
      habitacionesVerReportes:this.props.ListaPermisosUpdate.habitacionesVerReportes,
      camaras:this.props.ListaPermisosUpdate.camaras,
      monitoresPerfilVer:this.props.ListaPermisosUpdate.monitoresPerfilVer,
      monitoresPerfilAgregar:this.props.ListaPermisosUpdate.monitoresPerfilAgregar,
      monitoresPrestamosPequenos:this.props.ListaPermisosUpdate.monitoresPrestamosPequenos,
      monitoresPrestamosGrande:this.props.ListaPermisosUpdate.monitoresPrestamosGrande,
      monitoresMultasAgregar:this.props.ListaPermisosUpdate.monitoresMultasAgregar,
      monitoresMultasGanancias:this.props.ListaPermisosUpdate.monitoresMultasGanancias,
      monitoresMultasReporte:this.props.ListaPermisosUpdate.monitoresMultasReporte,
      tokens:this.props.ListaPermisosUpdate.tokens,
      lugares:this.props.ListaPermisosUpdate.lugares,
      Usuarios:this.props.ListaPermisosUpdate.Usuarios,
      usuario_permisos: this.props.ListaPermisosUpdate.usuario_permisos,
      correo_alertas: this.props.ListaPermisosUpdate.correo_alertas,
      palabras_clave: this.props.ListaPermisosUpdate.palabras_clave,
      alertasPlataforma: this.props.ListaPermisosUpdate.alertasPlataforma,
      deuda_modelos: this.props.ListaPermisosUpdate.deuda_modelos,
      servicios_reporte: this.props.ListaPermisosUpdate.servicios_reporte,
      servicios_rol: this.props.ListaPermisosUpdate.servicios_rol,
    };

    update_permiso(data,permiso_id);
  };

  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {


    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">PERMISOS</h1>

          <div className="reporte__div__principal__permisos">
            {this.mostrarAccion()}
            <div className="container-fluid">
              <div className="row">
              <div className="col-md-12 mx-auto">
                  <div className="row">
                    <div
                      className="col-md-6 mx-auto"
                      style={{ display: "flex",paddingTop: "20px" }}
                    >
                      <div style={{ width: "100%" }}>
                        <input
                          type="text"
                          style={{ width: "100%" }}
                          placeholder="Nombre Permiso"
                          value={this.props.ListaPermisosUpdate.nombre || ""}
                          onChange={(e) =>
                            this.handleChange( "nombre", e.target.value)
                        }
                        />
                            <div style={{ width: "100%" }}>{this.listaLugares()}</div>
                      </div>

                      <span
          className="Permisos_Usuarios_Stage__insideDiv__icon__add_permisos"
          onClick={(e) => {
            this.setLugar();
          }}
        >
          <img alt='img para aagregar permisos a la lista' src={btonAgregarPermiso}/>
        </span>
                    </div>
                    <div className="col-md-6 mx-auto">
                      <table className="table table-striped tabla-global" style={{textAlign:"center"}}>
                        <tbody>
                          <tr>
                
                          </tr>
                          {this.listaPermisos()}
                        </tbody>
                      </table>
                    </div>
                  </div>
          
                </div>

                
              </div>

         
            </div>
          </div>


          <div className="row reporte__div__secundario__permisos" >  
      
            <div className="col-md-8 mx-auto">
            <table className="table table-striped tabla-global">
                  <span className="pageTitulos">MENÚ</span>
                    <tbody>
                     
                      {this.ponerHabitaciones()}
                    </tbody>
                  </table>
            </div>
            <div className="col-md-8 mx-auto ">
                <button className="invetario__btn" onClick={this.updatePermiso}>
                  update Permiso
                </button>
              </div>
            </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (reducers) => {
  return reducers.PermisosUsuariosReducer;
};

export default connect(mapStateToProps, permisosaction)(permisosUsuarios);
