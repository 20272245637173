import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as tokenAgregarAction from "../../actions/tokenAgregarAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import { FaTimes } from "react-icons/fa";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";

// Imagine you have a list of languages that you'd like to autosuggest.

// Teach Autosuggest how to calculate suggestions for any given input value.

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.

export class tokenAgregar extends Component {

  componentDidMount() {
    this.props.consultarTokens();
  }

  componentDidUpdate() {
    const { researchPrestamo, } = this.props;
   
    if(!researchPrestamo){
      this.props.consultarTokens();
    }
  }
  componentWillUnmount() {
    const { researchtrue, } = this.props;

    researchtrue();
  }

  cambioNombre = event => {
    this.props.cambioNombre(event.target.value);
  };

  cambioToken = event => {
    this.props.cambioToken(event.target.value);
  };

  guardar = (e) => {

    e.preventDefault()
    const {

      nombre,
      token,
      agregar
    } = this.props;
    const data = {
      nombre: nombre,
      token: token
    };
    agregar(data);
  };


  handleSubmit(e){
    e.preventDefault()
  }

  handledelete(id, e) {
    e.preventDefault();
    const { eliminar } = this.props;

    eliminar(id);
  }

  renderPrestamos = () => {
    const { tokenPlataforma } = this.props;

    if(tokenPlataforma){
      const ponerInfo = () =>
      tokenPlataforma.map((token, key) => (
          <tr key={key}>
            <td>{`${token.nombre}`}</td>
            <td>{`${token.token}`}</td>
            <td>
    
                <span
                  className="loans__icon__x"
                  onClick={e => this.handledelete(token.id, e)}
                >
                  <FaTimes
                   
                    className="Home_Work_Stage__insideDiv__icon__x btn_action"
                  />
                </span>
              
            </td>
          </tr>
        ));
  
      return ponerInfo(); 
    }

  };

  Ponerlabelguardar = () => {

    // Autosuggest will pass through all these props to the input.

    return (
      <form className="" onSubmit={e => this.handleSubmit(e)}>
        <fieldset className="form-fieldset">
     

          <input
            className="prestamosGlobar__text"
            placeholder="Nombre"
            type="text"
            value={this.props.nombre}
            onChange={this.cambioNombre}
          />

          <input
            type="text"
            className="prestamosGlobar__text"
            placeholder="Token"
            onChange={this.cambioToken}
            value={this.props.token}
          />
    
        </fieldset>

        <div className="btn__reportes_div">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={e => this.guardar(e)}
                    >
                      Guardar
                    </button>
                  </div>
      </form>
    );
  };

  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {


    return (
      <Fragment>
        <div className="main-content fade-in">
          <h1 className="tituloPaginas">
            TOKENS <strong className="tituloPaginasPequeño">AGREGAR</strong>
          </h1>
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <div className="reporte__div__principal">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 ">
                  {this.Ponerlabelguardar()}
                  {this.mostrarAccion()}
                </div>

  
                <div className="col-md-8 todos__prestamos__div ">
                  <table className="table table-striped tabla-global">
                    <thead>
                      <tr>
                       <th>NOMBRE</th>
                        <th>TOKEN</th>
                        <th>ACCIÓN</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderPrestamos()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.tokenAgregarReducer;
};

export default connect(mapStateToProps, tokenAgregarAction)(tokenAgregar);
