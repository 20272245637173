export const CONSULTAR = 'reportesmodelos_pago_traer_todas';
export const CARGANDO = 'reportesmodelos_pago_cargando';
export const ERROR = 'reportesmodelos_pago_error';
export const GUARDAR = 'reportesmodelos_pago_guardada';
export const ACTUALIZAR = 'reportesmodelos_pago_actualizar';
export const LIMPIAR = 'reportesmodelos_pago_limpiar';
export const RESEARCHTRUE = 'reportesmodelos_pago_research';
export const SUCCESS = 'reportesmodelos_pago_succes';
export const CAMBIO_FECHAPRESTAMO = 'reportesmodelos_pago_fecha';
export const CAMBIO_CANTIDAD = 'reportesmodelos_pago_cantidad';
export const CAMBIO_CONCEPTO = 'reportesmodelos_pago_concepto';
export const CAMBIO_FECHA1 = 'reportesmodelos_pago_fecha1';
export const CAMBIO_FECHA2 = 'reportesmodelos_pago_fecha2';
export const FILTRAR_MODELO_PAGO = 'reportesmodelos_pago_ganancias_filtrar';
export const CAMBIO_FECHA_PAGOS = 'reportesmodelos_pago_cambio_fecha_pagos';
export const CONSULTAR_LUGARES = 'reportesmodelos_pago_consultar_lugares';
export const CONSULTARS = 'reportesmodelos_pago_traer_todas_suggestions';
export const CONSULTAR_FECHAS_PAGOS = 'reportesmodelos_pago_traer_todas_fechas_pagos';
export const IMAGEN_PERFIL_LOGO = 'reportesmodelos_pago_logo';
export const CONSULTAR_PAGO_NOMINA_LUGAR = 'pago_nomina_lugar_plataforma';
export const CONSULTAR_PAGO_NOMINA_TOTALES_LUGAR = 'pago_total_lugar_plataforma';








