import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as MultasAgregarMonitorAction from "../../actions/MultasAgregarMonitorAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import { FaTimes } from "react-icons/fa";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import DatePicker from "react-date-picker";
import { MdFileUpload } from "react-icons/md";
import moment from "moment";

// Imagine you have a list of languages that you'd like to autosuggest.

// Teach Autosuggest how to calculate suggestions for any given input value.

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.

export class MultaAgregarMonitor extends Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: "",
      id: "",
      text: "",
      suggestVar: false,
      date: new Date()
    };
  }
  componentDidMount() {
    this.props.consultarGananciasMultas();
    this.cambiofechaprestamo(this.state.date);
  }

  componentDidUpdate() {
    const { researchPrestamo, } = this.props;

    if (!researchPrestamo) {
      this.props.consultarGananciasMultas();
    }
  }
  componentWillUnmount() {
    const { researchtrue, } = this.props;

    researchtrue();
  }

  /* 
          getSuggestions2 = value => {
            const inputValue = value.trim().toLowerCase();
            const inputLength = inputValue.length;
    
            var ArrayVar = inputLength === 0 ? [] : this.props.SuggestionDatabase.filter(lang =>
              lang.name.toLowerCase()=== inputValue
            );
           return ArrayVar[0] ? ArrayVar[0].id: []
           
          }; 
     */
  cambiofechaprestamo = date => {
    this.setState({ date: date });
 
    this.props.cambiofechaprestamo((moment(date).format('YYYY/MM/DD') ));
  };

  cambiofecha1 = event => {
    this.props.cambiofecha1(event.target.value);
  };

  cambiofecha2 = event => {
    this.props.cambiofecha2(event.target.value);
  };

  cambiocantidad = event => {
    this.props.cambiocantidad(event.target.value);
  };

  cambioconcepto = event => {
    this.props.cambioconcepto(event.target.value);
  };

  guardar = () => {
    const {
      /*    match: {
           params: { model_id }
         }, */
      cantidad,
      fechaprestamo,
      agregarGananciaMulta
    } = this.props;
    const prestamomodelo = {
      fecha: fechaprestamo,
      datos_id: this.state.id,
      cantidad: cantidad
    };

         agregarGananciaMulta(prestamomodelo);
  };


  guardarGlobal =() =>{
    const {
      /*    match: {
           params: { model_id }
         }, */
      cantidad,
      fechaprestamo,
      agregarGananciaMultaGloabal
    } = this.props;
    const prestamomodelo = {
      fecha: fechaprestamo,
      cantidad: cantidad
    };

         agregarGananciaMultaGloabal(prestamomodelo);

  }

  agregarGananciaMulta
  consultarMultasModelo = () => {
    const { consultar_multa_monitor } = this.props;

    consultar_multa_monitor(this.state.id);
  };

  handleSubmit(e) {
    e.preventDefault();
  }

  onTextChanged = e => {
    const value = e.target.value;
    let suggestions = [];

    const { consultarSuggestions } = this.props;
    const dataSuggestion = {
      data: value
    };
    if (value.length > 2) {
      this.setState(() => ({ suggestVar: true }));
      consultarSuggestions(dataSuggestion);
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      suggestions =
        inputLength === 0
          ? []
          : this.props.SuggestionDatabase.filter(
            lang =>
              lang.name.toLowerCase().slice(0, inputLength) === inputValue
          );
    } else {
      this.setState(() => ({ suggestVar: false }));
    }
    this.setState(() => ({ suggestions, text: value }));
  };

  suggestionSelected(value) {
    this.setState(() => ({
      text: value.name,
      id: value.id,
      suggestVar: false
    }));
  }

  renderSuggestions() {
    if (!this.state.suggestVar) {
      return null;
    }
    return (
      <div className="srchList">
        {this.props.SuggestionDatabase.map((item, key) => (
          <li key={key} onClick={() => this.suggestionSelected(item)}>
            {item.name}
          </li>
        ))}
      </div>
    );
  }

  handledelete(id, e) {
    e.preventDefault();
    const { eliminarMultasGanacias } = this.props;

    eliminarMultasGanacias(id);
  }

  handleEditar = (id, e,key) => {
    const { editarGananciasMultas } = this.props;
    const UploadDatosMultas = {
      cantidad: this.props.ganaciaMultasModelos[key].cantidad,

    };
    e.preventDefault();

    editarGananciasMultas(UploadDatosMultas, id);


  };

    handleEditaMultaSearch = (key,id, e) => {
    e.preventDefault();
    const { editarGananciasMultas } = this.props;
    const UploadDatosMultas = {
      cantidad: this.props.ganaciaMultasModelo[key].cantidad,

    };
 

    editarGananciasMultas(UploadDatosMultas, id);


  }; 
  handleChangeGanancia(index, dataType, value) {


    this.props.handleChangeGanancia(index, dataType, value)

  }

  handleChangeMultaHistorialGanancia(index, dataType, value) {

    this.props.handleChangeMultaHistorialGanancia(index, dataType, value)

  }
  renderPrestamos = () => {
    const { ganaciaMultasModelos } = this.props;
    const ponerInfo = () =>
    ganaciaMultasModelos.map((multas, key) => (
        <tr key={key}>
          <td>{`${multas.cedula}`}</td>
          <td>{`${multas.nombre} ${multas.apellidos}`}</td>
          <td>{multas.fecha}</td>
          <td>    <input
            type="text"
            value={multas.cantidad || ''}
            onChange={(e) => this.handleChangeGanancia(key, 'cantidad', e.target.value)}
          /></td>
      
          <td>
            {<Fragment>

              <span
                className="loans__icon__x"
                onClick={e => this.handleEditar(multas.id, e,key)}
              >
                <MdFileUpload
                 
                  className="Home_Work_Stage__insideDiv__icon__x btn_action"
                />
              </span>


              <span
                className="loans__icon__x"
                onClick={e => this.handledelete(multas.id, e)}
              >
                <FaTimes
                 
                  className="Home_Work_Stage__insideDiv__icon__x btn_action"
                />
              </span>
            </Fragment>
            }
          </td>
        </tr>
      ));

    return ponerInfo();
  };
  renderPrestamosModelo = () => {
    const { ganaciaMultasModelo } = this.props;
    const ponerInfo = () => (
      <div>
        <table className="table table-striped tabla-global">
          <thead>
            <tr>
              <th>CÉDULA</th>
              <th>NOMBRE</th>
              <th>FECHA </th>
              <th>CANTIIDAD </th>
          
              <th>ACCIÓN </th>
            </tr>
          </thead>
          <tbody>
            {ganaciaMultasModelo.map((multas, key) => (
              <tr key={key}>
                <td>{`${multas.cedula}`}</td>
                <td>{`${multas.nombre} ${multas.apellidos}`}</td>
                <td>{multas.fecha}</td>
                <td>    <input
                  type="text"
                
                  value={multas.cantidad || ''}
                  onChange={(e) => this.handleChangeMultaHistorialGanancia(key, 'cantidad', e.target.value)}
                /></td>
          
                <td>
                  {<Fragment>

                    <span
                      className="loans__icon__x"
                      onClick={e => this.handleEditaMultaSearch(key,multas.id, e)}
                    >
                      <MdFileUpload
                     
                        className="Home_Work_Stage__insideDiv__icon__x btn_action"
                      />
                    </span>


                    <span
                      className="loans__icon__x"
                      onClick={e => this.handledelete(multas.id, e)}
                    >
                      <FaTimes
                    
                        className="Home_Work_Stage__insideDiv__icon__x btn_action"
                      />
                    </span>
                  </Fragment>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );

    if (ganaciaMultasModelo.length > 0) {
      return ponerInfo();
    } else {
      return "";
    }
  };
  Ponerlabelguardar = () => {
    const { text } = this.state;
    // Autosuggest will pass through all these props to the input.

    return (
      <form className="" onSubmit={e => this.handleSubmit(e)}>
        <fieldset className="form-fieldset">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-12 input">
                <input
                  className="input__autocomplete__Text prestamosGlobar__text"
                  value={text}
                  onChange={this.onTextChanged}
                  type="text"
                  placeholder="Buscar Monitor"
                  required
                />
              </div>
              <div className="col-md-12 justify-content-md-center input__autocomplete__SuggestionText">
                {this.renderSuggestions()}
              </div>
            </div>
          </div>

          <input
            className="prestamosGlobar__text"
            placeholder="Cantidad"
            type="number"
            value={this.props.multa}
            onChange={this.cambiocantidad}
          />

          <div>
            <DatePicker
              className="prestamosGlobar__text"
              onChange={this.cambiofechaprestamo}
              value={this.state.date}
            />
          </div>
        </fieldset>
      </form>
    );
  };

  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {
    //   const { match: { params: {modelname } },   } = this.props

    console.log(this.props);

    return (
      <Fragment>
        <div className="main-content fade-in">
          <h1 className="tituloPaginas">
            GANANCIA MULTAS<strong className="tituloPaginasPequeño"></strong>
          </h1>
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <div className="reporte__div__principal">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 ">
                  {this.Ponerlabelguardar()}

                  <div className="btn__reportes_div">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={this.consultarMultasModelo}
                    >
                      Filtrar
                    </button>
                  </div>

                  <div className="btn__reportes_div">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={this.guardarGlobal}
                    >
                      Pago global
                    </button>
                  </div>
                  <div className="btn__reportes_div">
                    <button
                      className="btn__reportes_page"
                      type="submit"
                      onClick={this.guardar}
                    >
                      Guardar
                    </button>
                  </div>
                  {this.mostrarAccion()}
                </div>
                <div className="col-md-8 todos__multas__div ">
                  <table className="table table-striped tabla-global">
                    <thead>
                      <tr>
                        <th>CÉDULA</th>
                        <th>NOMBRE</th>
                        <th>FECHA </th>
                        <th>CANTIIDAD </th>
                    
                        <th>ACCIÓN </th>
                      </tr>
                    </thead>
                    <tbody>{this.renderPrestamos()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {this.renderPrestamosModelo()}
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.MultasAgregarMonitorReducer;
};

export default connect(mapStateToProps, MultasAgregarMonitorAction)(MultaAgregarMonitor);
