import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as habitacionagregaraction from "../../actions/HabitacionesAgregarAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import cambiarFotoImg from "../../img/cambiarFotoImg.png";
//import img360 from "../../img/360img.png";
import updateBtnImg from "../../img/updateBtnImg.png";
import seeBtnImg from "../../img/seeBtnImg.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import { URL } from "../../../src/General/url";
import { MdCancel } from "react-icons/md";



 
export class HabitacionesAgregar extends Component {
  constructor() {
    super();

    this.state = {
      sede_id: "",
      inventarioHabitaciones:[
        {name:'Camara',cantidad:0},
        {name:'Computador',cantidad:0},
        {name:'Monitor',cantidad:0},
        {name:'Tv',cantidad:0},
        {name:'Teclado',cantidad:0},
        {name:'Mouse',cantidad:0},
        {name:'Parlante',cantidad:0},
        {name:'Lampara',cantidad:0},
        {name:'Tripode',cantidad:0}
      ],
      itemValue:''
    };
  }
  state = {
    opacity: "30%",
  };



  componentDidMount() {
    const {
     
      consultar_sedes,
      contar_habitacion_sede,
    } = this.props;
  
    consultar_sedes();
    contar_habitacion_sede();
  }


  changeAmount=(name,cantidad)=>{

  const newhabitaciones =  this.state.inventarioHabitaciones.map((item, i) => {
    if (item.name === name) {
      if(cantidad>=0){
        return {...item, 'cantidad': cantidad};
      }
    
    }
    return item;
  });

  this.setState({inventarioHabitaciones:newhabitaciones})
  }

  componentDidUpdate() {
    console.log(this.state);
  }

  componentWillUnmount() {}

  handleSubmit(e) {
    e.preventDefault();
  }

  additem=()=>{
    if(this.state.itemValue){
      let newItems = [...this.state.inventarioHabitaciones,
        {name:this.state.itemValue}]
        this.setState({inventarioHabitaciones:newItems})
    }
  }
  
  deleteItem=(name)=>{

    
    this.setState({inventarioHabitaciones:this.state.inventarioHabitaciones.filter( e => e.name !== name )})

  }


  listadoInventario = () =>{

    const listado = () =>
    this.state.inventarioHabitaciones.map((inventario, key) => (
      <div className='col-md-2 mx-auto' style={{textAlign:'center', marginTop:'2px', border:'1px solid',borderRadius:'2px', padding:'2px'}}><span style={{display:'flex',justifyContent:'space-between'}}>{inventario.name}<MdCancel onClick={()=>this.deleteItem(inventario.name)} className="Home_Work_Stage__Button size_btn_add" /></span>
      <input type='number'
      onChange={(e)=>this.changeAmount(inventario.name,e.target.value)}
      style={{width:'100%'}}
      value={inventario.cantidad}
      placeholder='Cantidad'/></div>
     ));

    return listado()
  
  }
  crearHabitacion = () => {
    const {
      /*    match: {
           params: { model_id }
         }, */
      numRoom,
      foto_perfil,
      descripcion,
      inventario,
      crearHabitacion,
    } = this.props;
    const data = {
      numero_habitacion: numRoom,
      foto1: foto_perfil,
      sede: this.state.sede_id,
      descripcion: descripcion,
      inventario: inventario,
      inventarioHabitaciones:this.state.inventarioHabitaciones
    };

    crearHabitacion(data);
  };

  UpdaateHabitacion(id, key, e) {
    e.preventDefault();
    const { editarRooms } = this.props;
    const datosInformacionModelo = {
      numero_habitacion: this.props.habitaciones[key].numero_habitacion,
      foto1: this.props.habitaciones[key].foto1,
      foto2: this.props.habitaciones[key].foto2,
      inventario: this.props.habitaciones[key].inventario,
      descripcion: this.props.habitaciones[key].descripcion,
      estado: this.props.habitaciones[key].estado,    };

    editarRooms(datosInformacionModelo, id);
  }

  

  handleChange(dataType, value) {
    this.props.handleChange(dataType, value);
    console.log(dataType, value);
  }

  handleChangeUpdate(index, dataType, value) {
    this.props.handleChangeUpdate(index, dataType, value);
  }

  cambioFotoPerfil = (event) => {
    const { guardarimagen } = this.props;
    const files = event.target.files[0];
    if (files) {
      const data = new FormData();
      data.append("file0", files);
      guardarimagen(data);
    }
  };

  cambioFoto360 = (event) => {
    const { guardarimagen360 } = this.props;
    const files = event.target.files[0];
    if (files) {
      const data = new FormData();
      data.append("file0", files);
      guardarimagen360(data);
    }
  };

  cambioFotoPerfilUpdate(key, dataType, e) {
    const { guardarimagenUpdate } = this.props;
    const files = e;
    if (files) {
      const data = new FormData();
      data.append("file0", files);
      guardarimagenUpdate(data, key, dataType);
    }
  }

  cambioFoto360Update = (key, dataType, e) => {
    const { guardarimagen360Update } = this.props;
    const files = e;
    if (files) {
      const data = new FormData();
      data.append("file0", files);
      guardarimagen360Update(data, key, dataType);
    }
  };

  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  listaSede = () => {

    const styleInventoryProp = {
      width: '100%',
      marginTop: '5px',
    };


    const { sedes } = this.props;

    const ponerOpcionesTurnos = () =>
      sedes.map((turnos, key) => (
        <option className="option-values" key={key} value={turnos.id}>
          {this.MaysPrimera(turnos.nombre.toLowerCase())}
        </option>
      ));

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        style={{...styleInventoryProp}}
        onChange={(e) => {
          this.setState({ sede_id: e.target.value });
        }}
        required
      >
        <option className="option-values">Sedes</option>
        {ponerOpcionesTurnos()}
      </select>
    );
  };

  ListaTotalHab = () => {
    const { totalHab } = this.props;
    const ponerOpcionesTurnos = () =>
      totalHab.map((habitaciones, key) => (
        <div className="col-md-2 mx-auto " key={key} style={{cursor:'pointer',textAlign: "center",backgroundColor: 'rgba(200, 200, 200, 0.3)',borderRadius:'5px'}} onClick={()=>{this.props.consultarRooms(habitaciones.lugares_id)}}>
          <span style={{ textAlign: "center" }}> {habitaciones.sede_hab}  {habitaciones.total_hab} </span>
         
        </div>
      ));

    return ponerOpcionesTurnos();
  };

  listaEstado = (key) => {
    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        required
        onChange={(e) => this.handleChange(key, "estado", e.target.value)}
      >
        <option
          className="option-values"
          value={this.props.habitaciones[key].estado}
        >
          {this.props.habitaciones[key].estado
            ? this.MaysPrimera("Activ@".toLowerCase())
            : this.MaysPrimera("Inactiv@".toLowerCase())}
        </option>
        <option className="option-values" value="1">
          {this.MaysPrimera("Activ@".toLowerCase())}
          {}
        </option>
        <option className="option-values" value="0">
          {this.MaysPrimera("Inactiv@".toLowerCase())}
          {}
        </option>
      </select>
    );
  };

  handleNextStep(e) {
    e.preventDefault();
    const { validar_creacion_modelos } = this.props;

    const datos = {
      tipo: "informacionmodelo",
      dato: this.props.cedulaModelo,
    };
    validar_creacion_modelos(datos);
  }

  ListaHabitaciones = () => {
    const { habitaciones } = this.props;

    const ponerHabitaciones = () =>
      habitaciones.map((habitaciones, key) => (
        <Fragment key={key} >
          <div className="col-md-3 mx-auto">
      <div  style={{backgroundColor:'rgba(200, 200, 200, 0.3)', borderRadius: '10px',padding: '0 5px 0 5px',
    border: '1px solid', margin:'5px 0 5px 0'}}>
            <img
              className="avatarModeloimg"
              alt="imgagerfil"
              src={`${URL}getImage/${
                this.props.habitaciones[key].foto1
                  ? this.props.habitaciones[key].foto1
                  : "defaultLogo.png"
              }`}
            />

            <div className="">
              {" "}
              <h1 style={{ textAlign: "center" }}>
                {habitaciones.numero_habitacion}
              </h1>
            </div>

            <div className="">
              <div className="">
                <textarea
                style={{width:'100%'}}
                  type="text-area"
                  placeholder="Descripción"
                  value={this.props.habitaciones[key].descripcion || ""}
                  onChange={(e) =>
                    this.handleChangeUpdate(key, "descripcion", e.target.value)
                  }
                ></textarea>
              </div>

              <div className="div__personal__modificar__info">
                <div className="text__rigth">Estado </div>{" "}
                {this.listaEstado(key)}
              </div>
            </div>

            <div className="div__btn__rooms__update">
              <Link to={`/HabitacionesView360/${habitaciones.id}`}>
                <img alt="enviar boton" src={seeBtnImg}
                 style={{ cursor: "pointer",width:'auto' }} />
              </Link>

              <img
                alt="update img"
                src={updateBtnImg}
                onClick={(e) => this.UpdaateHabitacion(habitaciones.id, key, e)}
                style={{ cursor: "pointer",width:'auto' }}
              />
            </div>
            </div>
          </div>
        </Fragment>
      ));

    return ponerHabitaciones();
  };

  ponerInformacionModelo = () => {

    const styleInventoryProp = {
      width: '100%',
      marginTop: '5px',
    };



    const ponerInfo = () => (
      <Fragment>
        <div className="col-md-4">
          <img
            className="avatarModeloimg"
            alt="imgagerfil"
            src={`${URL}getImage/${
              this.props.foto_perfil
                ? this.props.foto_perfil
                : "defaultLogo.png"
            }`}
          />
          <label
            className="foto_perfil_modelo_label"
            htmlFor="foto_perfil_modelo"
          >
            <img
              src={cambiarFotoImg}
              alt="Click aquí para subir tu foto"
              title="Click aquí para subir tu foto"
            />
          </label>
          <input
            className="form-element-field -hasvalue"
            id={`foto_perfil_modelo`}
            type="file"
            onChange={this.cambioFotoPerfil}
          />

<div className="">
            <div className="">
             
              <input
                type="number"
                className="input__modificiar_informacion_modelo"
                value={this.props.numRoom}
                style={{...styleInventoryProp}}
                placeholder={'#room'}
                onChange={(e) =>
                  this.handleChange("CAMBIO_ROOM", e.target.value)
                }
              />
            </div>
            <div className="">
              {this.listaSede()}
            </div>

            <div className="">
              <textarea
                type="text-area"
                className="input__modificiar_informacion_modelo"
                style={{...styleInventoryProp}}
                value={this.props.descripcion}
                placeholder={'Descripción'}
                onChange={(e) =>
                  this.handleChange("CAMBIO_DESCRIPCION", e.target.value)
                }
              ></textarea>
            </div>


          </div>

          <div className="btn__reportes_div">
            <button
              className="btn__Agregar__habtacion"
              type="submit"
              onClick={this.crearHabitacion}
            >
              Crear Habitacion
            </button>
          </div>
        </div>

        <div className="col-md-8 ">
          <h1 style={{width:'100%',textAlign:'center'}}>Inventario</h1>

          <div className="row" style={{padding:'5px'}}> {this.listadoInventario()} 

          <div className="col-md-12 mx-auto" style={{marginTop:'5px',display:'flex',justifyContent:'center'}}>
              <input
              type='text'
              placeholder='Item'
              onChange={e=>{this.setState({itemValue:e.target.value})}}
              value={this.state.itemValue}
              /> 
                <button onClick={()=>this.additem()}>Crear item</button>
              </div>
         
              </div>
         
        </div>
      </Fragment>
    );

    return ponerInfo();
  };

  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {


    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            ROOMS <strong className="tituloPaginasPequeño">AGREGAR</strong>
          </h1>

          <div className="reporte__div__principal">
            <div className="container-fluid">
              <div className="row">{this.ponerInformacionModelo()}</div>
            </div>
          </div>

          {this.mostrarAccion()}

          <div className="container-fluid">
            <div className="row" >
              {this.ListaTotalHab()}
            
              <div className="col-md-12 mx-auto" style={{marginTop:'5px'}}>
                <div className="row">{this.ListaHabitaciones()}</div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (reducers) => {
  return reducers.HabitacionesAgregarReducer;
};

export default connect(
  mapStateToProps,
  habitacionagregaraction
)(HabitacionesAgregar);
