import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as nominaaction from "../../actions/NominaAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";


/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */

export class NominaRecibosMonires extends Component {


  state = {
    dateStart: new Date(),
    dateEnd: new Date(),
  }

  componentDidMount() {

    const {
      match: {
        params: { fecha_id }
      },
      consultar_nomina_monitores,
    } = this.props
    consultar_nomina_monitores(fecha_id);


  }

  componentDidUpdate() {
    console.log(this.props);


  }

  componentWillUnmount() {
    const { researchtrue } = this.props;

    researchtrue();
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  pagosNominaMonitores = () => {
    
    const { pagosNominaMonitores,

    } = this.props

  
    const ponerInfo = () => pagosNominaMonitores.map((pagosNominaMonitores, key) => (

      <tr key={key}>
        <td>{key} </td>
        <td>{pagosNominaMonitores.cedula}</td>
        <td>{pagosNominaMonitores.nombres} {pagosNominaMonitores.apellidos}</td>
        <td>{`$${pagosNominaMonitores.total}`} </td>
        <td> </td>
        <td></td>

      


      </tr>
    ))
  
    return (ponerInfo())
  }




  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {
    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            NÓMINA <strong className="tituloPaginasPequeño"> MONITORES PLANILLAS</strong>
          </h1>
          <div className="reporte__div__principal_pagonomina">
            <div className="container-fluid">
              <div className="row">

              <div className="col-md-12 ">

<table className="table table-bordered tabla-global" >
  <thead>
    <tr>
      <th># </th>
      <th>Cedula </th>
      <th>Nombre</th>
      <th>Total a Pagar</th>
      <th>Fecha</th>
      <th>Firma del monitor</th>


    </tr>
  </thead>
  <tbody>
    {this.pagosNominaMonitores()}
  </tbody>
</table>
</div>

                
                {

                }
              </div>
              {this.mostrarAccion()}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.NominaReducer;
};

export default connect(mapStateToProps, nominaaction)(NominaRecibosMonires);
