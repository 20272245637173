export const CONSULTAR = 'home_traer_todas';
export const CARGANDO = 'home_cargando';
export const ERROR = 'home_error';
export const CAMBIO_USUARIO_ID = 'home_cambio_usuario_id';
export const CAMBIO_SEARCH = 'home_cambio_search';
export const GUARDAR = 'homes_guardada';
export const ACTUALIZAR = 'home_actualizar';
export const LIMPIAR = 'home_limpiar';
export const RESEARCHTRUE = 'home_research';
export const CONSULTAR_MODELO = 'home_consultar_modelo';
export const CONTAR_MODELO = 'home_contar_modelo';
export const CAMBIO_CONCEPTO = 'home_cambio_homework';
export const CONTAR_TAREAS = 'home_contar_tareas';
export const CONSULTAR_TAREAS = 'home_consultar_tareas';
export const SUCCESS = 'home_tareas_succes';
export const GUARDARTAREAS = 'home_tareas_crear';
export const CAMBIO_SEARCH_TAREAS = 'home_cambio_search_tarea';;
export const CONSULTAR_EVENTO_PROXIMO = 'home_consultar_proximos_eventos';





 
 

