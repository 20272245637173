export const CONSULTAR = 'report_retencion_traer_todas';
export const CARGANDO = 'report_retencion_cargando';
export const ERROR = 'report_retencion_error';
export const GUARDAR = 'report_retencion_guardada';
export const ACTUALIZAR = 'report_retencion_actualizar';
export const LIMPIAR = 'report_retencion_limpiar';
export const RESEARCHTRUE = 'report_retencion_research';
export const SUCCESS = 'report_retencion_succes';
export const CAMBIO_FECHAPRESTAMO = 'report_retencion_fecha';
export const CAMBIO_CANTIDAD = 'report_retencion_cantidad';
export const CAMBIO_CONCEPTO = 'report_retencion_concepto';
export const CAMBIO_FECHA1 = 'report_retencion_fecha1';
export const CAMBIO_FECHA2 = 'report_retencion_fecha2';
export const FILTRAR_PRESTAMOS_MINIMOS = 'report_retencion_minimos_filtrar';
export const CAMBIO_LUGARES = 'report_retencion_cambio_lugares';
export const CONSULTAR_LUGARES = 'report_retencion_consultar_lugares';




