import axios from 'axios'
import { CARGANDO, ERROR,  GUARDAR, CONSULTAR,CAMBIO_CANTIDAD,RESEARCHTRUE,CAMBIO_CONCEPTO,
    SUCCESS,FILTRAR_TOKENS,RESEARPRESTAMO,CAMBIO_NOMBRE_LUGAR,CONSULTAR_SERVICIOS} from '../types/tokenAgregarTypes'

    import {URL} from '../../src/General/url'



export const agregar = (prestamomodelo) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(prestamomodelo)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'createToken', params,{
            headers: headers
        })
    
      
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })


        if(respuesta.data.status==="success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

            dispatch({
        
                type: RESEARPRESTAMO,
                payload: false
                
            
            })

        }else{
               dispatch({
        
                type: ERROR,
                payload: 'Error al crear el token'
                
            
            })
        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const agregarLugar = (data) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'createPlace', params,{
            headers: headers
        })
    
      
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })


        if(respuesta.data.status==="success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

            dispatch({
        
                type: RESEARPRESTAMO,
                payload: false
                
            
            })

        }else{
               dispatch({
        
                type: ERROR,
                payload: 'Error al crear el lugar'
                
            
            })
        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const editar = (data,id) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`placeEdit/${id}`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
}

if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
        
 /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

 
}

export const agregarServicio = (data) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'createService', params,{
            headers: headers
        })
    
      
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })


        if(respuesta.data.status==="success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

            dispatch({
        
                type: RESEARPRESTAMO,
                payload: false
                
            
            })

        }else{
               dispatch({
        
                type: ERROR,
                payload: 'Error al crear el lugar'
                
            
            })
        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const consultarTokens = () => async(dispatch)=>{
    

    try {

    

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+'getTokens' ,{
            headers: headers
        })
        if(respuesta.data.status==="Success"){
            dispatch({
                type: FILTRAR_TOKENS,
                payload:respuesta.data.data
            })

        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
  

        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload:error.message
                
            
            })
        }
    }
}



export const eliminar = (id) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {



        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.delete(URL+`deleteToken/${id}`,{
            headers: headers
        })
    
    
        
        if(respuesta.data.status==="Success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

            dispatch({
        
                type: RESEARPRESTAMO,
                payload: false
                
            
            })

        }else{
               dispatch({
        
                type: ERROR,
                payload: 'Error al eliminar el token'
                
            
            })
        }

        
        
    } catch (error) {

        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}

export const eliminarLugar = (id) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.delete(URL+`deletePlace/${id}`,{
            headers: headers
        })
    
    
        
        if(respuesta.data.status==="Success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

            dispatch({
        
                type: RESEARPRESTAMO,
                payload: false
                
            
            })

        }else{
               dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })
        }

        
        
    } catch (error) {

        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const eliminarServicio = (id) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.delete(URL+`deleteService/${id}`,{
            headers: headers
        })
    
    
        
        if(respuesta.data.status==="Success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

            dispatch({
        
                type: RESEARPRESTAMO,
                payload: false
                
            
            })

        }else{
               dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })
        }

        
        
    } catch (error) {

        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}

export const consultar = () => async(dispatch)=>{
    


    try {

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`getPlaces`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTAR,
            payload:respuesta.data.data,
        })

        
        
    } catch (error) {
        
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}


export const consultarServices = () => async(dispatch)=>{
    


    try {

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`getServices`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTAR_SERVICIOS,
            payload:respuesta.data.data,
        })

        
        
    } catch (error) {
        
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

export const cambioToken = (data) => (dispatch)=>{

    dispatch({
        type: CAMBIO_CONCEPTO,
        payload: data
        
    })


}


export const cambioNombre = (data) => (dispatch)=>{
    dispatch({
        type: CAMBIO_CANTIDAD,
        payload: data
        
    })
}


export const cambioNombreLugar = (data) => (dispatch)=>{
    dispatch({
        type: CAMBIO_NOMBRE_LUGAR,
        payload: data
        
    })
}





export const researchtrue  = () => (dispatch,) => {
    dispatch({

        type: RESEARCHTRUE
        
    
    })


    

   
};


export const handleChange  = (index, dataType, value) => (dispatch,getState) => {

    const {lugar} = getState().tokenAgregarReducer


    const newData = lugar.map((item, i) => {
        if (i === index) {
          return {...item, [dataType]: value};
        }
        return item;
      });
  

      dispatch({
        type: CONSULTAR,
        payload:newData
    })


    

   
};