import axios from "axios";
import {
  CARGANDO,
  ERROR,
  SUCCESS,
  IMAGEN_PERFIL,
  CAMBIO_ESTADO_CREAR,
  CAMBIO_ESTADO_INICIAL,
  CAMBIO_PERMISOS_ID,
  CAMBIO_PERMISOS,
  CAMBIO_PERMISOS_PAGOS,
  CAMBIO_PASSWORD_MONITOR,
  CAMBIO_USUARIO,
  CONSULTAR_TODOS_MONITORES,
  NEW_USERS
} from "../types/crearMonitoresTypes";

//acciones para tareas

import {URL} from '../../src/General/url'
export const estado_flag = () => async(dispatch)=>{
    dispatch({

        type: CAMBIO_ESTADO_INICIAL,
        payload: false
        
    
    })

}
export const handleChangeUpdate  = (index, dataType, value) => (dispatch,getState) => {

    const {usuarios_todos} = getState().crearMonitoresReducer

    const newUsuarios = usuarios_todos.map((item, i) => {
        if (i === index) {
          return {...item, [dataType]: value};
        }
        return item;
      });
  

      dispatch({
        type: NEW_USERS,
        payload:newUsuarios
    })


    

   
};
export const editarUser = (data,id) => async(dispatch)=>{
   
    
  dispatch({

      type: CARGANDO
      
  
  })

  try {
    
      let json = JSON.stringify(data)
      let params = json;

        

      let headers = {
          "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.put(URL+`updateUser/${id}`, params,{
          headers: headers
      })
   
if(respuesta.data.status==="Success"){
  dispatch({

      type: SUCCESS,
      payload: respuesta.data.message
      
  
  })
}
if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
      
/*    
      */
      
      
      
  } catch (error) {
      
      if(error.message==="Request failed with status code 401"){
          window.localStorage.setItem('token','')
          window.localStorage.setItem('userData','')
      }else{
  dispatch({

      type: ERROR,
      payload: 'Intente mas tarde - Cuenta Ocupada'
      
  
  })
  }
}


}


export const desabiltiarUsuario = (data,id) => async(dispatch)=>{
   
    
  
    try {
      
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`desableUser/${id}`, params,{
            headers: headers
        })
     
  if(respuesta.data.status==="Success"){
    dispatch({
  
        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })

    dispatch({

        type: CAMBIO_ESTADO_INICIAL,
        payload: true
        
    
    })
  }

  if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
        
  /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({
  
        type: ERROR,
        payload: 'Intente mas tarde - Cuenta Ocupada'
        
    
    })
    }
  }
  
  
  }
export const consultar_todos_monitores = (id) => async (dispatch) => {

    dispatch({
      type: CARGANDO
    });
  
    try {
  
     
        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL +  `getUsers`, {
            headers: headers
        })
  
  
        if (respuesta.data.status === "Success") {
          dispatch({
            type: CONSULTAR_TODOS_MONITORES,
            payload: respuesta.data.data
          });
          
          dispatch({

            type: CAMBIO_ESTADO_INICIAL,
            payload: false
            
        
        })
        }
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }


export const guardarimagen = (data) => async (dispatch) => {




  dispatch({

      type: CARGANDO


  })

  try {


      let params = data
      let headers = {
          'Content-Type': 'multipart/from-data',
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.post(URL + 'StoreImage', params, {
          headers: headers
      })

      dispatch({
        type: IMAGEN_PERFIL,
        payload: respuesta.data.imagename
      });
    

    /*   dispatch({
          type: CAMBIO_FOTO_PERFIL,
          payload: respuesta.data.imagename
      }) */

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}

export const validar_creacion_modelos = (data) => async (dispatch) => {




    dispatch({
  
        type: CARGANDO
  
  
    })
  
    try {
  
  
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL + 'validarCreacionModelo', params, {
            headers: headers
        })
        
        if(respuesta.data.status==="Success"){
            dispatch({
                type: CAMBIO_ESTADO_CREAR,
                payload: respuesta.data.data
              });
        }

        if(respuesta.data.status==="Error"){
            dispatch({
                type: CAMBIO_ESTADO_CREAR,
                payload: respuesta.data.data
              });

              dispatch({
  
                type: ERROR,
                payload: respuesta.data.message
  
  
            })
        }
    
      
  
      /*   dispatch({
            type: CAMBIO_FOTO_PERFIL,
            payload: respuesta.data.imagename
        }) */
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }

  export const crear_monitor = (data) => async (dispatch) => {

  
    try {
  
  
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL + 'createUser', params, {
            headers: headers
        })
        
        if(respuesta.data.status==="Success"){
            dispatch({
                type: SUCCESS,
                payload: respuesta.data.message
              });

              dispatch({

                type: CAMBIO_ESTADO_INICIAL,
                payload: true
                
            
            })
        }

        if(respuesta.data.status==="Error"){
            dispatch({
                type: CAMBIO_ESTADO_CREAR,
                payload: respuesta.data.data
              });

              dispatch({
  
                type: ERROR,
                payload: respuesta.data.message
  
  
            })
        }
    
      
  
      /*   dispatch({
            type: CAMBIO_FOTO_PERFIL,
            payload: respuesta.data.imagename
        }) */
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }


export const handleChange  = (dataType, value) => (dispatch) => {


if(dataType==='CAMBIO_USUARIO'){
    dataType=CAMBIO_USUARIO
}else if(dataType==='CAMBIO_PASSWORD_MONITOR'){
    dataType=CAMBIO_PASSWORD_MONITOR
}
else if(dataType==='CAMBIO_PERMISOS_ID'){
    dataType=CAMBIO_PERMISOS_ID
}
    dispatch({
      type: dataType,
      payload:value
  })


  

 
};


export const cambioPermisosId = (data) => (dispatch)=>{

    dispatch({
        type: CAMBIO_PERMISOS_ID,
        payload: data
        
    })
  }

 export const consultar_permisos = () => async (dispatch) => {


  try {



    let headers = {
        "Content-Type": "application/json",
          'Authorization': window.localStorage.getItem('token')
      }
      const respuesta = await axios.get(URL + 'getRol', {
          headers: headers
      })

      if (respuesta.data.status === "Success") {

        dispatch({
            type: CAMBIO_PERMISOS,
            payload: respuesta.data.data
        })
      }

      if(respuesta.data.status==="Error"){
        dispatch({
    
            type: ERROR,
            payload: respuesta.data.message
            
        
        })

    }
   

  } catch (error) {


      if (error.message === "Request failed with status code 401") {
          window.localStorage.setItem('token', '')
          window.localStorage.setItem('userData', '')
      } else {
          dispatch({

              type: ERROR,
              payload: error.message


          })
      }
  }
}

export const consultar_permisos_pagos = () => async (dispatch) => {


    try {
  
  
  
      let headers = {
          "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL + 'getRolPayments', {
            headers: headers
        })
  
        if (respuesta.data.status === "Success") {
  
          dispatch({
              type: CAMBIO_PERMISOS_PAGOS,
              payload: respuesta.data.data
          })
        }
  
        if(respuesta.data.status==="Error"){
          dispatch({
      
              type: ERROR,
              payload: respuesta.data.message
              
          
          })
  
      }
     
  
    } catch (error) {
  
  
        if (error.message === "Request failed with status code 401") {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('userData', '')
        } else {
            dispatch({
  
                type: ERROR,
                payload: error.message
  
  
            })
        }
    }
  }
  




