import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as crearaction from "../../actions/CrearAction";
import Spinner from "../../General/Spinner";
import Fatal from "../../General/Fatal";
import cambiarFotoImg from "../../img/cambiarFotoImg.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles.scss";
import Success from "../../General/Success";
import { Redirect } from "react-router-dom";
import {URL} from '../../../src/General/url'
import DatePicker from 'react-date-picker';
import { RiArrowLeftRightLine } from "react-icons/ri";
import { IoMdDownload } from "react-icons/io";
import moment from "moment";


/* 
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { FaEye } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'



import { IoIosSave } from 'react-icons/io'
import { FaFileUpload } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md' */

export class AgregarInformacionModelo extends Component {


  state = {
    opacity:'30%',
    image:'',
    imageName:'',
    nombre:'',
    cantidad:0,
    fecha:new Date(),
    sedeName:'',
    sedeId:'',
    sedeBusquedaName:'',
    sedeBusquedaId:'',
    crearValue:false
  }

  componentDidMount() {
    const {

      consultar_lugares,

    } = this.props

    if(!this.props.fechaInicialPago){

      this.cambiofecha1(new Date())
  }
  if(!this.props.fechaFinalPago){

      this.cambiofecha2(new Date())
  }
    consultar_lugares()

  }

  componentDidUpdate() {
    console.log(this.state)
  
  }



  componentWillUnmount() {

  }

  handleSubmit(e) {
    e.preventDefault();
  }


  style={
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#eeee',
    cursor:'pointer'
  };


  handleChange(dataType, value) {
    this.props.handleChange(dataType, value)
  }

  cambioFotoPerfil = event => {
    const { guardarimagenGastos } = this.props;
    const files = event.target.files[0];
    if (files) {
      const data = new FormData();
      data.append("file0", files);
      guardarimagenGastos(data);
    }

  };

  cambioLugarId = (event) => {
    console.log('entrar',event.target.value)
    this.setState({sedeId:event.target.value})
  }



  cambioTurnoId = (event) => {
    this.props.cambioTurnoId(event.target.value)
  }


  MaysPrimera(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  enviarGastos = (event) =>{
   
    event.preventDefault()

    console.log('enviar')

  }
  
  consultarGastos=()=>{
    const { consultarGastos } = this.props;
    consultarGastos(  this.props.fechaInicialPago,
      this.props.fechaFinalPago,
      this.state.sedeId,)
  }

  listaLugares = () => {
    const { lugares } = this.props;

    const ponerOpcionesLugares = () =>
      lugares.map((lugar, key) => (
        <option className='option-values' key={key} value={lugar.id}>
          {this.MaysPrimera(lugar.nombre.toLowerCase())}{}
        </option>
      ));

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        onChange={this.cambioLugarId}
        required={true}
      >
        <option className='option-values' value='' >
          Lugar
        </option>
        {ponerOpcionesLugares()}
      </select>
    );
  };

  crearGasto =(e)=>{

    e.preventDefault()

    const { crearGasto } = this.props;

   const  data={
      nombre:this.state.nombre,
      cantidad:this.state.cantidad,
      img:this.props.img_gastos,
      sedeId:this.state.sedeId,
      fecha:this.state.fecha
    }

    crearGasto(data)
    
  }
  
    cambiofecha1 = date => {
      var fechaFinalString = ''
      if (date) {
          fechaFinalString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      } else {
          fechaFinalString = ''
      }
      this.props.cambiofecha1(fechaFinalString);
    };

    cambiofecha2 = date => {
     
        var fechaFinalString = ''
        if (date) {
            fechaFinalString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        } else {
            fechaFinalString = ''
        }
        this.props.cambiofecha2(fechaFinalString);
    };

  listaTurnos = () => {
    const { turnos } = this.props;

    const ponerOpcionesTurnos = () =>
      turnos.map((turnos, key) => (
        <option className='option-values' key={key} value={turnos.id}>
          {this.MaysPrimera(turnos.nombre.toLowerCase())}{}
        </option>
      ));

    return (
      <select
        className="input__div__personal__modificar__info input__modificiar_informacion_modelo"
        name="select"
        onChange={this.cambioTurnoId}
        required


      >
        <option className='option-values'>
          Turno
        </option>
        {ponerOpcionesTurnos()}
      </select>
    );
  };

  ponerInformacionModelo = () => {

    const ponerInfo = () => (
      <Fragment >
        <div className="col-md-4 mx-auto ">

          <img
            className="avatarModeloimgGastos"
            alt="imgagerfil"
            src={`${URL}getImage/${
              this.props.img_gastos
                ? this.props.img_gastos
                : "defaultLogo.png"
              }`}
          />
          <label className='foto_perfil_modelo_label' htmlFor="foto_perfil_modelo">
            <img src={cambiarFotoImg} alt="Click aquí para subir tu foto" title="Click aquí para subir tu foto" />
          </label>
          <input

            className="form-element-field -hasvalue"
            id={`foto_perfil_modelo`}
            type="file"
            onChange={this.cambioFotoPerfil}
          />

        </div>

        <div className="col-md-4 mx-auto">

          <form onSubmit={(e)=>{this.crearGasto(e)}}>
          <div className='informacion_personal_modificar_info_modelo'>
            <div className='div__personal__modificar__info'><div className='text__rigth'>Nombre   </div>   <input
              type="text"
              className='input__modificiar_informacion_modelo'
              value={this.props.nombre}
              required={true}
              onChange={(e) => this.setState({nombre:e.target.value})}
            /></div>

            <div className='div__personal__modificar__info'><div className='text__rigth'>Cantidad  </div>   <input
              type="number"
              className='input__modificiar_informacion_modelo'
              value={this.props.cantidad}
              required={true}
              onChange={(e) => this.setState({cantidad:e.target.value})}
            /></div>

            <div className='div__personal__modificar__info'><div className='text__rigth'>Fecha  </div>   <input
              type="date"
              className='input__modificiar_informacion_modelo'
              value={this.state.fecha}
              required={true}
              onChange={(e) => this.setState({fecha:e.target.value})}
            /></div >

        <div className='centrar_verticalmente div__personal__modificar__info__derecha'><div className='text__rigth'>Sede  </div> {this.listaLugares()}</div>

      
          </div>

          
          <button
            type='submit'
            className='btn__reportes_page'>
            Crear
            </button>

          </form>
        </div>

     

      </Fragment>
    )

    return (ponerInfo())
  }




  ponerInformacionFiltrar = () => {

    const ponerInfo = () => (
      <Fragment >


        <div className="col-md-4 mx-auto">

          <form onSubmit={(e)=>{this.enviarGastos(e)}}>
          <div className='informacion_personal_modificar_info_modelo'>


<div>


<DatePicker
    onChange={this.cambiofecha1}
    value={this.props.fechaInicialPago?new Date(this.props.fechaInicialPago):''}
/>
</div>
<div>
<DatePicker
    onChange={this.cambiofecha2}
    value={this.props.fechaFinalPago?new Date(this.props.fechaFinalPago):''}
/>

</div>

        <div className='centrar_verticalmente div__personal__modificar__info__derecha'><div className='text__rigth'>Sede  </div> {this.listaLugares()}</div>

      
          </div>

          
          <button
            type='submit'
            onClick={()=>this.consultarGastos()}
            className='btn__reportes_page'>
          
            Filtrar
            </button>

          </form>
        </div>

     

      </Fragment>
    )

    return (ponerInfo())
  }




  mostrarAccion = () => {
    const { error, cargando, success } = this.props;
    if (cargando) {
      return <Spinner />;
    }

    if (success) {
      return <Success mensaje={success} />;
    }

    if (error) {
      return <Fatal mensaje={error} />;
    }
    return false;
  };

  render() {

    return (
      <Fragment>
        <div className="main-content fade-in">
          {!window.localStorage.getItem("token") ? <Redirect to="/" /> : ""}
          <h1 className="tituloPaginas">
            PERFIL <strong className="tituloPaginasPequeño">GASTOS</strong>
          </h1>
          <div className='Paso__paso__div'>
       
            <h2>{this.state.crearValue?'Crear Gasto':'Filtral Gastos'}</h2>

            <div style={{...this.style}} onClick={this.state.crearValue?()=>this.setState({crearValue:false}):()=>this.setState({crearValue:true})} >  ir a {this.state.crearValue?'Filtral':'Crear'} <RiArrowLeftRightLine /></div>
      

          </div>

          <div className="reporte__div__principal">

            <div className="container-fluid">
              <div className="row">
                
                {this.state.crearValue?  this.ponerInformacionModelo():this.ponerInformacionFiltrar()
              }


              </div>



            </div>
          </div>
          {this.mostrarAccion()}
    
          <h1>{this.props.totalGastos?`Total: ${this.props.totalGastos}`:''}</h1>
          
          <div className="row">
         
          {this.props.gastos&&
              this.props.gastos.map((gastos, key) => (


               
                <div className="col-md-3 mx-auto " key={key}>
                
                <IoMdDownload size={25} style={{cursor:'pointer'}} onClick={()=>this.props.descargarImage(gastos.img)}/>
                <img
                 className="avatarModeloimgGastos"
                alt="imgagerfil"
                src={`${URL}getImage/${
                gastos.img
                  ? gastos.img
                  : "defaultLogo.png"
                }`}
                />

                <div style={{textAlign:'center'}}>Nombre: {gastos.nombre}</div>
                <div style={{textAlign:'center'}}>Cantidad: {gastos.cantidad}</div>
                <div style={{textAlign:'center'}}>Fecha: {new Date(gastos.fecha).toLocaleDateString() }</div>
                <div style={{textAlign:'center'}}>Usuario: {gastos.username}</div>
                  </div>
                           
                                ))}
       
           </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = reducers => {
  return reducers.crearReducer;
};

export default connect(mapStateToProps, crearaction)(AgregarInformacionModelo);
