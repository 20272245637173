import { CARGANDO, ERROR,GUARDAR,CONSULTAR,CAMBIO_FECHAPRESTAMO,CAMBIO_CANTIDAD,RESEARCHTRUE,CAMBIO_CONCEPTO
    ,SUCCESS,CAMBIO_FECHA1,CAMBIO_FECHA2,FILTRAR_PRESTAMOS_MINIMOS} from '../types/PrestamosTypes'

const INITIAL_STATE = {
    multas: [],
    vermultas:[],
    cargando: false,
    error: '',
    cantidad: 0,
    fechaprestamo: '',
    fecha1: '',
    fecha2: '',
    modelos_id:'',
    concepto:'',
    research:'',
    success:''

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTAR:
            return {
                ...state,
                multas: action.payload,
                cargando: false,
                research:false,
                cantidad: 0,
                fechaprestamo: '',
                concepto:'',
                
            }

            case FILTRAR_PRESTAMOS_MINIMOS:
                return {
                    ...state,
                    vermultas: action.payload,
                    cargando: false,
                    research:false,

                    
                }

            
          
        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false,success:'' }
            

            case SUCCESS:
                return { ...state, success: action.payload, cargando: false,error: '' }


        case GUARDAR:
            return {
                ...state,
                cargando: false,
                error: '',
                cantidad: 0,
                fechaprestamo: '',
                modelos_id:'',
                concepto:'',
                research:true,
                success:''
             

            }

            
            
        case CAMBIO_FECHAPRESTAMO:
            return {
                ...state,
                fechaprestamo: action.payload,

            }

            case CAMBIO_FECHA1:
                return {
                    ...state,
                    fecha1: action.payload,
    
                }

                case CAMBIO_FECHA2:
                    return {
                        ...state,
                        fecha2: action.payload,
        
                    }
        


        case CAMBIO_CANTIDAD:
            return {
                ...state,
                cantidad: action.payload,

            }

            case CAMBIO_CONCEPTO:
                return {
                    ...state,
                    concepto: action.payload,
    
                }

            case RESEARCHTRUE
            :
                return {
                    ...state,
                  
                    multas: [],
    vermultas:[],
    cargando: false,
    error: '',
    cantidad: 0,
    fechaprestamo: '',
    fecha1: '',
    fecha2: '',
    modelos_id:'',
    concepto:'',
    success:'',
    research:'',
                  
                  
    
                }


        default: return state
    }
  
}