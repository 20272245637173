import {
    CARGANDO, ERROR, CAMBIO_FECHAPRESTAMO, CAMBIO_HORARIOS, RESEARCHTRUE
    , SUCCESS, CAMBIO_FECHA1, FILTRAR_REPORTES_DIARIOS, CONSULTAR_HORARIOS,CONSULTAR_NOVEDADES,CREAR_NOVEDADES
} from '../types/ReportesDiarioStudioTypes'

const INITIAL_STATE = {
    ReporteDiario: [],
    Novedades:[],
    ListaHorarios: [],
    cargando: false,
    texto_novedades:'',
    error: '',
    horarios_id: '',
    fecha1: '',
    research: '',
    success: '',
    ResearNovedades:true
    

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {


        case FILTRAR_REPORTES_DIARIOS:
            return {
                ...state,
                ReporteDiario: action.payload,
                cargando: false,
                research: false,
                success: ''
                


            }



        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false, success: '' }


        case SUCCESS:
            return { ...state, success: action.payload, cargando: false, error: '' }




        case CONSULTAR_HORARIOS:
            return {
                ...state,
                ListaHorarios: action.payload,

            }


        case CAMBIO_FECHAPRESTAMO:
            return {
                ...state,
                fechaprestamo: action.payload,

            }

        case CAMBIO_FECHA1:
            return {
                ...state,
                fecha1: action.payload,

            }


            
            case CONSULTAR_NOVEDADES:
                return {
                    ...state,
                    Novedades: action.payload,
                    texto_novedades:'',
                    ResearNovedades:true,
                   
                   
    
                }
                case CREAR_NOVEDADES:
                    return {
                        ...state,
                        cargando: false,
                        error: '',
                        ResearNovedades:false,
                      
                       
                       
        
                    }
                
            case CAMBIO_HORARIOS:
                return {
                    ...state,
                    horarios_id: action.payload,
    
                }
        case RESEARCHTRUE
            :
            return {
                ...state,

                multas: [],
                vermultas: [],
                cargando: false,
                error: '',
                lugares_id: '',
                fecha1: '',
                success: '',
                research: '',



            }


        default: return state
    }

}