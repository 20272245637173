import {
    CARGANDO, ERROR, CONSULTAR, RESEARCHTRUE,
    CAMBIO_CORREO, CONSULTARS, SUCCESS, CAMBIO_PASSWORD_CORREO,CONSULTAR_CORREOS_MODELO
} from '../types/CorreosPlataformaTypes'

const INITIAL_STATE = {

    correosModelo: [],
    SuggestionDatabase: [],
    cargando: false,
    correo: '',
    passwordCorreo: '',
    error: '',
    research: '',
    success: '',
    researchEmailFlag:false

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTAR:
            return {
                ...state,
                researchEmailFlag:true

            }


        case CONSULTAR_CORREOS_MODELO:
            return {
                ...state,
                correosModelo: action.payload,
                cargando: false,
                research: false,
                researchEmailFlag:false

            }

        case CAMBIO_CORREO:
            return {
                ...state,
                correo: action.payload,


            }


        case CAMBIO_PASSWORD_CORREO:
            return {
                ...state,
                passwordCorreo: action.payload,


            }






        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false, success: '' }


        case SUCCESS:
            return { ...state, success: action.payload, cargando: false, error: '' }


        case CONSULTARS:
            return {
                ...state,
                SuggestionDatabase: action.payload,

            }


        case RESEARCHTRUE
            :
            return {
                ...state,

                multas: [],
                vermultas: [],
                cargando: false,
                error: '',
                success: '',
                research: '',



            }


        default: return state
    }

}