import axios from 'axios'
import { CARGANDO, ERROR,  GUARDAR, CONSULTAR,CAMBIO_CANTIDAD,RESEARCHTRUE,CAMBIO_CONCEPTO,
    SUCCESS,FILTRAR_PRESTAMOS_MINIMOS,CONSULTARS,RESEARPRESTAMO,ULTIMO_PAGO,CAMBIO_FECHAPRESTAMO} from '../types/PrestamoGrandeTypes'

    import {URL} from '../../src/General/url'




export const editar = (data,model_id) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateBigLoans/${model_id}`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
}
if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
        
 /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

 
}

export const cambiofechaprestamo  = (fecha_prestamo) => async(dispatch,) => {
    dispatch({

        type: CAMBIO_FECHAPRESTAMO,
        payload: fecha_prestamo
        
    
    })
    
};


export const editarPrestamoGrandeHostorial = (data,model_id) => async(dispatch)=>{
   
    
    dispatch({

        type: CARGANDO
        
    
    })

    try {
      
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.put(URL+`updateBigLoansHistory/${model_id}`, params,{
            headers: headers
        })
     
if(respuesta.data.status==="Success"){
    dispatch({

        type: SUCCESS,
        payload: respuesta.data.message
        
    
    })
}
if(respuesta.data.status==="Error"){
    dispatch({

        type: ERROR,
        payload: respuesta.data.message
        
    
    })

}
        
 /*    
        */
        
        
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

 
}

export const agregarPrestamoGrande = (data) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {

    

        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.post(URL+'createBigLoans', params,{
            headers: headers
        })
    
      
        dispatch({
            type: GUARDAR,
            payload:respuesta
        })


        if(respuesta.data.status==="success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

            dispatch({
        
                type: RESEARPRESTAMO,
                payload: false
                
            
            })

        }
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const consultarPrestamosGrandes = () => async(dispatch)=>{
    

    try {

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+'BigLoansReport' ,{
            headers: headers
        })
      
        dispatch({
            type: FILTRAR_PRESTAMOS_MINIMOS,
            payload:respuesta.data.data
        })
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        
        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}



export const eliminar = (id) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.delete(URL+`deleteModelBigLoans/${id}`,{
            headers: headers
        })
    
    
        if(respuesta.data.status==="Success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

            dispatch({
        
                type: RESEARPRESTAMO,
                payload: false
                
            
            })

        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
        
    } catch (error) {

        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}

export const eliminar_prestamo_historial = (id) => async(dispatch)=>{
    

    
    dispatch({

        type: CARGANDO
        
    
    })

    try {


        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.delete(URL+`deleteModelBigLoansHistory/${id}`,{
            headers: headers
        })
    
    
        if(respuesta.data.status==="Success"){
            dispatch({
        
                type: SUCCESS,
                payload: respuesta.data.message
                
            
            })

            dispatch({
        
                type: RESEARPRESTAMO,
                payload: false
                
            
            })

        }

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
        
    } catch (error) {

        if(error.message==="Request failed with status code 401"){
            window.localStorage.setItem('token','')
            window.localStorage.setItem('userData','')
        }else{
            dispatch({

                type: ERROR,
                payload: error.message
                
            
            })
        }
    }
}


export const consultar = (model_id) => async(dispatch)=>{
    


    try {

     
        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
        const respuesta = await axios.get(URL+`searchModelsBigloansReport/${model_id}`,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTAR,
            payload:respuesta.data.data,
        })

        dispatch({
            type: ULTIMO_PAGO,
            payload:respuesta.data.ultimo_pago,
        })

        
        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
    } catch (error) {
        
    dispatch({

        type: ERROR,
        payload: error.message
        
    
    })
    }
}

export const consultarSuggestions = (data) => async(dispatch)=>{
    


    try {

     
        let json = JSON.stringify(data)
        let params = json;

        

        let headers = {
            "Content-Type": "application/json",
            'Authorization': window.localStorage.getItem('token')
        }
    
        const respuesta = await axios.post(URL+`getSuggestionModels`, params,{
            headers: headers
        })
    
  
        dispatch({
            type: CONSULTARS,
            payload:respuesta.data.data,
        })

        if(respuesta.data.status==="Error"){
            dispatch({
        
                type: ERROR,
                payload: respuesta.data.message
                
            
            })

        }
        
    } catch (error) {
        dispatch({

            type: ERROR,
            payload: error.message
            
        
        })
    }
}


export const cambiocuota = (concepto_prestamo) => (dispatch)=>{

    dispatch({
        type: CAMBIO_CONCEPTO,
        payload: concepto_prestamo
        
    })


}


export const cambioprestamo = (cantidad_prestamo) => (dispatch)=>{
    dispatch({
        type: CAMBIO_CANTIDAD,
        payload: cantidad_prestamo
        
    })
}



export const researchtrue  = () => (dispatch,) => {
    dispatch({

        type: RESEARCHTRUE
        
    
    })


    

   
};

export const handleChange  = (index, dataType, value) => (dispatch,getState) => {

    const {verperstamos} = getState().PrestamoGrandeReducer


    const newPrestamoGrade = verperstamos.map((item, i) => {
        if (i === index) {
          return {...item, [dataType]: value};
        }
        return item;
      });
  

      dispatch({
        type: FILTRAR_PRESTAMOS_MINIMOS,
        payload:newPrestamoGrade
    })


    

   
};


export const handleChangePrestamoHistorial  = (index, dataType, value) => (dispatch,getState) => {

    const {prestamos} = getState().PrestamoGrandeReducer


    const newPrestamoGradeHistorial = prestamos.map((item, i) => {
        if (i === index) {
          return {...item, [dataType]: value};
        }
        return item;
      });
  

      dispatch({
        type: CONSULTAR,
        payload:newPrestamoGradeHistorial
    })


    

   
};

