import {
    CARGANDO, ERROR, CONSULTAR, RESEARCHTRUE
    , SUCCESS, CAMBIO_FECHA1, CAMBIO_FECHA2, CONSULTAR_SUMA_TOTAL,CONSULTAR_ELIMINADAS
} from '../types/MultaTotalType'

const INITIAL_STATE = {
    MultasTotal: [],
    MultasTotalSuma: [],
    MultasTotalEliminadas: [],
    cargando: false,
    error: '',
    fecha1: '',
    fecha2: '',
    research: '',
    success: ''

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONSULTAR:
            return {
                ...state,
                MultasTotal: action.payload,
                cargando: false,
                research: false,

            }
            case CONSULTAR_SUMA_TOTAL:
                return {
                    ...state,
                    MultasTotalSuma: action.payload,
                    cargando: false,
                    research: false,
    
                }
                case CONSULTAR_ELIMINADAS:
                    return {
                        ...state,
                        MultasTotalEliminadas: action.payload,
                        cargando: false,
                        research: false,
        
                    }




        case CARGANDO:
            return { ...state, cargando: true }


        case ERROR:
            return { ...state, error: action.payload, cargando: false, success: '' }


        case SUCCESS:
            return { ...state, success: action.payload, cargando: false, error: '' }


        case CAMBIO_FECHA1:
            return {
                ...state,
                fecha1: action.payload,

            }

        case CAMBIO_FECHA2:
            return {
                ...state,
                fecha2: action.payload,

            }

        case RESEARCHTRUE
            :
            return {
                ...state,
                cargando: false,
                error: '',
                fecha1: '',
                fecha2: '',
                success: '',
                research: '',



            }


        default: return state
    }

}